import React, { FC, useEffect, useState } from "react";
import { Course, Student } from "../../../../models/student.model";
import { PRACTICE_CHAPTERS } from "../../../../routes/routeConstants/appRoutes";
import AuthService from "../../../../services/AuthService/auth.service";
import { LearnService } from "../../../../services/LearnService/learn.service";
import { StudentService } from "../../../../services/StudentService/student.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import ListSubjects from "../../LearnModule/Learn/ListSubjects";
import ListExams from "./ListExams";
import ScoreIcon from "../../../../assets/icon/reward.svg";
import AccuracyIcon from "../../../../assets/icon/dart.svg";
import SpeedIcon from "../../../../assets/icon/sketch.svg";
import PlaceholderIcon from "../../../../assets/images/placeholder-dark.svg";
import InfoIcon from "../../../../assets/icon/info-white.svg";
import "./practiceModule.scss";
import MetricsDescription from "../../../../shared/components/MetricsDescription";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import { StudentClassResult } from "../../../../models/Analytics/class.model";

let PractiCard = ({ appName, ...rest }) => {
  try {
    return <div style={{ background: `url(${require(`../../../../assets/${appName}/carousel-bg.svg`)}) no-repeat` }}  {...rest} />;
  }
  catch (err) {
    return <div style={{ background: `url(${require('../../../../assets/images/carousel-bg.svg')}) no-repeat` }} {...rest} />;
  }
}

interface PracticeDashboardProps extends ITitleState {
  setTitle: ({ ...data }: ITitleState) => void;
  type: string;
  // currentProfile: any;
  user: Student;
  currentCourse: Course;
  app_name: string
}

const PracticeDashboard: FC<PracticeDashboardProps> = ({
  setTitle,
  type,
  user,
  currentCourse,
  app_name
}) => {
  const [courses, setCourses] = useState<Course[]>();
  const [showMetrics, setMetricsVisibility] = useState(false);
  const [analytics, setAnalytics] = useState<StudentClassResult>();
  useEffect(() => {
    setTitle({
      title: "Practice",
      subText: "You are doing , Keep it up!",
      showBack: false,
      searchText: "Search subjects, topics ...",
      twoBgs: false,
      float: false,
      showSearch: true
    });
    fetchAnalytics();
    LearnService.getStudentCourses(
      (response) => {
        setCourses(response);
      },
      () => {}
    );
  }, []);

  const fetchAnalytics = () => {
    if (type == "exam") {
      AnalyticsService.fetchExamAnalytics(
        currentCourse.id,
        (result) => {
          setAnalytics(result);
        },
        () => {}
      );
    } else if (type == "class_room") {
      AnalyticsService.fetchClassAnalytics(
        currentCourse.id,
        (result) => {
          setAnalytics(result);
        },
        () => {}
      );
    }
  };
  return (
    <div className="practice__container">
      <MetricsDescription
        visible={showMetrics}
        closeHandler={() => setMetricsVisibility(false)}
      />
      <PractiCard appName={app_name} className="practice-card__container">
        <div className="content__left">
          <div className="profile-image__container">
            <img src={user?.displayPictureUrl ?? PlaceholderIcon} />
          </div>
          <div className="profile-details__container">
            <p className="profile__name">{user?.firstName}</p>
            <p className="profile__class">
              {currentCourse?.course?.classRoom?.name ||
                currentCourse?.course?.exam?.name}
            </p>
          </div>
        </div>
        <div className="content__right">
          <div className="profile__measure">
            <div className="profile-measure__icon">
              <img src={ScoreIcon} />
            </div>
            <div className="measure__wrapper">
              <p className="measure__value">{analytics?.score ? (analytics?.score)?.toFixed(1) : "0"} %</p>
              <p className="measure__desc">Avg Score</p>
            </div>
          </div>
          <div className="profile__measure">
            <div className="profile-measure__icon">
              <img src={AccuracyIcon} />
            </div>
            <div className="measure__wrapper">
            <p className="measure__value">{(analytics?.accuracy) ? (analytics?.accuracy)?.toFixed(1) : "0"} %</p>
              <p className="measure__desc">Accuracy</p>
            </div>
          </div>
          <div className="profile__measure">
            <div className="profile-measure__icon">
              <img src={SpeedIcon} />
            </div>
            <div className="measure__wrapper">
              <p className="measure__value">
                {(analytics?.averageTimePerQuestion !== undefined) ? (analytics?.averageTimePerQuestion)?.toFixed(1) : "0"} sec/ques
              </p>
              <p className="measure__desc">Speed</p>
            </div>
          </div>
        </div>
        <div className="info__icon" onClick={() => setMetricsVisibility(true)}>
          <img src={InfoIcon} />
        </div>
      </PractiCard>
      <ListSubjects title="Practice" url={PRACTICE_CHAPTERS} />
      {type == "exam" && (
        <ListExams
          courses={courses?.filter(
            (data) => data.isActive && data.course?.type == "exam"
          )}
        />
      )}
    </div>
  );
};

export default AuthContainer(TitleContainer(PracticeDashboard));
