import React, { FC } from "react";

import PopupModal from "../../../../shared/components/PopupModal";
import { Products } from "../../../../models/ProductModel/products.model";

import "./productDetails.scss";
import PointsIcon from "../../../../assets/icon/points.svg";
interface ProductDetailsProps {
  visible: boolean;
  okHandler: () => void;
  closeHandler: () => void;
  content: Products;
}

const ProductDetails: FC<ProductDetailsProps> = (props) => {
  const { visible, content, okHandler, closeHandler } = props;

  return (
    <PopupModal
      visiblity={visible}
      okHandler={okHandler}
      closeHandler={closeHandler}
      className="product-details"
    >
      <div className="product-details__container">
        <div className="product-details__header">
          <img src={PointsIcon} />
          <div className="product-details-header__content">
            <p className="product-details-header-content__title">
              {content.productName}
            </p>
            {/* <p className="product-details-header-content__desc">
              you earn points for the following actions
            </p> */}
          </div>
        </div>
        <div className="product-details__body">
          <div className="product-details-point__container">
            <p className="product-details__point">Board :</p>
            <p className="product-details-point__desc">
               {content?.boardName}
            </p>
          </div>
          <div className="product-details-point__container">
            <p className="product-details__point">Class :</p>
            <p className="product-details-point__desc">
               {content?.className}
            </p>
          </div>
          <div className="product-details-point__container">
            <p className="product-details__point">Subjects :</p>
            <p className="product-details-point__desc">
               {content?.subjectNames?.toString()}
            </p>
          </div>
          <div className="product-details-point__container">
            <p className="product-details__point">Price :</p>
            <p className="product-details-point__desc">
               {content?.price}
            </p>
          </div>
          <div className="product-details-point__container">
            <p className="product-details__point">Duration :</p>
            <p className="product-details-point__desc">
               {content?.duration}
            </p>
          </div>

          <div className="product-details-point__container">
            {/* <p className="product-details__point">+</p> */}
            <p className="product-details-point__desc">
              {content?.description}
            </p>
          </div>

        </div>
      </div>
    </PopupModal>
  );
};

export default ProductDetails;
