import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/_axiosInstance";
import { WorksheetModel } from "../../models/Worksheet/worksheet.model";
import { PaginationModel } from "../../models/pagination.model";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";

export default class WorksheetService {
  static fetchWorksheets(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.WORKSHEET_LIST, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data["studentWorksheet"]) as WorksheetModel[];
        const pageMeta = deserialize(PaginationModel, response.data["meta"]);
        onSuccess(data, pageMeta);

      })
      .catch((error) => {
        onError();
        console.log(error);

      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchWorksheetInfo(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.WORKSHEET_INFO, { params: queryParams })
      .then((response) => {
        const data = deserialize(WorksheetModel, response.data) as WorksheetModel;
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }


  static submitWorkSheet(
    formData: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.SUBMIT_WORKSHEET, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((response) => {
        const data = deserialize(WorksheetModel, response.data) as WorksheetModel;
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
