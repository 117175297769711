import { Col, Dropdown, Input, Menu, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Course, Student } from "../../../../models/student.model";
import { LearnService } from "../../../../services/LearnService/learn.service";
import EditIcon from "../../../../assets/icon/SVG/edit-white.svg";
import PhoneIcon from "../../../../assets/icon/acct-phone.svg";
import MailIcon from "../../../../assets/icon/acct-mail.svg";
import "./listCourses.scss";
import { EllipsisOutlined } from "@ant-design/icons";
import { RouteComponentProps, withRouter } from "react-router";
import { ADD_COURSE } from "../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../store/container/AuthContainer";
import AuthService from "../../../../services/AuthService/auth.service";
import ContentPlaceholder from "../../../../assets/images/content-placeholder.svg";
import { serialize } from "serializr";
const ListCourses: FC = (props: any) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [exams, setExams] = useState<Course[]>([]);
  const [showEditMobile, setEditMobileVisibility] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const fetchStudentCourses = () => {
    LearnService.getStudentCourses(
      (response: Course[]) => {
        setCourses(response);
      },
      () => {}
    );
  };
  useEffect(() => {
    fetchStudentCourses();
  }, []);

  useEffect(() => {
    setPhoneNumber(props?.user?.phoneNumber);
  }, [props?.user]);

  const onUpdate = () => {
    const payload = {
      student: serialize(Student, { phoneNumber: phoneNumber }),
    };
    AuthService.updateStudent(
      payload,
      () => {
        setEditMobileVisibility(false);
      },
      () => {}
    );
  };

  return (
    <Row>
      <Col span={24}>
        <div>
          <div className="course-heading__wrapper">
            <p className="course__heading">Manage Courses</p>
            {/* <p
              className="subscribe-new__exam"
              onClick={() => {
                props.history.push(ADD_COURSE, {
                  type: "exam",
                });
              }}
            >
              + Subscribe New Exam
            </p> */}
          </div>
          <Row className="list-courses__wrapper" gutter={16}>
            {courses.map((value, index) => {
              return (
                <Col span={12}>
                  <div className="list-courses__container">
                    <div className="list-courses__dp">
                      <img
                        src={
                          value?.course?.classRoom?.displayPictureUrl ||
                          value?.course?.exam?.displayPictureUrl ||
                          ContentPlaceholder
                        }
                      />
                    </div>
                    <div className="list-courses__details">
                      <p className="list-courses-details__acronym">
                        {value.course?.exam?.acronym ||
                          value.course?.classRoom?.boardAcronym}
                      </p>
                      <p className="list-courses-details__name">
                        {value.course?.exam?.name ||
                          value.course?.classRoom?.name}
                      </p>
                    </div>
                    {/* <div className="list-courses__menu">
                      <Dropdown
                        overlay={
                          <Menu className="list-courses-edit__menu">
                            <Menu.Item className="list-courses-edit__item">
                              <p>
                                <span>
                                  <img className="edit__icon" src={EditIcon} />
                                </span>
                                Edit Course
                              </p>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <EllipsisOutlined rotate={90} />
                      </Dropdown>
                    </div> */}
                  </div>
                </Col>
              );
            })}
            {/* {exams.map((value, index) => {
              return (
                <Col span={12}>
                  <div className="list-courses__container">
                    <div className="list-courses__dp">
                      <img
                        src={
                           value.course?.exam?.displayPictureUrl || ContentPlaceholder
                        }
                      />
                    </div>
                    <div className="list-courses__details">
                      <p className="list-courses-details__acronym">
                        {value.course?.exam?.acronym ||
                          value.course?.classRoom?.boardAcronym}
                      </p>
                      <p className="list-courses-details__name">
                        {value.course?.exam?.name ||
                          value.course?.classRoom?.name}
                      </p>
                    </div> */}
            {/* <div className="list-courses__menu">
                      <Dropdown
                        overlay={
                          <Menu className="list-courses-edit__menu">
                            <Menu.Item className="list-courses-edit__item">
                              <p>
                                <span>
                                  <img className="edit__icon" src={EditIcon} />
                                </span>
                                Edit Course
                              </p>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <EllipsisOutlined rotate={90} />
                      </Dropdown>
                    </div> */}
            {/* </div>
                </Col>
              );
            })} */}
            {/* <Col span={6}>
            <div
              className="list-courses-add__course"
              onClick={() => props.history.push(ADD_COURSE)}
            >
              <p>+ Subscribe New Subject</p>
            </div>
          </Col> */}
          </Row>
        </div>
      </Col>
      {/* <Col span={24}>
        <div>
          <p className="course__heading">Exam</p>

          <Row className="list-courses__wrapper" gutter={16}>
            {exams.map((value, index) => {
              return (
                <Col span={6}>
                  <div className="list-courses__container">
                    <div className="list-courses__dp">
                      <img
                        src={
                          value.course?.exam?.displayPictureUrl ||
                          value.course?.classRoom?.displayPictureUrl
                        }
                      />
                    </div>
                    <div className="list-courses__details">
                      <p className="list-courses-details__acronym">
                        {value.course?.exam?.acronym ||
                          value.course?.classRoom?.boardAcronym}
                      </p>
                      <p className="list-courses-details__name">
                        {value.course?.exam?.name ||
                          value.course?.classRoom?.name}
                      </p>
                    </div>
                    <div className="list-courses__menu">
                      <Dropdown
                        overlay={
                          <Menu className="list-courses-edit__menu">
                            <Menu.Item className="list-courses-edit__item">
                              <p>
                                <span>
                                  <img className="edit__icon" src={EditIcon} />
                                </span>
                                Edit Course
                              </p>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <EllipsisOutlined rotate={90} />
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              );
            })}
            <Col span={6}>
              <div
                className="list-courses-add__course"
                onClick={() =>
                  props.history.push(ADD_COURSE, {
                    type: "exam",
                  })
                }
              >
                <p>+ Subscribe New Exam</p>
              </div>
            </Col>
          </Row>
        </div>
      </Col> */}
      <Col span={24}>
        <div>
          <p className="my-account__heading">My Account</p>
          <Row>
            <Col span={12}>
              <div className="my-account__container">
                <div className="my-account-details__wrapper">
                  <div className="my-account-details__image">
                    <img src={MailIcon} />
                  </div>
                  <p className="my-account__details">{props?.user?.email}</p>
                </div>
                <div className="my-account-phone__container">
                  <div className="my-account-image__wrapper">
                    <div className="my-account-details__wrapper">
                      <div className="my-account-details__image">
                        <img src={PhoneIcon} />
                      </div>
                      {showEditMobile ? (
                        <Input
                          defaultValue={props?.user?.phoneNumber}
                          placeholder="Phone Number"
                          className="edit__email"
                          type="number"
                          onBlur={() => onUpdate()}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      ) : (
                        <p className="my-account__details">
                          {props?.user?.phoneNumber}
                        </p>
                      )}
                    </div>
                    {!showEditMobile && (
                      <p
                        className="phone__change"
                        onClick={() => setEditMobileVisibility(true)}
                      >
                        Change
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(AuthContainer(ListCourses));
