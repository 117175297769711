import React, { FC, useEffect, useState } from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { LearnService } from "../../../../services/LearnService/learn.service";
import { ExamService } from "../../../../services/ExamService/exam.service";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import SubjectsAnalyticsCards from "../SubjectsAnalyticsCards";
import {
  Chapter,
  Chapter as ChapterModel,
} from "../../../../models/Chapters/chapter.model";
import { LIST_ANALYTICS_EXERCISES } from "../../../../routes/routeConstants/appRoutes";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import AnalyticsContainer from "../../../../store/container/AnalyticsContainer";
import { Course } from "../../../../models/student.model";
import { StudentChapterResult } from "../../../../models/Analytics/chapter.model";
import { StudentClassResult } from "../../../../models/Analytics/class.model";
import {
  SearchOutlined,
  RightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import AnalyticsComponent from "../AnalyticsComponent";
import { Badge, Col, Input, Progress, Row, Spin } from "antd";

import TitleContainer from "../../../../store/container/TitleContainer";
import "./listChapterAnalytics.scss";
import { AnalyticsEmptyScreen } from "../EmptyScreen";


interface ListChapterAnalyticsProps extends ITitleState {
  setTitle: Function;
  type: string;
  currentCourse: Course;
  courseAnalytics: StudentClassResult;
  setAnalyticsData: (result: StudentClassResult) => void;
}
const ListChapterAnalytics: FC<ListChapterAnalyticsProps> = ({
  type,
  setTitle,
  courseAnalytics,
  currentCourse,
  setAnalyticsData
}) => {
  const { state }: { state: any } = useLocation();
  const history = useHistory();
  const params = useParams();

  const [chapters, setChapters] = useState<StudentChapterResult[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<StudentChapterResult>();

  const [showSearch, setSearchVisibility] = useState(false);
  const [studentChapterResult, setStudentChapterResult] = useState<StudentChapterResult>();

  
  useEffect(() => {
    setTitle({
      title: state?.subjectName,
      color: "#FFF",
      showBack: true,
      float: false,
      showSearch: false,
      twoBgs: true,
    });
    fetchChapters({});
  }, []);

  useEffect(() => {
    //console.log(state);
  }, []);
  const fetchChapters = (params: any) => {

    if (type == "exam") {
      ExamService.getExamSubjectChapters(
        params,
        {
          subjectId: state?.subjectId,
          courseId: currentCourse?.id,
        },
        (response: ChapterModel[]) => {
          setChapters(response);
          if (response?.length) {
            setSelectedChapter(response[0]);
            fetchChapterAnalytics(response[0]);
          }
        },
        () => { }
      )
    } else {
      // LearnService.getChapters(
      //   params,
      //   {
      //     subjectId: state?.subjectId,
      //     courseId: currentCourse?.id,
      //   },
      //   (response: ChapterModel[]) => {
      //     setChapters(response);
      //     if (response?.length) {
      //       setSelectedChapter(response[0]);
      //       fetchChapterAnalytics(response[0]);
      //     }
      //     //console.log(response);
      //   }
      // );

      if (courseAnalytics?.studentSubjects === undefined) {
        AnalyticsService.fetchClassAnalytics(
          currentCourse.id,
          (result) => {
            setAnalyticsData(result)
            setChaptersData(result, params)
          },
          () => { }
        );
      } else {
        setChaptersData(courseAnalytics, params)
      }
    }
  };

  const setChaptersData = (ChaptersData: any, params: any) => {
    let chapterList = ChaptersData?.studentSubjects?.find(element => element.subjectId == state?.subjectId)?.studentChapterResults || []
    if (params?.search?.toUpperCase() !== undefined) {
      chapterList = chapterList?.filter(element => element.name?.toUpperCase()?.includes(params?.search?.toUpperCase()))
    }
    setChapters(chapterList);
    if(chapterList.length){
      setSelectedChapter(chapterList[0]);
      fetchChapterAnalytics(chapterList[0]);
    }
    
  }


  const fetchChapterAnalytics = (chapter: any) => {
    if (type == "exam") {
      AnalyticsService.fetchExamChapterAnalytics(
        currentCourse?.id,
        chapter?.id,
        (response) => {
          //console.log(response);
          setStudentChapterResult(response);
        },
        () => { }
      )
    } else {
      //   AnalyticsService.fetchChapterAnalytics(
      //     currentCourse?.id,
      //     chapterId,
      //     (response) => {
      //       //console.log(response);
      //       setStudentChapterResult(response);
      //     },
      //     () => { }
      //   );
      setStudentChapterResult(chapter);
    }
  };


  return (
    <Row className="list__chapters">
      {chapters.length == 0 && !showSearch ? (
        <Spin size="small" />
      ) : (
        <>
          <Col span={12} style={{ height: "100%" }}>
            <div className="content__left">
              {showSearch ? (
                <div className="content-left__search">
                  <Input
                    className="content-left-search__input"
                    placeholder={"Search"}
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      fetchChapters({ search: e.target.value });
                    }}
                  />
                  <CloseOutlined
                    className="content-left-search__icon"
                    onClick={() => {
                      setSearchVisibility(false)
                      fetchChapters({});
                    }}
                  />
                </div>
              ) : (
                <div className="content-left__header">
                  <p>Chapters</p>
                  <div onClick={() => setSearchVisibility(true)}>
                    <SearchOutlined />
                  </div>
                </div>
              )}
              {chapters?.map((value, index) => {
                return (
                  <div
                    key={'list__chapters#' + index}
                    className={`subject__card ${selectedChapter?.id == value.id && "subject-card__active"
                      }`}
                    onClick={() => {
                      setSelectedChapter(value);
                      fetchChapterAnalytics(value);
                    }}
                  >
                    <p className="subject-card__name">{value?.name}</p>
                    <div className="subjects-details__container">
                      <p>
                        {value?.exercisesCount || value?.examExercisesCount}{" "}
                        Exercises
                      </p>
                      <Badge status="default" className="dull-text" />
                      <div className="progress__wrapper">
                        <Progress showInfo={false} />
                        <p>
                          {(value?.cummulativeCompletionPercentage !==
                            undefined &&
                            value?.cummulativeCompletionPercentage !== null &&
                            value?.cummulativeCompletionPercentage > 0 &&
                            Math.ceil(
                              value?.cummulativeCompletionPercentage
                            )) ||
                            0}
                          % Completed
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col span={12} style={{ height: "100%" }}>
            {chapters.length !== 0 &&           
            <div className="content__right">
              <div className="content-right__header">
                <p>{selectedChapter?.name}</p>
                <div
                  className="content-right__btn"
                  onClick={() => {
                    history.push(
                      generatePath(LIST_ANALYTICS_EXERCISES, {
                        ...params,
                        chapterId: selectedChapter?.id,
                      }),
                      {
                        ...state,
                        chapterId: selectedChapter?.id,
                        chapterName: selectedChapter?.name,
                      }
                    );
                  }}
                >
                  View by exercise{" "}
                  <span>
                    <RightOutlined />
                  </span>
                </div>
              </div>
              <AnalyticsComponent
                showAttempts={false}
                accuracy={studentChapterResult?.accuracy}
                contentLearnt={studentChapterResult?.completedContentCount || 0}
                totalContent={studentChapterResult?.totalContentCount || 0}
                exerciseAttempted={
                  studentChapterResult?.numberOfExerciseAttempts || 0
                }
                totalExercise={studentChapterResult?.totalExerciseCount || 0}
                speed={studentChapterResult?.averageTimePerQuestion}
                score={studentChapterResult?.score}
                duration={studentChapterResult?.exerciseAttemptDuration}
                noOfExerciseAttempted={
                  studentChapterResult?.numberOfExerciseAttempts || 0
                }
              />
            </div>
            } 
          </Col>
        </>
      )}
    </Row>
  );
};
export default AuthContainer(TitleContainer(AnalyticsContainer(ListChapterAnalytics)));
