import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/_axiosInstance";
import { Leaderboard } from "../../models/Leaderboard/leaderboard.model";
import { LEADERBOARD } from "../../routes/routeConstants/apiRoutes";

export class LeaderboardService {
  static fetchLeaderboardData(
    courseId: number,
    onSuccess: (value: Leaderboard) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(LEADERBOARD,{params:{
        CourseId:courseId
      }}) //{ courseId }
      .then((response) => {
        const data = deserialize(Leaderboard, response["data"]?.["studentLeaderboard"]);       
        onSuccess(data);
      })
      .catch(e => {
          onError(e)
      })
      .finally(onFinal)
  }
}
