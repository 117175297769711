import * as Yup from "yup";

export const updatePasswordValidation = Yup.object().shape({
  password: Yup.string()
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
    "Password should be alphanumeric and contain minimum 8 characters!"
  )
  .required("Password is required!")
  .nullable(),
confirmPassword: Yup.string()
  .oneOf([Yup.ref("password")], "Both passwords need to be the same!")
  .required("Password is required!")
  .nullable(),
});
