import React, { useEffect } from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { Course } from "../../../../../../models/student.model";
import "./examCards.scss"
import * as appRoutes from "../../../../../../routes/routeConstants/appRoutes"
import ContentPlaceholder from "../../../../../../assets/images/content-placeholder.svg"

interface ExamCardsProps extends RouteComponentProps {
  courses: Course[];
}
const ExamCards = ({ courses, history }: ExamCardsProps) => {

    useEffect(() => {
        //console.log(courses)
    }, [])

  return (
    <div className="exam-cards__wrapper">
      {courses?.map((value, index) => {
        return (
          <div className="exam-cards__item"
          onClick={() => {
          
            history.push({
                pathname: generatePath(appRoutes.PRACTICE_EXAMS, {examName: value.course?.exam?.acronym, examId: value.course?.exam?.id}),
                state: {examName: value.course?.exam?.acronym, examId: value.course?.exam?.id}
            })              
          }}
          >
            
            <img src={value.course?.exam?.displayPictureUrl ?? ContentPlaceholder} />
            <div>
                <p className="exam__acronym">{value.course?.exam?.acronym}</p>
                <p className="exam__name">{value.course?.exam?.name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default withRouter(ExamCards);