import { serializable, alias,list, primitive,object } from 'serializr';

export class CartModel {
    @serializable(alias('cartItemId', primitive()))
    cartItemId?: string;
    @serializable(alias('productId', primitive()))
    productId?: string;
    @serializable(alias('productName', primitive()))
    productName?: string;
    @serializable(alias('amount', primitive()))
    amount?: string;
    @serializable(alias('totalAmount', primitive()))
    totalAmount?: string;
    @serializable(alias('thumbnail', primitive()))
    thumbnail?: string;
}


export class CartsModel {
    @serializable(alias('cartId', primitive()))
    cartId?: string;
    @serializable(alias('studentId', primitive()))
    studentId?: string;
    @serializable(alias("cartList", list(object(CartModel))))
    cartList?: CartModel[] = [];
}


