import React, { FC, useRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { deserialize } from "serializr"

import { Badge, Col, Row, Skeleton } from "antd";
import { Field, Form, Formik } from "formik";
import EmptyState from "../../../shared/components/EmptyState";
import ProductCard from "./ProductCard";
import PaginationComponent from "../../../shared/components/PaginationComponent";
import DropdownField from "../../../shared/components/DropdownField";
import Filters from "../../../shared/components/Filters";
import ProductDetails from "./ProductDetails";
import filterIcon from "../../../assets/icon/filter.svg";

import TitleContainer from "../../../store/container/TitleContainer";


import { Course, Student } from "../../../models/student.model";
import { Products, FilterProducts } from "../../../models/ProductModel/products.model";
import { MetaModel } from "../../../models/meta.model";

import ProductService from "../../../services/ProductService/products.service";
import CartService from "../../../services/CartService/cart.service";
import { MetaService } from "../../../services/MetaService/meta.service";

import { CART } from "../../../routes/routeConstants/appRoutes";

import "./product.scss";
import Heading from "../../../shared/components/Heading";







export const SkeletonLoader = (): any => {
    return Array(2)
        .fill(" ")
        .map((value, idex) => {
            return (
                <div key={"SKL" + idex} className="skeleton-loader">
                    <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
                </div>
            );
        });
};

interface ProductsProps {
    setTitle: Function
}



const ProductsLibrary: FC<ProductsProps> = ({ setTitle }) => {
    const history = useHistory();
    const { state }: { state: any } = useLocation();

    const [products, setProductList] = useState<Products[]>([]);
    const [currentProduct, setCurrentProduct] = useState<any>("");
    const [paginationMeta, setPaginationMeta] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const defaultFilter = { CurrentPage: 1, Pagesize: 10 }
    const formRef = useRef<any>();
    const [filters, setFilters] = useState<FilterProducts>(defaultFilter);
    const [showFilter, setShowFilter] = useState<boolean>(false);

    const [boards, setBoards] = useState<MetaModel[]>([]);
    const [classRooms, setClassRooms] = useState<MetaModel[]>([]);


    const fetchProductList = (queryParams: FilterProducts) => {
        setLoading(true);
        ProductService.getProductList(
            queryParams,
            (contentLibrary: any, pageMeta: any) => {
                setProductList(contentLibrary);
                setPaginationMeta(pageMeta);
                setLoading(false);
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    const fetchClassRooms = (BoardId: number | string) => {
        MetaService.fetchClassRoomsMeta(
            BoardId,
            (classRooms) => {
                if (classRooms.length) {
                    setClassRooms(classRooms);
                    if (state) {
                        setFilters({ ...filters, BoardId: state.BoardId, ClassId: state.ClassRoomId })
                        fetchProductList({ ...filters, BoardId: state.BoardId, ClassId: state.ClassRoomId });
                    } else {
                        setFilters({ ...filters, BoardId })
                        fetchProductList({ ...filters, BoardId, ClassId: 0 });
                    }
                } else {
                    fetchProductList(filters);
                }
            },
            () => { },
            () => { }
        );
    };

    const fetchBoards = () => {
        setLoading(true);
        MetaService.fetchBoardsMeta(
            (boardData) => {
                if (boardData.length > 0 && boardData[0].value !== undefined) {
                    setBoards(boardData);
                    fetchClassRooms(boardData[0].value)
                }
            },
            () => { },
            () => { }
        );
    };


    useEffect(() => {
        setTitle({
            title: `hide`,
            showBack: false,
            subText: "",
            icon: null,
            showSearch: false,
            float: false,
            searchText: "Search subjects, topics ...",
            twoBgs: false,
            filterVisibility: false,
            filterCount: 1
        });
        fetchBoards()
    }, []);


    const handlePageChange = (page: number, pageSize: any) => {
        fetchProductList({ ...filters, CurrentPage: page, Pagesize: pageSize });
        setFilters({ ...filters, CurrentPage: page, Pagesize: pageSize });
    };

    const handleBuyProduct = (record: any) => {
        setLoading(true);
        CartService.addToCart(
            record,
            (data) => {
                setLoading(false);
                history.push(CART)
            },
            () => { setLoading(false) },
            () => { }
        );

    }


    return (
        <>
            <div className="page-header">
                <div className={`heading ml-5`}>
                    <Heading >{"Courses" || null}</Heading>
                    <div className="cust-filter">
                        {/* <Badge count={5}> */}
                        <img
                            src={filterIcon}
                            onClick={() => {
                                setShowFilter(true)
                            }} />
                        {/* </Badge> */}
                    </div>

                </div>
            </div>


            <div className="product__container">

                {/* Product Details */}
                <ProductDetails
                    visible={showInfo}
                    content={currentProduct}
                    closeHandler={() => {
                        setCurrentProduct({})
                        setShowInfo(false)
                    }
                    }
                    okHandler={() => {
                        setCurrentProduct({})
                        setShowInfo(false)
                    }}
                />

                {loading || products?.length ? (
                    <div className="activity-class-activity-now">
                        {loading ? (
                            <SkeletonLoader />
                        ) : (
                            <>

                                <Row gutter={[16, 24]}>
                                    {
                                        products?.map((record, index) => {
                                            return (
                                                <Col span={8} key={record.productId}>
                                                    <ProductCard
                                                        data={record}
                                                        isLive
                                                        onBuy={() => handleBuyProduct(record)}
                                                        onDetails={() => {
                                                            setCurrentProduct(record)
                                                            setShowInfo(true)
                                                        }
                                                        }
                                                    />
                                                </Col>
                                            );
                                        })
                                    }

                                </Row>
                                <div className="pagination__footer">
                                    <PaginationComponent
                                        handlePageChange={handlePageChange}
                                        paginationMeta={paginationMeta}
                                    />
                                </div>
                            </>

                        )}
                    </div>
                ) : (
                    <EmptyState text={"No Course found."} />
                )}




                <Formik
                    initialValues={filters}
                    onSubmit={() => { }}
                    enableReinitialize
                    innerRef={formRef}
                >
                    {({ values, setFieldValue, resetForm }) => (
                        <Filters
                            expand={showFilter}
                            handleApply={() => {
                                fetchProductList(values)
                                setShowFilter(false)
                            }}
                            handleReset={() => {
                                resetForm({});
                                setShowFilter(false)
                                fetchBoards()
                                //setFilters(defaultFilter)
                                //fetchWorksheetList(defaultFilter);
                            }}
                            closeHandler={() => setShowFilter(false)}
                        >
                            <Form>
                                <div className="filter-form__input">
                                    <div className="filter-form__label">Board</div>
                                    <DropdownField
                                        name="BoardId"
                                        placeholder="Select"
                                        value={values?.BoardId}
                                        options={boards}
                                        showSearch={false}
                                        optionFilterProp="label"
                                        onChange={(value) => {
                                            setFieldValue("BoardId", value);
                                            fetchClassRooms(value)
                                        }}
                                    />
                                </div>

                                <div className="filter-form__input">
                                    <div className="filter-form__label">Class</div>
                                    <DropdownField
                                        name="ClassId"
                                        placeholder="Select"
                                        value={values?.ClassId}
                                        options={classRooms}
                                        showSearch={false}
                                        optionFilterProp="label"
                                        onChange={(value) => {
                                            setFieldValue("ClassId", value);
                                        }}
                                    />
                                </div>
                            </Form>
                        </Filters>
                    )}
                </Formik>

            </div>
        </>

    )
}

export default TitleContainer(ProductsLibrary)