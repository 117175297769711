import React, { FC } from "react";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

interface PDFReaderProps {
  fileUrl: string;
}

const PDFReader: FC<PDFReaderProps> = ({ fileUrl }) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <div className="pdf-content">
        <div style={{ height: "750px" }}>
          {fileUrl && <Viewer fileUrl={fileUrl} />}
        </div>
      </div>
    </Worker>
  );
};

export default PDFReader;
