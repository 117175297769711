import { Col, Input, Row } from "antd";
import { Formik } from "formik";
import { ArrowRightOutlined } from "@ant-design/icons";
import * as React from "react";
import UIButton from "../../../../../shared/components/UIButton";
import { StudentService } from "../../../../../services/StudentService/student.service";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
interface ListCourseClassesProps extends RouteComponentProps {
  data: any;
  onSubmit: Function
}
const ListCourseClasses = ({ data }: ListCourseClassesProps) => {
  const [selectedClass, setSelectedClass] = React.useState("");
  const history = useHistory();


  return (
    <div className="register-form__container">
      <h1>Select your class</h1>
      <Row>
        <Col span={24}>
          <div className="new-boards__wrapper">
            <Row>
              {data?.map((value: any, index: number) => {
                return (
                  <Col span={12}>
                    <div
                      className={`class-board__card ${
                        selectedClass == value.id ? "card__border" : ""
                      }`}
                      onClick={() => {
                        setSelectedClass(value.id);
                      }}
                    >
                      <p>{value?.name}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="btn__wrapper btn__wrapper--margin" span={24}>
          <UIButton
            type="submit"
            className="mt-5"
            icon={<ArrowRightOutlined />}
            clickHandler={() => {
              StudentService.createStudentCourse(
                {
                  courseType: "ClassRoom",
                  courseId: selectedClass,
                },
                () => {
                  history.goBack();
                },
                () => {}
              );
            }}
          >
            Submit
          </UIButton>
        </Col>
      </Row>
    </div>
  );
};
export default withRouter(ListCourseClasses);
