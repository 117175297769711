import { History } from "history";
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, withRouter } from "react-router-dom";
import { LiveClassModel } from "../../../models/LiveClass/liveClass.model";
import { LIVE_CLASS_MEETING } from "../../../routes/routeConstants/appRoutes";
import LiveClassService from "../../../services/LiveClassService/liveClass.service";
import TitleContainer from "../../../store/container/TitleContainer";
import { ITitleState } from "../../../store/reducers/titleReducer";
import LiveClassCard from "./LiveClassCard";
import LiveClassComingSoon from "./LiveClassComingSoon";
import { debounce } from "lodash";
import "./liveClassModule.scss";
import { LocalStorage } from "../../../shared/utils/LocalStorageHelpers";
import { CABLE_URL } from "../../../routes/routeConstants/apiRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { Student } from "../../../models/student.model";
import Notification from "../../../shared/components/Notification";
import ActionCable from "actioncable";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { Skeleton, Spin } from "antd";
interface LiveClassModuleProps {
  setTitle: (title: ITitleState) => void;
  history: History;
  onSearch: string;
  user: Student;
}
let cable: ActionCable.Cable | null = null;

const LiveClassModule: FC<LiveClassModuleProps> = ({
  setTitle,
  history,
  onSearch,
  user,
}) => {
  const [upcomingClasses, setUpcomingClasses] = useState<LiveClassModel[]>([]);
  const [currentLiveClass, setCurrentLiveClass] = useState<LiveClassModel>();
  const [liveClassesNow, setLiveClassesNow] = useState<LiveClassModel[]>([]);
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [loadingLive, setLoadingLive] = useState(false);

  const connectToCable = () => {
    cable = ActionCable.createConsumer(
      `${CABLE_URL}?access_token=${LocalStorage.getItem("ACCESS_TOKEN")}`
    );

    cable?.subscriptions?.create(
      {
        channel: "StudentLiveClassChannel",
        room: `student_live_class_${user?.id}`,
      },
      {
        received: (message: any) => {
          Notification({
            type: NotificationTypes.INFO,
            message: message,
            description: "",
          });
          fetchLiveClassesNow({});
          fetchUpcomingClasses({});
        },
      }
    );
  };

//   useEffect(() => {
//     console.log(cable);
//     if (cable) {
//       cable?.disconnect()
//     }
//     connectToCable();
//   }, []);

  const fetchUpcomingClasses = (params: { search?: string }) => {
    setLoadingUpcoming(true);
    LiveClassService.fetchUpcomingLiveClasses(
      params,
      (liveClasses) => {
        setUpcomingClasses(liveClasses);
      },
      () => {},
      () => {
        setLoadingUpcoming(false);
      }
    );
  };
  const fetchLiveClassesNow = (params: { search?: string }) => {
    setLoadingLive(true);
    LiveClassService.fetchLiveClassesNow(
      params,
      (liveClasses) => {
        setLiveClassesNow(liveClasses);
      },
      () => {},
      () => {
        setLoadingLive(false);
      }
    );
  };
  useEffect(() => {
    setTitle({
      title: "Live Class",
      subText: "",
      showBack: false,
      twoBgs: true,
      color: "#FFF",
      showSearch: true,
      searchText: "Search live class",
      float: false,
    });
    fetchLiveClassesNow({});
    fetchUpcomingClasses({});
  }, []);
  const handleSearch = (search: string) => {
    fetchLiveClassesNow({ search });
    fetchUpcomingClasses({ search });
  };

  useEffect(() => {
    handleSearch(onSearch);
  }, [onSearch]);

  const joinHandler = (liveClassId: number) => {
    setTitle({
      twoBgs: false,
      showBack: false,
    });
    // history?.push(generatePath(LIVE_CLASS_MEETING, { liveClassId }));
    setLoadingLive(true);
    const data = {
      "userType": "student",
      "userId": user?.id,
      "liveClassId": liveClassId,
      'requestFrom':'web'
    }
    LiveClassService.joinLiveClass_Zoom(
      data,
      (joinLink) => {
        window.open(joinLink, "_blank")
      },
      () => { },
      () => { setLoadingLive(false); }
    )
  };

  const SkeletonLoader = (): any => {
    return Array(2)
      .fill(" ")
      .map((value) => {
        return (
          <div className="skeleton-loader">
            <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
          </div>
        );
      });
  };

  return (
    <div className="live-class">
      {loadingLive ? (
        <SkeletonLoader />
      ) : liveClassesNow?.length ? (
        <div className="live-class-live-now">
          <h3>Live now</h3>
          {liveClassesNow?.map((value, index) => {
            return (
              <LiveClassCard
                data={value}
                isLive
                joinHandler={() => {
                  if (value?.id) joinHandler(value?.id);
                }}
              />
            );
          })}
        </div>
      ) : (
        ""
      )}
      <div
        className={`live-class-upcoming-classes ${
          liveClassesNow?.length > 1 || loadingLive ? "live" : "no-live"
        }`}
      >
        <h3>Upcoming Classes</h3>
        {loadingUpcoming ? (
          <SkeletonLoader />
        ) : upcomingClasses?.length ? (
          upcomingClasses?.map((value, index) => {
            return <LiveClassCard data={value} />;
          })
        ) : (
          <div className="no-live-class-text">No upcoming live classes !</div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AuthContainer(TitleContainer(LiveClassModule)));
