import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setAnalyticsData } from "../actions/analytics";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        courseAnalytics: state.analytics.courseAnalytics,
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setAnalyticsData,
}, dispatch);

const AnalyticsContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AnalyticsContainer;