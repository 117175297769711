import { serializable, alias, object, list, primitive } from "serializr";
import { ExamChapterResult, StudentChapterResult } from "./chapter.model";
import { StudentExamResult } from "./class.model";


// export class StudentSubjectResult {
//   @serializable(alias("id", primitive()))
//   id?: number;

//   @serializable(alias("score", primitive()))
//   score?: number;

//   @serializable(alias("accuracy", primitive()))
//   accuracy?: number;

//   @serializable(alias("average_time_per_question", primitive()))
//   averageTimePerQuestion?: number;

//   @serializable(alias("exercise_attempt_duration", primitive()))
//   exerciseAttemptDuration?: number;

//   @serializable(alias("number_of_exercise_attempts", primitive()))
//   numberOfExerciseAttempts?: number;

//   @serializable(alias("subject_name", primitive()))
//   subjectName?: string;

//   @serializable(alias("subject_id", primitive()))
//   subjectId?: number;

//   @serializable(
//     alias("student_chapter_results", list(object(StudentChapterResult)))
//   )
//   studentChapterResults?: StudentChapterResult[] = [];

//   @serializable(alias("total_content_count", primitive()))
//   totalContentCount?: number;

//   @serializable(alias("student_content_count", primitive()))
//   completedContentCount?: number;

//   @serializable(alias("total_exercise_count", primitive()))
//   totalExerciseCount?: number;

//   @serializable(alias("attempted_exercise_count", primitive()))
//   completedExerciseCount?: number;
// }


export class StudentSubjectResult {
  @serializable(alias('subjectId', primitive()))
  id?: number;

  @serializable(alias("subjectId", primitive()))
  subjectId?: number;

  @serializable(alias('subjectName', primitive()))
  name?: string;

  @serializable(alias('chapterCount', primitive()))
  chapterCount?: number;

  @serializable(alias('completedPerc', primitive()))
  cummulativeCompletionPercentage?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("totalContent", primitive()))
  totalContentCount?: number;

  @serializable(alias("contentDone", primitive()))
  completedContentCount?: number;

  @serializable(alias("contentCompleted", primitive()))
  contentCompletionPercentage?: number;

  @serializable(alias("duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("totalExcercise", primitive()))
  totalExerciseCount?: number;

  @serializable(alias("excerciseDone", primitive()))
  completedExerciseCount?: number;

  @serializable(alias("excerciseDone", primitive()))
  numberOfExerciseAttempts?: number;

  @serializable(alias("excerciseCompleted", primitive()))
  exerciseCompletionPercentage?: number;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("speed", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("chapterData", list(object(StudentChapterResult))))
  studentChapterResults?: StudentChapterResult[] = [];
}



export class ExamSubjectResult {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("average_time_per_question", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("number_of_attempts", primitive()))
  numberOfExerciseAttempts?: number;

  @serializable(alias("exam_subject_name", primitive()))
  subjectName?: string;

  @serializable(alias("exam_subject_id", primitive()))
  subjectId?: number;

  @serializable(alias("exam_chapter_results", list(object(ExamChapterResult))))
  examChapterResults?: ExamChapterResult[] = [];

  @serializable(alias("exam_content_count", primitive()))
  totalContentCount?: number;

  @serializable(alias("student_exam_content_count", primitive()))
  completedContentCount?: number;

  @serializable(alias("total_exam_exercise_count", primitive()))
  totalExerciseCount?: number;

  @serializable(alias("attempted_exam_exercise_count", primitive()))
  completedExerciseCount?: number;
}
