import { serializable, alias,list, primitive } from 'serializr';


export class Products {
    @serializable(alias('productId', primitive()))
    productId?: string;
    @serializable(alias('productName', primitive()))
    productName?: string;
    @serializable(alias('boardId', primitive()))
    boardId?: string;
    @serializable(alias('boardName', primitive()))
    boardName?: string;
    @serializable(alias('classId', primitive()))
    classId?: string;
    @serializable(alias('className', primitive()))
    className?: string;
    @serializable(alias('subjectId', list(primitive())))
    subjectId?: any[];
    @serializable(alias('subjectNames', list(primitive())))
    subjectNames?: any[];
    @serializable(alias('description', primitive()))
    description?: string;
    @serializable(alias('thumbnailPath', primitive()))
    thumbnailPath?: string;
    @serializable(alias('price', primitive()))
    price?: string;
    @serializable(alias('duration', primitive()))
    duration?: string;
    @serializable(alias('thumbnailImage', primitive()))
    thumbnailImage?: string;
}

export class ProductModel {
    @serializable(alias('ProductId', primitive()))
    ProductId?: string;
    @serializable(alias('ProductName', primitive()))
    ProductName?: string;
    @serializable(alias('BoardId', primitive()))
    BoardId?: string;
    @serializable(alias('BoardName', primitive()))
    BoardName?: string;
    @serializable(alias('ClassId', primitive()))
    ClassId?: string;
    @serializable(alias('ClassName', primitive()))
    ClassName?: string;
    @serializable(alias('SubjectId', list(primitive()) || primitive() ))
    SubjectId?: any;
    @serializable(alias('SubjectNames', primitive()))
    SubjectNames?: [];
    @serializable(alias('Description', primitive()))
    Description?: string;
    @serializable(alias('ThumbnailPath', primitive()))
    ThumbnailPath?: string;
    @serializable(alias('Price', primitive()))
    Price?: string;
    @serializable(alias('Duration', primitive()))
    Duration?: string;
    @serializable(alias('ThumbnailImage', primitive()))
    ThumbnailImage?: string;
}

export class FilterProducts {
    @serializable(alias("classId", primitive()))
    ClassId?: number | string;
    @serializable(alias('boardId', primitive()))
    BoardId?: number | string;
    @serializable(alias('CurrentPage', primitive()))
    CurrentPage?: number;
    @serializable(alias('Pagesize', primitive()))
    Pagesize?: number;
  }
  
  

