import * as React from "react";
import { useState, useEffect } from "react";
import { ITitleState } from "../../../store/reducers/titleReducer";
import TitleContainer from "../../../store/container/TitleContainer";
import "./queryModule.scss";
import { QuerySearchBar } from "./QuerySearchBar";
import { YourQueries } from "./YourQueries";
import { QueryService } from "../../../services/QueryService/query.service";
import  SearchResult  from "./SearchResult";
import moment from 'moment'
import Modal from "antd/lib/modal/Modal";
import { Query } from "../../../models/Query/query.model";
import { PaginationModel } from "../../../models/pagination.model";
import QueryComingSoon from "./QueryComingSoon";
interface SetTitleProps {
  setTitle: ({ ...data }: ITitleState) => void;
}
const QueryModule = ({ setTitle }: SetTitleProps) => {
  

  const [isSearch, setSearch] = useState(false);
  const [searchData, setSearchData] = useState<any>([])
  const [metaData,setMetaData] = useState<any>()
  const [searchText, setSearchText] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [queries, setQueries] = useState<any>([])
  const onClickSearch = (value: any) => {
      QueryService
      .searchQueries(
          {
              search: value,
          }, 
          (response: Query, meta: PaginationModel) => {
            //console.log(meta)
            //console.log(response)
            setSearchText(value)
            setSearchData(response)
            setMetaData(meta)
            setSearch(true)
          },
          (error: any) => {
              //console.log(error)
          }
      )
  };

  // const refreshList = () => {
  //   setSearch(false);
  //   setRefresh(state => !state);
  //   getQueries()
    // let data = queries
    // data.push({
    //   id: queries[queries.length - 1].id + 1,
    //   content: content,
    //   attachment: null,
    //   comments: [],
    //   subject_name: name,
    //   created_at: moment().utc()
    // })
    // setQueries([...data])
  // }
  // const getQueries = () => {
  //   QueryService.fetchMyQueries(
  //     (response: any) => {
  //       //console.log(response);
  //       setQueries(response);
  //     },
  //     (error: any) => {
  //       //console.log(error);
  //     }
  //   );
  // };
  useEffect(() => {
    // getQueries()
    setTitle({
      title: "",
      subText: "",
      showBack: false,
    });
  }, []);

  return (
    <QueryComingSoon/>

    // <div>
    //  <p className="query__title">Query</p>
    //   <QuerySearchBar
    //     onChange={value => setSearchText(value)}
    //     refresh={refresh}
    //     onClick={(text) => {
    //       onClickSearch(text);
    //     }}
    //   />
    //   {!isSearch ? (queries.length > 0 ? <YourQueries refreshQueries={getQueries}  queriesData={queries}/>: <div/>) : <SearchResult data={searchData} meta={metaData} search={searchText} refreshList={refreshList}/>} 
    // </div>
  );
}
export default TitleContainer(QueryModule);
