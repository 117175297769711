import { Col, Drawer, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Chapter, Chapters } from '../../../../models/Chapters/chapter.model';
import { ExamChapterModel } from '../../../../models/Exam/ChapterModel/chapter.model';
import { LearnService } from '../../../../services/LearnService/learn.service';
import { ExamService } from '../../../../services/ExamService/exam.service';
import { LocalStorage } from '../../../../shared/utils/LocalStorageHelpers';
import AuthContainer from '../../../../store/container/AuthContainer';
import './practiceChapter.scss';
import Quizes from './Quizes';
import SubjectCard from './SubjectCard';
import TitleContainer from '../../../../store/container/TitleContainer';
import { Course } from '../../../../models/student.model';
import { ITitleState } from '../../../../store/reducers/titleReducer';

import { ReactComponent as BackIcon } from '../../../../assets/icon/back.svg';
interface PracticeChapterProps extends ITitleState {
    type: string;
    setTitle: Function;
    currentCourse: Course;
}
const PracticeChapter: FC<PracticeChapterProps> = ({
    type,
    setTitle,
    currentCourse,
    onSearch,
}) => {
    const [chapters, setChapters] = useState<any>([]);
    const [currentChapter, setCurrentChapter] = useState<Chapter>(
        new Chapter()
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const params: any = useParams();
    const { state }: { state: any } = useLocation();

    const onClose = () => {
        setIsOpen(false);
    };

    const customTitle = (
        <div className='quizes__heading__wrapper'>
            <h2>Quiz</h2>
            <p>{currentChapter?.name}</p>
        </div>
    );
    const fetchExercises = (queryParams: any) => {
        if (params?.subjectId && currentCourse?.id) {
            type == 'exam'
                ? ExamService.getExamSubjectChapters(
                      queryParams,
                      {
                          subjectId: params?.subjectId,
                          courseId: currentCourse?.id,
                      },
                      (chapters: any) => {
                          setChapters(chapters);
                          if (chapters?.length) {
                              setCurrentChapter(chapters[0]);
                          }
                          //console.log(chapters);
                      },
                      () => {}
                  )
                : LearnService.getChapters(
                      queryParams,
                      {
                          subjectId: params?.subjectId,
                          courseId: currentCourse?.id,
                      },
                      (chapters: Chapter[]) => {
                          setChapters(chapters);
                          if (chapters?.length) {
                              setCurrentChapter(chapters[0]);
                          }
                      }
                  );
        }
    };

    useEffect(() => {
        setTitle({
            title: state?.subjectName,
            subText: '',
            showBack: true,
            searchText: 'Search exercises',
            color: '#FD9000',
            float: true,
        });
        fetchExercises({});
    }, []);

    return (
        <div className='practice-chapter__container'>
            <Row className='exercises__container'>
                <Col span={11} className='chapters_wrapper_col'>
                    <div className='chapters__wrapper'>
                        <div className='chapters__container'>
                            <div
                                style={{
                                    overflowY: 'auto',
                                    paddingRight: '1rem',
                                }}
                                className='chapters__container_div'
                            >
                                {chapters?.map(
                                    (chapter: any, index: number) => (
                                        <SubjectCard
                                            active={
                                                currentChapter?.id ===
                                                chapter?.id
                                            }
                                            chapter={chapter}
                                            index={index}
                                            onSelect={(chapter) => {
                                                setCurrentChapter(chapter);
                                                setIsOpen(true);
                                            }}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
                {chapters.length > 0 ? (
                    <Col span={13} className='quiz_wrapper_col'>
                        <div className='quiz__wrapper'>
                            <Quizes
                                chapter={
                                    chapters[
                                        chapters.findIndex(
                                            (chapter: any) =>
                                                chapter.id === currentChapter.id
                                        )
                                    ]
                                }
                            />
                        </div>
                    </Col>
                ) : (
                    <div />
                )}
            </Row>
            <Drawer
                title={customTitle}
                onClose={onClose}
                visible={isOpen}
                closeIcon={<BackIcon />}
                className='quiz__drawer'
            >
                <div className='quiz__wrapper'>
                    <Quizes
                        chapter={
                            chapters[
                                chapters.findIndex(
                                    (chapter: any) =>
                                        chapter.id === currentChapter.id
                                )
                            ]
                        }
                    />
                </div>
            </Drawer>
        </div>
    );
};

export default TitleContainer(AuthContainer(PracticeChapter));
