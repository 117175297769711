import { serializable, alias, primitive, object, list } from "serializr";


export class FileMetaModel {
  @serializable(alias("attachmentId", primitive()))
  attachmentId?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("path", primitive()))
  path?: string;

  @serializable(alias("isDownload", primitive()))
  isDownload?: boolean;
}

export class WorksheetModel {
  @serializable(alias("worksheetId", primitive()))
  worksheetId?: number;
  @serializable(alias("studentId", primitive()))
  studentId?: number;
  @serializable(alias("studentName", primitive()))
  studentName?: number;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("instructions", primitive()))
  instructions?: string;
  @serializable(alias("dueDate", primitive()))
  dueDate?: string;
  @serializable(alias("points", primitive()))
  points?: number;
  @serializable(alias("classId", primitive()))
  classId?: number;
  @serializable(alias("sectionId", primitive()))
  sectionId?: number;
  @serializable(alias("subjectId", primitive()))
  subjectId?: number;
  @serializable(alias("className", primitive()))
  className?: string;
  @serializable(alias("sectionName", primitive()))
  sectionName?: string;
  @serializable(alias("subjectName", primitive()))
  subjectName?: string;
  @serializable(alias("postedDate", primitive()))
  createdOn?: string;

  @serializable(alias("studentWorksheetId", primitive()))
  studentWorksheetId?: number;
  @serializable(alias("attachmentList", list(object(FileMetaModel))))
  attachmentList?: FileMetaModel[] = [] ;
  @serializable(alias("studentAttachmentList", list(object(FileMetaModel))))
  studentAttachmentList?: FileMetaModel[] = [] ;
  @serializable(alias("evaluationAttachmentList", list(object(FileMetaModel))))
  evaluationAttachmentList?: FileMetaModel[] = [] ;
  
  @serializable(alias("studentPoint", primitive()))
  studentPoint?: number;
  
  @serializable(alias("privateMessage", primitive()))
  privateMessage?: number;
  @serializable(alias("turnInStatus", primitive()))
  turnInStatus?: boolean;
  @serializable(alias("turnInDate", primitive()))
  turnInDate?: number;
}