import React, { FC, useEffect, useState } from "react";
import { Leaderboard } from "../../../models/Leaderboard/leaderboard.model";
import { LeaderboardService } from "../../../services/LeaderboardService/leaderboard.service";
import AuthContainer from "../../../store/container/AuthContainer";
import TitleContainer from "../../../store/container/TitleContainer";
import { ITitleState } from "../../../store/reducers/titleReducer";
import LeaderboardComingSoon from "./LeaderboardComingSoon";
import "./leaderboardModule.scss";
import InfoIcon from "../../../assets/icon/info-blue.svg";
import RankBoard from "./RankBoard";
import { Course } from "../../../models/student.model";
import CurrentStudentRank from "./CurrentStudentRank";
import PointsHistory from "./PointsHistory";
import LeaderboardInfo from "./LeaderboardInfo";
interface LeaderboardModuleProps {
  setTitle: ({ ...data }: ITitleState) => void;
  currentCourse: Course;
}

const LeaderboardModule: FC<LeaderboardModuleProps> = (props) => {
  const { setTitle, currentCourse } = props;
  useEffect(() => {
    setTitle({
      title: "Leaderboard",
      subText: "",
      showBack: false,
      twoBgs: true,
      color: "#FFF",
      showSearch: false,
    });
  }, []);

  const [leaderboard, setLeaderboard] = useState<Leaderboard>({});
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const fetchLeaderboardData = () => {
    if (currentCourse?.id) {
      LeaderboardService?.fetchLeaderboardData(
        currentCourse?.id,
        (data) => {
          setLeaderboard(data);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    fetchLeaderboardData();
  }, [currentCourse?.id]);

  return (
    <div className="leaderboard-module">
      <div className="how-it-works__container" onClick={() => setShowInfo(true)}>
        <p>How it works</p>
        <img src={InfoIcon} />
      </div>
      <LeaderboardInfo visible={showInfo} closeHandler={() => setShowInfo(false)} okHandler={() => setShowInfo(false)}/>
      <RankBoard data={leaderboard} />
      <CurrentStudentRank data={leaderboard} />
      <PointsHistory data={leaderboard} />
    </div>
  );
};

export default AuthContainer(TitleContainer(LeaderboardModule));
