import React, { FC } from "react";
import { Button, Carousel } from "antd";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";
import "./uiCarousel.scss";

const chunkArrayBy = (array: Array<any>, chunk: number) => {
  var temparray: any = [];
  for (let i = 0, j = array.length; i < j; i += chunk) {
    temparray.push(array.slice(i, i + chunk));
  }
  return temparray;
};

interface CarouselProps {
  data: Array<any>;
}

const UICarousel: FC<CarouselProps> = ({ data }) => {
  const windowWidth = window.innerWidth
  const d = chunkArrayBy(data, windowWidth <= 768 ? 1 : 4);


  return (
    <div>
      <Carousel
        className="carousel__container"
        arrows
        swipeToSlide
        draggable
        prevArrow={<Button><span><CaretLeftOutlined/></span></Button>}
        nextArrow={<Button><span><CaretRightOutlined/></span></Button>}
      >
        {d.map((videos,index) => (
          <div key={"Carousel#"+index}>
            <h3 className="continue-where-you-left__wrapper">
              {videos.map((VideoCard) => VideoCard)}
            </h3>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default UICarousel;
