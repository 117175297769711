import React, { FC, Fragment, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { SubjectModel } from "../../../models/Exam/SubjectModel/subject.model";
import { LIST_ANALYTICS_CHAPTERS } from "../../../routes/routeConstants/appRoutes";
import { LearnService } from "../../../services/LearnService/learn.service";
import { ExamService } from "../../../services/ExamService/exam.service";
import { LocalStorage } from "../../../shared/utils/LocalStorageHelpers";
import AuthContainer from "../../../store/container/AuthContainer";
import TitleContainer from "../../../store/container/TitleContainer";
import { ITitleState } from "../../../store/reducers/titleReducer";
import AnalyticsCard from "./ AnalyticsCard";
import ListExamSubjectsAnalytics from "./ListExamSubjectsAnalytics";
import ListPaperAnalytics from "./ListPaperAnalytics";
import ListSchedulesAnalytics from "./ListSchedulesAnalytics";
import { SwitchTabs } from "./SwitchTabs";
import { Course } from "../../../models/student.model";
import SubjectsAnalyticsCard from "./SubjectsAnalyticsCards";

import DropdownField from "../../../shared/components/DropdownField";
import SubscriptionPopup from "../../../shared/components/SubscriptionPopup";
import "./analytics.scss";
interface AnalyticsProps {
  setTitle: (values: ITitleState) => void;
  type: string;
  courseId: any;
  currentCourse: Course;
  isTrialCourse: boolean;
  isCourseExpired: boolean
}
const Analytics: FC<AnalyticsProps> = ({
  setTitle,
  type,
  courseId,
  currentCourse,
  isTrialCourse,
  isCourseExpired
}) => {
  useEffect(() => {
    setTitle({
      title:
        currentCourse?.course?.classRoom?.boardAcronym ||
        currentCourse?.course?.exam?.acronym,
      color: "#FFF",
      showBack: false,
      float: false,
      showSearch: false,
      twoBgs: true,
    });
  }, []);
  const history = useHistory();
  const [selected, setSelected] = useState("subject");
  const [infoPopup, setInfoPopup] = useState(false);

  return (
    <Fragment>
      <SubscriptionPopup
        visiblity={infoPopup}
        closeHandler={() => setInfoPopup(false)}
      />

      <div className="analytics__container">
        {type == "exam" && (
          <DropdownField
            className="analytics__dropdown"
            options={[
              {
                label: "View by Subject",
                value: "subject",
              },
              {
                label: "View by Schedule",
                value: "schedule",
              },
              {
                label: "View by Previous paper",
                value: "paper",
              },
            ]}
            onChange={(value) => {
              setSelected(value);
            }}
            showSearch={true}
            value={selected}
          />
        )}

        {(selected == "subject" || selected == "paper") && <AnalyticsCard showPaper={selected == "paper"} />}
        {type == "exam" ? (
          selected == "subject" ? (
            <SubjectsAnalyticsCard
              onClick={(id: any, name: string) => {
                isTrialCourse || isCourseExpired
                  ? setInfoPopup(true)
                  : history.push({
                    pathname: generatePath(LIST_ANALYTICS_CHAPTERS, {
                      subjectId: id,
                    }),
                    state: {
                      subjectId: id,
                      subjectName: name,
                    },
                  });
              }}
            />
          ) : (
            <ListPaperAnalytics />
          )
        ) : (
          <SubjectsAnalyticsCard
            onClick={(id: any, name: string) => {
              isCourseExpired ? setInfoPopup(true) : history.push({
                pathname: generatePath(LIST_ANALYTICS_CHAPTERS, {
                  subjectId: id,
                }),
                state: {
                  subjectId: id,
                  subjectName: name,
                },
              });
            }}
          />
        )}
      </div>
    </Fragment>
  );
};
export default AuthContainer(TitleContainer(Analytics));
