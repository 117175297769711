import Modal from "antd/lib/modal/Modal";
import React, { FC } from "react";
import NotificationCard from "./NotificationCard";
import "./notificationsModal.scss";
import faker from "faker";
import { NotificationCardTypes } from "../../../../enums/notificationCardTypes";
import NotificationService from "../../../../services/NotificationService/notifications.service";
import {
  NotificationMeta,
  Notifications,
} from "../../../../models/Notifications/notifications.model";
import { useState } from "react";
import { useEffect } from "react";
import ActionCable from "actioncable";
import AuthContainer from "../../../../store/container/AuthContainer";
import { Parent } from "../../../../models/user.model";
import { deserialize } from "serializr";
import { LocalStorage } from "../../../utils/LocalStorageHelpers";
import Notification from "../../Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { CABLE_URL } from "../../../../routes/routeConstants/apiRoutes";
import { Student } from "../../../../models/student.model";
import PaginationComponent from "../../PaginationComponent";
import moment from "moment";
const NotificationSound = require("../../../../assets/sounds/notification.mp3");
interface NotificationsModalProps {
  visible: boolean;
  closeHandler: () => void;
  user: Parent;
  // currentProfile: Student;
  refreshList: () => void;
  notifications: Notifications[];
  paginationMeta: NotificationMeta;
  handlePageChange: (page: number, pageSize?: number) => void;
}
const NotificationsModal: FC<NotificationsModalProps> = ({
  visible,
  closeHandler,
  user,
  // currentProfile,
  notifications,
  refreshList,
  paginationMeta,
  handlePageChange,
}) => {
  const deleteNotification = (id: any) => {
    NotificationService.deleteNotification(
      id,
      () => {
        refreshList();
      },
      () => {},
      () => {}
    );
  };
  const deleteAllNotifications = () => {
    NotificationService.deleteAllNotifications(
      () => {
        refreshList();
      },
      () => {},
      () => {}
    );
  };

  console.log(notifications);

  const connectToCable = () => {
    const cable = ActionCable.createConsumer(
      `${CABLE_URL}?access_token=${LocalStorage.getItem("ACCESS_TOKEN")}`
    );
    // console.log(cable);
    cable?.subscriptions?.create(
      {
        channel: "StudentNotificationChannel",
        room: `student_notification_${user?.id}`,
      },
      {
        received: (data: Notifications) => {
          const notification = deserialize(Notifications, data);

          if (notifications) {
            const audio = new Audio(NotificationSound);
            audio.play();
            Notification({
              message: `New notification for ${
                notification?.receiver?.student?.id == user?.id
                  ? "you"
                  : notification?.receiver?.student?.firstName
              }!`,
              description: notification?.title ?? "",
              type: NotificationTypes.OPEN,
              push: true,
            });
            // let pushIntoNotifications = notifications;
            // pushIntoNotifications.unshift(notification);
            // setNotifications(pushIntoNotifications);
            refreshList();
          }
        },
      }
    );
  };

//   useEffect(() => {
//     connectToCable();
//   }, []);

  return (
    <Modal
      visible={visible}
      onCancel={closeHandler}
      className={"notifications-modal__container"}
      title={
        <div className="notifications-modal__title">
          <p>Notifications</p>
          <p className="clear__all" onClick={() => deleteAllNotifications()}>
            Clear all
          </p>
        </div>
      }
    >
      <div className="notifications-list__wrapper">
        {notifications?.map((value, index) => {
          return (
            <NotificationCard
            key={"notifications_CARD#"+index}
              data={value}
              title={
                value?.data?.excercise?.title ||
                value?.data?.chapterContent?.title ||
                value?.data?.examChapterContent?.title ||
                value?.data?.examExercise?.title ||
                value?.title
              }
              subtitle={value?.shortTitle}
              notificationType={value?.data?.type}
              description={value?.body}
              image={value?.data?.chapterContent?.contentLibrary?.thumbnailUrl}
              time={value?.createdAt}
              onClickDelete={() => deleteNotification(value?.id)}
              closeHandler={closeHandler}
              refreshList={refreshList}
            />
          );
        })}
      </div>

      {/* <NotificationCard
        title={faker.lorem.sentence()}
        type={NotificationCardTypes.VIDEO}
        description={faker.lorem.sentences(2)}
        image={faker.image.abstract()}
        time={faker.datatype.number(59)}
        onClickDelete={() => deleteNotification(1)}
      />
      <NotificationCard
        title={faker.lorem.sentence()}
        type={NotificationCardTypes.PDF}
        description={faker.lorem.sentences(2)}
        image={faker.image.abstract()}
        time={faker.datatype.number(59)}
        onClickDelete={() => deleteNotification(2)}
      />
      <NotificationCard
        title={faker.lorem.sentence()}
        type={NotificationCardTypes.ADMIN_MESSAGE}
        description={faker.lorem.sentences(2)}
        time={faker.datatype.number(59)}
        onClickDelete={() => deleteNotification(3)}
      />
      <NotificationCard
        title={faker.lorem.sentence()}
        type={NotificationCardTypes.TUTOR_REPLY}
        description={faker.lorem.sentences(2)}
        time={faker.datatype.number(59)}
        otherData={faker.name.firstName()}
        onClickDelete={() => deleteNotification(4)}
      /> */}
      <PaginationComponent
        handlePageChange={handlePageChange}
        paginationMeta={paginationMeta}
      />
    </Modal>
  );
};
export default AuthContainer(NotificationsModal);
