import { AgoraVideoPlayer, createScreenVideoTrack } from "agora-rtc-react";
import React, { FC, useEffect } from "react";
import "./liveClassVideos.scss";
import {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import StartVideoIcon from "../../../../../assets/icon/live-class/video-camera-slash-fill.svg";

interface LiveClassVideosProps {}

const LiveClassVideos = (props: {
  users: IAgoraRTCRemoteUser[];
  videoTrack: [IMicrophoneAudioTrack, ICameraVideoTrack] | null;
  audioTrack: IMicrophoneAudioTrack | null;
  isParticipantsVisible: boolean;
  isInfoVisible: boolean;
  client: IAgoraRTCClient;
  host?: IAgoraRTCRemoteUser;
}) => {
  const { users, videoTrack, audioTrack, isParticipantsVisible, client, isInfoVisible, host } = props;
  return (
    <div
      className={`live-class-videos ${
        (isParticipantsVisible || isInfoVisible) &&
        "live-class-videos__drawer-shown"
      }`}
    >
      {users.length > 0 && (
        <div className="participants-videos__wrapper">
          {host ? (
            videoTrack?.[1]?.enabled ? (
              <div className="live-class-video-player__wrapper">
                <AgoraVideoPlayer
                  className="live-class-video-player participants"
                  videoTrack={videoTrack?.[1]}
                  key={client?.uid}
                />

                <div className="live-class-video-player__name">
                  {client?.uid
                    ?.toString()
                    ?.split("-")?.[0]
                    ?.replace(/ /gi, " ")}{" "}
                  (You)
                </div>
              </div>
            ) : (
              <div className="live-class-video-player__stopped">
                <img src={StartVideoIcon} />
                <div className="live-class-video-player-stopped__name">
                  {client?.uid
                    ?.toString()
                    ?.split("-")?.[0]
                    ?.replace(/ /gi, " ")}{" "}
                  (You)
                </div>
              </div>
            )
          ) : (
            ""
          )}

          {/* {users
            ?.filter((user) => user?.uid !== host?.uid)
            .map((user) => {
              return user?.videoTrack ? (
                <div className="live-class-video-player__wrapper">
                  <AgoraVideoPlayer
                    className="live-class-video-player participants"
                    videoTrack={user?.videoTrack}
                    key={user?.uid}
                  />

                  <div className="live-class-video-player__name">
                    {user?.uid
                      ?.toString()
                      ?.split("-")?.[0]
                      ?.replace(/ /gi, " ")}
                  </div>
                </div>
              ) : (
                <div className="live-class-video-player__stopped">
                  <img src={StartVideoIcon} />
                  <div className="live-class-video-player-stopped__name">
                    {user?.uid
                      ?.toString()
                      ?.split("-")?.[0]
                      ?.replace(/ /gi, " ")}
                  </div>
                </div>
              );
            })} */}
        </div>
      )}
      {host ? (
        host?.videoTrack ? (
          <div className="live-class-video-player__wrapper">
            <AgoraVideoPlayer
              className={`live-class-video-player host ${
                users.length ? "participants-joined" : ""
              }`}
              videoTrack={host?.videoTrack}
              key={host?.uid}
            />
            <div className="live-class-video-player__name">
              {host?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ") +
                " (Host)"}
            </div>
          </div>
        ) : (
          <div className="live-class-video-player__stopped host">
            <img src={StartVideoIcon} />
            <div className="live-class-video-player-stopped__name">
              {host?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ") +
                " (Host)"}
            </div>
          </div>
        )
      ) : videoTrack?.[1]?.enabled ? (
        <div className="live-class-video-player__wrapper">
          <AgoraVideoPlayer
            className={`live-class-video-player host ${
              users.length ? "participants-joined" : ""
            }`}
            videoTrack={videoTrack?.[1]}
            key={client?.uid}
          />
          <div className="live-class-video-player__name">
            {client?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
          </div>
        </div>
      ) : (
        <div className="live-class-video-player__stopped student">
          <img src={StartVideoIcon} />
          <div className="live-class-video-player-stopped__name">
            {client?.uid?.toString()?.split("-")?.[0]?.replace(/ /gi, " ")}
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveClassVideos;
