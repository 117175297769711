import { Col, Divider, Dropdown, Menu, Row, Table, Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import QuestionContainer from "../../../../../store/container/QuestionContainer";
import { QuestionReducerProps } from "../../../../../store/reducers/questionReducer";
import { initZoro } from "../../../../utils/initZero";
import { Type } from "./Question";
import Question from "./Question";
import "./questionsList.scss";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import UIButton from "../../../UIButton";
import { ExamService } from "../../../../../services/ExamService/exam.service";
import { Question as QuestionModel } from "../../../../../models/Question/question.model";
import {
  EXERCISE_EXAM,
  PAPER_EXAM,
} from "../../../../../routes/routeConstants/appRoutes";
import InstructionPopup from "../../../../../views/Home/PracticeModule/PracticeChapter/Quizes/Instruction";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { ExerciseService } from "../../../../../services/ExerciseService/exercise.service";
import { PreviousPaperService } from "../../../../../services/PreviousPaperService/previouspaper.service";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import ScoreIcon from "../../../../../assets/icon/reward.svg";
import AccuracyIcon from "../../../../../assets/icon/dart.svg";
import SpeedIcon from "../../../../../assets/icon/sketch.svg";
import DurationIcon from "../../../../../assets/icon/sand-clock.svg";
import Profile1 from "../../../../../assets/icon/profile1.png";
import AnalyticsIcon from "../../../../../assets/icon/analytics.svg";
import SwitchQuestionIcon from "../../../../../assets/icon/switch-question.svg";

import Chart from "react-google-charts";
import AnalyticsComponent from "../../../../../views/Home/AnalyticsModule/AnalyticsComponent";
import { ExerciseAttemptResults } from "../../../../../models/Analytics/exercise.model";
import { AnalyticsService } from "../../../../../services/AnalyticsService/analytics.service";
import { Course } from "../../../../../models/student.model";
import { ContentExerciseAttemptResults } from "../../../../../models/Analytics/content.model";
const { TabPane } = Tabs;
const colors = {
  blue: {
    dark: "#145CE7",
    light: "#D0DEFA",
  },
  black: {
    dark: "#0C1013",
    light: "#ECEDEF",
  },
  grey: {
    dark: "rgba(145, 155, 176, 0.5)",
    light: "#919BB0",
  },
  orange: {
    dark: "#FF9810",
    light: "#FFEACF",
  },
  red: {
    dark: "#DC1313",
    light: "#F8CFCF",
  },
  green: {
    dark: "#108E10",
    light: "#CFE8CF",
  },
};

export const QuestionStats = ({ nos, text, color }: any) => {
  return (
    <div className={"question-stats__wrapper"} style={{ borderColor: color }}>
      <p className="nos">{nos}</p>
      <p className="text">{text}</p>
    </div>
  );
};

interface QuestionListProps extends QuestionReducerProps {
  shrink?: boolean;
  currentCourse?: Course;
  type?: string;
}

const QuestionsList: FC<QuestionListProps> = ({
  shrink = false,
  questions,
  currentCourse,
  attempted,
  remaning,
  marked,
  setCurrentQuestion,
  attempt,
  type,
}) => {
  const [correct, setCorrect] = useState(0);
  const [inCorrect, setInCorrect] = useState(0);
  const [infoPopup, setInfoPopup] = useState(false);
  const [evaluating, setEvaluating] = useState(0);
  const [unanswered, setUnanswered] = useState(0);
  const location = useLocation();
  const [selectedResult, setSelectedResult] =
    useState<ExerciseAttemptResults>();
  const [studentExerciseResults, setStudentExerciseResults] = useState<
    ExerciseAttemptResults[]
  >([]);
  const [noAttempts, setNoAttempts] = useState(false);
  const [showAnalytics, setAnalyticsVisible] = useState(false);
  const history = useHistory();
  const params: { id: string } = useParams();
  useEffect(() => {
    let correct = 0,
      inCorrect = 0;
    questions?.forEach((question) => {
      if (question?.isCorrect === "yes") {
        correct += 1;
      } else if (question?.isCorrect === "no") {
        inCorrect += 1;
      } else {
      }
    });
    setCorrect(correct);
    setInCorrect(inCorrect);
    setEvaluating(
      questions.filter((data) => data.isCorrect == "pending").length
    );
    setUnanswered(questions.filter((data) => !data.studentAnswer).length);
  }, [questions]);

  const handleChangeQuestion = (index: number) => {
    setCurrentQuestion(index);
  };

  const questionType = (isCorrect: "yes" | "no"): Type => {
    if (isCorrect === "yes") return "correct";
    if (isCorrect === "no") return "wrong";
    return "not-visited";
  };
  const fetchStudentExerciseResults = (attemptId: any) => {
    if (attemptId) {
      if (location.pathname.includes("quick_quiz")) {
        AnalyticsService.fetchContentAnalytics(
          currentCourse?.id,
          attemptId,
          (response: ExerciseAttemptResults[]) => {
            setNoAttempts(false);
            setStudentExerciseResults(response);
            if (response?.length) {
              setSelectedResult(response[response?.length-1]);
            }
          },
          () => {}
        );
      } 
      else if (location.pathname.includes("paper")) {
        AnalyticsService.fetchPaperAttemptsAnalytics(
          currentCourse?.id,
          attemptId,
          (response: ExerciseAttemptResults[]) => {
            setNoAttempts(false);
            setStudentExerciseResults(response);
            if (response?.length) {
              setSelectedResult(response[response?.length-1]);
            }
          },
          () => {}
        )
      }
      else {
        type == "exam"
          ? AnalyticsService.fetchExamExerciseAnalytics(
              currentCourse?.id,
              attemptId,
              (response: ExerciseAttemptResults[]) => {
                setNoAttempts(false);
                setStudentExerciseResults(response);
                if (response?.length) {
                  setSelectedResult(response[response?.length-1]);
                }
              },
              () => {}
            )
          : AnalyticsService.fetchExerciseAnalytics(
              currentCourse?.id,
              attemptId,
              (response: ExerciseAttemptResults[]) => {
                setNoAttempts(false);
                setStudentExerciseResults(response);
                if (response?.length) {
                  setSelectedResult(response[response?.length-1]);
                }
              },
              () => {}
            );
      }
    } else {
      setNoAttempts(true);
    }
  };

  useEffect(() => {
    fetchStudentExerciseResults(attempt?.id);
  }, []);
  return (
    <div
      className={`questions-list__container ${
        !shrink && "question-list__shrink"
      }`} //no-scrollbar
    >
      <>
        <p className="exercise__title">{attempt?.title}</p>
        {location?.pathname.includes("results") ? (
          <Tabs defaultActiveKey="1" onChange={() => {}}>
            <TabPane
              tab={
                <span className="tab__name">
                  <img src={SwitchQuestionIcon} />
                  Switch to a question
                </span>
                // <p className="tab__name">
                //   <span>
                //     <img src={SwitchQuestionIcon} />
                //   </span>
                //   Switch to a question
                // </p>
              }
              key="1"
            >
              <>
                <Row className="mtp-5">
                  <Col span={6}>
                    <QuestionStats
                      nos={correct}
                      text="Correct"
                      color={colors.green?.dark}
                    />
                  </Col>
                  <Col span={6}>
                    <QuestionStats
                      nos={inCorrect}
                      text="Incorrect"
                      color={colors.red?.dark}
                    />
                  </Col>
                  <Col span={6}>
                    <QuestionStats
                      nos={evaluating}
                      text="Evaluating"
                      color={colors.orange.dark}
                    />
                  </Col>
                  <Col span={6}>
                    <QuestionStats
                      nos={unanswered}
                      text="Unanswered"
                      color={colors.grey.dark}
                    />
                  </Col>
                </Row>
                <div className="questions-pallet">
                  {questions?.map((question: any, index) => {
                    return (
                      <Question
                        key={question?.id}
                        nos={initZoro(index + 1, 2)}
                        index={index}
                        type={
                          question?.marked
                            ? "marked"
                            : question?.visited
                            ? "visited"
                            : questionType(question?.isCorrect)
                        }
                        clickHandler={() => handleChangeQuestion(index)}
                      />
                    );
                  })}
                </div>
              </>
            </TabPane>

            <TabPane
              tab={
                <span className="tab__name">
                  <img src={AnalyticsIcon} />
                  View Analytics
                </span>
                // <p className="tab__name">
                //   <span>
                //     <img src={AnalyticsIcon} />
                //   </span>
                //   View Analytics
                // </p>
              }
              key="2"
            >
              <AnalyticsComponent
                attempts={studentExerciseResults}
                selectedAttempt={selectedResult}
                showAttempts={true}
                accuracy={selectedResult?.accuracy}
                speed={selectedResult?.averageTimePerQuestion}
                score={selectedResult?.score}
                duration={selectedResult?.averageTimePerQuestion}
                onChangeAttempt={(option: ExerciseAttemptResults) => {
                  studentExerciseResults?.map((result)=>{
                    option?.value==result?.id &&
                    setSelectedResult(result);
                  })
                  // setSelectedResult(option);
                }}
              />
            </TabPane>
          </Tabs>
        ) : (
          <>
            <Row className="mt-5">
              <Col xl={8} xxl={6}>
                <QuestionStats
                  nos={attempted}
                  text="Attempted"
                  color={colors.blue?.dark}
                />
              </Col>
              <Col xl={8} xxl={6}>
                <QuestionStats
                  nos={remaning}
                  text="Remaining"
                  color={colors.grey?.dark}
                />
              </Col>
              <Col xl={8} xxl={6}>
                <QuestionStats
                  nos={marked}
                  text="Marked for Review"
                  color={colors.orange?.dark}
                />
              </Col>
            </Row>

            <div className="questions-pallet">
              {questions?.map((question: any, index) => {
                return (
                  <Question
                    key={question?.id}
                    nos={initZoro(index + 1, 2)}
                    index={index}
                    type={
                      question?.marked
                        ? "marked"
                        : question?.visited
                        ? "visited"
                        : questionType(question?.isCorrect)
                    }
                    clickHandler={() => handleChangeQuestion(index)}
                  />
                );
              })}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default AuthContainer(QuestionContainer(QuestionsList));
