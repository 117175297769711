import { Col, Row } from "antd";
import React, { FC, useState } from "react";
import ForgotPasswordImage from "../../../../assets/images/select-class.svg";
import "./forgotPassword.scss";
import Logo from "../../../../assets/images/app-icon-white.svg";
import { Form, Formik } from "formik";
import { forgotPasswordValidation } from "./forgotPasswordValidation";
import InputField from "../../../../shared/components/InputField";
import MailIcon from "../../../../assets/icon/mail.svg";
import UIButton from "../../../../shared/components/UIButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  LOGIN,
  RESET_PASSWORD,
} from "../../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router";
import PasswordResetLink from "../PasswordResetLink";
import PasswordResetImage from "../../../../assets/images/verify-otp.svg";
import AuthService from "../../../../services/AuthService/auth.service";
import AuthContainer from "../../../../store/container/AuthContainer";


const LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../../assets/${appName}/app-icon-white.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../../assets/images/app-icon-white.svg')} {...rest} />;
  }
}
interface ForgotPasswordProps {
  app_name?: string
}

const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
  const { app_name } = props;
  const [initialValues, setInitialValues] = useState({ email: "" });
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");

  const onSubmit = (values: any) => {
    AuthService.sendResetPasswordEmail(
      { email: values?.email, redirectUrl: `${window.location.origin}${RESET_PASSWORD}` },
      () => {
        setEmail(values?.email);
        setStep(1);
      },
      () => { },
      () => { }
    );
  };
  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={step ? PasswordResetImage : ForgotPasswordImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <LoadImage  className="mindjjo__logo" appName={app_name} />
          {step ? (
            <PasswordResetLink
              email={email}
              wrongHandler={() => {
                setStep(0);
                setEmail("");
              }}
              loginHandler={() => {
                history.push(LOGIN);
              }}
            />
          ) : (
            <div className="forgot-password">
              <h2>Forgot Password</h2>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={forgotPasswordValidation}
              >
                {({ values, isValid }) => (
                  <Form>
                    <Row>
                      <Col span={24}>
                        <InputField
                          type="email"
                          name="email"
                          placeholder="Mail Id"
                          className="mt-5"
                          suffix={<img src={MailIcon} />}
                        />
                      </Col>
                      <Col span={24}>
                        <div className="btn__wrapper">
                          <UIButton
                            type="submit"
                            className="mt-2"
                            icon={<ArrowRightOutlined />}
                          >
                            Send Password Reset Link
                          </UIButton>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              <p className="forgot-password__login">
                Know your password?{" "}
                <span onClick={() => history.push(LOGIN)}> Login here</span>
              </p>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default AuthContainer(ForgotPassword);
