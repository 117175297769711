import React, { FC, useEffect } from "react";
import UIButton from "../../../../shared/components/UIButton";
import "./passwordUpdateLink.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import AuthService from "../../../../services/AuthService/auth.service";
import { RESET_PASSWORD, UPDATE_PASSWORD } from "../../../../routes/routeConstants/appRoutes";

interface PasswordUpdateLinkProps {
  email: string;
  loginHandler: () => void;
}

const PasswordUpdateLink: FC<PasswordUpdateLinkProps> = (props) => {
  const { email, loginHandler } = props;
  const sendUpdatePasswordEmail = () => {
    AuthService.sendResetPasswordEmail(
      { email, redirectUrl: `${window.location.origin}${UPDATE_PASSWORD}` },
      () => {},
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    sendUpdatePasswordEmail();
  }, [])
  return (
    <div className="password-update-link">
      <h2>Update your password</h2>
      <p className="password-update-link__info">
        Password Update Link is sent to
        <br />
        {email}
      </p>
      <div className="password-update-link__controllers">
        <UIButton
          type="button"
          icon={<ArrowRightOutlined />}
          clickHandler={loginHandler}
        >
          Proceed to Login
        </UIButton>
      </div>
    </div>
  );
};

export default PasswordUpdateLink;
