import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect } from "react";
import { Course, Student } from "../../../../models/student.model";
import PlaceholderIcon from "../../../../assets/images/placeholder.svg";
import PlaceholderSsvmIcon from "../../../../assets/images/placeholder-ssvm.svg";
import PlaceholderDarkIcon from "../../../../assets/images/placeholder-dark.svg";
import PlaceholderSsvmDarkIcon from "../../../../assets/images/placeholder-ssvm-dark.svg";
import AuthContainer from "../../../../store/container/AuthContainer";
import ProceedIcon from "../../../../assets/icon/proceed.svg";
import MyProfileIcon from "../../../../assets/icon/myprofile.svg";
import analytics from "../../../../assets/icon/analytics.svg";
import leaderboard from "../../../../assets/icon/leaderboard.svg";
import SwitchProfileIcon from "../../../../assets/icon/switch-profile.svg";
import SubscriptionIcon from "../../../../assets/icon/subscription.svg";
import SettingsIcon from "../../../../assets/icon/settings.svg";
import LogoutIcon from "../../../../assets/icon/logout.svg";
import ContactUsIcon from "../../../../assets/icon/contact-us.svg";
import { CheckCircleFilled } from "@ant-design/icons";
import "./profileModal.scss";
import { Divider } from "antd";
import { StudentService } from "../../../../services/StudentService/student.service";
import { LocalStorage } from "../../../utils/LocalStorageHelpers";
import { useHistory } from "react-router";
import { setUnAuthenticated } from "../../../../store/actions/auth";
import * as appRoutes from "../../../../routes/routeConstants/appRoutes";
import { useLocation } from "react-router-dom";
import ContentPlaceholder from "../../../../assets/images/content-placeholder.svg"
import { store } from '../../../../store';
import { UNAUTHENTICATED } from '../../../../store/definitions/authConstants';

interface ProfileModalProps {
  visible: boolean;
  closeHandler: () => void;
  // currentProfile: Student;
  user: Student,
  currentCourse: Course;
  setCurrentCourse: (course: Course | null) => void;
  setType: (type: any) => void;
  courses: Course[];
  onClickSwitchProfile: () => void;
  app_name: string;
}
interface ProfileMenuCardProps {
  title: string;
  icon: any;
  showDivider?: boolean;
  onClick: () => void;

}
const ProfileMenuCard: FC<ProfileMenuCardProps> = ({
  title,
  icon,
  showDivider = true,
  onClick,
}) => {
  return (
    <>
      <div className="profile__menu" onClick={onClick}>
        <div className="profile-menu__wrapper">
          <div className="profile-menu__icon">
            <img src={icon} />
          </div>
          <p className="profile-menu__title">{title}</p>
        </div>
        <div className="profile-menu-proceed__icon">
          <img src={ProceedIcon} />
        </div>
      </div>
      {showDivider && <Divider />}
    </>
  );
};
const ProfileModal: FC<ProfileModalProps> = ({
  visible,
  closeHandler,
  currentCourse,
  user,
  courses,
  setCurrentCourse,
  onClickSwitchProfile,
  setType,
  app_name
}) => {
  const history = useHistory();
  const location = useLocation();
  const onLogout = () => {
    localStorage.clear();
    store.dispatch({
      type: UNAUTHENTICATED,
      payload: {
        authenticated: false,
        user: null,
        courseId: null,
        currentCourse: null
      },
    });
    history.push(appRoutes.LOGIN);
  };

  const onSelectCourse = (course: Course) => {
    if (currentCourse?.id !== course?.id) {
      if (location?.pathname?.includes("my-progress")) {
        history.push(appRoutes.ANALYTICS);
      }
      if (location?.pathname?.includes("practice")) {
        history.push(appRoutes.PRACTICE);
      }
      if (location?.pathname?.includes("learn")) {
        history.push(appRoutes.LEARN);
      }

      StudentService.updateStudentCourse(
        course?.id,
        {
          isActive: true,
        },
        (response: any) => {
          setCurrentCourse(response);
          setType(response?.course?.type);
          LocalStorage.setItem("CURRENT_COURSE", response);
          LocalStorage.setItem("COURSE_ID", response?.id);
        },
        () => { },
        () => {
          closeHandler()
        }
      );
    }
  };

  const profilePicSrc = () => {
    const isSsvmKidscasa = app_name === 'ssvmkidscasa';
    const isDark = user?.displayPictureUrl;

    return isSsvmKidscasa ?
      (isDark ? PlaceholderSsvmDarkIcon : PlaceholderSsvmIcon) :
      (isDark ? PlaceholderDarkIcon : PlaceholderIcon);
  };

  return (
    <Modal
      visible={visible}
      className="profile-modal"
      mask={false}
      onCancel={closeHandler}
    >
      <div className="profile-modal__wrapper">
        <div className="profile-image__container">
          <img src={profilePicSrc()} />
        </div>
        <div className="profile-content__container">
          <p className="profile-welcome__note">
            Hi {`${user?.firstName}`}, What are you preparing for?
          </p>
          <div className="profile-courses__container">
            {courses?.filter(data => data.isActive === true)?.map((value, index) => {
              return (
                <div
                  key={"profile-courses#" + index}
                  onClick={() => onSelectCourse(value)}
                  className={`profile-courses__card ${currentCourse?.id == value.id &&
                    "profile-courses-card__selected"
                    }`}
                >
                  <div className="profile-courses-checkbox__container">
                    {currentCourse?.id == value.id ? (
                      <CheckCircleFilled className="profile-courses__check" />
                    ) : (
                      <div className="profile-courses__uncheck" />
                    )}
                  </div>
                  <div className="profile-course__logo">
                    <img
                      src={
                        value?.course?.classRoom?.displayPictureUrl ||
                        value?.course?.exam?.displayPictureUrl || ContentPlaceholder
                      }
                    />
                  </div>
                  <div className="profile-course__details">
                    <p className="profile-course__board">
                      {value?.course?.classRoom?.boardAcronym ||
                        value?.course?.exam?.acronym}
                    </p>
                    <p className="profile-course__name">
                      {value?.course?.classRoom?.name ||
                        value?.course?.exam?.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="profile-menus__container">
            <ProfileMenuCard
              icon={analytics}
              title={"My Progress"}
              onClick={() => {
                history.push(appRoutes.ANALYTICS);
                closeHandler()
              }}
            />
            <ProfileMenuCard
              icon={leaderboard}
              title={"Leaderboard"}
              onClick={() => {
                history.push(appRoutes.LEADERBOARD);
                closeHandler()
              }}
            />
            <ProfileMenuCard
              icon={MyProfileIcon}
              title={"My Profile"}
              onClick={() => {
                history.push(appRoutes.MY_PROFILE);
                closeHandler()
              }}
            />
            {/* <ProfileMenuCard
              icon={SwitchProfileIcon}
              title={"Switch Profile"}
              onClick={onClickSwitchProfile}
            /> */}
            {/* <ProfileMenuCard
              icon={SubscriptionIcon}
              title={"Subscription"}
              onClick={() => {}}
            /> */}
            <ProfileMenuCard
              icon={SettingsIcon}
              title={"Settings"}
              onClick={() => { }}
            />
            <ProfileMenuCard
              icon={ContactUsIcon}
              title={"Contact Us"}
              onClick={() => { }}
            />
            <ProfileMenuCard
              icon={LogoutIcon}
              title={"Logout"}
              showDivider={false}
              onClick={() => onLogout()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AuthContainer(ProfileModal);
