import React, { FC } from "react";
import "./checkboxField.scss";
import { CheckCircleFilled } from "@ant-design/icons";
interface CheckboxFieldProps {
  checked: boolean;
  checkColor?: string;
  uncheckColor?: string
}
export const CheckboxField: FC<CheckboxFieldProps> = ({
  checked,
  checkColor,
  uncheckColor
}) => {
  return (
    <div className="checkbox-field__container">
      {checked ? (
        <CheckCircleFilled className="check" style={{ color: checkColor ?? "white" }} />
      ) : (
        <div className={`uncheck ${uncheckColor}`}></div>
      )}
    </div>
  );
};
