import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { ChapterContent } from "../../../../../models/Chapters/chapter.model";
import { Course } from "../../../../../models/student.model";
import { ContentService } from "../../../../../services/ContentService/content.service";
import VideoCard from "../../../../../shared/components/VideoCard";
import { LocalStorage } from "../../../../../shared/utils/LocalStorageHelpers";
import AuthContainer from "../../../../../store/container/AuthContainer";
import "./relatedContent.scss";
interface RelatedContentProps {
  type: string;
  currentCourse: Course;
  chapterId: number;
  contentId?: number
}
const RelatedContent: FC<RelatedContentProps> = ({ type, currentCourse, chapterId, contentId }) => {
  const [contents, setContents] = useState<ChapterContent[]>([]);

  const { state }: { state: any } = useLocation();

  const params: any = useParams();

  const fetchChapterContents = () => {
    if(type == "exam") {
      ContentService.getExamChapterContents(
        {},
        {
          courseId: currentCourse?.id,
          chapterId: chapterId,
        },
        (contents: ChapterContent[]) => {
          setContents(contents.filter(data => !data.isCompleted && data?.id !== contentId));
        },
        () => {}
      ) 
        return
    }
    if(type == "class_room") {
      ContentService.getChapterContents(
        {},
        {
          courseId: currentCourse?.id,
          chapterId: chapterId,
        },
        (contents: ChapterContent[]) => {
          setContents(contents.filter(data => !data.isCompleted && data?.id !== contentId));
        },
        () => {}
      ) 
      return
    }
      
  };
  useEffect(() => {
    fetchChapterContents();
  }, []);
  return (
    <div className="related-content__container">
      <p className="title">Related Content</p>
      <div className="related-video__wrapper">
        {contents.map(({ title, description, contentLibrary, id, subjectName, examSubjectName }) => (
          <VideoCard
            color="#FD6C00"
            subject={subjectName || examSubjectName}
            content={title}
            thumbnail={contentLibrary?.thumbnailUrl}
            id={id}
            chapterId={state?.chapterId}
            contentLibrary={contentLibrary}
            url={contentLibrary?.attachmentUrl}
            contentType={contentLibrary?.contentType}
            subjectId={params?.subjectId}
          />
        ))}
      </div>
    </div>
  );
};

export default AuthContainer(RelatedContent);
