import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Col, Row, Skeleton } from "antd";
import EmptyState from "../../../shared/components/EmptyState";
import CartItem from "./CartItem";
import CheckoutCard from "./CheckoutCard";


import TitleContainer from "../../../store/container/TitleContainer";
import AuthContainer from "../../../store/container/AuthContainer";

import { Course, Student } from "../../../models/student.model";
import { CartsModel, CartModel } from "../../../models/CartModel/cart.model";
import CartService from "../../../services/CartService/cart.service";


import "./cart.scss";
import DeleteAlert from "../../../shared/components/DeleteAlert";


export const SkeletonLoader = (): any => {
    return Array(2)
        .fill(" ")
        .map((value, idex) => {
            return (
                <div key={"Skeleton" + idex} className="skeleton-loader">
                    <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
                </div>
            );
        });
};


interface CartProps {
    setTitle: Function;
    // currentProfile: any;
    user: Student;
    currentCourse: Course;
    app_name: string
}



const Cart: FC<CartProps> = ({ setTitle, user, currentCourse, app_name }) => {
    const history = useHistory();

    const [cartData, setCartData] = useState<CartModel[]>([]);
    const [cartDetails, setCartDetails] = useState<CartsModel>({});
    const [loading, setLoading] = useState(false);
    const [deleteVisible, setDeleteVisibility] = useState(false);
    const [currentCartItem, setCurrentCartItem] = useState<CartModel | null>();
    const [checkoutAmount, setCheckoutAmount] = React.useState<any>({});

    const [loadingForCoupon, setLoadingForCoupon] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [appliedCoupon, setAppliedCoupon] = useState("");


    const fetchCartList = () => {
        setLoading(true);
        CartService.getCartList(
            (data: any, cartDetails: any) => {
                setCartData(data);
                setCartDetails(cartDetails)
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        setTitle({
            title: `Cart`,
            showBack: true,
            subText: "",
            icon: null,
            showSearch: false,
            float: false,
            searchText: "Search subjects, topics ...",
            twoBgs: false,
        });
        fetchCartList();
    }, []);

    const handleDelete = () => {
        setDeleteVisibility(false);
        setLoading(true);
        CartService.deleteFromCart(
            currentCartItem,
            () => {
                fetchCartList();
                setCurrentCartItem(null);
            },
            () => { },
            () => { }
        );
    };

    /*-------------------handel Apply Coupon--------------------*/
    const handelApplyCoupon = () => {
        setLoadingForCoupon(true)
        CartService.applyCoupon(
            coupon,
            (data: any) => {
                const newCartItems = cartData.map(item => {
                    data.forEach(data => {
                        if (data?.productId === item.productId)
                            item.totalAmount = data.totalAmount
                    })
                    return item
                })
                setCartData(newCartItems)
                setAppliedCoupon(coupon)
                setCoupon("")
            },
            () => { },
            () => {
                setLoadingForCoupon(false);
            }
        );
    }

    /*-------------------handel Apply Coupon--------------------*/
    const handelRemoveCoupon = () => {
        const newCartItems = cartData.map(item => {
            item.totalAmount = item.amount
            return item
        })
        setCartData(newCartItems)
        setAppliedCoupon("")
    }

    /*-----------------------Handel Checkout--------------------*/
    const handelCheckout = () => {
        const payload = {
            "cartId": cartDetails.cartId,
            "couponCode": appliedCoupon,
            "domain": document.location.host,
            "type": ""
        }
        setLoading(true);
        CartService.initiateTransaction(
            payload,
            (data: any) => {
                makePaymentPage(data)
            },
            () => { },
            () => {
                setLoading(false);
            }
        );

    }



    const makePaymentPage = (formData: any) => {

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        ; form.setAttribute("action", formData.checkoutURL);

        var FN = document.createElement("input");
        FN.setAttribute("type", "hidden");
        FN.setAttribute("name", 'encRequest');
        FN.setAttribute("id", 'encRequest');
        FN.setAttribute("value", formData.encString);
        form.appendChild(FN)

        var F2 = document.createElement("input");
        F2.setAttribute("type", "hidden");
        F2.setAttribute("name", 'access_code');
        F2.setAttribute("id", 'access_code');
        F2.setAttribute("value", formData.accessCode);
        form.appendChild(F2)

        document.body.appendChild(form)
        form.submit();
    }


    useEffect(() => {
        const TOTEL_AMOUNT = cartData?.reduce((totalAmount, currentValue) => totalAmount + Number(currentValue.totalAmount), 0)
        const AMOUNT = cartData?.reduce((totalAmount, currentValue) => totalAmount + Number(currentValue.amount), 0)
        setCheckoutAmount({
            amount: Number(AMOUNT?.toFixed(2)),
            totelAmount: Number(TOTEL_AMOUNT?.toFixed(2))
        })
    }, [cartData])

    return (
        <div className="cart__container">
            {loading || cartData?.length ? (
                <div >
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <Row gutter={[10, 24]}>
                            <Col span={16} >
                                {
                                    cartData?.map((record, index) => (
                                        <CartItem
                                            key={'cart' + index}
                                            data={record}
                                            onClick={() => { }}
                                            handleDelete={() => {
                                                setCurrentCartItem(record)
                                                setDeleteVisibility(true)
                                            }
                                            }
                                        />
                                    ))
                                }
                            </Col>
                            <Col span={8}>
                                <CheckoutCard
                                    coupon={coupon}
                                    appliedCoupon={appliedCoupon}
                                    setCoupon={setCoupon}
                                    payableAmount={checkoutAmount}
                                    loading={loadingForCoupon}
                                    onApplyCoupon={handelApplyCoupon}
                                    onRemoveCoupon={handelRemoveCoupon}
                                    onCheckout={handelCheckout}
                                />
                            </Col>
                        </Row>

                    )}
                </div>
            ) : (
                <EmptyState text={"No Cart item found."} />
            )}

            <DeleteAlert
                title={currentCartItem?.productName}
                detail={(currentCartItem?.amount || 0) + " Amount"}
                visible={deleteVisible}
                setVisible={setDeleteVisibility}
                deleteText="Product"
                deleteWarning="This product will be deleted permanently"
                handleDelete={handleDelete}
            />

        </div>
    )
}

export default AuthContainer(TitleContainer(Cart))