import React, {FC} from "react";
import './deleteAlert.scss';
import {Modal} from "antd";
import closeIcon from "../../../assets/icon/close.svg";
import deleteAlertIcon from "../../../assets/icon/alert-triangle.svg";
import ButtonComponent from "../ButtonComponent";

interface DeleteAlertProps{
    title?: string;
    logo?: string;
    detail?: string;
    visible: boolean;
    setVisible: Function;
    deleteText?: string;
    deleteContent?: string;
    deleteWarning?: string;
    contentTitle?:string;
    contentSubject?:string;
    contentThumbnail?:string;
    handleDelete?:()=>void;
    blacklist?:string;
    setBlacklistReason?:Function;
}

const DeleteAlert:FC<DeleteAlertProps> = (props) => {

    const {title, logo, detail, visible, setVisible, deleteText, deleteWarning, deleteContent,
        contentTitle, contentSubject, contentThumbnail, handleDelete, blacklist,
    setBlacklistReason} = props;

    const handleClose = () => {
        setVisible(false)
    }

    return (
        <Modal title={null}
               centered
               visible={visible}
               onCancel={handleClose}
               footer={null}
               className='delete-alert__modal'
               closeIcon={<img src={closeIcon} alt=''/>}
        >
            <div className='delete-alert__icon'>
                <img src={deleteAlertIcon} alt='delete'/>
            </div>
            {deleteText && <div className='delete-alert__text'>
                Are you sure to delete the <span>{deleteText}</span>?
            </div>}
            {deleteContent && <div className='delete-alert__text'>
                {deleteContent}
            </div>}
            <div className='delete-alert__warning'>
                {deleteWarning}
            </div>
            {logo && <div className='delete-alert__logo'>
                <img src={logo} alt=''/>
            </div>}
            {contentThumbnail && <div className='delete-alert__thumbnail'>
                <img src={contentThumbnail} alt=''/>
            </div>}
            {title && <div className='delete-alert__title'>
                {title}
            </div>}
            {contentTitle && <div className='delete-content__title'>
                {contentTitle}
            </div>}
            {detail && <div className='delete-alert__detail'>
                {detail}
            </div>}
            {contentSubject && <div className='delete-alert__detail delete-content__detail'>
                {contentSubject}
            </div>}
            {blacklist && <div className='blacklist-reason'>
                <div className='label'>Reason for {blacklist==='add' ? 'blacklisting' : 'removing from blacklist'}</div>
                <input type={'text'}
                        name={'reason'}
                        placeholder={'Type here'}
                        onChange={(e:any)=>{
                            console.log(e.target.value)
                            if(setBlacklistReason) setBlacklistReason(e.target.value)
                        }}
                />
            </div>}
            <div className='delete-alert__action'>
                <div className='delete-alert__button'>
                    <ButtonComponent buttonContent='Cancel'
                                     type='text'
                                     onClick={handleClose}
                    />
                </div>
                <div className='delete-alert__button'>
                    <ButtonComponent buttonContent={blacklist==='add' ? 'Blacklist'
                        : blacklist==='remove' ? 'Remove' : 'Delete'}
                                     type='primary'
                                     onClick={handleDelete}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeleteAlert;
