import { generatePath } from "react-router-dom";
import { deserialize,serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import _axiosInstance from "../../interceptor/_axiosInstance";

import { 
  JoinLiveClassResponseModel, 
  LiveClassModel,
  JoinLiveClassModel,
 } from "../../models/LiveClass/liveClass.model";


import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
export default class LiveClassService {
  static fetchUpcomingLiveClasses(
    params: { search?: string },
    onSuccess: (value: LiveClassModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.UPCOMING_LIVE_CLASSES, { params })
      .then((response) => {
        const data = deserialize(
          LiveClassModel,
          response.data["live_classes"]
        ) as LiveClassModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchLiveClassesNow(
    params: { search?: string },
    onSuccess: (value: LiveClassModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.LIVE_CLASSES_NOW, { params })
      .then((response) => {
        const data = deserialize(LiveClassModel, response.data["live_classes"]) as LiveClassModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static joinLiveClass(
    liveClassId: number,
    onSuccess: (value: JoinLiveClassResponseModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_JOIN, { liveClassId });
    axiosInstance
    .post(API_URL)
    .then((response) => {
      const data = deserialize(JoinLiveClassResponseModel, response.data["live_class"]);
      onSuccess(data);
    })
    .catch((error) => {
      const { response } = error
      onError(response?.data?.error);
    })
    .finally(() => {
      onFinal();
    });
  }

  static joinLiveClass_Zoom(
    data: JoinLiveClassModel,
    onSuccess: (value: string) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = serialize(JoinLiveClassModel, data);
    _axiosInstance.post(ApiRoutes.LIVE_CLASS_JOIN_NEW, payload)
      .then((response) => {
        if (response.data.isSuccess) {
          onSuccess(response.data.message);
        } else {
          Notification({ message: "Error", description: 'something went wrong!', type: NotificationTypes.ERROR });
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }


  static fetchLiveClassDetails(
    liveClassId: number,
    onSuccess: (value: LiveClassModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_DETAILS, { liveClassId });
    axiosInstance
    .get(API_URL)
    .then((response) => {
      const data = deserialize(LiveClassModel, response.data["live_class"]);
      onSuccess(data);
    })
    .catch((error) => {
      onError();
    })
    .finally(() => {
      onFinal();
    });
  }
  static endLiveClass(
    liveClassId: number,
    onSuccess: (value: JoinLiveClassResponseModel) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.LIVE_CLASS_END, { liveClassId });
    axiosInstance
    .post(API_URL)
    .then((response) => {
      const data = deserialize(JoinLiveClassResponseModel, response.data["live_class"]);
      onSuccess(data);
    })
    .catch((error) => {
      onError();
    })
    .finally(() => {
      onFinal();
    });
  }
}
