import { Col, Collapse, Divider, Input, Row, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import PDFReader from "./PDFReader";
import RelatedContent from "./RelatedContent";
import "./studyMaterial.scss";
import VideoPlayer from "./VideoPlayer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UpvoteActiveIcon from "../../../../assets/icon/upvote-active.svg";
import UpvoteIcon from "../../../../assets/icon/upvote.svg";
import DownvoteIcon from "../../../../assets/icon/downvote.svg";
import DownvoteActiveIcon from "../../../../assets/icon/downvote-active.svg";
import MarkCompletedIconActive from "../../../../assets/icon/mark-completed-active.svg";
import MarkCompletedIcon from "../../../../assets/icon/mark-completed.svg";
import QuickQuizIcon from "../../../../assets/icon/quickquiz.svg";
import NotesIcon from "../../../../assets/icon/notes.svg";
import Modal from "antd/lib/modal/Modal";
import UIModal from "../../../../shared/components/UIModal";
import { QuestionReducerProps } from "../../../../store/reducers/questionReducer";
import { IAuthState } from "../../../../store/reducers/authReducer";
import QuestionContainer from "../../../../store/container/QuestionContainer";
import { ContentService } from "../../../../services/ContentService/content.service";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import AuthContainer from "../../../../store/container/AuthContainer";
import InstructionPopup from "../../PracticeModule/PracticeChapter/Quizes/Instruction";
import { Question } from "../../../../models/Question/question.model";
import {
  QUICK_QUIZ,
  QUICK_QUIZ_RESULTS,
  STUDY_MATERIAL,
} from "../../../../routes/routeConstants/appRoutes";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { ChapterContent } from "../../../../models/Chapters/chapter.model";
import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import Paragraph from "antd/lib/typography/Paragraph";
import UIButton from "../../../../shared/components/UIButton";
const { Panel } = Collapse;
interface StudyMaterialProps extends QuestionReducerProps, IAuthState {
  setTitle: (title: ITitleState) => void;
  authenticated;
}
const StudyMaterial: FC<StudyMaterialProps> = ({
  setExamAttempt,
  setQuestions,
  type,
  setTitle,
  authenticated,
}) => {
  const { state }: { state: any } = useLocation();
  const params: any = useParams();
  const [content, setContent] = useState<ChapterContent>();
  const [response, setResponse] = useState<"unset" | "liked" | "disliked">(
    "unset"
  );
  const history = useHistory();
  const [showNotes, setNotesVisibility] = useState<boolean>(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [attemptId, setAttemptId] = useState<number>();
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const fetchContent = () => {
    if (type == "exam") {
      ContentService.getExamChapterContent(
        params.chapterId,
        params.contentId,
        (content) => {
          setContent(content);
          setIsLoading(false);
        },
        () => { }
      );
      return;
    }

    if (type == "class_room") {
      ContentService.getChapterContent(
        params.chapterId,
        params.contentId,
        (content) => {
          setContent(content);
          setIsLoading(false);
        },
        () => { }
      );
      return;
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const handleOkInstruction = (
    Service: any,
    attemptId?: number,
    viewAns?: boolean
  ) => {
    if (attemptId) {
      Service(
        attemptId,
        (questions: Question[]) => {
          if (questions.length) {
            setQuestions(questions);
            if (viewAns) {
              history.push({
                pathname: generatePath(QUICK_QUIZ_RESULTS, {
                  id: content?.id,
                  ...state,
                  ...params,
                }),
                state: {
                  ...state,
                  ...params,
                  examName: content?.title,
                  url: STUDY_MATERIAL,
                  contentLibraryId: content?.contentLibrary?.id
                },
              });
              return;
            }
            setInfoPopup(false);
            history.push({
              pathname: generatePath(QUICK_QUIZ, {
                id: content?.id,
                ...params,
                ...state,
              }),
              state: {
                ...state,
                ...params,
                examName: content?.title,
                url: STUDY_MATERIAL,
                contentLibraryId: content?.contentLibrary?.id
              },
            });
          } else {
            Notification({
              message: "No questions",
              description: "Questions not yet added",
              type: NotificationTypes.ERROR,
            });
          }
        },
        () => { }
      );
    }
  };
  const createAttempt = (contentId: any, contentAttempt: any) => {
    if (
      content?.contentLibrary?.contentExerciseAttempt &&
      content?.contentLibrary?.contentExerciseAttempt.completedAt
    ) {
      setExamAttempt(contentAttempt);
      handleOkInstruction(
        ContentService.getContentQuestionsWithAnswers,
        contentAttempt?.id,
        true
      );
      return;
    }
    ContentService.createContentAttempt(
      {
        courseId: LocalStorage.getItem("COURSE_ID"),
        contentLibraryId: contentId,
        chapterId: params.chapterId,
      },
      (attempt) => {
        setExamAttempt(attempt);
        setInfoPopup(true);
        setAttemptId(attempt.id);
      },
      () => {
        setExamAttempt(content?.contentLibrary?.contentExerciseAttempt as any);

        handleOkInstruction(
          ContentService.getContentQuestions,
          contentAttempt?.id
        );
      }
    );
  };

  const handleVote = (value: any) => {
    type == "exam"
      ? ContentService.updateExamChapterContent(
        {
          upvote: value,
        },
        params.chapterId,
        params.contentId,
        () => {
          let data = content as any;
          data.currentStudentVote = value;
          setContent({ ...data });
        },
        () => { }
      )
      : ContentService.updateChapterContent(
        {
          upvote: value,
        },
        params.chapterId,
        params.contentId,
        () => {
          let data = content as any;
          data.currentStudentVote = value;
          setContent({ ...data });
        },
        () => { }
      );
  };

  const updateNotes = () => {
    type == "exam"
      ? ContentService.updateExamChapterContent(
        {
          notes: note,
        },
        params.chapterId,
        params.contentId,
        () => { },
        () => { }
      )
      : ContentService.updateChapterContent(
        {
          notes: note,
        },
        params.chapterId,
        params.contentId,
        () => { },
        () => { }
      );
  };
  // console.log(content)

  const markAsCompleted = () => {
    type == "exam"
      ? ContentService.updateExamChapterContent(
        {
          isCompleted: true,
        },
        params.chapterId,
        params.contentId,
        () => {
          setContent({ ...content, isCompleted: true });
        },
        () => { }
      )
      : ContentService.updateChapterContent(
        {
          isCompleted: true,
        },
        params.chapterId,
        params.contentId,
        () => {
          setContent({ ...content, isCompleted: true });
        },
        () => { }
      );
  };

  return (
    <div className="study-material__container">
      {isLoading ? (
        <div className="loading__container">
          <Spin size="large" tip="Loading content..." />
        </div>
      ) : (
        <>
          <InstructionPopup
            okHandler={() => {
              handleOkInstruction(
                ContentService.getContentQuestions,
                attemptId
              );
            }}
            closeHandler={() => setInfoPopup(false)}
            visiblity={infoPopup}
          />
          <Row className="video__body" justify="space-between">
            <Col xs={24} xl={17}>
              <div className="video-content">
                <div className="header">
                  <ArrowLeftOutlined onClick={() => history.goBack()} />
                  <div className="nav-content__wrapper">
                    <p className="nav__content">{content?.title}</p>
                  </div>
                </div>

                {content?.contentLibrary?.contentType === "video" && (
                  <VideoPlayer
                    updateCompletion={() => markAsCompleted()}
                    fileUrl={(content?.contentLibrary.contentUrl ? content?.contentLibrary.contentUrl : content?.contentLibrary.attachmentUrl) ?? ""}
                    contentLibraryId={(content?.contentLibrary?.id) || ""}
                  />
                )}
                {content?.contentLibrary?.contentType === "pdf" && (
                  <PDFReader
                    fileUrl={content?.contentLibrary.attachmentUrl ?? ""}
                  />
                )}
                {content?.contentLibrary?.contentType === "external_link" && (
                  <iframe
                    style={{ width: "100%", height: "75vh" }}
                    src={content?.contentLibrary.contentUrl}
                    allowFullScreen
                  ></iframe>
                )}
                <UIModal
                  title={content?.title}
                  subtitle="Notes"
                  visible={showNotes}
                  noControl={false}
                  closeHandler={() => {
                    if (note) {
                      updateNotes();
                    }
                    setNotesVisibility(false);
                  }}
                >
                  <Input.TextArea
                    className="content__notes"
                    defaultValue={content?.notes}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </UIModal>
                <div className="content__panel">
                  <div className="header__left">
                    <div className="nav-content__wrapper">
                      <p className="nav__content">{content?.title}</p>
                      {
                        (state?.subjectName && state?.chapterName) ? <p className="nav__subject">
                          {state?.subjectName}&nbsp;&nbsp;|&nbsp;&nbsp;
                          {state?.chapterName}
                        </p> : ""
                      }

                    </div>
                  </div>
                  <hr className='horizontal-divider' style={{color: 'green'}}/>
                  <div className="header__right">
                    <Row justify="end">
                      <Col xl={5} xxl={4}>
                        <div className="text-center">
                          {content?.currentStudentVote == true ? (
                            <img
                              src={UpvoteActiveIcon}
                              onClick={(event) => {
                                handleVote(
                                  content?.currentStudentVote == true
                                    ? "reset"
                                    : true
                                );
                                event?.stopPropagation();
                              }}
                            />
                          ) : (
                            <img
                              src={UpvoteIcon}
                              onClick={(event) => {
                                handleVote(
                                  content?.currentStudentVote == "reset" ||
                                    !content?.currentStudentVote
                                    ? true
                                    : "reset"
                                );
                                event?.stopPropagation();
                              }}
                            />
                          )}
                          <p
                            className={
                              content?.currentStudentVote == true
                                ? "vote-active"
                                : "vote-inactive"
                            }
                          >
                            Upvote
                          </p>
                        </div>
                      </Col>
                      <Col xl={5} xxl={4}>
                        <div className="text-center">
                          {content?.currentStudentVote == false ? (
                            <img
                              src={DownvoteActiveIcon}
                              onClick={(event) => {
                                handleVote(
                                  content?.currentStudentVote == false
                                    ? "reset"
                                    : true
                                );
                                event?.stopPropagation();
                              }}
                            />
                          ) : (
                            <img
                              src={DownvoteIcon}
                              onClick={(event) => {
                                handleVote(
                                  content?.currentStudentVote == "reset" || true
                                    ? false
                                    : "reset"
                                );
                                event?.stopPropagation();
                              }}
                            />
                          )}
                          <p
                            className={
                              content?.currentStudentVote == false
                                ? "vote-active"
                                : "vote-inactive"
                            }
                          >
                            Downvote
                          </p>
                        </div>
                      </Col>
                      <Col xl={5} xxl={4}>
                        <div className="text-center">
                          {content?.isCompleted ? (
                            <img
                              src={MarkCompletedIconActive}
                              alt={'img'}
                              onClick={(event) => {
                                event?.stopPropagation();
                              }}
                            />
                          ) : (
                            <img
                              src={MarkCompletedIcon}
                              alt={'img'}
                              onClick={(event) => {
                                // markAsCompleted();
                                event?.stopPropagation();
                              }}
                            />
                          )}
                          <p className="mark-complete">
                            {content?.isCompleted
                              ? "Completed"
                              : "Mark Complete"}
                          </p>
                        </div>
                      </Col>
                      <Col xl={5} xxl={4}>
                        <div
                          className="text-center"
                          onClick={(event) => {
                            setNotesVisibility(true);
                            event?.stopPropagation();
                          }}
                        >
                          <img src={NotesIcon} className='notes-icon'/>
                          <p className="notes">Notes</p>
                        </div>
                      </Col>
                      <Col xl={4} xxl={4}>
                        <div
                          className="text-center"
                          onClick={(event) => {
                            createAttempt(
                              content?.contentLibrary?.id,
                              content?.contentLibrary?.contentExerciseAttempt
                            );
                            event?.stopPropagation();
                          }}
                        >
                          <img src={QuickQuizIcon} />
                          <p className="quick-quiz">Quick Quiz</p>
                        </div>
                      </Col>
                      {/* <Col span={6}>
                    <UIButton
                    type="button"
                    >
                      Mark as completed
                    </UIButton>
                  </Col> */}
                    </Row>
                  </div>
                </div>
                <Divider />
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: true,
                    symbol: "see more",
                  }}
                >
                  {content?.description}
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} xl={6}>
              <RelatedContent chapterId={params?.chapterId} contentId={content?.id} />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TitleContainer(AuthContainer(QuestionContainer(StudyMaterial)));
