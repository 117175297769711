import React, { FC, useEffect, useState } from "react";
import { Badge, Col, Dropdown, Input, Menu, Progress, Row } from "antd";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { ExerciseAttemptResults } from "../../../../models/Analytics/exercise.model";
import { ExamExercise } from "../../../../models/Exam/ExerciseModel/exercise.model";
import {
  ExcerciseAttempt,
  Exercise,
} from "../../../../models/Exercise/exercise.model";
import { Course } from "../../../../models/student.model";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import { ExamService } from "../../../../services/ExamService/exam.service";
import { ExerciseService } from "../../../../services/ExerciseService/exercise.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import AnalyticsComponent from "../AnalyticsComponent";
import { SearchOutlined } from "@ant-design/icons";
import "./listQuickQuizAnalytics.scss";
import { AnalyticsEmptyScreen } from "../EmptyScreen";
import { ContentService } from "../../../../services/ContentService/content.service";
import {
  ChapterContent,
  ContentLibrary,
} from "../../../../models/Chapters/chapter.model";
import {
  ContentExerciseAttemptResults,
  ContentExerciseAttempts,
} from "../../../../models/Analytics/content.model";
import QuestionContainer from "../../../../store/container/QuestionContainer";
import { QuestionReducerProps } from "../../../../store/reducers/questionReducer";
import { Question } from "../../../../models/Question/question.model";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import {
  QUICK_QUIZ,
  LIST_ANALYTICS_EXERCISES,
} from "../../../../routes/routeConstants/appRoutes";
import InstructionPopup from "../../PracticeModule/PracticeChapter/Quizes/Instruction";

interface listContentsProps extends QuestionReducerProps {
  currentCourse: Course;
  type: any;
}
const ListQuickQuizAnalytics: FC<listContentsProps> = ({
  currentCourse,
  type,
  setExamAttempt,
  setQuestions,
}) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a>1st menu item</a>
      </Menu.Item>
      <Menu.Item>
        <a>2nd menu item</a>
      </Menu.Item>
    </Menu>
  );
  const { state }: { state: any } = useLocation();
  const [selectedContent, setSelectedContent] = useState<ChapterContent>();
  const [exercises, setContents] = useState<ChapterContent[]>([]);
  const [studentContentResults, setStudentContentResults] = useState<
    ContentExerciseAttemptResults[]
  >([]);
  const history = useHistory();
  const params = useParams();

  const [selectedResult, setSelectedResult] =
    useState<ContentExerciseAttemptResults>();
  const [noAttempts, setNoAttempts] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [attemptId, setAttemptId] = useState<number>();
  const fetchContents = (params?: any) => {
    if (type == "exam") {
      ContentService.getExamChapterContents(
        params,
        {
          courseId: currentCourse.id,
          chapterId: state?.chapterId,
        },
        (response: ChapterContent[]) => {
          setContents(response);
          if (response.length) {
            setSelectedContent(response[0]);
            fetchStudentContentResults(
              response[0].contentLibrary?.contentExerciseAttempt?.id
            );
          }
        },
        () => {}
      );
    } else if (type == "class_room") {
      ContentService.getChapterContents(
        params,
        {
          courseId: currentCourse.id,
          chapterId: state?.chapterId,
        },
        (response: ChapterContent[]) => {
          setContents(response);
          if (response.length) {
            setSelectedContent(response[0]);
            fetchStudentContentResults(
              response[0].contentLibrary?.contentExerciseAttempt?.id
            );
          }
        },
        () => {}
      );
    }
  };

  const fetchStudentContentResults = (attemptId: any) => {
    if (attemptId) {
      AnalyticsService.fetchContentAnalytics(
        currentCourse?.id,
        attemptId,
        (response: ContentExerciseAttemptResults[]) => {
          //console.log(response);
          setStudentContentResults(response);
          if (response?.length) {
            setSelectedResult(response[0]);
          }
        },
        () => {}
      );
    } else {
      setNoAttempts(true);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  const handleOkInstruction = (
    Service: any,
    attemptId?: number,
    viewAns?: boolean
  ) => {
    if (attemptId) {
      Service(
        attemptId,
        (questions: Question[]) => {
          if (questions.length) {
            setQuestions(questions);
            setInfoPopup(false);
            history.push({
              pathname: generatePath(QUICK_QUIZ, {
                id: selectedContent?.id,
                videoId: selectedContent?.id,
              }),
              state: {
                examName: selectedContent?.title,
                url: LIST_ANALYTICS_EXERCISES,
                ...params,
                ...state,
              },
            });
          } else {
            Notification({
              message: "No questions",
              description: "Questions not yet added",
              type: NotificationTypes.ERROR,
            });
          }
        },
        () => {}
      );
    }
  };
  const createAttempt = (contentId: any, contentAttempt: any) => {
    if (
      selectedContent?.contentLibrary?.contentExerciseAttempt &&
      selectedContent?.contentLibrary?.contentExerciseAttempt.completedAt
    ) {
      setExamAttempt(contentAttempt);
      handleOkInstruction(
        ContentService.getContentQuestionsWithAnswers,
        contentAttempt?.id,
        true
      );
      return;
    }
    ContentService.createContentAttempt(
      {
        courseId: currentCourse?.id,
        contentLibraryId: contentId,
        chapterId: state.chapterId,
      },
      (attempt) => {
        setExamAttempt(attempt);
        setInfoPopup(true);
        setAttemptId(attempt.id);
      },
      () => {
        setExamAttempt(
          selectedContent?.contentLibrary
            ?.contentExerciseAttempt as ExcerciseAttempt
        );

        handleOkInstruction(
          ContentService.getContentQuestions,
          contentAttempt?.id
        );
      }
    );
  };
  return (
    <Row className="exercises__wrapper">
      <InstructionPopup
        okHandler={() => {
          handleOkInstruction(ContentService.getContentQuestions, attemptId);
        }}
        closeHandler={() => setInfoPopup(false)}
        visiblity={infoPopup}
      />
      {exercises.length == 0 ? (
        <AnalyticsEmptyScreen title={"No exercises"} />
      ) : (
        <>
          <Col span={12} style={{ height: "100%" }}>
            <div className="content-left__wrapper">
              {exercises?.map((value, index) => {
                return (
                  <div
                    className={`subject__card ${
                      selectedContent?.id == value.id && "subject-card__active"
                    }`}
                    onClick={() => {
                      setSelectedContent(value);
                      fetchStudentContentResults(
                        value?.contentLibrary?.contentExerciseAttempt?.id
                      );
                    }}
                  >
                    <p className="subject-card__name">{value?.title}</p>
                    <div className="subjects-details__container">
                      <p>{"0"} Attempts</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col span={12} style={{ height: "100%" }}>
            <div className="content__right">
              {noAttempts && !selectedResult ? (
                <div className="mtp-15">
                  <AnalyticsEmptyScreen
                    title={"No attempts"}
                    showButton
                    buttonText={"Take Test"}
                    clickHandler={() => {
                      createAttempt(
                        selectedContent?.contentLibrary?.id,
                        selectedContent?.contentLibrary?.contentExerciseAttempt
                      );
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="content-right__header">
                    <p>{selectedContent?.title}</p>
                  </div>
                  <AnalyticsComponent
                    attempts={studentContentResults}
                    selectedAttempt={selectedResult}
                    showAttempts={true}
                    accuracy={selectedResult?.accuracy}
                    speed={selectedResult?.averageTimePerQuestion}
                    score={selectedResult?.score}
                    duration={selectedResult?.duration}
                    onChangeAttempt={(
                      option: ContentExerciseAttemptResults
                    ) => {
                      setSelectedResult(option);
                    }}
                  />
                </>
              )}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};
export default AuthContainer(QuestionContainer(ListQuickQuizAnalytics));
