import { Carousel, Col, Row } from "antd";
import React, { FC, useEffect } from "react";
import { caroselData } from "./carosel";
import Subject from "../../../../assets/images/subject.png";
import "./learn.scss";
import ListSubjects from "./ListSubjects";
import ContinueWhereYouLeft from "./ContinueWhereYouLeft";
import TitleContainer from "../../../../store/container/TitleContainer";
import LeaderboardCupIcon from "../../../../assets/icon/cup.svg";
import PlaceholderIcon from "../../../../assets/images/placeholder-dark.svg";
import PointsIcon from "../../../../assets/icon/points.svg";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import { CHAPTERS } from "../../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../../store/container/AuthContainer";
import { Course, Student } from "../../../../models/student.model";
import UIButton from "../../../../shared/components/UIButton";
import firebase from "firebase";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";

const contentStyle = {
  background: `url(${Subject}) no-repeat center center fixed`,
};


let LearnCard = ({ appName, ...rest }) => {
  try {
    return <div style={{ background: `url(${require(`../../../../assets/${appName}/carousel-bg.svg`)}) no-repeat` }}  {...rest} />;
  }
  catch (err) {
    const windowWidth = window.innerWidth

    return <div style={{ background: `url(${require(windowWidth <= 768 ? '../../../../assets/images/logo_image.png' : '../../../../assets/images/carousel-bg.svg')}) no-repeat #18156d` }} {...rest} />;
  }
}


interface LearnProps {
  setTitle: Function;
  // currentProfile: any;
  user: Student;
  currentCourse: Course | null;
  app_name: string
}

const Learn: FC<LearnProps> = ({ setTitle, user, currentCourse, app_name }) => {
  useEffect(() => {
    setTitle({
      title: `Learn`,
      showBack: false,
      subText: "You are doing good, Keep it up!",
      icon: null,
      showSearch: true,
      float: false,
      searchText: "Search subjects, topics ...",
      twoBgs: false,
    });
  }, [setTitle]);

  return (
    <div className="learn__container">
      <Row>
        <Col className="learn__carousel__wrapper" span={24}>
          {/* <Carousel effect="fade">
						{caroselData?.map(
							({
								heading,
								title,
								tutor: { name, img },
								language,
								rating,
								reviews,
							}) => (
								<div className="carousel__wrapper">
									<h3>
										<div
											className="background-image__cover"
											style={contentStyle}
										>
											<p className="heading">{heading}</p>
											<h1 className="title">{title}</h1>
											<div className="tutor__wrapper">
												<div className="img__wrapper">
													<img src={img} alt="" />
												</div>
												<p>{name}</p>
											</div>
											<div className="content">
												<p className="language-item">{language}</p>
												<p>
													{rating}{" "}
													<span>
														({reviews}) reviews
													</span>
												</p>
											</div>
										</div>
									</h3>
								</div>
							)
						)}
					</Carousel> */}
          <LearnCard appName={app_name} className="learn-card__container">
            <div className="content__left">
              <div className="profile-image__container">
                <img
                  src={user?.displayPictureUrl ?? PlaceholderIcon}
                />
              </div>
              <div className="profile-details__container">
                <p className="profile__name">
                  Welcome <span>{`${user?.firstName}`}</span>
                </p>
                <p className="profile__class">
                  {currentCourse?.course?.classRoom?.name ||
                    currentCourse?.course?.exam?.name}
                </p>
              </div>
            </div>
            {/* <div className="content__right">
              <div className="leaderboard__cup">
                <img src={LeaderboardCupIcon} />
              </div>
              <div className="points__container">
                <p className="points">
                  245 Points{" "}
                  <span>
                    <img src={PointsIcon} />
                  </span>
                </p>
                <div className="points__btn">
                  <UIButton secondary type="button">
                    View Leaderboard
                  </UIButton>
                </div>
              </div>
            </div> */}
          </LearnCard>
        </Col>
      </Row>
      <ListSubjects url={CHAPTERS} />
      <ContinueWhereYouLeft />
    </div>
  );
};

export default AuthContainer(TitleContainer(Learn));
