import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import SubIcon from "../../../../assets/icon/math.svg";
import { LearnService } from "../../../../services/LearnService/learn.service";
import VideoCard from "../../../../shared/components/VideoCard";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import {
  Chapter as ChapterModel,
  ChapterContent,
} from "../../../../models/Chapters/chapter.model";
import "./chapter.scss";
import AuthContainer from "../../../../store/container/AuthContainer";
import ExamStats from "../../../../shared/components/ExamPannel/QuestionsDrawer/Stats";
import { ExamService } from "../../../../services/ExamService/exam.service";
import { ContentService } from "../../../../services/ContentService/content.service";
import { Col, Row } from "antd";
import PaginationComponent from "../../../../shared/components/PaginationComponent";
import { PaginationModel } from "../../../../models/pagination.model";

interface ChapterProps extends ITitleState {
  setTitle: Function;
  type: any;
  onSearch: string;
  currentProduct: any

}

const Chapter: FC<ChapterProps> = ({
  setTitle,
  type,
  onSearch,
  currentProduct }) => {
  // const params: any = useParams();
  const [chapter, setChapter] = useState<ChapterModel>();
  const [contents, setContents] = useState<ChapterContent[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<any>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const { state }: { state: any } = useLocation();
  const params: any = useParams();

  const fetchChapterContents = (params: any) => {
    if (type == "exam") {
      ContentService.getExamChapterContents(
        params,
        {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId: state.id,
        },
        (contents: ChapterContent[], meta: PaginationModel) => {
          if (currentProduct?.studenttype === 'institution' && currentProduct?.instituteId === 1)
            setContents(contents.filter(data => data?.isactive === true))
          setContents(contents);
          setPaginationMeta(meta);
        },
        () => { }
      );
    } else if (type == "class_room") {
      ContentService.getChapterContents(
        params,
        {
          courseId: LocalStorage.getItem("COURSE_ID"),
          chapterId: state.id,
        },
        (contents: ChapterContent[], meta: PaginationModel) => {
          setContents(contents);
          setPaginationMeta(meta);
        },
        () => { }
      );
    }
  };

  // useEffect(() => {
  //   setTitle({
  //     title: state?.chapterName,
  //     showBack: true,
  //     searchText: "Search contents",
  //     subText: `${state.subjectName}    |    ${
  //       contents.filter((data) => data.contentLibrary?.contentType == "video").length
  //     } Videos, ${
  //       contents.filter((data) => data.contentLibrary?.contentType == "pdf")
  //         .length
  //     } PDF &
  //   ${
  //     contents.filter(
  //       (data) => data.contentLibrary?.contentType == "external_link"
  //     ).length
  //   } External link
  //   `,
  //     icon: null,
  //   });
  // }, []);
  // useEffect(() => {
  //   fetchChapterContents({});
  // }, []);

  const handlePageChange = (page: number, pageSize: any) => {
    fetchChapterContents({ page });
    setCurrentPageNumber(page);
  };

  useEffect(() => {
    fetchChapterContents({ search: onSearch, page: currentPageNumber });
  }, []);

  useEffect(() => {
    setTitle({
      title: state?.chapterName,
      showBack: true,
      searchText: "Search contents",
      icon: null,
    });
  }, []);

  return (
    <div className="list-all-chapters__container">
      <p className="contents__count">{`${state.subjectName}    |    ${contents?.filter((data) => data.contentLibrary?.contentType == "video")
        .length
        } Videos, ${contents?.filter((data) => data.contentLibrary?.contentType == "pdf")
          .length
        } PDF & 
  ${contents?.filter(
          (data) => data.contentLibrary?.contentType == "external_link"
        ).length
        } External link
  `}</p>
      <Row className="chapter__outer__container" gutter={[24, 24]}>
        {contents.map(({ title, description, contentLibrary, id }) => (
          <Col xs={8} xl={6}>
            <VideoCard
              color="#FD6C00"
              icon={SubIcon}
              subject={state?.subjectName}
              content={title}
              thumbnail={contentLibrary?.thumbnailUrl}
              id={id}
              chapterName={state?.chapterName}
              chapterId={state?.id}
              contentLibrary={contentLibrary}
              url={contentLibrary?.attachmentUrl}
              contentType={contentLibrary?.contentType}
              subjectId={params?.subjectId}
            />
          </Col>
        ))}
      </Row>
      <PaginationComponent
        handlePageChange={handlePageChange}
        paginationMeta={paginationMeta}
      />
    </div>
  );
};

export default AuthContainer(TitleContainer(Chapter));
