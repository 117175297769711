import React, { FC } from "react";
import UIButton from "../../../../shared/components/UIButton";
import "./passwordResetLink.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
interface PasswordResetLinkProps {
  email: string;
  loginHandler: () => void;
  wrongHandler: () => void;
}

const PasswordResetLink: FC<PasswordResetLinkProps> = (props) => {
  const { email, loginHandler, wrongHandler } = props;

  return (
    <div className="password-reset-link">
      <h2>
        Password Reset Link is sent to
        <br />
        {email}
      </h2>
      <p className="password-reset-link__info">
        Check your email inbox to find the password reset link
      </p>
      <div className="password-reset-link__controllers">
        <UIButton
          type="button"
          icon={<ArrowRightOutlined />}
          clickHandler={loginHandler}
        >
          Proceed to Login
        </UIButton>
      </div>
      <p className="password-reset-link__wrong">
        Wrong Mail ID? <span onClick={wrongHandler}>Click here</span>
      </p>
    </div>
  );
};

export default PasswordResetLink;
