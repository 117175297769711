import React, { FC, useEffect, useState } from "react";
import Heading from "../../../../../shared/components/Heading";
import icon from "../../../../../assets/icon/icon.png";
import "./listSubjects.scss";
import SubjectCard from "./SubjectCard";
import { generatePath, useHistory } from "react-router-dom";
import { CHAPTERS } from "../../../../../routes/routeConstants/appRoutes";
import { LearnService } from "../../../../../services/LearnService/learn.service";
import { LocalStorage } from "../../../../../shared/utils/LocalStorageHelpers";
import { Subject } from "../../../../../models/Subject/subject.model";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { ExamService } from "../../../../../services/ExamService/exam.service";
import { ExamSubjectModel, SubjectModel } from "../../../../../models/Exam/SubjectModel/subject.model";
import { Col, Row } from "antd";
import { Course } from "../../../../../models/student.model";
import { ITitleState } from "../../../../../store/reducers/titleReducer";
import TitleContainer from "../../../../../store/container/TitleContainer";

const colors = [
  "#EB6296",
  "#1CC29E",
  "#5140A0",
  "#FD9000",
  "#3A9EEB",
  "#FFC107",
  "#713C46",
];

interface ListSubjectsProps extends ITitleState {
  currentCourse: Course;
  currentProduct: any;
  isTrialCourse: boolean,
  title?: string;
  url: string;
  type: any;
}

const ListSubjects: FC<ListSubjectsProps> = ({
  title = "Learn",
  currentCourse,
  currentProduct,
  url,
  type,
  onSearch,
}) => {




  const history = useHistory();
  const [subjects, setSubjects] = useState<any[]>([]);



  const fetchSubjects = (params: any) => {

    const assignedSubject = currentProduct?.course?.filter(data => data.id === currentCourse?.course?.classRoom?.id)[0]?.subjects

    if (type == "exam") {
      ExamService.getExamSubjects(
        params,
        currentCourse?.id,
        (response: SubjectModel[]) => {
          const subjects = response.map((subject, index) => ({
            ...subject,
            icon,
            color: colors[Math.floor(Math.random() * (7 - 1) + 1)]
          }));
          setSubjects(subjects);
        },
        (error: any) => { }
      );
    } else if (type == "class_room") {
      LearnService.getSubjects(
        params,
        currentCourse?.id,
        (data: SubjectModel[]) => {
          const subjects = data.filter(data => {
            if (assignedSubject?.length) {
              if (assignedSubject.includes(data?.subject?.id))
                return data
            } else return data
          }).map((subject, index) => {
            return {
              ...subject,
              icon,
              color: colors[Math.floor(Math.random() * (7 - 1) + 1)],
            }

          });
          setSubjects(subjects);
        }
      );
    }
  };
  useEffect(() => {
    if (currentCourse) {
      fetchSubjects({});
    }
  }, [currentCourse]);

  useEffect(() => {
    if (onSearch) fetchSubjects({ search: onSearch });
  }, [onSearch]);

  const handleClick = (subjectId: number, name: string) => {
    const URL = generatePath(url, {
      subjectId,
    });
    history.push({
      pathname: URL,
      state: {
        subjectName: name,
      },
    });
  };
  return (
    <div className="list-subjects__container">
      <Heading sub>{`${title} by Subjects`} </Heading>
      <Row className="list-subject__wrapper" gutter={[16, 12]}>
        {subjects.map((sub, ind) => {
          return (
            <Col key={"SUB_" + sub?.name + "_" + ind} xs={12} xl={4} xxl={3}>
              <SubjectCard
                name={sub?.name}
                color={sub?.subject?.colorCode ?? sub?.color}
                icon={sub?.subject?.displayPictureUrl ?? icon}
                completion={url.includes("practice") ? (sub?.exerciseCompletionPercentage || sub?.examExerciseCompletionPercentage || 0) + "%" : (sub?.contentCompletionPercentage || sub?.examContentCompletionPercentage || 0) + "%"}
                clickHandler={() => (sub?.id && sub?.name) && handleClick(sub?.id, sub?.name)}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default TitleContainer(AuthContainer(ListSubjects));
