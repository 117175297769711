import React, { FC } from 'react'
import "./queryComingSoon.scss"
import ClockIcon from "../../../../assets/images/clock.svg"

const QueryComingSoon: FC = () => {

    return (
        <div className="query-coming-soon">
            <div className="content">
                <h1>QUERY</h1>
                <p className="description">Clear your doubts with top tutors</p>

                <div className="launching__soon">
                    <img src={ClockIcon}/>
                    <p>lauching soon ...</p>
                </div>
            </div>
        </div>
    )
}

export default QueryComingSoon;