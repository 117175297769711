import React, { FC } from "react";
import NoAttemptsIcon from "../../../../assets/images/no-attempts.svg";
import UIButton from "../../../../shared/components/UIButton";
import "./emptyScreen.scss";
interface AnalyticsEmptyScreenProps {
  title: string;
  showButton?: boolean;
  buttonText?: any;
  clickHandler?: () => void

}
export const AnalyticsEmptyScreen: FC<AnalyticsEmptyScreenProps> = ({
  title,
  showButton,
  buttonText,
  clickHandler
}) => {
  return (
    <div className="analytics-empty-screen__container">
      <img src={NoAttemptsIcon} />
      <p>{title}</p>
      {showButton && (
        <div className="take-test-btn__container">
          <UIButton clickHandler={clickHandler} info type="button">
            {buttonText}
          </UIButton>
        </div>
      )}
    </div>
  );
};
