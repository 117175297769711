import React, { FC, useEffect } from "react";
import TitleContainer from "../../../store/container/TitleContainer";
import { ITitleState } from "../../../store/reducers/titleReducer";
import ListCourses from "./ListCourses";
import "./myProfileModule.scss";
import ProfileCard from "./profileCard";
interface MyProfileProps {
  setTitle: ({ ...data }: ITitleState) => void;
}
const MyProfile: FC<MyProfileProps> = ({ setTitle }) => {
  useEffect(() => {
    setTitle({
      title: "My Profile",
      showBack: false,
      showSearch: false
    });
  }, []);

  return (
    <div>
      <ProfileCard />
      <ListCourses />
    </div>
  );
};
export default TitleContainer(MyProfile);
