import React, { FC } from 'react'
import { Leaderboard } from '../../../../models/Leaderboard/leaderboard.model';
import "./currentStudentRank.scss"
import PointsIcon from "../../../../assets/icon/points.svg";
import AuthContainer from '../../../../store/container/AuthContainer';
import { Student } from '../../../../models/student.model';
import PlaceholderIcon from "../../../../assets/images/placeholder-dark.svg"
interface CurrentStudentRankProps {
    data: Leaderboard,
    // currentProfile: Student
    user: Student
}

const CurrentStudentRank: FC<CurrentStudentRankProps> = (props) => {
    const { data, user } = props;

    return (
        <div className="current-student-rank__container">
            <div className="current-student-rank__left">
                <img className="current-student__image" src={user?.displayPictureUrl || PlaceholderIcon}/>
                <div className="current-student-rank__wrapper">
                    <p className="current-student-rank__label">Your Rank</p>
                    <p className="current-student__rank">{data?.currentStudentRank || "-"}</p>
                </div>
            </div>
            <div className="current-student-rank__right">
                <p>{data?.currentStudentPoint || "No"} points</p>
                <img src={PointsIcon}/>
            </div>
        </div>
    )
}

export default AuthContainer(CurrentStudentRank);