import { Badge, Col, Progress, Row } from 'antd';
import React, { FC } from 'react';
import { Chapter } from '../../../../../models/Chapters/chapter.model';
import { initZoro } from '../../../../../shared/utils/initZero';
import './subjectCard.scss';

interface SubjectCard {
    chapter: Chapter;
    index: number;
    active: boolean;
    onSelect: (chapter: Chapter) => void;
}

const SubjectCard: FC<SubjectCard> = ({ index, chapter, onSelect, active }) => {
    return (
        <Row
            className={`subject-card-expand__container ${
                active && 'active-subject-card'
            }`}
            onClick={() => onSelect(chapter)}
        >
            <Col xl={2} xs={4} xxl={2}>
                <h2 className='subject-card__index'>
                    {initZoro(index + 1, 2)}
                </h2>
            </Col>
            <Col xl={22} xs={20} xxl={22}>
                <p className='chapter__name'>{chapter.name}</p>
                <Row className='mt-2'>
                    <Col xs={8} xl={8} xxl={6} className='dull-text'>
                        {chapter?.exercisesCount ||
                            chapter?.examExercisesCount ||
                            0}{' '}
                        Exercises
                    </Col>
                    <Badge
                        status='default'
                        className='dull-text exercise-badge'
                    />
                    <Col xs={14} xl={14} xxl={16}>
                        <Row justify='space-around'>
                            <Col span={5}>
                                <Progress
                                    percent={
                                        chapter?.examExerciseCompletionPercentage ||
                                        chapter?.exerciseCompletionPercentage ||
                                        0
                                    }
                                    showInfo={false}
                                    strokeColor={active ? '#fff' : 'gray'}
                                    trailColor={'rgba(0, 0, 0, 0.2)'}
                                />
                            </Col>
                            <Col span={16}>
                                <p className='dull-text-completion'>
                                    {chapter?.examExerciseCompletionPercentage ||
                                        chapter?.exerciseCompletionPercentage ||
                                        '0'}
                                    % Completed
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SubjectCard;
