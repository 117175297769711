import { Checkbox, Col, Input, Row } from "antd";
import * as React from "react";
import { useState, useEffect } from "react";

import UIButton from "../../../../../shared/components/UIButton";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../../register.scss";
import "./prepareExam.scss";
import AuthService from "../../../../../services/AuthService/auth.service";
import {
  BUILDING,
  HOME,
  PROFILES,
  STUDENT_BOARDS,
} from "../../../../../routes/routeConstants/appRoutes";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { StudentService } from "../../../../../services/StudentService/student.service";
import { CheckboxField } from "../../../../../shared/components/CheckboxField";
import { Exam, Student } from "../../../../../models/student.model";
import StepPencil from "../../../../../shared/components/StepPencil";
import AuthContainer from "../../../../../store/container/AuthContainer";
import StepContainer from "../../../../../store/container/StepContainer";
import Logo from "../../../../../assets/images/app-icon-white.svg";
import SelectExamImage from "../../../../../assets/images/select-exam.svg";
interface PrepareExamProps {
  user: Student;
  setStep: (step: number, stepPercentage: number) => void;
}
const PrepareExam = ({ user, setStep }: PrepareExamProps) => {
  const [selectedExam, setSelectedExam] = useState<any>();
  const [exams, setExams] = useState<Exam[]>([]);
  const [examsHolder, setExamsHolder] = useState<Exam[]>([]);
  const { state }: { state: any } = useLocation();
  const params: any = useParams();
  const history = useHistory();
  const fetchExams = () => {
    AuthService.listExams(
      (response: Exam[]) => {
        setExams(response);
        setExamsHolder(response);
      },
      () => {}
    );
  };

  useEffect(() => {
    fetchExams();
  }, []);

  const onSubmit = () => {
    StudentService.createStudentCourse(
      {
        courseType: "Exam",
        courseId: selectedExam,
      },
      () => {
        AuthService.updateStep(
          0,
          user?.id,
          () => {
            history.push(BUILDING);
          },
          () => {},
          () => {}
        );
      },
      () => {}
    );
  };
  const onSearch = (value: string) => {
    const filtered = examsHolder.filter((item: any) => {
      const itemName = item.exam.toUpperCase();

      const textData = value.toUpperCase();
      return itemName.indexOf(textData) > -1;
    });

    setExams(filtered);
  };

  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <img src={SelectExamImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <div className="exam-form__container">
            <StepPencil />
            <h1>
              <span>
                <ArrowLeftOutlined onClick={() => history.goBack()} />
              </span>
              Preparing for which exam
            </h1>
            <Row>
              <Col span={24}>
                <Input
                  placeholder="Search for exams"
                  onChange={(e) => onSearch(e.target.value)}
                  bordered={false}
                  className="search__input"
                />
              </Col>
            </Row>
            <div className="exams__wrapper">
              {exams?.map((value: Exam, index: number) => {
                return (
                  // <Row>
                  //   <Col span={24}>
                  <div
                    className={`exam-board__card ${
                      selectedExam == value.id ? "exam-card__border" : ""
                    }`}
                    onClick={() => {
                      setSelectedExam(value.id);
                    }}
                  >
                    <CheckboxField checked={selectedExam == value.id} />
                    <img
                      alt=""
                      src={value.displayPictureUrl}
                      className="card__icon"
                    />
                    <div className="exam-details__wrapper">
                      <p>{value?.name}</p>
                      <p className="acronym">{value?.acronym}</p>
                    </div>
                  </div>
                  //   </Col>
                  // </Row>
                );
              })}
            </div>
            <Row>
              <Col
                className="btn__wrapper prepare-btn__wrapper--margin"
                span={24}
              >
                <UIButton
                  type="submit"
                  className="mt-5"
                  icon={<ArrowRightOutlined />}
                  clickHandler={onSubmit}
                >
                  Next
                </UIButton>
              </Col>
            </Row>
            {/* {params?.usageId == "0" && (
        <Row>
          <Col span={24}>
            <p
              className="skip__btn"
              onClick={() => {
                AuthService.updateStep(
                  0,
                  currentProfile.id,
                  () => {
                    history.push(HOME);
                  },
                  () => {},
                  () => {}
                );
              }}
            >
              Skip
            </p>
          </Col>
        </Row>
      )} */}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(AuthContainer(StepContainer(PrepareExam)));
