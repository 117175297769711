import axiosInstance from "../../interceptor/_axiosInstance";

import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";

import { GET_CART, ADD_TO_CART, DELETE_FROM_CART, APPLY_COUPON, INITIATE_TRANSACTION,GET_TRANSACTION_INFO } from "../../routes/routeConstants/apiRoutes";
import { CartsModel, CartModel } from "../../models/CartModel/cart.model";


export default class CartService {

    static getCartList(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(GET_CART)
            .then((response) => {
                const cartList = response.data !== null
                    ? deserialize(
                        CartModel,
                        response.data.cartList.map(data => {
                            data.totalAmount = data.amount
                            return data
                        })
                    )
                    : [];

                const cartDetails = response.data !== null
                    ? deserialize(CartsModel, response.data)
                    : {};


                onSuccess(cartList, cartDetails);
            })
            .catch((error) => {
                Notification({
                    message: "Failed",
                    description: "Unable to fetch the product list",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);
    }

    static addToCart(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {

        let payload = serialize(CartModel, data);
        payload.amount = data.price;
        payload.thumbnail = data.thumbnailPath || "";
        return axiosInstance
            .post(ADD_TO_CART, payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError(error)
                Notification({
                    message: "Failed",
                    description: "something went wrong",
                    type: NotificationTypes.ERROR,
                });
            })
            .finally(onFinal);
    }

    static deleteFromCart(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {

        return axiosInstance
            .post(DELETE_FROM_CART, data)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError(error)
                Notification({
                    message: "Failed",
                    description: "something went wrong",
                    type: NotificationTypes.ERROR,
                });
            })
            .finally(onFinal);
    }

    static applyCoupon(
        Code: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(APPLY_COUPON, { params: { Code } })
            .then((response) => {
                if (response.data.isSuccess) {
                    onSuccess(response.data.data);
                } else throw new Error
            })
            .catch((error) => {
                Notification({
                    message: "Failed",
                    description: "Unable to apply Coupon",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);
    }


    static initiateTransaction(
        payload: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(INITIATE_TRANSACTION, payload)
            .then((response) => {
                if (response.data.isSuccess) {
                    onSuccess(response.data.data)
                } else throw new Error
            })
            .catch((error) => {
                console.log(error);
                
                Notification({
                    message: "Failed",
                    description: "Unable to initiate transaction",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);
    }

    static checkPaymentStatus(
        TransactionId: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(GET_TRANSACTION_INFO,null, { params: {
                TransactionId,
              }})
            .then((response) => {               
                if (response.data.isSuccess) {
                    onSuccess(response.data.data)
                } else throw new Error
            })
            .catch((error) => {                
                Notification({
                    message: "Failed",
                    description: "Unable to check Payment Status",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);
    }


  
}