import React from 'react';
import { Checkbox, Col, Input, Row } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import './registerForm.scss';
import '../register.scss';
import InputField from '../../../../shared/components/InputField';
import {
    ArrowRightOutlined,
    MailOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import UIButton from '../../../../shared/components/UIButton';
import {
    LOGIN,
    PARENT_VERIFY,
    PRIVACY_POLICY,
    TAC,
} from '../../../../routes/routeConstants/appRoutes';
import { useHistory, withRouter } from 'react-router-dom';
import { validationSchema } from '../RegisterValidation';
import { serialize } from 'serializr';
import { UserRegister } from '../../../../models/user.model';
import AuthService from '../../../../services/AuthService/auth.service';
import { useState } from 'react';

import { Select } from 'antd';
import { countryCodes } from '../../../../shared/utils/countryCodes';

const { Option } = Select;
interface handleStepProps {
    handleStep: (step: number, phoneNumber: string) => void;
}

interface RegisterProps {
    phoneNumber: string;
    email: string;
}
const RegisterForm = () => {
    const initialValues = {
        phoneNumber: '',
        email: '',
    };

    const history = useHistory();
    const [agree, setAgree] = useState<boolean>(false);
    const [prefix, setPrefix] = useState<string>('+91');
    const onSendOTP = (values: RegisterProps) => {
        // temp
        // history.push(PARENT_VERIFY, {
        //   phoneNumber: "+91" + values.phoneNumber,
        // });
        //=====================

        //console.log(values) current code
        AuthService.createParent(
            { ...values, phoneNumber: prefix + values.phoneNumber },
            () => {
                history.push(PARENT_VERIFY, {
                    phoneNumber: prefix + values.phoneNumber,
                });
            },
            () => {}
        );

        // AuthService.registerUser(
        //   { ...values, phoneNumber: "+91" + values.phoneNumber },
        //   (response: any) => {
        //     handleStep(1, values.phoneNumber);
        //   },
        //   (error: any) => {
        //     //console.log(error);
        //   },
        //   () => {}
        // );
    };

    return (
        <div className='register-form'>
            <h1>Create an account</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {}}
            >
                {({ values, dirty, isValid, setFieldValue }) => (
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Input.Group compact>
                                    <Select
                                        showSearch
                                        defaultValue={prefix}
                                        style={{ width: '20%' }}
                                        onChange={(value) => setPrefix(value)}
                                        optionFilterProp="country"
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.country ?? '').toLowerCase().localeCompare((optionB?.country ?? '').toLowerCase())
                                        }
                                        options={countryCodes}
                                    />

                                    <Field
                                        as={Input}
                                        style={{ width: '80%' }}
                                        type='number'
                                        name='phoneNumber'
                                        placeholder='Mobile Number'
                                        onChange={(e) =>
                                            setFieldValue(
                                                'phoneNumber',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Input.Group>
                                <div className='input__error'>
                                <ErrorMessage
                                    name='phoneNumber'

                                />
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <InputField
                                    type='email'
                                    name='email'
                                    placeholder='Mail Id'
                                    className='mt-3'
                                    suffix={<MailOutlined />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <InputField
                                    type='text'
                                    name='firstName'
                                    placeholder='Student Name'
                                    className='mt-3'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <InputField
                                    type='dob'
                                    name='dob'
                                    placeholder='Date of Birth'
                                    className='mt-3'
                                    suffix={<CalendarOutlined />}
                                    setFieldValue={(value) =>
                                        setFieldValue('dob', value)
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Checkbox
                                    checked={agree}
                                    onChange={(e) => setAgree(e.target.checked)}
                                    className='terms-and-conditions__checkbox'
                                >
                                    {/* <p className="terms-and-conditions"> */}
                                    <span className='terms-and-conditions'>
                                        By creating an account, you agree to our{' '}
                                        <span onClick={() => history.push(TAC)}>
                                            Terms and Conditions
                                        </span>{' '}
                                        and that you have read our{' '}
                                        <span
                                            onClick={() =>
                                                history.push(PRIVACY_POLICY)
                                            }
                                        >
                                            Privacy Policy.
                                        </span>
                                        {/* </p> */}
                                    </span>
                                </Checkbox>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='btn__wrapper' span={24}>
                                <UIButton
                                    type='submit'
                                    className='mt-1'
                                    icon={<ArrowRightOutlined />}
                                    clickHandler={() => {
                                        isValid && onSendOTP(values);
                                    }}
                                    disabled={!agree}
                                >
                                    Send OTP
                                </UIButton>
                                <p className='already-have-acc'>
                                    Already have an account?{' '}
                                    <span onClick={() => history.push(LOGIN)}>
                                        Login here
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default RegisterForm;
