/* Auth Routes */
export const AUTH = "/auth";
export const REGISTER = AUTH + "/register";
export const BUILDING = "/building";
export const PARENT_REGISTER = REGISTER + "/";
export const PARENT_VERIFY = REGISTER + "/verify";
export const PROFILES = REGISTER + "/profiles";
export const ADD_PROFILE = PROFILES + "/add";
export const STUDENT_USAGE = ADD_PROFILE + "/:studentId/usage";
export const STUDENT_BOARDS = STUDENT_USAGE + "/:usageId/boards";
export const STUDENT_CLASSES = STUDENT_BOARDS + "/:boardId/classes";
export const STUDENT_EXAMS = STUDENT_USAGE + "/:usageId/exams";

export const LOGIN = AUTH + "/login";
export const LOGIN_OTP = AUTH + "/login_otp";
export const UPDATE_PASSWORD = AUTH + "/update-password";
export const RESET_PASSWORD = AUTH + "/reset-password";
export const FORGOT_PASSWORD = LOGIN + "/forgot-password"
export const TAC = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const MAIN='/main'


/* Home Route */
export const HOME = "/";
export const LEARN = "/learn";
export const CHAPTERS = LEARN + "/:subjectId";
export const CHAPTER = CHAPTERS + "/chapter/:chapterId";
export const STUDY_MATERIAL = CHAPTER + "/content/:contentId";
export const QUERY = "/query";
export const MY_PROFILE = "/my-profile";
export const ANALYTICS = "/my-progress";
export const LEADERBOARD = "/leaderboard";
export const ACCOUNT = "/account";
export const SUBSCRIPTION = "/subscription";
export const LIVE_CLASS = "/live-class";
export const LIVE_CLASS_MEETING = LIVE_CLASS + "/:liveClassId/meeting"
export const ASSIGNMENT = "/assignment";
export const ASSIGNMENT_DETAILS = ASSIGNMENT + "/:assignmentId";

/*Learn Route*/
export const QUICK_QUIZ = STUDY_MATERIAL + "/:id/quick_quiz";
export const QUICK_QUIZ_RESULTS = STUDY_MATERIAL + "/:id/quick_quiz/results";

/*Analytics Route*/

export const LIST_ANALYTICS_CHAPTERS = ANALYTICS + "/:subjectId";
export const LIST_ANALYTICS_EXERCISES = LIST_ANALYTICS_CHAPTERS + "/:chapterId";
/* Practice Route */
export const PRACTICE = "/practice";
export const PRACTICE_HOME = PRACTICE + "/";
export const PRACTICE_CHAPTERS = PRACTICE + "/:subjectId";
export const EXERCISE_EXAM = PRACTICE + "/exercise/:id/exam";
export const EXERCISE_EXAM_RESULTS = PRACTICE + "/exercise/:id/exam/results";
export const PRACTICE_EXAMS = PRACTICE + "/practice_by_exams/:examId";
export const PAPER_EXAM = PRACTICE + "/paper/:id/exam";
export const PAPER_EXAM_RESULTS = PRACTICE + "/paper/:id/exam/results";

/*My Profile*/
export const MY_PROFILE_HOME = MY_PROFILE + "/";
export const ADD_COURSE = MY_PROFILE + "/add_course";

/* Announcement */
export const ANNOUNCEMENT = "/announcement"
export const ANNOUNCEMENT_DETAILS = ANNOUNCEMENT + "/:announcementId"

/* Products */
export const PRODUCTS = "/products"
export const CART = "/cart"
export const PG_RESPONSE="/pgResponse/:transaction"
export const PAYMENT_REQUEST="/payment-request"

/* Mindjjo ERP */
export const AUTHENTICAT_ERP = '/auth/erp'
