import { Checkbox, Col, Input, Row } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import UIButton from "../../../../../shared/components/UIButton";
import "./schoolBoard.scss";
import { BoardService } from "../../../../../services/BoardService/board.service";
import AuthService from "../../../../../services/AuthService/auth.service";
import {
  HOME,
  PROFILES,
  STUDENT_CLASSES,
  STUDENT_EXAMS,
} from "../../../../../routes/routeConstants/appRoutes";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router";
import { Board } from "../../../../../models/Boards/board.model";
import { CheckboxField } from "../../../../../shared/components/CheckboxField";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { Student } from "../../../../../models/student.model";
import StepPencil from "../../../../../shared/components/StepPencil";
import StepContainer from "../../../../../store/container/StepContainer";
import Logo from "../../../../../assets/images/app-icon-white.svg";
import SelectBoardimage from "../../../../../assets/images/select-board.svg";

interface SchoolBoardProps {
  // currentProfile: Student;,
  user: Student;

  setStep: (step: number, stepPercentage: number) => void;
}
const SchoolBoard = ({ user, setStep }: SchoolBoardProps) => {
  const history = useHistory();
  const params: any = useParams();
  const [boards, setBoards] = useState<Board[]>([]);
  const [boardsHolder, setBoardsHolder] = useState<Board[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<any>("");
  const { state }: { state: any } = useLocation();
  const onSearch = (value: string) => {
    const filtered = boardsHolder.filter((item: any) => {
      const itemName = item.name.toUpperCase();
      const itemAcronym = item.name.toUpperCase();
      const textData = value.toUpperCase();
      return (
        itemName.indexOf(textData) > -1 || itemAcronym.indexOf(textData) > -1
      );
    });

    setBoards(filtered);
  };
  const getBoardsList = () => {
    BoardService.getBoardsList(
      (response: Board[]) => {
        const filterBoard=response.filter((data) => data.classRooms?.length)
        setBoards(filterBoard);
        setBoardsHolder(filterBoard);
        if(filterBoard.length===1){
          setSelectedBoard(filterBoard[0].id)
        }
      },
      (error: Error) => {
        console.log(error);
      },
      () => {}
    );
  };
  React.useEffect(() => {
    getBoardsList();
  }, []);
  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <img src={SelectBoardimage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <div className="board-form__container">
            <StepPencil />

            <h1>
              <span>
                <ArrowLeftOutlined onClick={() => history.goBack()}/>
              </span>
              Select your school board
            </h1>
            <Row>
              <Col span={24}>
                <Input
                  placeholder="Search your board"
                  onChange={(e) => onSearch(e.target.value)}
                  bordered={false}
                  className="search__input"
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="boards__wrapper">
                  {boards?.map((value: Board, index) => {
                    return (
                      // <Row>
                      //   <Col span={23}>
                      <div
                        className={`school-board__card ${
                          selectedBoard == value.id ? "card__border" : ""
                        }`}
                        onClick={() => {
                          setSelectedBoard(value.id);
                        }}
                      >
                        <CheckboxField checked={selectedBoard == value.id} />
                        <img
                          alt=""
                          src={
                            value.displayPictureUrl
                              ? value.displayPictureUrl
                              : "https://www.pngkey.com/png/detail/914-9142128_jee-neet-go-gmat-way-to-hold-entrance.png"
                          }
                          className="card__icon"
                        />
                        <div className="board-details__wrapper">
                          <p>{value?.name}</p>
                          <p className="acronym">{value?.acronym}</p>
                        </div>
                      </div>
                      //   </Col>
                      // </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div className="btn__wrapper">
                  <UIButton
                    type="submit"
                    className="mt-5"
                    icon={<ArrowRightOutlined />}
                    clickHandler={() => {
                      // if (params?.usageId == "1") {
                      //   history.push(generatePath(PROFILES, { ...params }));
                      // } else {
                      params?.usageId == "0" ? setStep(2, 80) : setStep(2, 50);
                      history.push(
                        generatePath(STUDENT_CLASSES, {
                          ...params,
                          boardId: selectedBoard,
                        }),
                        {
                          classRooms: boards.filter(
                            (data: any) => data.id == selectedBoard
                          )[0]?.classRooms,
                          ...state,
                        }
                      );
                      // }
                      // AuthService.updateStep(
                      //   {
                      //     step: nextStep,
                      //   },
                      //   () => {
                      //     let selected = boards.filter(
                      //       (data: any) => data.id == selectedBoard
                      //     );
                      //     window.history.pushState(
                      //       selected[0].classRooms,
                      //       "selected"
                      //     );
                      //   },
                      //   () => {},
                      //   () => {}
                      // );
                    }}
                  >
                    Next
                  </UIButton>
                </div>
              </Col>
            </Row>
            {/* {state?.usageType == "exam" && (
        <Row>
          <Col span={24}>
            <p
              className="skip__btn"
              onClick={() => {
                // AuthService.updateStep(
                //   currentProfile,
                //   1,
                //   () => {
                //     history.push(HOME);
                //   },
                //   () => {},
                //   () => {}
                // );
              }}
            >
              Skip
            </p>
          </Col>
        </Row>
      )} */}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(StepContainer(AuthContainer(SchoolBoard)));
