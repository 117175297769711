import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../../../routes/routeConstants/appRoutes";
import PractiveDashboard from "./Dashboard";
import  PracticeByExams  from "./Dashboard/PracticeByExams";
import ExerciseExam from "./ExerciseExam";
import ExerciseExamResult from "./ExerciseExamResult";
import PracticeChapter from "./PracticeChapter";
import "./practiceModule.scss";

const PracticeModule = () => {
	const Routes = [
		{
			path: routes.PRACTICE_HOME,
			Component: <PractiveDashboard />,
			exact: true,
		},
		{
			path: routes.PRACTICE_EXAMS,
			Component: <PracticeByExams />,
			exact: true,
		},
		{
			path: routes.PRACTICE_CHAPTERS,
			Component: <PracticeChapter />,
			exact: true,
		},
		{
			path: routes.EXERCISE_EXAM,
			Component: <ExerciseExam />,
			exact: true,
		},
		{
			path: routes.EXERCISE_EXAM_RESULTS,
			Component: <ExerciseExamResult />,
			exact: true,
		},
		{
			path: routes.PAPER_EXAM,
			Component: <ExerciseExam />,
			exact: true,
		},
		{
			path: routes.PAPER_EXAM_RESULTS,
			Component: <ExerciseExamResult />,
			exact: true,
		},
	];

	return (
		<div className="practice-module__container">
			<Switch>
				{Routes?.map(({ exact, Component, ...rest }) => (
					<Route
						exact={exact}
						component={() => Component}
						{...rest}
					/>
				))}
				{/* <Redirect to={routes.PRACTICE}/> */}
			</Switch>
		</div>
	);
};

export default PracticeModule;
