import { Course } from "../../models/student.model";
import { SET_APP_NAME,SET_PRODUCT_DETAILS, SET_CURRENT_COURSE, UNAUTHENTICATED, SET_TYPE, SET_CURRENT_PROFILE, AUTHENTICATED, SET_IS_TRAIL_COURSE } from "../definitions/authConstants";

export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null,
            currentCourse: null,
            courseId: null
        }
    }
}

export const setAuthenticated = (user: any) => {
    return {
        type: AUTHENTICATED,
        payload: {
            authenticated: true,
            user: user
        }
    }
}
//dont use without parent
export const setCurrentProfile = (currentProfile: any) => {
    return {
        type: SET_CURRENT_PROFILE,
        payload: {
            currentProfile
        }
    }
}

export const setCurrentCourse = (course: Course) => {
    return {
        type: SET_CURRENT_COURSE,
        payload: {
            course
        }
    }
}

export const setProductDetails = (product: any) => {
    return {
        type: SET_PRODUCT_DETAILS,
        payload: {
            product
        }
    }
}

export const setType = (type: any) => {
    return {
        type: SET_TYPE,
        payload: {
            type
        }
    }
}

export const setIsTrailIsExpired = (istrail: boolean, isexpired: boolean) => {
    return {
        type: SET_IS_TRAIL_COURSE,
        payload: { istrail, isexpired }
    }
}



export const setAppName = (app_name: any) => {
    return {
        type: SET_APP_NAME,
        payload: {
            app_name
        }
    }
}
