import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { CreateStudent, Student } from "../../../../models/student.model";
import UIButton from "../../../../shared/components/UIButton";
import InputField from "../../../../shared/components/InputField";
import { studentDetailsValidationSchema } from "../RegisterValidation";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import Logo from "../../../../assets/images/app-icon-white.svg";
import WhoIsLearningImage from "../../../../assets/images/who-is-learning.svg";

import "./addStudents.scss";
import AuthService from "../../../../services/AuthService/auth.service";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../../shared/utils/dataFormatConverter";
import ProfileUpload from "../../../../shared/components/ProfileUpload";
import { useHistory } from "react-router";
import { ADD_PROFILE, HOME, LEARN } from "../../../../routes/routeConstants/appRoutes";
import { StudentService } from "../../../../services/StudentService/student.service";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";
import PlaceholderIcon from "../../../../assets/images/placeholder-dark.svg";
import AddIcon from "../../../../assets/icon/add-2.svg";

import AuthContainer from "../../../../store/container/AuthContainer";
interface ProfilesProps {
  setCurrentProfile: Function;
}
const Profiles: FC<ProfilesProps> = ({ setCurrentProfile }) => {
  const history = useHistory();
  const fetchStudents = () => {
    AuthService.listStudents(
      (response) => {
        //console.log(response);
        setStudents(response);
      },
      () => {}
    );
  };

  const [students, setStudents] = useState<Student[]>([]);

  const [disableBtn, setDisablebtn] = useState(false);

  const handleSubmit = (studentId: any) => {
    StudentService.updateStudent(
      studentId,
      {
        student: {
          is_current: true,
        },
      },
      (response) => {
        setCurrentProfile(response);
        LocalStorage.setItem("CURRENT_PROFILE", response);
        history.push(LEARN);
      },
      () => {}
    );
  };
  useEffect(() => {
    fetchStudents();
  }, []);
  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <img src={WhoIsLearningImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <div className="add-profiles__container">
            <p className="add-profiles__title">
              Who is Learning? <span>Select your profile</span>
            </p>
            <Row className="student__wrapper">
              {students.map((value, index) => {
                return (
                  <Col span={12}>
                    <div
                      className={`student__container ${value?.isBlocked && "student__container-blocked"}`}
                      onClick={() => handleSubmit(value?.id)}
                    >
                      <img
                        className="switch-profile-avatar__image"
                        src={value?.displayPictureUrl ?? PlaceholderIcon}
                      />
                      <p>{value?.firstName}</p>
                    </div>
                  </Col>
                );
              })}
{/* 
              <Col span={12}>
                <div
                  className="add-new__profile"
                  onClick={() => history.push(ADD_PROFILE)}
                >
                  <img src={AddIcon} />
                </div>
              </Col> */}
            </Row>

            {/* <Row>
              <Col span={24}>
                <div className="btn__wrapper">
                  <UIButton
                    type="submit"
                    className={`mt-5 ${disableBtn && "wobble"}`}
                    icon={<ArrowRightOutlined />}
                    clickHandler={() => {
                      if (students.length) {
                        history.push(HOME);
                      } else {
                        setDisablebtn(true);
                      }
                    }}
                  >
                    Proceed
                  </UIButton>
                </div>
              </Col>
              <Col span={24}>
                <div className="student__warning">
                  {disableBtn && <p>Add atleast one student!</p>}
                </div>
              </Col>
            </Row> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AuthContainer(Profiles);
