import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { Skeleton } from "antd";
import AuthContainer from "../../../../store/container/AuthContainer";

import { CartsModel, } from "../../../../models/CartModel/cart.model";
import CartService from "../../../../services/CartService/cart.service";
import { LocalStorage } from "../../../../shared/utils/LocalStorageHelpers";




export const SkeletonLoader = (): any => {
    return Array(2)
        .fill(" ")
        .map((value, idex) => {
            return (
                <div key={"Skeleton" + idex} className="skeleton-loader">
                    <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
                </div>
            );
        });
};


interface CartProps { }

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymetRequest: FC<CartProps> = ({ }) => {
    let query = useQuery();

    const [cartDetails, setCartDetails] = useState<CartsModel>({});
    const [loading, setLoading] = useState(false);


    const fetchCartList = () => {
        setLoading(true);
        CartService.getCartList(
            (data: any, cartDetails: any) => {
                setCartDetails(cartDetails)
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    };

    useEffect(() => {
        LocalStorage.setItem("ACCESS_TOKEN", query.get("tk"));
         fetchCartList();

   }, []);

    useEffect(() => {
        if (cartDetails.cartId)
            handelCheckout();
    }, [cartDetails]);



    /*-----------------------Handel Checkout--------------------*/
    const handelCheckout = () => {
        const payload = {
            "cartId": cartDetails.cartId,
            "couponCode": query.get("cc"),
            "domain": document.location.host,
            "type": query.get("type")
        }
        setLoading(true);
        CartService.initiateTransaction(
            payload,
            (data: any) => {
                makePaymentPage(data)
            },
            () => { },
            () => {
                setLoading(false);
            }
        );
    }


    const makePaymentPage = (formData: any) => {
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", formData.checkoutURL);
        var FN = document.createElement("input");
        FN.setAttribute("type", "hidden");
        FN.setAttribute("name", 'encRequest');
        FN.setAttribute("id", 'encRequest');
        FN.setAttribute("value", formData.encString);
        form.appendChild(FN)
        var F2 = document.createElement("input");
        F2.setAttribute("type", "hidden");
        F2.setAttribute("name", 'access_code');
        F2.setAttribute("id", 'access_code');
        F2.setAttribute("value", formData.accessCode);
        form.appendChild(F2)
        document.body.appendChild(form)
        form.submit();
    }


    return (
        <div className="cart__container">
            {loading && <SkeletonLoader />}
        </div>
    )
}

export default AuthContainer(PaymetRequest)