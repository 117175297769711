import {serializable, alias, object, list, primitive} from 'serializr';


export class CoinableData { 

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('subtitle', primitive()))
	subtitle?: string;

	@serializable(alias('content', primitive()))
	content?: string;

	@serializable(alias('content_thumbnail_url', primitive()))
	contentThumbnailUrl?: string;

	@serializable(alias('thumbnail_url', primitive()))
	thumbnailUrl?: string;

	@serializable(alias('subject_color_code', primitive()))
	subjectColorCode?: string;


}

export class PointHistory { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('points', primitive()))
	point?: number;

	@serializable(alias('contentName', primitive()))
	contentName?: string;

	@serializable(alias('contentThumbnailUrl', primitive()))
	contentThumbnailUrl?: string;

	@serializable(alias('counts', primitive()))
	counts?: number;

	@serializable(alias('fieldType', primitive()))
	fieldType?: string;

	@serializable(alias('chapterName', primitive()))
	chapterName?: string;

	@serializable(alias('subjectColorCode', primitive()))
	subjectColorCode?: string;

	@serializable(alias('subjectName', primitive()))
	subjectName?: string;

	@serializable(alias('subjectThumbnailUrl', primitive()))
	subjectThumbnailUrl?: string;
}

export class Leaderboard { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('firstRank', primitive()))
	firstRank?: string;

	@serializable(alias('firstRankPoint', primitive()))
	firstRankPoint?: number;

	@serializable(alias('firstRankImageUrl', primitive()))
	firstRankImageUrl?: string

	@serializable(alias('secondRank', primitive()))
	secondRank?: string;

	@serializable(alias('secondRankPoint', primitive()))
	secondRankPoint?: number;
	
	@serializable(alias('secondRankImageUrl', primitive()))
	secondRankImageUrl?: string

	@serializable(alias('thirdRank', primitive()))
	thirdRank?: string;

	@serializable(alias('thirdRankPoint', primitive()))
	thirdRankPoint?: number;

	@serializable(alias('thirdRankImageUrl', primitive()))
	thirdRankImageUrl?: string

	@serializable(alias('currentStudentRank', primitive()))
	currentStudentRank?: number;

	@serializable(alias('currentStudentPoint', primitive()))
	currentStudentPoint?: number;

	@serializable(alias('pointHistory', list(object(PointHistory))))
	pointHistory?: PointHistory[] = [];

}