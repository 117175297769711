import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
  // phoneNumber: Yup.string()
  //   .min(10, "Phone Number should not be more or less then 10 digits").max(10, "Phone Number should not be more or less then 10 digits")
  //   .required('Phone Number is required!'),
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Email address is required!"),
  password: Yup.string()
    .matches(
      /^\S*$/,
      "This field cannot contain white space"
      ///^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
      //"Password must has at least 8 characters that include at least 1 number and 1 special character in (!@#$%^&*)"
    )
    .required("Password is required!").nullable(),
});
