import React from 'react'
import ExamPannel from '../../../../shared/components/ExamPannel'

const ExerciseExamResult = () => {
    return (
        <div>
            <ExamPannel />      
        </div>
    )
}

export default ExerciseExamResult;