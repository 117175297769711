import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MyProfile from ".";
import * as routes from "../../../routes/routeConstants/appRoutes";
import AddCourse from "./AddCourse";

const MyProfileModule = () => {
	return (
		<div>
			<Switch>
				<Route
					exact
					path={routes.MY_PROFILE_HOME}
					component={() => <MyProfile />}
				/>
				<Route
					exact
					path={routes.ADD_COURSE}
					component={() => <AddCourse/>}
				/>
			</Switch>
		</div>
	);
};

export default MyProfileModule;
