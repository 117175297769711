import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import requiredAuth from "../shared/components/HOC/requireAuth";
import Home from "../views/Home";
import scrollToTop from "../shared/utils/scrollToTop";
import { BuilidingContent } from "../views/Auth/Register/BuildingContent";
import TermsAndConditions from "../views/TermsAndConditions";
import PrivacyPolicy from "../views/PrivacyPolicy";
import PGResponse from "../views/PGResponse";
import PaymentRequest from "../views/Home/CartModule/PaymentRequest";
import Main from "../views/Main";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
  const isAuthenticated = (component: any) => {
    return requiredAuth(component);
  };

  const routes = [
    { exact: true, path: appRoutes.MAIN, component: Main },
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    { exact: true, path: appRoutes.BUILDING, component: BuilidingContent },
    { exact: true, path: appRoutes.TAC, component: TermsAndConditions },
    { exact: true, path: appRoutes.PRIVACY_POLICY, component: PrivacyPolicy },
    { exact: true, path: appRoutes.PG_RESPONSE, component: PGResponse },
    { exact: true, path: appRoutes.PAYMENT_REQUEST, component: PaymentRequest },
    { exact: false, path: appRoutes.HOME, component: isAuthenticated(Home) },
  ];

  return (
    <Router history={appHistory}>
      <Switch>
        {routes.map((route, index) => {
          return <Route key={index} {...route} component={route.component} />;
        })}
        <Route path="*" render={() => <Redirect to={appRoutes.MAIN} />} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
