import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setStep } from "../actions/step"

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        step: state.step.step,
        stepPercentage: state.step.stepPercentage,
        ...ownProps
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setStep
}, dispatch);

const StepContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component)
}
export default StepContainer