import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import AgoraRTM, { RtmChannel } from "agora-rtm-sdk";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  useClient,
  useMicrophoneAndCameraTracks,
  useMicrophoneTrack,
} from "..";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { JoinLiveClassResponseModel } from "../../../../../models/LiveClass/liveClass.model";
import { Student } from "../../../../../models/student.model";
import Notification from "../../../../../shared/components/Notification";
import AuthContainer from "../../../../../store/container/AuthContainer";
import Controls from "../Controls";
import LiveClassInfo from "../LiveClassInfo";
import LiveClassParticipants from "../LiveClassParticipants";
import Videos from "../LiveClassVideos";
import "./liveClassVideoCall.scss";

interface LiveClassVideoCallProps {
  leaveCall: () => void;
  channel: JoinLiveClassResponseModel;
  user: Student;
}

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

const LiveClassVideoCall: FC<LiveClassVideoCallProps> = (props) => {
  const { leaveCall, channel, user } = props;
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const usersRef = useRef(users);

  const [start, setStart] = useState<boolean>(false);
  const client = useClient();
  const videoTrack = useMicrophoneAndCameraTracks();
  const audioTrack = useMicrophoneTrack();
  const [showParticipants, setParticipantsVisibility] = useState(false);
  const [showInfo, setInfoVisibility] = useState(false);
  const [currentHost, setCurrentHost] = useState<IAgoraRTCRemoteUser>();
  const [trackState, setTrackState] = useState({
    video: true,
    audio: true,
  });
  const rtmClient = AgoraRTM.createInstance(AGORA_APP_ID ?? "");
  const [rtmChannel, setRtmChannel] = useState<RtmChannel>();

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    let init = async (channel: JoinLiveClassResponseModel) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (user?.uid?.toString()?.includes("host")) setCurrentHost(user);
        console.log("subscribe success", mediaType);
        if (mediaType === "video") {
          let userIndex = usersRef.current?.findIndex(
            (User) => User?.uid == user?.uid
          );
          if (userIndex > -1) {
            let usersHolder = usersRef.current?.map((User) => User);
            usersHolder[userIndex] = user;
            setUsers(usersHolder);
          } else {
            setUsers([...usersRef.current, user]);
          }
        }
        if (mediaType === "audio") {
          let userIndex = usersRef.current?.findIndex(
            (User) => User?.uid == user?.uid
          );
          if (userIndex > -1) {
            user.audioTrack?.play();
            setUsers((prevUsers) => {
              return prevUsers.map((User) =>
                User.uid == user.uid ? user : User
              );
            });
          } else {
            user.audioTrack?.play();
            setUsers([...usersRef.current, user]);
          }
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
          setUsers((prevUsers) => {
            return prevUsers.map((User) =>
              User.uid == user.uid ? user : User
            );
          });
        }

        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.map((User) =>
              User.uid == user.uid ? user : User
            );
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      if (
        process.env.REACT_APP_AGORA_APP_ID &&
        channel?.channelName &&
        channel?.token &&
        channel?.rtmTokenUid &&
        channel?.rtmToken
      ) {
        await client.join(
          process.env.REACT_APP_AGORA_APP_ID,
          channel?.channelName,
          channel?.token,
          "Student"
        );

        await rtmClient
          .login({
            uid: channel?.rtmTokenUid,
            token: channel?.rtmToken,
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });

        let rtmChannelHolder = rtmClient.createChannel(channel?.channelName);
        rtmChannelHolder?.join();
        setRtmChannel(rtmChannelHolder);
      }
    };

    if (
      (videoTrack?.ready && videoTrack?.tracks) ||
      (audioTrack?.ready && audioTrack?.track)
    ) {
      console.log("init ready");
      init(channel);
    }
  }, [
    channel,
    client,
    videoTrack?.ready,
    audioTrack?.ready,
    videoTrack?.tracks,
    audioTrack?.track,
  ]);

  const handleParticipants = () => {
    setParticipantsVisibility((visible) => !visible);
  };

  const handleInfo = () => {
    setInfoVisibility((visible) => !visible);
  };

  useEffect(() => {
    if (videoTrack?.tracks) {
      client.publish([videoTrack?.tracks[0], videoTrack?.tracks[1]]);
      setStart(true);
    } else {
      if (audioTrack?.track) client.publish(audioTrack?.track);
      setStart(true);
    }
  }, [videoTrack, audioTrack]);

  return (
    <div className="live-class-video-call">
      {start && (videoTrack?.tracks || audioTrack?.track) && (
        <Videos
          isParticipantsVisible={showParticipants}
          isInfoVisible={showInfo}
          users={users}
          videoTrack={videoTrack?.tracks}
          audioTrack={audioTrack?.track}
          client={client}
          host={currentHost}
        />
      )}
      {((videoTrack?.ready && videoTrack?.tracks) ||
        (audioTrack?.ready && audioTrack?.track)) && (
        <Controls
          rtmChannel={rtmChannel}
          users={users}
          videoTrack={videoTrack?.tracks}
          audioTrack={audioTrack?.track}
          callType={videoTrack?.ready && videoTrack?.tracks ? "video" : "audio"}
          setStart={setStart}
          leaveHandler={() => {
            rtmClient?.logout();
            leaveCall();
          }}
          participantsHandler={handleParticipants}
          infoHandler={handleInfo}
          clientControlsHandler={(controls) => {
            setTrackState(controls);
          }}
        />
      )}

      <LiveClassParticipants
        visible={showParticipants}
        closeHandler={() => setParticipantsVisibility(false)}
        users={users}
        client={client}
        trackState={trackState}
        isAudioOnly={audioTrack?.track && !videoTrack?.tracks ? true : false}
      />
      <LiveClassInfo
        visible={showInfo}
        closeHandler={() => setInfoVisibility(false)}
      />
    </div>
  );
};

export default AuthContainer(LiveClassVideoCall);
