import React, { FC } from 'react'
import { Leaderboard } from '../../../../models/Leaderboard/leaderboard.model';
import "./pointsHistory.scss"
import faker from "faker"
import { Badge } from 'antd';
import PointsIcon from "../../../../assets/icon/points.svg";

interface PointsHistoryProps {
    data: Leaderboard
}

const PointsHistory: FC<PointsHistoryProps> = (props) => {
    const { data } = props;

    return (
        <div className="points-history">
            <h4>Points History</h4>
            {
                data?.pointHistory?.length ?
                    <div className="points-history__wrapper">
                        {
                            data?.pointHistory?.map((value, index) => {
                                return (
                                    <div key={"POINT_HISTORY#" + index} className="points-hisory__card">
                                        <div className="points-hisory-card__left">
                                            {
                                                value?.contentThumbnailUrl &&
                                                <img className="points-history-content__image" src={value?.contentThumbnailUrl} />
                                            }
                                            <div className="points-history-content__container">
                                                <div className="points-history-content-title__container">
                                                    <p className="points-history-content__title" style={{ color: value?.subjectColorCode }}>{value?.subjectThumbnailUrl && <span><img src={value?.subjectThumbnailUrl} /></span>}{value?.subjectName}</p>
                                                    <Badge status="default" />
                                                    <p className="points-history-content__type">{value?.fieldType}</p>
                                                </div>
                                                <div className="points-history-content-title__container">
                                                    <p className="points-history-content__title not-mobile">{value?.chapterName}</p>
                                                    <Badge status="default" className='not-mobile' />
                                                    <p className="points-history-content__name">{value?.contentName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points-hisory-card__right">
                                            <p>+{value?.point}{" "}</p>
                                            <img src={PointsIcon} />
                                        </div>
                                        <div className="points-hisory-card__right">
                                            <p>{value?.counts}{" "}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className="no-points-history__text">No points history!</div>
            }

        </div>
    )
}

export default PointsHistory;
