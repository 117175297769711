import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";
import * as routes from "../../routes/routeConstants/appRoutes";
import Learn from "./LearnModule/Learn";
// import Navbar from "../../shared/components/Navbar";
import { Col, Row } from "antd";
import Sidebar from "../../shared/components/Sidebar";
import Navbar from "../../shared/components/Navbar";
import ListChapters from "./LearnModule/ListChapters";
import Chapter from "./LearnModule/ListChapterContents";
import StudyMaterial from "./LearnModule/StudyMaterial";
import { LearnService } from "../../services/LearnService/learn.service";
import AuthService from "../../services/AuthService/auth.service";
import PracticeModule from "./PracticeModule";
import QueryModule from "./QueryModule";
import Analytics from "./AnalyticsModule";
import ListChaptersAnalytics from "./AnalyticsModule/ListChaptersAnalytics";
import AnalyticsModule from "./AnalyticsModule/analyticsRouter";
import MyProfileModule from "./MyProfileModule/myProfileRouter";
import ExerciseExam from "./PracticeModule/ExerciseExam";
import ExerciseExamResult from "./PracticeModule/ExerciseExamResult";
import TitleContainer from "../../store/container/TitleContainer";
import { ITitleState } from "../../store/reducers/titleReducer";
import LeaderboardModule from "./LeaderboardModule";
import LiveClassModule from "./LiveClassModule";
import LiveClassMeeting from "./LiveClassModule/LiveClassMeeting";

import Assignment from "./AssignmentModule";
import AssignmentDetails from "./AssignmentModule/AssignmentDetails";
import AnnouncementDetails from "./Announcement/AnnouncementDetails";
import Announcement from "./Announcement";


// temp
import ProductModule from "./ProductModule";
import CartModule from "./CartModule";
import BottomBar from '../../shared/components/BottomBar';


const Home = (props: ITitleState) => {
  const location = useLocation();
  let hostName = window.location.host.split('.')[0].toLocaleLowerCase()

  const checkPath = () => {
    return (
      location?.pathname?.split("/")[4] === "exam" ||
      location?.pathname?.includes("quick_quiz") ||
      location?.pathname.includes("add_course") ||
      location?.pathname.includes("content") ||
      (location?.pathname?.includes(routes.LIVE_CLASS) && location?.pathname?.includes("meeting"))
    );
  };

  const Routes = [
    {
      path: routes.LEARN,
      Component: <Learn />,
      exact: true,
    },
    {
      path: routes.QUICK_QUIZ,
      Component: <ExerciseExam />,
      exact: true,
    },
    {
      path: routes.QUICK_QUIZ_RESULTS,
      Component: <ExerciseExamResult />,
      exact: true,
    },
    {
      path: routes.CHAPTERS,
      Component: <ListChapters />,
      exact: true,
    },
    {
      path: routes.CHAPTER,
      Component: <Chapter />,
      exact: true,
    },
    {
      path: routes.STUDY_MATERIAL,
      Component: <StudyMaterial />,
      exact: true,
    },
    {
      path: routes.PRACTICE,
      Component: <PracticeModule />,
      exact: false,
    },
    {
      path: routes.QUERY,
      Component: <QueryModule />,
      exact: false,
    },
    {
      path: routes.ANALYTICS,
      Component: <AnalyticsModule />,
      exact: false,
    },
    {
      path: routes.MY_PROFILE,
      Component: <MyProfileModule />,
      exact: false,
    },
    {
      path: routes.LEADERBOARD,
      Component: <LeaderboardModule />,
      exact: true,
    },
    {
      path: routes.LIVE_CLASS,
      Component: <LiveClassModule />,
      exact: true,
    },
    {
      path: routes.LIVE_CLASS_MEETING,
      Component: <LiveClassMeeting />,
      exact: true,
    },
    {
      path: routes.ASSIGNMENT_DETAILS,
      Component: <AssignmentDetails />,
      exact: true,
    },
    {
      path: routes.ASSIGNMENT,
      Component: <Assignment />,
      exact: true,
    },
    {
      path: routes.ANNOUNCEMENT_DETAILS,
      Component: <AnnouncementDetails />,
      exact: true,
    },
    {
      path: routes.ANNOUNCEMENT,
      Component: <Announcement />,
      exact: true,
    },
    {
      path: routes.PRODUCTS,
      Component: <ProductModule />,
      exact: true,
    },
    {
      path: routes.CART,
      Component: <CartModule />,
      exact: true,
    }
  ];

  return (
    <Row>
      {checkPath() ? null : (
        <Col xl={4} xxl={3} className='sidebar'>
          <Sidebar />
        </Col>
      )}
      <BottomBar />
      <Col
        xl={checkPath() ? 24 : 20}
        xxl={checkPath() ? 24 : 21}
        className={`${
          checkPath()
            ? "application-body__full-view"
            : props?.float
            ? "application-body-modify__nav"
            : props?.title
            ? "application-body"
            : ""
        } ${props.twoBgs && `application-body-two-bgs ${hostName}`}`}
      >
        <Navbar />
        <Switch>
          {Routes?.map(({ exact, Component, ...rest },index) => (
            <Route key={'HOME_ROUTES#'+index} exact={exact} component={() => Component} {...rest} />
          ))}
          <Redirect exact strict from={"*"} to={routes.LEARN} />
        </Switch>
      </Col>
    </Row>
  );
};

export default TitleContainer(Home);
