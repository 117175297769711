import { Checkbox, Col, Input, Row } from "antd";
import React, { FC } from "react";
import { BoardService } from "../../../../../services/BoardService/board.service";
import UIButton from "../../../../../shared/components/UIButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./listBoards.scss";
import { useHistory } from "react-router";
interface ListBoardsProps {
  onSelectBoard: (value: any) => void;
  onSubmit: () => void
}
const ListBoards: FC<ListBoardsProps> = ({ onSelectBoard, onSubmit }) => {
  const onSearch = (value: string) => {
    const filtered = boardsHolder.filter((item: any) => {
      const itemName = item.name.toUpperCase();
      const itemAcronym = item.name.toUpperCase();

      const textData = value.toUpperCase();
      return (
        itemName.indexOf(textData) > -1 || itemAcronym.indexOf(textData) > -1
      );
    });

    setBoards(filtered);
  };
  const [boards, setBoards] = React.useState([]);
  const [boardsHolder, setBoardsHolder] = React.useState([]);
  const [selectedBoard, setSelectedBoard] = React.useState("");
  const history = useHistory();
  const getBoardsList = () => {
    BoardService.getBoardsList(
      (response: any) => {
        setBoards(response);
        setBoardsHolder(response);
      },
      (error: Error) => {
        //console.log(error);
      },
      () => {}
    );
  };
  React.useEffect(() => {
    getBoardsList();
  }, []);
  return (
    <div className="register-form__container">
      <h1>Select your school board</h1>
      <Row>
        <Col span={24}>
          <Input
            placeholder="Search your board"
            onChange={(e) => onSearch(e.target.value)}
            bordered={false}
            className="search__input"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="new-boards__wrapper">
            {boards.map((value: any, index) => {
              return (
                <Row>
                  <Col span={23}>
                    <div
                      className={`school-board__card ${
                        selectedBoard == value.id ? "card__border" : ""
                      }`}
                      onClick={() => {
                        onSelectBoard(value.classRooms);
                        setSelectedBoard(value.id);
                      }}
                    >
                      <Checkbox
                        className="board__checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            onSelectBoard(value.id);
                            setSelectedBoard(value.id);
                          }
                        }}
                        checked={selectedBoard == value.id}
                      ></Checkbox>
                      <img
                        alt=""
                        src={
                          value.img_url
                            ? value.img_url
                            : "https://www.pngkey.com/png/detail/914-9142128_jee-neet-go-gmat-way-to-hold-entrance.png"
                        }
                        className="card__icon"
                      />
                      <p>{value?.name}</p>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="btn__wrapper btn__wrapper--margin" span={24}>
          <UIButton
            type="submit"
            className="mt-5"
            icon={<ArrowRightOutlined />}
            clickHandler={onSubmit}
          >
            Next
          </UIButton>
        </Col>
       
      </Row>
      <Row>
      {/* <Col span={24} onClick={() => onSkip()}>
        <p className="skip">Skip</p>
        </Col> */}
      </Row>
    </div>
  );
};
export default ListBoards;
