import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  RECENT_CONTENT_HISTORY,
  UPDATE_RECENT_CONTENT_HISTORY
} from "../../routes/routeConstants/apiRoutes";
import { ContehtHistoryModel, ContehtHistoryUpdateModel } from '../../models/ContentHistory/contentHistory';
import { ChapterContent } from '../../models/Chapters/chapter.model';

export class ContentHistoryService {
  static getRecentWatchedHistory(params: {courseId: any}, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(RECENT_CONTENT_HISTORY, params), {})
      .then((response) => {
        const data  = deserialize(ChapterContent, response["data"]["chaptor_contents"])
        onSuccess(data);
      })
      .catch((e) => {
        onError(e);
      });
  }
  static createRecentlyWatchedHistory(params: {courseId: any}, data: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .post(
        generatePath(RECENT_CONTENT_HISTORY, params),
        {content_history: data},
      )
      .then((response) => {

        const contents = deserialize(
          ContehtHistoryModel,
          response["data"]["content_history"]
        );
        onSuccess(contents);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static updateContentHistory(
    data: ContehtHistoryModel,
    params: { courseId: any, id: number },
    onSuccess: Function,
    onError: Function
  ) {
    const serializeData = serialize(ContehtHistoryUpdateModel, data)

    return axiosInstance
      .put(
        generatePath(UPDATE_RECENT_CONTENT_HISTORY, params),
        {content_history: serializeData}
      )
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }
}
