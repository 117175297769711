import {serializable, alias, primitive} from 'serializr';

export class PaginationModel {
    @serializable(alias('current_page', primitive()))
    currentPage?: string;
    @serializable(alias('total_pages', primitive()))
    totalPages?: string;
    @serializable(alias('total_count', primitive()))
    totalCount?: string;
    @serializable(alias('next_page', primitive()))
    nextPage?: string;
    @serializable(alias('prev_page', primitive()))
    prevPage?: boolean;
    @serializable(alias('items_per_page', primitive()))
    pageSize?: boolean;
}
