import axiosInstance from "../../interceptor/_axiosInstance";
import { PRODUCT_LIST } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

import { PaginationModel } from "../../models/pagination.model"; 
import { Products } from "../../models/ProductModel/products.model";

import { generatePath } from "react-router";

export default class ProductService {

    static getProductList(
        queryParams: object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PRODUCT_LIST ,{ params: queryParams })
            .then((response) => {
                const productList = response.data["products"] !== null
                    ? deserialize(
                        Products,
                        response.data["products"]
                    )
                    : [];
                const pageMeta = response.data["meta"] !== null ? deserialize(PaginationModel, response.data["meta"]) : [];
   
                onSuccess(productList, pageMeta);
            })
            .catch((error) => {
                Notification({
                    message: "Failed",
                    description: "Unable to fetch the product list",
                    type: NotificationTypes.ERROR,
                });
                onError(error);
            })
            .finally(onFinal);
    }




}