import React, { FC } from "react";
import { Content, Subtitle, SuperSubtitle, Title } from "../TermsAndConditions";
import "./privacyPolicy.scss";

interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = (props) => {
  const {} = props;

  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <Content>Last Updated: June 19, 2023</Content>
      <Content>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.{" "}
      </Content>
      <Content>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </Content>
      <Title>Interpretation and Definitions</Title>
      <Subtitle>Interpretation</Subtitle>
      <Content>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </Content>
      <Subtitle>Definitions</Subtitle>
      <Content>For the purposes of this End-User License Agreement:</Content>
      <div className="tac__tab">
        <Content heading={"Agreement"}>
          means this End-User License Agreement that forms the entire agreement
          between You and the Company regarding the use of the Application.
        </Content>
        <Content heading={"Affiliate"}>
          means an entity that controls, is controlled by or is under common
          control with a party, where "control" means ownership of 50% or more
          of the shares, equity interest or other securities entitled to vote
          for election of directors or other managing authority.{" "}
        </Content>
        <Content heading={"Application"}>
          means the software program provided by the Company downloaded by You
          through an Application Store's account to a Device, named Mindjjo.
        </Content>
        <Content heading={"Company"}>
          (referred to as either "the Company", "We", "Us" or "Our" in this
          Agreement) refers to Panditji, 1st Floor, NO.159 E-7, K.R.N.PLAZA,
          AVARAMPALAYAM ROAD, OPP. CO-.
        </Content>
        <Content heading={"Country"}>refers to: Tamil Nadu, India</Content>
        <Content heading={"Device"}>
          means any device that can access the Service such as a computer, a
          cellphone or a digital tablet.
        </Content>
        <Content heading={"Personal Data"}>
          is any information that relates to an identified or identifiable
          individual.{" "}
        </Content>
        <Content heading={"Service"}>refers to the Application.</Content>
        <Content heading={"Service Provider"}>
          means any natural or legal person who processes the data on behalf of
          the Company. It refers to third-party companies or individuals
          employed by the Company to facilitate the Service, to provide the
          Service on behalf of the Company, to perform services related to the
          Service or to assist the Company in analyzing how the Service is used.
        </Content>
        <Content heading={"Third-party Social Media Service"}>
          refers to any website or any social network website through which a
          User can log in or create an account to use the Service.{" "}
        </Content>
        <Content heading={"Usage Data"}>
          refers to data collected automatically, either generated by the use of
          the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).{" "}
        </Content>
        <Content heading={"You"}>
          means the individual accessing or using the Service, or the company,
          or other legal entity on behalf of which such individual is accessing
          or using the Service, as applicable.
        </Content>
      </div>

      <Title>Collecting and Using Your Personal Data</Title>
      <Subtitle>Types of Data Collected </Subtitle>
      <Subtitle>Personal Data </Subtitle>
      <Content>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </Content>
      <ul>
        <li>Email address </li>
        <li>First name and last name </li>
        <li>Phone number </li>
        <li>Address, State, Province, ZIP/Postal code, City </li>
        {/*<li>
          Bank account information in order to pay for products and/or services
          within the Service{" "}
          </li>*/}
        <li>Usage Data </li>
      </ul>
       {/*<Content>
        When You pay for a product and/or a service via bank transfer, We may
        ask You to provide information to facilitate this transaction and to
        verify Your identity. Such information may include, without limitation:
      </Content>
      <ul>
        <li>Date of birth </li>
        <li>Passport or National ID card </li>
        <li>Bank card statement </li>
        <li>Other information linking You to an address </li>
        </ul>*/}
      <Subtitle>Usage Data </Subtitle>
      <Content>
        Usage Data is collected automatically when using the Service.{" "}
      </Content>
      <Content>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </Content>
      <Content>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Content>
      <Content>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </Content>
      <Subtitle>Information Collected while Using the Application </Subtitle>
      <Content>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:{" "}
      </Content>
      <ul>
        <li>Information regarding your location</li>
         {/*<li>Information from your Device's phone book (contacts list)</li>*/}
        <li>
          Pictures and other information from your Device's camera and photo
          library{" "}
        </li>
      </ul>
      <Content>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </Content>
      <Content>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </Content>
      <Subtitle>Use of Your Personal Data </Subtitle>
      <Content>
        The Company may use Personal Data for the following purposes:{" "}
      </Content>
      <ul>
        <li>
          <Content heading={"To provide and maintain our Service,"}>
            including to monitor the usage of our Service.{" "}
          </Content>
        </li>
        <li>
          <Content heading={"To manage Your Account:"}>
            to manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.{" "}
          </Content>
        </li>
        <li>
          <Content heading={"For the performance of a contract:"}>
            the development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </Content>
        </li>
        <li>
          <Content heading={"To contact You:"}>
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation.
          </Content>
        </li>
        <li>
          <Content heading={"To provide You"}>
            with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that
            you have already purchased or enquired about unless You have opted
            not to receive such information.{" "}
          </Content>
        </li>
        <li>
          <Content heading={"To manage Your requests:"}>
            To attend and manage Your requests to Us.{" "}
          </Content>
        </li>
        <li>
          <Content heading={"To deliver targeted advertising to You:"}>
            We may use Your information to develop and display content and
            advertising (and work with third-party vendors who do so) tailored
            to Your interests and/or location and to measure its effectiveness.
          </Content>
        </li>
        <li>
          <Content heading={"For business transfers:"}>
            We may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.For other purposes:
          </Content>
        </li>
        <li>
          <Content heading={"For other purposes:"}>
            We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </Content>
        </li>
      </ul>
      <Content>
        We may share Your personal information in the following situations:{" "}
      </Content>
      <ul>
        <li>
          <Content heading={"With Service Providers:"}>
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to advertise on third
            party websites to You after You visited our Service, for payment
            processing, to contact You.u
          </Content>
        </li>
        <li>
          <Content heading={"For business transfers: "}>
            We may share or transfer Your personal information in connection
            with, or during negotiations of, any merger, sale of Company assets,
            financing, or acquisition of all or a portion of Our business to
            another company.
          </Content>
        </li>
        <li>
          <Content heading={"With Affiliates:"}>
            We may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
          </Content>
        </li>
        <li>
          <Content heading={"With business partners:"}>
            We may share Your information with Our business partners to offer
            You certain products, services or promotions.
          </Content>
        </li>
        <li>
          <Content heading={"With other users:"}>
            When You share personal information or otherwise interact in the
            public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside. If You interact with
            other users or register through a Third-Party Social Media Service,
            Your contacts on the Third-Party Social Media Service may see Your
            name, profile, pictures and description of Your activity. Similarly,
            other users will be able to view descriptions of Your activity,
            communicate with You and view Your profile.
          </Content>
        </li>
        <li>
          <Content heading={"With Your consent:"}>
            We may disclose Your personal information for any other purpose with
            Your consent.
          </Content>
        </li>
      </ul>
      <Subtitle>Retention of Your Personal Data </Subtitle>
      <Content>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.{" "}
      </Content>
      <Content>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </Content>
      <Subtitle>Transfer of Your Personal Data </Subtitle>
      <Content>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.{" "}
      </Content>
      <Content>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </Content>
      <Content>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </Content>
      <Subtitle>Disclosure of Your Personal Data </Subtitle>
      <SuperSubtitle>Business Transactions </SuperSubtitle>
      <Content>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.{" "}
      </Content>
      <SuperSubtitle>Law enforcement </SuperSubtitle>
      <SuperSubtitle>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).{" "}
      </SuperSubtitle>
      <SuperSubtitle>Other legal requirements </SuperSubtitle>
      <Content>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:{" "}
      </Content>
      <ul>
        <li>Comply with a legal obligation </li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service{" "}
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public{" "}
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <Subtitle>Security of Your Personal Data </Subtitle>
      <Content>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Content>
      <Title>
        Detailed Information on the Processing of Your Personal Data
      </Title>
      <Content>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.{" "}
      </Content>
      <Subtitle>Analytics</Subtitle>
      <Content>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.{" "}
      </Content>
      <div className="tac__tab">
        <p className="content__title">Google Analytics</p>
        <Content>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          You may opt-out of certain Google Analytics features through your
          mobile device settings, such as your device advertising settings or by
          following the instructions provided by Google in their Privacy Policy:
          <a target="__blank" href={"https://policies.google.com/privacy"}>
            https://policies.google.com/privacy
          </a>{" "}
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms web page:{" "}
          <a target="__blank" href={"https://policies.google.com/privacy"}>
            https://policies.google.com/privacy
          </a>
        </Content>
      </div>
      <Subtitle>Email Marketing</Subtitle>
      <Content>
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </Content>
      <Content>
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </Content>
      <div className="tac__tab">
        <p className="content__title">Gmail workspace </p>
        <Content>Their Privacy Policy can be viewed at gmail.com</Content>
      </div>
      <Subtitle>Payments</Subtitle>
      <Content>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </Content>
      <Content>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </Content>
      <div className="tac__tab">
        <p className="content__title">Apple Store In-App Payments</p>
        <Content>
          Their Privacy Policy can be viewed at
          <a
            target="__blank"
            href={"https://www.apple.com/legal/privacy/en-ww/"}
          >
            https://www.apple.com/legal/privacy/en-ww/
          </a>
        </Content>
        <p className="content__title">Google Play In-App Payments</p>
        <Content>
          Their Privacy Policy can be viewed at
          <a target="__blank" href={"https://www.google.com/policies/privacy/"}>
            https://www.google.com/policies/privacy/
          </a>
        </Content>
        <p className="content__title">PayPal</p>
        <Content>
          Their Privacy Policy can be viewed at
          <a
            target="__blank"
            href={"https://www.paypal.com/webapps/mpp/ua/privacy-full"}
          >
            https://www.paypal.com/webapps/mpp/ua/privacy-full
          </a>
        </Content>
      </div>
      <Content>
        When You use Our Service to pay a product and/or service via bank
        transfer, We may ask You to provide information to facilitate this
        transaction and to verify Your identity.{" "}
      </Content>
      <Subtitle>Behavioral Remarketing</Subtitle>
      <Content>
        The Company uses remarketing services to advertise to You after You
        accessed or visited our Service. We and Our third-party vendors use
        cookies and non-cookie technologies to help Us recognize Your Device and
        understand how You use our Service so that We can improve our Service to
        reflect Your interests and serve You advertisements that are likely to
        be of more interest to You.{" "}
      </Content>
      <Content>
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies and to enable Us to:
      </Content>
      <ul>
        <li>
          Measure and analyze traffic and browsing activity on Our Service{" "}
        </li>
        <li>
          Show advertisements for our products and/or services to You on
          third-party websites or apps{" "}
        </li>
        <li>
          Show advertisements for our products and/or services to You on
          third-party websites or apps{" "}
        </li>
      </ul>
      <Content>
        Some of these third-party vendors may use non-cookie technologies that
        may not be impacted by browser settings that block cookies. Your browser
        may not permit You to block such technologies. You can use the following
        third-party tools to decline the collection and use of information for
        the purpose of serving You interest-based advertising:{" "}
      </Content>
      <ul>
        <li>
          The NAI's opt-out platform:{" "}
          <a
            target="__blank"
            href={"http://www.networkadvertising.org/choices/"}
          >
            http://www.networkadvertising.org/choices/
          </a>
        </li>
        <li>
          The EDAA's opt-out platform{" "}
          <a target="__blank" href={"http://www.youronlinechoices.com/"}>
            http://www.youronlinechoices.com/
          </a>
        </li>
        <li>
          The DAA's opt-out platform:{" "}
          <a target="__blank" href={"http://optout.aboutads.info/?c=2&lang=EN"}>
            http://optout.aboutads.info/?c=2&lang=EN
          </a>
        </li>
      </ul>
      <Content>
        You may opt-out of all personalized advertising by enabling privacy
        features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
        Out of Ads Personalization (Android). See Your mobile device Help system
        for more information.{" "}
      </Content>
      <Content>
        We may share information, such as hashed email addresses (if available)
        or other online identifiers collected on Our Service with these
        third-party vendors. This allows Our third-party vendors to recognize
        and deliver You ads across devices and browsers. To read more about the
        technologies used by these third-party vendors and their cross-device
        capabilities please refer to the Privacy Policy of each vendor listed
        below.
      </Content>
      <Content>The third-party vendors We use are: </Content>
      <div className="tac__tab">
        <p className="content__title">Google Ads (AdWords)</p>
        <Content>
          Google Ads (AdWords) remarketing service is provided by Google Inc.
          You can opt-out of Google Analytics for Display Advertising and
          customise the Google Display Network ads by visiting the Google Ads
          Settings page:{" "}
          <a target="__blank" href={"http://www.google.com/settings/ads"}>
            http://www.google.com/settings/ads
          </a>
          <br /> Google also recommends installing the Google Analytics Opt-out
          Browser Add-on -{" "}
          <a target="__blank" href={"https://tools.google.com/dlpage/gaoptout"}>
            https://tools.google.com/dlpage/gaoptout
          </a>{" "}
          - for your web browser. Google Analytics Opt-out Browser Add-on
          provides visitors with the ability to prevent their data from being
          collected and used by Google Analytics.
          <br /> For more information on the privacy practices of Google, please
          visit the Google Privacy & Terms web page:
          <a target="__blank" href={"https://policies.google.com/privacy"}>
            https://policies.google.com/privacy
          </a>{" "}
        </Content>
        <p className="content__title">Facebook</p>
        <Content>
          Facebook remarketing service is provided by Facebook Inc. <br /> You
          can learn more about interest-based advertising from Facebook by
          visiting this page:{" "}
          <a
            target="__blank"
            href={"https://www.facebook.com/help/516147308587266"}
          >
            https://www.facebook.com/help/516147308587266
          </a>{" "}
          <br />
          To opt-out from Facebook's interest-based ads, follow these
          instructions from Facebook:
          <a
            target="__blank"
            href={"https://www.facebook.com/help/568137493302217"}
          >
            https://www.facebook.com/help/568137493302217
          </a>{" "}
          <br /> Facebook adheres to the Self-Regulatory Principles for Online
          Behavioural Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA{" "}
          <a target="__blank" href={"http://www.aboutads.info/choices/"}>
            http://www.aboutads.info/choices/
          </a>
          , the Digital Advertising Alliance of Canada in Canada
          <a target="__blank" href={"http://youradchoices.ca/"}>
            http://youradchoices.ca/
          </a>{" "}
          or the European Interactive Digital Advertising Alliance in Europe{" "}
          <a target="__blank" href={"http://www.youronlinechoices.eu/"}>
            http://www.youronlinechoices.eu/
          </a>
          , or opt-out using your mobile device settings. <br /> For more
          information on the privacy practices of Facebook, please visit
          Facebook's Data Policy:{" "}
          <a
            target="__blank"
            href={"https://www.facebook.com/privacy/explanation"}
          >
            https://www.facebook.com/privacy/explanation
          </a>
        </Content>
      </div>
      <Subtitle>Usage, Performance and Miscellaneous </Subtitle>
      <Content>
        We may use third-party Service Providers to provide better improvement
        of our Service.
      </Content>
      <div className="tac__tab">
        <p className="content__title">Invisible reCAPTCHA </p>
        <Content>
          We use an invisible captcha service named reCAPTCHA. reCAPTCHA is
          operated by Google. The reCAPTCHA service may collect information from
          You and from Your Device for security purposes. The information
          gathered by reCAPTCHA is held in accordance with the Privacy Policy of
          Google:{" "}
          <a
            target="__blank"
            href={"https://www.google.com/intl/en/policies/privacy/"}
          >
            https://www.google.com/intl/en/policies/privacy/
          </a>{" "}
        </Content>
        <p className="content__title">Google Places</p>
        <Content>
          Google Places is a service that returns information about places using
          HTTP requests. It is operated by Google Google Places service may
          collect information from You and from Your Device for security
          purposes. The information gathered by Google Places is held in
          accordance with the Privacy Policy of Google:{" "}
          <a
            target="__blank"
            href={"https://www.google.com/intl/en/policies/privacy/"}
          >
            https://www.google.com/intl/en/policies/privacy/
          </a>
        </Content>
      </div>
      <Title>Children's Privacy </Title>
      <Content>
        The Service may contain content appropriate for children under the age
        of 13. As a parent, you should know that through the Service children
        under the age of 13 may participate in activities that involve the
        collection or use of personal information. We use reasonable efforts to
        ensure that before we collect any personal information from a child, the
        child's parent receives notice of and consents to our personal
        information practices.{" "}
      </Content>
      <Content>
        We also may limit how We collect, use, and store some of the information
        of Users between 13 and 18 years old. In some cases, this means We will
        be unable to provide certain functionality of the Service to these
        Users. If We need to rely on consent as a legal basis for processing
        Your information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.{" "}
      </Content>
      <Content>
        We may ask a User to verify its date of birth before collecting any
        personal information from them. If the User is under the age of 13, the
        Service will be either blocked or redirected to a parental consent
        process.{" "}
      </Content>
      <Subtitle>
        Information Collected from Children Under the Age of 13{" "}
      </Subtitle>
      <Content>
        The Company may collect and store persistent identifiers such as cookies
        or IP addresses from Children without parental consent for the purpose
        of supporting the internal operation of the Service.{" "}
      </Content>
      <Content>
        We may collect and store other personal information about children if
        this information is submitted by a child with prior parent consent or by
        the parent or guardian of the child.{" "}
      </Content>
      <Content>
        The Company may collect and store the following types of personal
        information about a child when submitted by a child with prior parental
        consent or by the parent or guardian of the child:{" "}
      </Content>
      <ul>
        <li>First and/or last name </li>
        <li>Date of birth </li>
        <li>Gender </li>
        <li>Grade level </li>
        <li>Email address </li>
        <li>Telephone number </li>
        <li>Parent's or guardian's name </li>
        <li>Parent's or guardian's email address </li>
      </ul>
      <Content>
        For further details on the information We might collect, You can refer
        to the "Types of Data Collected" section of this Privacy Policy. We
        follow our standard Privacy Policy for the disclosure of personal
        information collected from and about children.{" "}
      </Content>
      <Subtitle>Consent of Legal Guardian for Minor</Subtitle>
      <Content>
        Persons who are "competent/capable" of contracting within the meaning of
        the Indian Contract Act, 1872 shall be eligible to register for the
        Application and all Our products or Services. Persons who are minors,
        un-discharged insolvents etc. are not eligible to register for Our
        products or Services. As a minor, if You wish to use Our products or
        Services, such use shall be made available to You by Your legal guardian
        or parents, who has agreed to these Terms. In the event a minor utilizes
        the Application/Website/Services, it is assumed that he/she has obtained
        the consent of the legal guardian or parents and such use is made
        available by the legal guardian or parents. The Company will not be
        responsible for any consequence that arises as a result of misuse of any
        kind of Our Application or any of Our products or services that may
        occur by virtue of any person including a minor registering for the
        Services/products provided. By using the products or Services You
        warrant that all the data provided by You is accurate and complete and
        that the student using the Application has obtained the consent of the
        parent/legal guardian (in case of minors). The Company reserves the
        right to terminate Your subscription and/or refuse to provide You with
        access to the products or services if it is discovered that You are
        under the age of 18 (eighteen) years and the consent to use the products
        or Services is not made by Your parent/legal guardian or any information
        provided by You is inaccurate. You acknowledge that the Company does not
        have the responsibility to ensure that You conform to the aforesaid
        eligibility criteria. It shall be Your sole responsibility to ensure
        that You meet the required qualification. Any persons under the age of
        18 (eighteen) should seek the consent of their parents/legal guardians
        before providing any information about themselves or their parents and
        other family members on the Application.
      </Content>
      <Subtitle>Parental Access </Subtitle>
      <Content>
        A parent who has already given the Company permission to collect and use
        his child personal information can, at any time:{" "}
      </Content>
      <ul>
        <li>Review, correct or delete the child's personal information</li>
        <li>
          Discontinue further collection or use of the child's personal
          information
        </li>
      </ul>
      <Content>
        To make such a request, You can write to Us using the contact
        information provided in this Privacy Policy.{" "}
      </Content>
      <Title>Data Deletion</Title>
      <Content>
        It is your right to delete any data we have collected on you. 
        To delete all your data collected by the application by sending an email at support@mypanditji.online.
        You data will be removed from our system in 7 working days.

      </Content>
      <Title>Links to Other Websites </Title>
      <Content>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.{" "}
      </Content>
      <Content>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.{" "}
      </Content>
      <Title>Changes to this Privacy Policy </Title>
      <Content>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.{" "}
      </Content>
      <Content>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.{" "}
      </Content>
      <Content>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.{" "}
      </Content>
      <Title>Contact Us </Title>
      <Content>
        If you have any questions about this Privacy Policy, You can contact us:{" "}
      </Content>
      <div className="tac__tab">
        <Content>By email: support@mypanditji.online </Content>
         {/*<Content>
          By visiting this page on our website:{" "}
          <a target="_blank" href={"http://mypanditji.online/wp/"}>
            http://mypanditji.online/wp/
          </a>
        </Content>*/}
        <Content>By phone number: 739 777 8151 </Content>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
