import { Col, Input, Row } from "antd";
import { Formik } from "formik";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import UIButton from "../../../../../shared/components/UIButton";
import "./classForBoard.scss";
import { BoardService } from "../../../../../services/BoardService/board.service";
import AuthService from "../../../../../services/AuthService/auth.service";
import { StudentService } from "../../../../../services/StudentService/student.service";
import {
  generatePath,
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router";
import {
  BUILDING,
  HOME,
  PROFILES,
  STUDENT_EXAMS,
} from "../../../../../routes/routeConstants/appRoutes";
import StepPencil from "../../../../../shared/components/StepPencil";
import { Student } from "../../../../../models/student.model";
import StepContainer from "../../../../../store/container/StepContainer";
import AuthContainer from "../../../../../store/container/AuthContainer";
import Logo from "../../../../../assets/images/app-icon-white.svg";
import SelectClassImage from "../../../../../assets/images/select-class.svg";

interface ClassForBoardProps {
  user: Student;
  setStep: (step: number, stepPercentage: number) => void;
}
const ClassForBoard = ({ user, setStep }: ClassForBoardProps) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [classes, setClasses] = useState([]);
  const { state }: { state: any } = useLocation();
  const params: any = useParams();
  const history = useHistory();
  useEffect(() => {
    setClasses(state?.classRooms);
  }, []);

  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <img src={SelectClassImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <div className="classes-form__container">
            <StepPencil />
            <h1>
              <span>
                <ArrowLeftOutlined onClick={() => history.goBack()} />
              </span>
              Select your class
            </h1>
            <Row>
              <Col span={24}>
                <div className="classes__wrapper">
                  <Row justify="start" gutter={[15, 5]}>
                    {classes?.map((value: any, index) => {
                      return (
                        <Col span={6}>
                          <div
                            className={`class-board__card ${selectedClass == value.id ? "card__border" : ""
                              }`}
                            onClick={() => {
                              setSelectedClass(value.id);
                            }}
                          >
                            <p>{value?.name}</p>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="btn__wrapper" span={24}>
                <UIButton
                  type="submit"
                  className="mt-5"
                  icon={<ArrowRightOutlined />}
                  clickHandler={() => {
                    StudentService.createStudentCourse(
                      {
                        courseType: "ClassRoom",
                        courseId: selectedClass,
                      },
                      () => {
                        // AuthService.updateStep(
                        //   {
                        //     step: params?.usageId == "exam" ? 0 : 5,
                        //   },
                        //   () => {
                        AuthService.updateStep(
                          params?.usageId == "0" ? 0 : 3,
                          user?.id,
                          () => {
                            params?.usageId == "0" ? setStep(0, 80) : setStep(3, 80);
                            AuthService.registerTrial();// Register Trial
                          },
                          () => { },
                          () => { }
                        );



                        params?.usageId == "0"
                          ? history.push(BUILDING)
                          : history.push(
                            generatePath(STUDENT_EXAMS, { ...params })
                          );
                        //   },
                        //   () => {},
                        //   () => {}
                        // );
                      },
                      () => { }
                    );
                  }}
                >
                  Next
                </UIButton>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default withRouter(StepContainer(AuthContainer(ClassForBoard)));
