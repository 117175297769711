import { serializable, alias, primitive, serialize, object } from "serializr";
import { Student } from "./student.model";

export class UserInfo {
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
}

export class User {
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("otp", primitive()))
  otp?: string;

  @serializable(alias("grant_type", primitive()))
  grantType?: string = "otp";
}

export class IdNameModel {
  @serializable(alias("id", primitive()))
  id!: number;

  @serializable(alias("name", primitive()))
  name?: string;
}

export class LoginCredentials {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("grant_type", primitive()))
  grantType?: string = "password";
}

export class ResetPasswordEmail {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("redirect_url", primitive()))
  redirectUrl?: string;
}

export class UpdatePasswordModel {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("reset_password_token", primitive()))
  resetPasswordToken?: string;

  @serializable(alias("new_password", primitive()))
  newPassword?: string;  
}


export class UserRegister {
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;
}

export class StepUpdate {
  @serializable(alias("step", primitive()))
  step?: number;
}
export class StudentDetails extends StepUpdate {
  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;
}
export class Parent {
  @serializable(alias("phone_number", primitive()))
  phoneNumber?: string;
  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("step", primitive()))
  step?: number;
  @serializable(alias("current_student", object(Student)))
  currentStudent?: Student;
}

export class TokenModel {
  @serializable(alias('access_token', primitive()))
  accessToken?: string;
  @serializable(alias('refresh_token', primitive()))
  refreshToken?: string;
  @serializable(alias('token_type', primitive()))
  tokenType?: string;
}
