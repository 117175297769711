import React, { FC, Fragment } from "react";
import moment from "moment";
import { Col, Row, Divider, Input, Button, Tag } from "antd";

import { CheckCircleOutlined } from '@ant-design/icons';

import "./checkoutCard.scss";


const { Search } = Input;
interface CheckoutCardProps {
  payableAmount: any
  setCoupon: Function,
  coupon: string,
  loading?: boolean,
  onApplyCoupon: Function,
  appliedCoupon: string,
  onRemoveCoupon: Function,
  onCheckout: Function,
}

const CheckoutCard: FC<CheckoutCardProps> = (props) => {
  const { payableAmount, setCoupon, coupon, loading, onApplyCoupon, appliedCoupon,onRemoveCoupon,onCheckout } = props

  return (
    <Row className={`checkout-card`} >
      <Col span={24}>
        <div className="checkout-card-date__container">
          <div className="white-wrap">
            <div className="cart-heading">
              <span className="__text">Total:</span>
              <span className="__title">₹ {payableAmount?.totelAmount | 0}
                {payableAmount?.amount !== payableAmount?.totelAmount && <span className="__sub_title">₹{payableAmount?.amount | 0}</span>}
              </span>

            </div>
            <div className="select-btn">
              <a onClick={()=>onCheckout()} className="main-btn-3" >Checkout</a>
            </div>
          </div>

          <Divider />

          <div>
            <span className="__text">Promotions</span>
            <Input.Group compact>
              <Search
                style={{ width: 'calc(100%)' }}
                onChange={(e) => { setCoupon(e.target.value); }}
                value={coupon}
                enterButton="Apply"
                size="large"
                loading={loading}
                onSearch={() => onApplyCoupon()}
              />
            </Input.Group>
          </div>
          {appliedCoupon ?
            <Tag icon={<CheckCircleOutlined />} color="success" closable onClose={()=>onRemoveCoupon()}>
              {appliedCoupon}
            </Tag>
            : null
          }

        </div>
      </Col>
    </Row>
  );
};

export default CheckoutCard;
