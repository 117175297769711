import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import { Form, Formik } from "formik";

import UIButton from "../../../../shared/components/UIButton";
import InputField from "../../../../shared/components/InputField";

import { Checkbox, Col, Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import {
  LOGIN,
  PARENT_VERIFY,
  PRIVACY_POLICY,
  TAC,
} from "../../../../routes/routeConstants/appRoutes";

import { validationSchema } from "../ValidationSchema";
import AuthService from "../../../../services/AuthService/auth.service";

// Style 
import "./sendotpForm.scss";
import "../loginotp.scss";


interface SendOTPProps {
  phoneNumber: string;
}

const SendOTPForm = () => {
  const initialValues = {
    phoneNumber: ""
  };

  const history = useHistory();
  const onSendOTP = (values: SendOTPProps) => {
    history.push(PARENT_VERIFY, {
      phoneNumber: "+91" + values.phoneNumber,
    });
    //console.log(values)
    // AuthService.createParent(
    //   { ...values, phoneNumber: "+91" + values.phoneNumber },
    //   () => {
    //     history.push(PARENT_VERIFY, {
    //       phoneNumber: "+91" + values.phoneNumber,
    //     });
    //   },
    //   () => { }
    // );
  };

  return (
    <div className="register-form">
      <h1>Login to continue</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => { }}
      >
        {({ values, dirty, isValid, setFieldValue }) => (
          <Form>
            <Row>
              <Col span={24}>
                <InputField
                  type="number"
                  name="phoneNumber"
                  placeholder="Mobile Number"
                  className="mt-8"
                  prefix={"+91"}
                />
              </Col>
            </Row>

            <Row>
              <Col className="btn__wrapper" span={24}>
                <UIButton
                  type="submit"
                  className="mt-5"
                  icon={<ArrowRightOutlined />}
                  clickHandler={() => {
                    isValid && onSendOTP(values);
                  }}
                >
                  Send OTP
                </UIButton>
                <p className="already-have-acc">
                  Login with email ?{" "}
                  <span onClick={() => history.push(LOGIN)}>Login here</span>
                </p>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SendOTPForm;
