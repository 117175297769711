import { combineReducers } from "redux";
import authReducer from "./authReducer";
import QuestionReducer from "./questionReducer";
import StepReducer from "./stepReducer";
import titleReducer from "./titleReducer";
import AnalyticsReducer from "./analyticsReducer";

const RootReducer = combineReducers({
    auth: authReducer,
    nav: titleReducer,
    ques: QuestionReducer,
    step: StepReducer,
    analytics:AnalyticsReducer
});
export type AppState=ReturnType<typeof RootReducer>
export default RootReducer;