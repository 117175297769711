import React, { FC, useEffect } from "react";
import { Course } from "../../../../../models/student.model";
import { PreviousPaperService } from "../../../../../services/PreviousPaperService/previouspaper.service";
import Heading from "../../../../../shared/components/Heading";
import AuthContainer from "../../../../../store/container/AuthContainer";
import  ExamCards from "./ExamCards";
import "./listExams.scss";
interface ListExamsProps {
  courses: Course[];
}
const ListExams: FC<ListExamsProps> = ({ courses }) => {
    //console.log(courses)
  return (
    <div className="list-exams__container">
      <Heading sub>{`Practice by Exams`} </Heading>
      <ExamCards courses={courses} />
    </div>
  );
};

export default AuthContainer(ListExams);
