import { serializable, alias, primitive, list, object } from 'serializr';

export class ExamExcerciseAttempt {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('time', primitive()))
    time?: string;

    @serializable(alias('started_at', primitive()))
    startedAt?: string;

    @serializable(alias('completed_at', primitive()))
    completedAt?: string;

    @serializable(alias('timer_options', primitive()))
    timerOptions?: string;
}


export class ExamExercise {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('exam_exercise_attempt', object(ExamExcerciseAttempt)))
    excerciseAttempt?: ExamExcerciseAttempt;

    @serializable(alias('is_active', primitive()))
    isActive?: string;

    @serializable(alias('time', primitive()))
    time?: string;

    @serializable(alias('timer_options', primitive()))
    timerOptions?: string;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('exam_questions_count', primitive()))
    examQuestionsCount?: string;
    
    @serializable(alias('exam_exercise_attempt_count', primitive()))
    examExerciseAttemptCount?: string;

}

export class ExamExercises {
    @serializable(alias('exam_exercises', list(object(ExamExercise))))
    exercises?: ExamExercise[];
}
