import React, { FC, useState } from "react";
import StepContainer from "../../../store/container/StepContainer";
import "./stepPencil.scss";
interface StepPencilProps {
    stepPercentage: number
    step: number
}
const StepPencil: FC<StepPencilProps> = ({
    stepPercentage
}) => {
  return (
    <div className="step-pencil__container">
      <div
        className="step-pencil__body"
        style={{
          background: `linear-gradient(to right,  #d75878 ${stepPercentage}%,#d75878 ${stepPercentage}%,#5839cb ${stepPercentage}%,#5839cb ${100 - stepPercentage}%)`,
        }}
      >
        {stepPercentage}%
      </div>
      <div className="step-pencil__neck"></div>
      <div className="step-pencil__hook"></div>
    </div>
  );
};
export default StepContainer(StepPencil);
