import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { DatePicker, Input } from "antd";
import Error from "../Error";
import "./inputField.scss";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
interface InputFieldProps {
  type: string;
  name: string;
  placeholder: string;
  className?: string;
  prefix?: string;
  suffix?: any;
  setFieldValue?: (value: any) => void;
  style?: {
    backgroundColor: string;
  };
}

const InputField: FC<InputFieldProps> = (props) => {
  const { name, className, setFieldValue } = props;
  return (
    <div>
      {name == "dob" ? (
        <DatePicker
          onChange={(date) =>
            setFieldValue && setFieldValue(date?.format("DD-MM-YYYY"))
          }
          className={`input-field ${className} w-100 `}
          placeholder="Date of Birth"
        />
      ) : (
        <Field
          as={props?.type == "password" ? Input.Password : Input}
          {...props}
          className={`input-field ${className}`}
        />
      )}

      <ErrorMessage name={name}>
        {(message: string) => <Error message={message} />}
      </ErrorMessage>
    </div>
  );
};

export default InputField;
