import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../../../routes/routeConstants/appRoutes";
import ListChaptersAnalytics from "./ListChaptersAnalytics";
import Analytics from "./index"
import ExercisesCardAnalytics from "./ExercisesCardAnalytics";
const AnalyticsModule = () => {
	const Routes = [
		{
			path: routes.ANALYTICS,
			Component: <Analytics />,
			exact: true,
		},
		{
			path: routes.LIST_ANALYTICS_CHAPTERS,
			Component: <ListChaptersAnalytics />,
			exact: true,
		},
		{
			path: routes.LIST_ANALYTICS_EXERCISES,
			Component: <ExercisesCardAnalytics />,
			exact: true,
		}
	];

	return (
		<div>
			<Switch>
				{Routes?.map(({ exact, Component, ...rest }, index) => (
					<Route
						key={'ANALYTICS_ROUTE#' + index}
						exact={exact}
						component={() => Component}
						{...rest}
					/>
				))}
			</Switch>
		</div>
	);
};

export default AnalyticsModule;
