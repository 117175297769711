import AgoraRTC, {
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ScreenVideoTrackInitConfig,
} from "agora-rtc-sdk-ng";
import React, { FC, useEffect, useState } from "react";
import { useClient } from "..";
import MuteIcon from "../../../../../assets/icon/live-class/microphone-fill.svg";
import UnmuteIcon from "../../../../../assets/icon/live-class/microphone-slash-fill.svg";
import StopVideoIcon from "../../../../../assets/icon/live-class/video-camera-fill.svg";
import StartVideoIcon from "../../../../../assets/icon/live-class/video-camera-slash-fill.svg";
import ParticipantsIcon from "../../../../../assets/icon/live-class/users-fill.svg";
import InfoIcon from "../../../../../assets/icon/live-class/info-fill.svg";

import "./controls.scss";
import UIButton from "../../../../../shared/components/UIButton";
import DeleteLiveClassAlert from "../DeleteLiveClassAlert";
import { useParams } from "react-router-dom";
import LiveClassService from "../../../../../services/LiveClassService/liveClass.service";
import { RtmChannel } from "agora-rtm-sdk";

interface ControlsProps {
  leaveHandler: () => void;
  setStart: React.Dispatch<React.SetStateAction<boolean>>;
  callType: "audio" | "video";
  audioTrack: IMicrophoneAudioTrack | null;
  videoTrack: [IMicrophoneAudioTrack, ICameraVideoTrack] | null;
  users: IAgoraRTCRemoteUser[];
  participantsHandler: () => void;
  infoHandler: () => void;
  clientControlsHandler: (controls: { audio: boolean; video: boolean }) => void;
  rtmChannel?: RtmChannel;
}

const Controls: FC<ControlsProps> = (props) => {
  const client = useClient();
  const {
    videoTrack,
    audioTrack,
    callType,
    setStart,
    leaveHandler,
    users,
    participantsHandler,
    infoHandler,
    clientControlsHandler,
    rtmChannel,
  } = props;
  const [trackState, setTrackState] = useState({
    video: true,
    audio: true,
    screen: false,
  });
  const [showDeleteAlert, setDeleteAlertVisibility] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const params: any = useParams();

  useEffect(() => {
    if (rtmChannel)
      rtmChannel.on("ChannelMessage", function (message: any, memberId) {
        if (message?.text == "mute") {
          mute("audio", false);
        }
        if (message?.text == "unmute") {
          mute("audio", true);
        }
        if (message?.text == "leave_channel") {
          leaveChannel();
        }
      });
  }, [rtmChannel]);

  const mute = async (type: "audio" | "video", value?: boolean) => {
    if (callType == "video") {
      if (type === "audio") {
        await videoTrack?.[0].setEnabled(
          value !== undefined ? value : !trackState.audio
        );
        clientControlsHandler({
          ...trackState,
          audio: value !== undefined ? value : !trackState.audio,
        });
        setTrackState((ps) => {
          return { ...ps, audio: value !== undefined ? value : !ps.audio };
        });
      } else if (type === "video") {
        await videoTrack?.[1].setEnabled(!trackState.video);
        clientControlsHandler({ ...trackState, video: !trackState.video });
        setTrackState((ps) => {
          return { ...ps, video: !ps.video };
        });
      }
    } else {
      await audioTrack?.setEnabled(
        value !== undefined ? value : !trackState.audio
      );
      clientControlsHandler({
        ...trackState,
        audio: value !== undefined ? value : !trackState.audio,
      });
      setTrackState((ps) => {
        return { ...ps, audio: value !== undefined ? value : !ps.audio };
      });
    }
  };

  const leaveChannel = async () => {
    if (params?.liveClassId) {
      setDeleteLoading(true);
      LiveClassService.endLiveClass(
        params?.liveClassId,
        async () => {
          await client.leave();
          client.removeAllListeners();
          if (callType == "video") {
            videoTrack?.[0].close();
            videoTrack?.[1].close();
          } else {
            audioTrack?.close();
          }
          await rtmChannel?.leave();
          setStart(false);
          leaveHandler();
          setDeleteAlertVisibility(false);
        },
        () => {},
        () => {
          setDeleteLoading(false);
        }
      );
    }
  };

  return (
    <div className="controls">
      <DeleteLiveClassAlert
        cancelHandler={() => {
          setDeleteAlertVisibility(false);
        }}
        visible={showDeleteAlert}
        successHandler={leaveChannel}
        isButtonLoading={deleteLoading}
      />
      <div className="controls__container">
        <div className="controls__item">
          <img
            src={trackState.audio ? MuteIcon : UnmuteIcon}
            onClick={() => mute("audio")}
          />
          <p>{trackState.audio ? "Mute" : "Unmute"}</p>
        </div>
        <div className="controls__item">
          <img
            src={trackState.video ? StopVideoIcon : StartVideoIcon}
            onClick={() => mute("video")}
          />
          <p>{trackState.video ? "Stop Video" : "Start Video"}</p>
        </div>

        <div className="controls__item">
          <img src={ParticipantsIcon} onClick={participantsHandler} />
          <p>Participants ({users?.length + 1})</p>
        </div>
        <div className="controls__item">
          <img src={InfoIcon} onClick={infoHandler} />
          <p>Info</p>
        </div>
      </div>
      <div className="end-btn__container">
        <UIButton
          danger
          clickHandler={() => {
            setDeleteAlertVisibility(true);
          }}
        >
          Leave Session
        </UIButton>
      </div>
      {/* <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
        {trackState.audio ? "MuteAudio" : "UnmuteAudio"}
      </p>
      <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
        {trackState.video ? "MuteVideo" : "UnmuteVideo"}
      </p>
      <p onClick={() => handleScreenShare()}>
        {trackState?.screen ? "Stop sharing" : "Share the screen"}
      </p>
      {<p onClick={() => leaveChannel()}>Leave</p>} */}
    </div>
  );
};

export default Controls;
