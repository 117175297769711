import React, { useEffect, useState, FC } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom'

import CartService from '../../services/CartService/cart.service';


//Lottie
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/payment-successful.json';
import loaderAnimationData from '../../assets/lotties/loader.json';
import { Alert, Button } from 'antd';
import Notification from '../../shared/components/Notification';
import { NotificationTypes } from "../../enums/notificationTypes";
import { LearnService } from '../../services/LearnService/learn.service';
import AuthContainer from '../../store/container/AuthContainer';
import AuthService from '../../services/AuthService/auth.service';
import { Course } from "../../models/student.model";
import { LocalStorage } from '../../shared/utils/LocalStorageHelpers';
import moment from 'moment';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface PGResponseProps {
    setProductDetails: (productDetails: any) => void;
    setCurrentCourse: (course: any) => void;
    setType: (type: any) => void;
    setIsTrailIsExpired: (istrail: boolean, isExpired: boolean) => void;
}


const PGResponse: FC<PGResponseProps> = ({
    setProductDetails,
    setIsTrailIsExpired,
    setCurrentCourse,
    setType,
}) => {

    const history = useHistory();
    const location = useLocation();
    let query = useQuery();


    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const current_location = location.pathname.split("/").pop();
        if (current_location === 'payment-response') {
            history.replace('/learn')
        } else {
            handleCheckPaymentStatus(current_location)
        }

    }, [])



    const getProductDetails = async (onSuccess: () => void) => {
        await AuthService.getStudentProductDetails(async (response: any) => {
            await fetchCourses(response, onSuccess)
            setProductDetails(response)
        })

    }
    const fetchCourses = async (currentProduct: any, onSuccess: () => void) => {
        await LearnService.getStudentCourses(
            (courses: Course[]) => {
                courses?.forEach((course) => {
                    if (course?.isActive) {
                        let istrail = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.istrial
                        let enddate = currentProduct?.course?.filter(data => data.id === course?.course?.classRoom?.id)[0]?.enddate;
                        let isExpired = moment().isAfter(enddate, 'day');

                        setIsTrailIsExpired(istrail, isExpired)
                        setCurrentCourse(course);
                        setType(course?.course?.type);

                        LocalStorage.setItem("CURRENT_COURSE", course);
                        LocalStorage.setItem("COURSE_ID", course?.id);
                        return;
                    }
                });
                onSuccess()
            },
            () => { }
        );
    };


    const handleCheckPaymentStatus = async (PGId) => {
        setLoading(true)
        CartService.checkPaymentStatus(
            PGId,
            async (data: any) => {
                if (data.transactionStatus === "Success") {
                    await getProductDetails(() => {
                        if (query.get("type") === 'flutter') {
                            if (typeof (window as any).flutter_inappwebview !== 'undefined') {
                                (window as any).flutter_inappwebview.callHandler('transactionStatus', { 'status': data.transactionStatus });
                            }
                        } else {
                            setLoading(false)
                            setTimeout(() => {
                                history.replace('/learn')
                            }, 3000);
                        }
                    })
                } else {
                    if (query.get("type") === 'flutter') {
                        if (typeof (window as any).flutter_inappwebview !== 'undefined') {
                            (window as any).flutter_inappwebview.callHandler('transactionStatus', { 'status': data.transactionStatus });
                        }
                    } else {
                        Notification({
                            message: "Payment Failed",
                            description: "Unfortunately,we couldn't collect payment on your purchase.You can try again.",
                            type: NotificationTypes.ERROR,
                        });
                        history.replace('/cart')
                    }
                }
            },
            () => { },
            () => { }
        )
    };




    if (loading) {
        return (<div className='page-container background-home'>

            <div style={{ height: '100%', width: '40vh', marginLeft: 'auto', marginRight: 'auto' }}>
                <Lottie options={loaderOptions}
                    height={'100%'}
                    width={'100%'} />
            </div>

        </div>)

    }

    return (
        <div className='page-container background-home'>

            <div style={{ height: '100%', width: '100%', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Lottie options={defaultOptions}
                    height={'100%'}
                    width={'100%'} />
            </div>



            <div style={{ height: '100%', width: '40vh', marginLeft: 'auto', marginRight: 'auto' }}>
                <Lottie options={loaderOptions}
                    height={'100%'}
                    width={'100%'} />
            </div>


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button color="secondary">Redirect </Button>
            </div>



        </div>
    );
}

export default AuthContainer(PGResponse);