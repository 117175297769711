import { Col, Row } from "antd";
import React, { FC, useState } from "react";
import AuthContainer from "../../../../store/container/AuthContainer";
import SchoolImage from "../../../../assets/images/school.svg";
import Logo from "../../../../assets/images/app-icon-white.svg";
import "./addCourse.scss";
import { CloseOutlined } from "@ant-design/icons";
import ListBoards from "./ListBoards";
import ListCourseExams from "./ListCourseExams";
import { useHistory, useLocation } from "react-router";
import ListCourseClasses from "./ListCourseClasses";

const AddCourse: FC = () => {
  const [step, setStep] = useState(0);
  const { state }: { state: any } = useLocation();
  const [selectedBoardClasses, setSelectedBoardClasses] = useState<any>();
  const history = useHistory();
  return (
    <>
      <div className="course__container">
        <Row>
          <Col span={13}>
            <div className="image__wrapper">
              <img src={Logo} className="panditji__logo" />
              <img src={SchoolImage} className="school__image" />
            </div>
          </Col>
          <Col span={7} className="form__wrapper">
            {state?.type == "exam" ? (
              <ListCourseExams />
            ) : !step ? (
              <ListBoards
                onSubmit={() => setStep(1)}
                onSelectBoard={(classes) => setSelectedBoardClasses(classes)}
              />
            ) : (
              <ListCourseClasses
                data={selectedBoardClasses}
                onSubmit={() => setStep(2)}
              />
            )}
          </Col>
          <Col span={3} style={{textAlign: "end"}}>
            <CloseOutlined
              className="course__close"
              onClick={() => history.goBack()}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AddCourse;
