import React, { FC, Fragment } from "react";
import { Col, Row } from "antd";

import SubjectIcon from "../../../../assets/images/Class_4.jpg";

import { Products } from "../../../../models/ProductModel/products.model";

import "./productCard.scss";



interface ProductCardProps {
  data: Products;
  isLive?: boolean;
  isJoinLoading?: boolean;
  onBuy?: () => void;
  onDetails?: () => void;
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const { data, isLive, onBuy, onDetails } = props;


  return (
    <Row className={`product-card ${isLive}`} >
      <Col span={24}>
        <div className="product-card-date__container">

          <div className="class-img">
            <img
             src={data?.thumbnailPath ||SubjectIcon} 
             onError={event => {
              event.currentTarget.src = SubjectIcon
              event.currentTarget.onerror = null
            }}
             />
          </div>

          <div className="white-wrap">
            <div className="cart-heading">
              <h2 className="heading01">{data.productName}</h2>
            </div>
            {/* <div className="class-text">
              <p>{data.description}</p>
            </div> */}
            <div className="more_details" onClick={onDetails}>
              See Details
            </div>
            <div className="select-btn">
              <a onClick={onBuy} className="main-btn-3">Buy Now</a>
            </div>
          </div>


        </div>
      </Col>
    </Row>
  );
};

export default ProductCard;
