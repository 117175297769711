import { Col, Modal, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import PlaceholderIcon from "../../../assets/images/placeholder.svg";
import AddIcon from "../../../assets/icon/add.svg";
import { Student } from "../../../models/student.model";
import AuthService from "../../../services/AuthService/auth.service";
import { StudentService } from "../../../services/StudentService/student.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { LocalStorage } from "../../utils/LocalStorageHelpers";

import "./switchProfile.scss";
import { useHistory } from "react-router";
import { ADD_PROFILE } from "../../../routes/routeConstants/appRoutes";
interface ModalProps {
  visible: boolean;
  handleOK: () => void;
  handleCancel: (event: any) => void;
  setVisible: (value: boolean) => void;
  setCurrentProfile: Function;
  user: Student
}
const SwitchProfile: FC<ModalProps> = ({
  visible,
  handleOK,
  setVisible,
  handleCancel,
  setCurrentProfile,
  user,

}) => {
  const history = useHistory() 
  const [students, setStudents] = useState<Student[]>([]);
  const fetchStudents = () => {
    // AuthService.listStudents(
    //   (response) => {
    //     //console.log(response);
    //     setStudents(response);
    //   },
    //   () => {}
    // );
  };
  useEffect(() => {
    fetchStudents();
  }, []);

  const updateStudent = (studentId: any) => {
    // if(currentProfile?.id !== studentId) {
    //   StudentService.updateStudent(
    //     studentId,
    //     {
    //       student: {
    //         is_current: true,
    //       },
    //     },
    //     (response) => {
    //       setCurrentProfile(response);
    //       LocalStorage.setItem("CURRENT_PROFILE", response)
    //       setVisible(false)
    //     },
    //     () => {}
    //   );
    // } else {
    //   setVisible(false)
    // }
    
  };

  return (
    <Modal
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      className="switch__profile"
  
    >
      {/* <div className="switch-modal__avatar">
        <div className="switch-modal-add__courses">
          <span>
            <img src={UsersIcon} />
          </span>
          Switch Profile
        </div>
      </div> */}
      <div className="switch-profile__content">
        <p>Who is learning? Select your profile</p>
      </div>
      <Row className="profiles__wrapper">
        {students.map((value, index) => {
          return (
            <Col span={12}>
              <div
                className="switch-profile-avatar__container"
                onClick={() => updateStudent(value?.id)}
              >
                <img
                  className="switch-profile-avatar__image"
                  src={value?.displayPictureUrl ?? PlaceholderIcon}
                />
                <p>{value?.firstName}</p>
              </div>
            </Col>
          );
        })}

        {/* <Col span={12}>
          <div className="add-new__profile" onClick={() => history.push(ADD_PROFILE)}>
            <img src={AddIcon}/>
          </div>
        </Col> */}
      </Row>
    </Modal>
  );
};
export default AuthContainer(SwitchProfile);
