import React, { FC } from "react";
import UIModal from "../../../../../shared/components/UIModal";
import "./deleteLiveClassAlert.scss";
import AlertIcon from "../../../../../assets/icon/alert.svg";
import UIButton from "../../../../../shared/components/UIButton";
interface DeleteLiveClassAlertProps {
  visible: boolean;
  successHandler: () => void;
  cancelHandler: () => void;
  isButtonLoading: boolean;
}

const DeleteLiveClassAlert: FC<DeleteLiveClassAlertProps> = (props) => {
  const { visible, successHandler, cancelHandler, isButtonLoading } = props;
  {console.log(isButtonLoading)}
  return (
    <UIModal
      visible={visible}
      closeHandler={cancelHandler}
      className="delete-live-class-alert__modal"
    >
      <div className="delete-live-class-alert">
        <img className="alert-icon" src={AlertIcon} />
        <p className="title">Are you sure to end the live class</p>
        <p className="subtitle">You will be removed from the class</p>
        <div className="controllers">
          <UIButton
            clickHandler={successHandler}
            outline
            loading={isButtonLoading}
            disabled={isButtonLoading}
          >
            Confirm
          </UIButton>
          <UIButton clickHandler={cancelHandler} info>
            Cancel
          </UIButton>
        </div>
      </div>
    </UIModal>
  );
};

export default DeleteLiveClassAlert;
