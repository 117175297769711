import React from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import * as routes from '../../../routes/routeConstants/appRoutes';

import Learn from '../../../assets/icon/learn.svg';
import LearnSelected from '../../../assets/icon/learn-selected.svg';

import flag from '../../../assets/icon/practice.svg';
import flagSelected from '../../../assets/icon/practice-selected.svg';

import live from '../../../assets/icon/liveclass.svg';
import liveSelected from '../../../assets/icon/liveclass-selected.svg';

import './BottomBar.scss';

import announcement from '../../../assets/icon/announcement.svg';
import announcementSelected from '../../../assets/icon/announcement-selected.svg';

const bottomBarData = [
  {
    text: 'Learn',
    icon: Learn,
    selectedIcon: LearnSelected,
    path: routes.LEARN,
    key: 'learn',
  },
  {
    text: 'Practice',
    icon: flag,
    selectedIcon: flagSelected,
    path: routes.PRACTICE,
    key: 'practice',
  },
  {
    text: 'Live Class',
    selectedIcon: liveSelected,
    icon: live,
    path: routes.LIVE_CLASS,
    key: 'live-class',
  },
];

const BottomBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Menu
      theme='dark'
      mode='horizontal'
      defaultSelectedKeys={['0']}
      selectedKeys={[location?.pathname?.split('/')[1]]}
      style={{ width: '100%' }}
      className='bottom-bar'
      id='bottom-bar'
    >
      {bottomBarData?.map(({ icon, text, path, selectedIcon, key }, index) => {
        return (
          <Menu.Item
            key={key}
            style={{ width: '20%' }}
            onClick={() => {
              history.push(path);
            }}
          >
            <div className='bottombar__item'>
              <img
                src={location?.pathname?.includes(key) ? selectedIcon : icon}
                className='sidebar__icon'
                alt=''
              />
              {text}
            </div>
          </Menu.Item>
        );
      })}
      <Menu.Item
        key={'announcement'}
        style={{ width: '25%' }}
        onClick={() => {
          history.push(routes.ANNOUNCEMENT);
        }}
      >
        <div className='bottombar__item'>
          <img
            src={
              location?.pathname?.includes('announcement')
                ? announcementSelected
                : announcement
            }
            className='sidebar__icon'
            alt=''
          />
          {'Notifications'}
        </div>
      </Menu.Item>
    </Menu>
  );
};

export default BottomBar;
