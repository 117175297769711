import React, { FC } from "react";
import { useHistory } from "react-router";
import PopupModal from "../PopupModal";


import diamonds from "../../../assets/icon/diamonds.svg";
import queryyellow from "../../../assets/icon/queryyellow.svg";
import liveyellow from "../../../assets/icon/liveyellow.svg";
import learnyellow from "../../../assets/icon/learnyellow.svg";
import flagyellow from "../../../assets/icon/flagyellow.svg";

import AuthContainer from "../../../store/container/AuthContainer";
import { Course } from "../../../models/student.model";


import "./subscription.scss";
import { PRODUCTS } from "../../../routes/routeConstants/appRoutes";
import { WHITE_LABLED_APPS } from '../../../store/definitions/whiteLabledApps';

interface SubscriptionProps {
  visiblity: boolean;
  closeHandler: () => void;
  currentCourse: Course;
  app_name: String;
}


const SubscriptionPopup: FC<SubscriptionProps> = ({ currentCourse, app_name, closeHandler, ...rest }) => {
  const history = useHistory()
  const instructions = [
    { instruction: "Unlock Exclusive Content.", icon: learnyellow },
    { instruction: "Practice with Quiz & Exercise.", icon: flagyellow },
    { instruction: "Exclusive Live Classes", icon: liveyellow },
    { instruction: "Get Clarified your Doubts", icon: queryyellow }
  ];

  const handleOk = () => {
    closeHandler()
    if (WHITE_LABLED_APPS.includes(app_name))
      var w = window.open('http://shop.mindjjo.com', "_blank")
    else
      history.push(
        PRODUCTS,
        {
          ClassRoomId: currentCourse?.course?.classRoom?.id,
          BoardId: currentCourse?.course?.classRoom?.board?.id
        }
      );
  }
  return (
    <div>
      <PopupModal
        className="subscription__wrapper"
        withCancel={true}
        okText={'Buy Subscription'}
        closeText={'Continue with Free Trial'}
        buttonDirection={'row'}
        okHandler={handleOk}
        closeHandler={closeHandler}
        {...rest}
      >
        <img src={diamonds} alt="" />
        <div className="instructions">
          <h2>Purchase courses and master the studies</h2>
          <div>
            {instructions?.map((data) => (
              <div className="points">
                <img src={data.icon} className="icon" /> {data.instruction}
              </div>
            ))}
          </div>
        </div>
      </PopupModal>
    </div>
  );
};

export default AuthContainer(SubscriptionPopup);
