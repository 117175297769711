import { Col, Row } from "antd";
import React, { FC } from "react";
import CKEditorComponent from "../../CKEditorComponent";
import "./option.scss";
const BASE_URL = process.env.REACT_APP_BASE_URL;
interface OptionProps {
  selected?: boolean;
  text: string;
  label: string;
  disabled: boolean;
  correct?: boolean;
  wrong?: boolean;
  onSelectHandler: (selected: string, text: string) => void;
}

const Option: FC<OptionProps> = ({
  selected = false,
  text,
  label,
  disabled,
  correct = false,
  wrong = false,
  onSelectHandler,
}) => {
  return (
    <Row
      align="middle"
      className={`option__container ${selected && "option-selected"} ${disabled && "option-disabled"
        } ${correct && "option-correct"} ${wrong && "option-wrong"}`}
      onClick={() => onSelectHandler(label, text)}
    >
      <Col xs={6} xl={2} className="label">
        <span>
          {String.fromCharCode(parseInt(label.charAt(label.length - 1)) + 96)}
        </span>
      </Col>
      <Col xs={18} xl={22} className="option">
        {/* {label.includes("image") ? <img src={BASE_URL + text} className="option__image"/> : <p>{text}</p>} */}
        {label.includes("image") ? (
          <img src={ text} className="option__image" />
          // <img src={BASE_URL + text} className="option__image" />
        ) : (
          <CKEditorComponent value={text} />
        )}
      </Col>
    </Row>
  );
};

export default Option;
