import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import RestrictAccess from "../RestrictedAccess";
import AuthContainer from '../../../store/container/AuthContainer';
import Login from '../../../views/Main';

const requireAuth = (Component: any, allowedRoles: any = []) => {   
    const Authentication = (props: any) => {
        useEffect(() => {
            const { authenticated, history } = props;
            if (!authenticated && history.location.pathname !== appRoutes.MAIN) {
                return history.push(appRoutes.MAIN);
            }
        }, [props]);

        if(allowedRoles.length) {
            const { user } = props;
            return allowedRoles.includes(user.role) ? <Component {...props} /> : <RestrictAccess />;
        }
        return props?.authenticated ? <Component {...props} /> : <Login {...props}/>
    };
    return withRouter(AuthContainer(Authentication));
};

export default requireAuth;
