import Learn from "../../../assets/icon/learn.svg";
import LearnSelected from "../../../assets/icon/learn-selected.svg";

import flag from "../../../assets/icon/practice.svg";
import flagSelected from "../../../assets/icon/practice-selected.svg";

// import query from "../../../assets/icon/query.svg";
// import querySelected from "../../../assets/icon/query-selected.svg";

import live from "../../../assets/icon/liveclass.svg";
import liveSelected from "../../../assets/icon/liveclass-selected.svg";

import analytics from "../../../assets/icon/analytics.svg";
import analyticsSelected from "../../../assets/icon/analytics-selected.svg";

import leaderboard from "../../../assets/icon/leaderboard.svg";
import leaderboardSelected from "../../../assets/icon/leaderboard-selected.svg";


// import subscription from "../../../assets/icon/course-structure.svg";
// import subscriptionSelected from "../../../assets/icon/course-structure-active.svg";

// import profile from "../../../assets/icon/profile.svg";
// import profileSelected from "../../../assets/icon/profileSelected.svg";
// import account from "../../../assets/icon/account.svg";
// import accountSelected from "../../../assets/icon/accountSelected.svg";

import * as routes from "../../../routes/routeConstants/appRoutes";

export const sidebarData = [
  {
    text: "Learn",
    icon: Learn,
    selectedIcon: LearnSelected,
    path: routes.LEARN,
    key: "learn",
  },
  {
    text: "Practice",
    icon: flag,
    selectedIcon: flagSelected,
    path: routes.PRACTICE,
    key: "practice"
  },
  {
    text: "My Progress",
    selectedIcon: analyticsSelected,
    icon: analytics,
    path: routes.ANALYTICS,
    key: "my-progress"
  },

  // {
  //   text: "Query",
  //   icon: query,
  //   selectedIcon: querySelected,
  //   path: routes.QUERY,
  //   key: "query"
  // },
  {
    text: "Live Class",
    selectedIcon: liveSelected,
    icon: live,
    path: routes.LIVE_CLASS,
    key: "live-class"
  },
  {
    text: "Leaderboard",
    icon: leaderboard,
    selectedIcon: leaderboardSelected,
    path: routes.LEADERBOARD,
    key: "leaderboard"
  },
  // {
  //   text: "Buy Course",
  //   icon: subscription,
  //   selectedIcon: subscriptionSelected,
  //   path: routes.PRODUCTS,
  //   key: "products"
  // }
];
