import { serializable, alias, primitive, object, list } from "serializr";
import { ClassRoom } from "../student.model";


export class Board {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("acronym", primitive()))
  acronym?: string;

  @serializable(alias("class_rooms", list(object(ClassRoom))))
  classRooms?: ClassRoom[];

  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;
}
