import React, { FC, useRef, useState } from "react";
import { CameraOutlined, UserOutlined } from "@ant-design/icons";
import "./profileUpload.scss";
interface ProfileUploadProps {
  image?: any;
  onUpload: (value: any) => void;
}
const ProfileUpload: FC<ProfileUploadProps> = ({ image, onUpload }) => {
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<string>();
  return (
    <div className="profile-upload">
      <input
        ref={attachmentRef}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          let file = e.target.files?.length && e.target.files[0];
          onUpload(file);
          setAttachment(URL.createObjectURL(file));
        }}
      />
      <div
        className="profile-upload__wrapper"
        onClick={() => attachmentRef.current?.click()}
      >
        <div className="profile-upload__camera">
          <CameraOutlined />
        </div>
        {attachment ? (
          <img src={attachment} />
        ) : (
          <>
            <div className="profile-upload__user">
              <UserOutlined />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileUpload;
