import { serializable, alias, object, list, primitive } from "serializr";

export class ExerciseAttemptResults {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  value?: number;

  @serializable(alias("created_at", primitive()))
  label?: string;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("average_time_per_question", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("exercise_attempt_duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("for_result", primitive()))
  forResult?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("correct", primitive()))
  correct?: string;

  @serializable(alias("incorrect", primitive()))
  incorrect?: string;

  @serializable(alias("unanswered", primitive()))
  unanswered?: string;

  @serializable(alias("evaluating", primitive()))
  evaluating?: string;
}

export class ExamExerciseAttemptResults {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("id", primitive()))
  value?: number;

  @serializable(alias("created_at", primitive()))
  label?: string;

  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("accuracy", primitive()))
  accuracy?: number;

  @serializable(alias("average_time_per_question", primitive()))
  averageTimePerQuestion?: number;

  @serializable(alias("duration", primitive()))
  exerciseAttemptDuration?: number;

  @serializable(alias("for_result", primitive()))
  forResult?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("correct", primitive()))
  correct?: string;

  @serializable(alias("incorrect", primitive()))
  incorrect?: string;

  @serializable(alias("unanswered", primitive()))
  unanswered?: string;

  @serializable(alias("evaluating", primitive()))
  evaluating?: string;
}
