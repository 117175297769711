import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation, withRouter } from "react-router";
import { PreviousPaperModel } from "../../../../../models/Exam/PaperModel/paper.model";
import { PreviousPaperService } from "../../../../../services/PreviousPaperService/previouspaper.service";

import Navbar from "../../../../../shared/components/Navbar";
import { setTitle } from "../../../../../store/actions/titleAction";
import AuthContainer from "../../../../../store/container/AuthContainer";
import TitleContainer from "../../../../../store/container/TitleContainer";
import PreviousPaper  from "../PreviousPaper";
import { PracticeExamSwitch } from "./SwitchExam";
interface PracticeByExamsProps extends RouteComponentProps {
  setTitle: Function;
}
interface IExamState {
    examName: string,
    examId: number
}
const PracticeByExams: FC<PracticeByExamsProps> = ({ setTitle }) => {
  const location = useLocation();
  const { examName, examId } = location.state as IExamState;
  const [showPapers, setPapersVisible] = useState(true)
  const [papers, setPapers] = useState<PreviousPaperModel[]>([])
  useEffect(() => {
    setTitle({
      title: examName,
      color: "#FFF",
      showBack: true,
      float: false,
      searchText: "Search practice paper...",
      showSearch: true,
      twoBgs: true,
    });
  }, []);

  const fetchPapers = () => {
      PreviousPaperService.fetchPreviousPaperList(
          (response: PreviousPaperModel[], meta) => {
              //console.log(response)
              setPapers(response)
          },
          (error: any) => {}
      )
  }
  useEffect(() => {
      fetchPapers()
  }, [])
  return <div className="practice-by-exams__container">
      {/* <PracticeExamSwitch onChange={(value) => {
        value == "paper" ? setPapersVisible(true) : setPapersVisible(false)
      }}/>
      {
          showPapers ? */}
          <PreviousPaper papers={papers} exam={{examName, examId}}/>
           {/* : <div></div>
      } */}
  </div>;
};
export default AuthContainer(TitleContainer(withRouter(PracticeByExams)));
