import React, { FC } from "react";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import "./ckEditorComponent.scss";
interface CKEditorComponentProps {
  value: any;
}
const CKEditorComponent: FC<CKEditorComponentProps> = ({ value }) => {
  return (
    <div className="ck-editor-component__container">
      <CKEditor
        disabled={true}
        config={{
          toolbar: {
            items: []
          },
        }}
        editor={ClassicEditor}
        data={value}
      />
    </div>
  );
};
export default CKEditorComponent;
