import React, { useEffect } from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "./styles/_main.scss";
const App = () => {
  useEffect(() => {
    
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    sessionStorage.setItem("APP_NAME", hostName);

    document.addEventListener("contextmenu", (event) => event.preventDefault());
    document.addEventListener("keydown", (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key == "s") {
        event.preventDefault();
      }
    });
  }, []);
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
};

export default App;
