import { alias, primitive, serializable, list, object } from "serializr";

export class MetaModel {
  @serializable(alias("id", primitive()))
  value?: number | string;

  @serializable(alias("name", primitive()))
  label?: string;
}

