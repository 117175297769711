import { act } from "react-dom/test-utils";
import { CreateReducer } from "../../shared/utils/createReducer";
import { SET_STEP } from "../definitions/stepConstants";

interface StepState {
   step: number;
   stepPercentage: number;
}

const initState: StepState = {
    step: 0,
    stepPercentage: 0
};

const StepReducer = CreateReducer(initState, {
    [SET_STEP](state: any, action: any) {
        const { step, stepPercentage } = action.payload
        return {...state, step, stepPercentage}
    }
})
export default StepReducer