import { ExcerciseAttempt } from "../../models/Exercise/exercise.model";
import { Question } from "../../models/Question/question.model";
import { CreateReducer } from "../../shared/utils/createReducer";
import { SET_CURRENT_QUESTION, SET_EXAM_ATTEMPT, SET_MARK_REVIEW, SET_QUESTIONS, SET_VISITED, UPDATE_QUESTION } from "../definitions/questionConstant";

interface QuestionProps {
    attempted: number,
    remaning: number,
    marked: number,
    questions: Question[],
    attempt: any;
    currentQuestion: number;
}

export interface QuestionReducerProps extends QuestionProps {
    setExamAttempt: (attempt: ExcerciseAttempt) => void;
    setQuestions: (questions: Question[]) => void;
    setCurrentQuestion: (currentQuestion: number) => void;
    setVisitedQuestion: (questionIndex: number) => void;
    setMarkedQuestion: (questionIndex: number) => void;
    updateQuestion: (question: Question) => void;
}

const initState: QuestionProps = {
    attempted: 0,
    remaning: 0,
    marked: 0,
    questions: [],
    attempt: {
        id: 1,
        timerOptions: "whole_quiz",
        time: 10
    },
    currentQuestion: 0,
};

const QuestionReducer = CreateReducer(initState, {
    [SET_QUESTIONS](state: QuestionProps, action: any): QuestionProps {
        return {
            ...state,
            remaning: action?.payload?.length,
            questions: action?.payload?.map((question: Question) => ({
                ...question,
                visited: false,
                marked: false,
                time: state.attempt?.time
            })),
            marked: 0
        }
    },
    [UPDATE_QUESTION](state: QuestionProps, action: any): QuestionProps {
        return {
            ...state,
            questions: state?.questions.map((question, index) => {
                if(question.id == action?.id) {
                    return {
                        ...question, action
                    }
                }
                return question
            })
        }
    },
    [SET_EXAM_ATTEMPT](state: QuestionProps, action: any): QuestionProps {
        return {
            ...state,
            attempt: action?.payload,
        }
    },
    [SET_CURRENT_QUESTION](state: QuestionProps, action: any): QuestionProps {
        return {
            ...state,
            currentQuestion: action?.payload
        }
    },
 
    [SET_MARK_REVIEW](state: QuestionProps, action: any): QuestionProps {
        let marked_count = 0
        return {
            ...state,
            questions: state?.questions?.map((question: any, index) => {
                if(question.marked){
                    marked_count += 1
                }
                if (index === action?.payload) {
                    if(question.marked){
                        marked_count -= 1
                    } else {
                        marked_count += 1
                    }
                    return {
                        ...question,
                        marked: !question.marked,
                    }
                }
                return question;
            }),
            marked: marked_count
        }
    },
    [SET_VISITED](state: QuestionProps, action: any): QuestionProps {
        let visited = false;
        state?.questions?.forEach((question: any, index) => {
            //console.log(question)
            if (index === action?.payload) {
                if (question?.visited) {
                    visited = true;
                }
            }
        })
        return {
            ...state,
            attempted: visited ? state?.attempted : state?.attempted + 1,
            remaning: visited ? state?.remaning : state?.remaning - 1,
            questions: state?.questions?.map((question, index) => {
                if (index === action?.payload) {
                    return {
                        ...question,
                        visited: true
                    }
                }
                return question;
            })
        }
    }
});

export default QuestionReducer;



