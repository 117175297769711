import { Checkbox, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import * as React from "react";
import "./usingPanditji.scss";
import UIButton from "../../../../../shared/components/UIButton";
import InputField from "../../../../../shared/components/InputField";
import { ArrowRightOutlined } from "@ant-design/icons";
import RadioButton from "../../../../../shared/components/RadioButton";
import AuthService from "../../../../../services/AuthService/auth.service";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import {
  STUDENT_BOARDS,
  STUDENT_EXAMS,
} from "../../../../../routes/routeConstants/appRoutes";
import { CheckboxField } from "../../../../../shared/components/CheckboxField";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { Student } from "../../../../../models/student.model";
import StepPencil from "../../../../../shared/components/StepPencil";
import StepContainer from "../../../../../store/container/StepContainer";
import Logo from "../../../../../assets/images/app-icon-white.svg";
import UsingMindjjoForImage from "../../../../../assets/images/using-mindjjo-for.svg";
interface UsingPanditjiProps {
  // currentProfile: Student;
  user: Student,
  setStep: (step: number, stepPercentage: number) => void;
}
const UsingPanditji = ({ user, setStep }: UsingPanditjiProps) => {
  const history = useHistory();
  const { state }: { state: any } = useLocation();
  const params = useParams();
  const [selected, setSelected] = React.useState<any>("0");

  const onSubmit = (selectedType) => {
    if (selectedType) {
      if (selectedType === "0") {
        AuthService.updateStep(
          2,
          user?.id,
          () => {
            setStep(2, 30);
            history.push(
              generatePath(STUDENT_BOARDS, {
                usageId: selectedType,
                ...params,
              }),
              { ...state }
            );
          },
          () => { },
          () => { }
        );
      } else if (selectedType == "1") {
        AuthService.updateStep(
          2,
          user?.id,
          () => {
            setStep(2, 80);
            history.push(
              generatePath(STUDENT_EXAMS, {
                usageId: selectedType,
                ...params,
              }),
              { ...state }
            );
          },
          () => { },
          () => { }
        );
      } else {
        AuthService.updateStep(
          2,
          user?.id,
          () => {
            setStep(2, 30);
            history.push(
              generatePath(STUDENT_BOARDS, {
                usageId: selectedType,
                ...params,
              }),
              { ...state }
            );
          },
          () => { },
          () => { }
        );
      }
    }
  }



  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <img src={UsingMindjjoForImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <div className="register-form__container">
            <StepPencil />
            <h1>Using Mindjjo for</h1>
            <Row>
              <Col span={24}>
                <div
                  className={`using-panditji__card using-for__school ${selected == "0" ? "using-card__border" : ""
                    }`}
                  onClick={() => setSelected("0")}
                >
                  <CheckboxField checked={selected == "0"} />

                  <p>Preparing School Subjects</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div
                  className={`disabled__div using-panditji__card using-for__exam ${selected == "1" ? "using-card__border" : ""
                    }`}
                  onClick={() => setSelected("1")}
                >
                  <CheckboxField checked={selected == "1"} />

                  <p>Competitive Exams</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div
                  className={`disabled__div using-panditji__card using-for__both ${selected == "2" ? "using-card__border" : ""
                    }`}
                  onClick={() => setSelected("2")}
                >
                  <CheckboxField checked={selected == "2"} />
                  <p>Both</p>
                </div>
              </Col>
            </Row>
            {/* <Row>
                <Col span={14}>
                    <RadioButton
                        className="usingPaditjiFor__radio"
                        name="usingPanditjiFor"
                        options={
                            [
                                {
                                    label: 'Preparing School Subjects',
                                    value: 'school'
                                },
                                {
                                    label: 'Competitive Exams',
                                    value: 'exams'
                                }
                            ]
                        }
                    />
                </Col>

            </Row> */}

            <Row>
              <Col className="btn__wrapper" span={24}>
                <UIButton
                  type="submit"
                  className="mt-2"
                  icon={<ArrowRightOutlined />}
                  clickHandler={() => onSubmit(selected)}
                >
                  Proceed
                </UIButton>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default StepContainer(AuthContainer(UsingPanditji));
