import React, { FC, useEffect } from "react";
import "./buildingContent.scss";
import UIButton from "../../../../shared/components/UIButton";
import AppIcon from "../../../../assets/images/app-icon-white.svg";
import PencilIcon from "../../../../assets/images/building-content.svg";
import { useHistory } from "react-router";
import { LEARN, LOGIN } from "../../../../routes/routeConstants/appRoutes";
export const BuilidingContent: FC = () => {
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
        history.push(LOGIN)
    }, 6000)
  }, [])
  return (
    <div className="building-content__container">
      <div className="app__icon">
        <img src={AppIcon} />
      </div>
      <div className="pencil__icon">
        <img src={PencilIcon} />
        <div className="red__circle"></div>
      </div>
      <p>
        Thank you for registration. You will shortly recieve the login details in email.
      </p>
      <p className="already-have-acc">
        Click here to{" "}
        <span onClick={() => history.push(LOGIN)}>Login.</span>
      </p>
    </div>
  );
};
