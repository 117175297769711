import React, { FC, useEffect, useState } from "react";
import { Badge, Col, Dropdown, Input, Menu, Progress, Row } from "antd";
import { generatePath, useHistory, useLocation } from "react-router";
import { ExerciseAttemptResults } from "../../../../models/Analytics/exercise.model";
import {
  ExamExercise,
  ExamExercises,
} from "../../../../models/Exam/ExerciseModel/exercise.model";
import {
  ExcerciseAttempt,
  Exercise,
  Exercises,
} from "../../../../models/Exercise/exercise.model";
import { Course } from "../../../../models/student.model";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import { ExamService } from "../../../../services/ExamService/exam.service";
import { ExerciseService } from "../../../../services/ExerciseService/exercise.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import AnalyticsComponent from "../AnalyticsComponent";
import { SearchOutlined } from "@ant-design/icons";
import "./listExercisesAnalytics.scss";
import { AnalyticsEmptyScreen } from "../EmptyScreen";
import UIButton from "../../../../shared/components/UIButton";
import QuestionContainer from "../../../../store/container/QuestionContainer";
import { QuestionReducerProps } from "../../../../store/reducers/questionReducer";
import { Question } from "../../../../models/Question/question.model";
import {
  EXERCISE_EXAM,
  EXERCISE_EXAM_RESULTS,
  LIST_ANALYTICS_EXERCISES,
} from "../../../../routes/routeConstants/appRoutes";
import InstructionPopup from "../../PracticeModule/PracticeChapter/Quizes/Instruction";

interface listExercisesProps extends QuestionReducerProps {
  currentCourse: Course;
  type: any;
}
const ListExercisesAnalytics: FC<listExercisesProps> = ({
  currentCourse,
  type,
  setQuestions,
  setExamAttempt,
}) => {
  const { state, pathname }: { state: any; pathname: any } = useLocation();
  const [selectedExercise, setSelectedExercise] = useState<
    Exercise | ExamExercise
  >();
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [studentExerciseResults, setStudentExerciseResults] = useState<
    ExerciseAttemptResults[]
  >([]);
  const [selectedResult, setSelectedResult] =
    useState<ExerciseAttemptResults>();
  const [infoPopup, setInfoPopup] = useState(false);
  const [attemptId, setAttemptId] = useState<number | undefined>(1);
  const history = useHistory();

  const [noAttempts, setNoAttempts] = useState(false);
  const fetchExercises = (params: any) => {
    type == "exam"
      ? ExamService.getExamExercises(
          params,
          {
            courseId: currentCourse.id,
            subjectId: state?.subjectId,
            chapterId: state?.chapterId,
          },
          (response: Exercise[]) => {
            setExercises(response);
            if (response.length) {
              //console.log(response);
              setSelectedExercise(response[0]);
              fetchStudentExerciseResults(response[0].excerciseAttempt?.id);
            }
          },
          () => {},
          () => {}
        )
      : ExerciseService.getExercises(
          {
            subjectId: state?.subjectId,
            chaptorId: state?.chapterId,
            ...params,
          },
          (response: ExamExercise[]) => {
            setExercises(response);
            if (response.length) {
              setSelectedExercise(response[0]);
              fetchStudentExerciseResults(response[0].excerciseAttempt?.id);
            }
          },
          () => {},
          () => {}
        );
  };

  const fetchStudentExerciseResults = (attemptId: any) => {
    if (attemptId) {
      if (type == "exam") {
        AnalyticsService.fetchExamExerciseAnalytics(
          currentCourse?.id,
          attemptId,
          (response: ExerciseAttemptResults[]) => {
            setNoAttempts(false);
            setStudentExerciseResults(response);
            if (response?.length) {
              setSelectedResult(response[0]);
            }
          },
          () => {}
        );
      } else if (type == "class_room") {
        AnalyticsService.fetchExerciseAnalytics(
          currentCourse?.id,
          attemptId,
          (response: ExerciseAttemptResults[]) => {
            setNoAttempts(false);
            setStudentExerciseResults(response);
            if (response?.length) {
              setSelectedResult(response[0]);
            }
          },
          () => {}
        );
      }
    } else {
      setNoAttempts(true);
    }
  };

  useEffect(() => {
    fetchExercises({});
  }, []);

  const createAttempt = (
    excercise_id: number,
    exerciseAttempt?: ExcerciseAttempt
  ) => {
    if (exerciseAttempt && exerciseAttempt?.completedAt) {
      setExamAttempt(exerciseAttempt);
      handleOkInstruction(
        type == "exam"
          ? ExerciseService.getExamQuestionsWithAnswers
          : ExamService.getExamQuestionsWithAnswers,
        exerciseAttempt?.id,
        true
      );
      return;
    }

    if (type == "exam") {
      const payload = {
        exam_exercise_attempt: {
          exam_exercise_id: excercise_id,
        },
      };

      ExerciseService.createAttempt(
        { chapterId: state?.chapterId as number },
        payload,
        (exerciseAttempt) => {
          //console.log(exerciseAttempt);
          setAttemptId(exerciseAttempt?.id);
          setInfoPopup(true);
          setExamAttempt(exerciseAttempt);
        },
        () => {
          exerciseAttempt && setExamAttempt(exerciseAttempt);
          handleOkInstruction(
            ExerciseService.getExamQuestions,
            exerciseAttempt?.id
          );
        }
      );
    } else {
      const payload = {
        excercise_attempt: {
          excercise_id,
        },
      };

      ExamService.createAttempt(
        { chaptorId: state?.chapterId as number },
        payload,
        (exerciseAttempt) => {
          setAttemptId(exerciseAttempt?.id);
          setInfoPopup(true);
          setExamAttempt(exerciseAttempt);
        },
        () => {
          exerciseAttempt && setExamAttempt(exerciseAttempt);
          handleOkInstruction(
            ExamService.getExamQuestions,
            exerciseAttempt?.id
          );
        }
      );
    }
  };

  const handleOkInstruction = (
    Service: any,
    attemptId?: number,
    viewAns?: boolean
  ) => {
    if (attemptId) {
      Service(attemptId, (questions: Question[]) => {
        setQuestions(questions);
        //console.log(questions);
        if (viewAns) {
          history.push({
            pathname: generatePath(EXERCISE_EXAM_RESULTS, {
              id: state?.chapterId,
            }),
            state: {
              name: state?.chapterName,
              url: LIST_ANALYTICS_EXERCISES,
              ...state,
            },
          });
          return;
        }
        setInfoPopup(false);
        history.push({
          pathname: generatePath(EXERCISE_EXAM, {
            id: state?.chapterId,
          }),
          state: {
            name: state?.chapterName,
            url: LIST_ANALYTICS_EXERCISES,
            ...state,
          },
        });
      });
    }
  };

  return (
    <Row className="exercises__wrapper">
      <InstructionPopup
        okHandler={() => {
          if (type == "exam") {
            handleOkInstruction(ExerciseService.getExamQuestions, attemptId);
          } else {
            handleOkInstruction(ExamService?.getExamQuestions, attemptId);
          }
        }}
        closeHandler={() => setInfoPopup(false)}
        visiblity={infoPopup}
      />
      {exercises.length == 0 ? (
        <AnalyticsEmptyScreen title={"No exercises"} />
      ) : (
        <>
          <Col span={12} style={{ height: "100%" }}>
            <div className="content-left__wrapper">
              <div className="content-left__header">
                <Input
                  placeholder={"Search .."}
                  prefix={<SearchOutlined />}
                  className="exercises-seach__input"
                  onChange={(e) => fetchExercises({ search: e.target.value })}
                />
              </div>
              {exercises?.map((value, index) => {
                return (
                  <div
                    className={`subject__card ${
                      selectedExercise?.id == value.id && "subject-card__active"
                    }`}
                    onClick={() => {
                      setSelectedExercise(value);
                      fetchStudentExerciseResults(value?.excerciseAttempt?.id);
                    }}
                  >
                    <p className="subject-card__name">{value?.title}</p>
                    <div className="subjects-details__container">
                      <p>
                        {value?.exerciseAttemptCount ||
                          value?.examExerciseAttemptCount ||
                          0}{" "}
                        Attempts
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col span={12} style={{ height: "100%" }}>
            {noAttempts ? (
              <div className="mtp-15">
                <AnalyticsEmptyScreen
                  showButton
                  title={"No attempts"}
                  buttonText={"Take Test"}
                  clickHandler={() => {
                    createAttempt(
                      selectedExercise?.id as number,
                      selectedExercise?.excerciseAttempt
                    );
                  }}
                />
              </div>
            ) : (
              <div className="content__right">
                <div className="content-right__header">
                  <p>{selectedExercise?.title}</p>
                </div>
                <AnalyticsComponent
                  attempts={studentExerciseResults}
                  selectedAttempt={selectedResult}
                  showAttempts={true}
                  accuracy={selectedResult?.accuracy}
                  speed={selectedResult?.averageTimePerQuestion}
                  score={selectedResult?.score}
                  duration={selectedResult?.exerciseAttemptDuration}
                  onChangeAttempt={(option: ExerciseAttemptResults) => {
                    setSelectedResult(option);
                  }}
                />
              </div>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};
export default AuthContainer(QuestionContainer(ListExercisesAnalytics));
