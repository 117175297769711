import { Col, Input, Row, Tabs } from "antd";
import Search from "antd/lib/input/Search";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import AuthContainer from "../../../../store/container/AuthContainer";
import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import AnalyticsComponent from "../AnalyticsComponent";
import { AnalyticsEmptyScreen } from "../EmptyScreen";
import "./exercisesCardAnalytics.scss";
import ListExercisesAnalytics from "../ListExercisesAnalytics"
import ListContentExercisesAnalytics from "../ListQuickQuizAnalytics";

const { TabPane } = Tabs;

interface ListExercisesAnalyticsProps extends ITitleState {
  setTitle: Function;
  type: string;
}
const ExercisesCardAnalytics: FC<ListExercisesAnalyticsProps> = ({
  setTitle,
  type,
}) => {
  const { state }: { state: any } = useLocation();
  const [exercises, setExercises] = useState<any[]>([]);
  const [selectedKey, setSelectedkey] = useState(0);
  
  useEffect(() => {
    setTitle({
      title: state?.chapterName,
      color: "#FFF",
      showBack: true,
      float: false,
      showSearch: false,
      twoBgs: true,
    });
  
  }, []);
 
  return (
    <Row className="list__exercises">
  
        <>
          <Col span={24} style={{ height: "100%" }}>
            <div className="content__wrapper">
              <Tabs
                defaultActiveKey="1"
                className="content-left__tabs"
                onChange={() => {}}
              >
               
                <TabPane tab="Exercises" key="1">
                  <ListExercisesAnalytics/>
                </TabPane>
                <TabPane tab="Quick Quiz" key="2">
                <ListContentExercisesAnalytics/>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </>
      
    </Row>
  );
};
export default AuthContainer(TitleContainer(ExercisesCardAnalytics));
