// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1";
export const NET_BASE_URL = process.env.REACT_APP_NET_BASE_URL

// Cable URL
export const CABLE_URL = process.env.REACT_APP_CABLE_URL + "/cable"

// Login URL
export const AUTH = "/user_management/student/auth";
export const VERIFY = AUTH + "/me";
export const USER_LOGIN = AUTH + "/login";
export const SEND_OTP = AUTH + "/otp";
export const USER_REGISTER = AUTH + "/signup";
export const UPDATE_USER = AUTH + "/info";
export const RESET_PASSWORD_EMAIL = AUTH + "/reset_password"
export const UPDATE_PASSWORD = AUTH + "/password"

// student
const STUDENT='/Student'
export const REGISTER_TRIAL = STUDENT + "/RegisterTrial"
export const STUDENT_PRODUCT_DETAILS = STUDENT + "/ProductDetails"
// Learn URL
export const STUDENT_COURSES = "/course/student/student_courses";
export const SUBJECTS = STUDENT_COURSES + "/:courseId/class_room_subjects";
export const CHAPTERS = SUBJECTS + "/:subjectId/chaptors";
export const CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/chaptor_contents";
export const EXERCISES = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercises";
export const VIEW_CHAPTER_CONTENT = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/chaptor_contents/:contentId";
export const VIEW_EXAM_CHAPTER_CONTENT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_contents/:contentId";
export const PENDING_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/pending_chapter_contents"
export const PENDING_EXAM_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/pending_exam_chapter_contents"

// Exam URL
export const EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/exc_atmpt_quest_attchmts"
export const EXAM_EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_question_attachments"

export const CONTENT_EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/content_exercise_attempts/:attemptId/content_attempt_question_attachments"
export const PAPER_EXERCISE_QUESTION_ATTACHMENT = STUDENT_COURSES + "/:courseId/paper_attempts/:attemptId/paper_attempt_question_attachments"
export const EXAM_ATTEMPT = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts"
export const GET_QUESTIONS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions";
export const GET_SINGLE_QUESTION = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/:id";
export const UPDATE_ANSWER = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/:id"
export const COMPLETE_EXERCISE_QUIZ = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts/:attemptId/complete";
export const EXAM_SUBJECTS = STUDENT_COURSES + "/:courseId/exam_subjects"
export const EXAM_CHAPTERS = EXAM_SUBJECTS + "/:subjectId/exam_chapters"
export const EXAM_CHAPTER_CONTENTS = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_contents"
export const EXAM_CHAPTER_EXERCISES = EXAM_CHAPTERS + "/:chapterId/exam_exercises"
export const EXAMS_LIST = "meta/exams"
export const VIEW_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/chaptors/:chaptorId/excercise_attempts/:attemptId"
export const VIEW_EXAM_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId"

export const CREATE_EXAM_EXERCISE_ATTEMPT = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts"
export const GET_EXAM_EXERCISE_QUESTIONS = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_questions"
export const GET_EXAM_EXERCISE_QUESTIONS_WITH_ANSWERS = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_questions/answers"
export const VIEW_EXAM_EXERCISE_QUESTION = GET_EXAM_EXERCISE_QUESTIONS + "/:id"
export const UPDATE_EXAM_EXERCISE_QUESTION = VIEW_EXAM_EXERCISE_QUESTION
export const START_EXAM_EXERCISE_QUESTION = VIEW_EXAM_EXERCISE_QUESTION + "/start"
export const COMPLETE_EXAM_EXERCISE_QUESTION = VIEW_EXAM_EXERCISE_QUESTION + "/complete"
export const LIST_EXAM_EXERCISES = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercises"
export const RESET_EXAM_EXERCISE_EXAM = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId/reset";
export const COMPLETE_EXAM_EXERCISE = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_exercise_attempts/:attemptId/complete"
// Boards URL
export const BOARDS_LIST = BASE_URL + "/meta/boards"
export const GET_ALL_QUESTIONS_WITH_ANSWERS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/excercise_attempt_questions/answers";
export const RESET_EXERCISE_EXAM = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/excercise_attempts/:attemptId/reset";

//Query URL
export const QUERY_LIST = "/ask/student/student_courses/:studentCourseId/queries"
export const CREATE_COMMENT = "/ask/student/queries/:queryId/comments"
export const SEARCH_QUERY = "?all=true&search="
export const UPDATE_QUERY = QUERY_LIST + "/:queryId"
export const CLASS_ROOM_SUBJECTS = "/class_room_subjects"

//Previous Paper URL
export const PAPER_LIST = STUDENT_COURSES + "/:studentCourseId/papers"
export const CREATE_PAPER_ATTEMPT = STUDENT_COURSES + "/:studentCourseId/paper_attempts"
export const VIEW_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId"
export const COMPLETE_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId/complete"
export const RESET_PAPER_ATTEMPT = CREATE_PAPER_ATTEMPT + "/:paperAttemptId/reset"
export const PAPER_ATTEMPT_QUESTIONS = VIEW_PAPER_ATTEMPT + "/paper_attempt_questions"
export const VIEW_UPDATE_PAPER_ATTEMPT_QUESTION = PAPER_ATTEMPT_QUESTIONS + "/:paperAttemptQuestionId"
export const START_PAPER_ATTEMPT_QUESTION = VIEW_UPDATE_PAPER_ATTEMPT_QUESTION + "/start"
export const PAPER_ATTEMPT_QUESTIONS_ANSWERS = VIEW_PAPER_ATTEMPT + "/paper_attempt_questions/answers"

//Content Exercise
export const CREATE_CONTENT_ATTEMPT = STUDENT_COURSES + "/:studentCourseId/chapters/:chapterId/content_libraries/:contentLibraryId/content_exercise_attempts"
export const VIEW_CONTENT_ATTEMPT = CREATE_CONTENT_ATTEMPT + "/:contentAttemptId"
export const CONTENT_ATTEMPT_QUESTIONS = STUDENT_COURSES + "/:studentCourseId/content_exercise_attempts/:contentAttemptId/content_exercise_attempt_questions"
export const CONTENT_ATTEMPT_QUESTIONS_ANSWERS = CONTENT_ATTEMPT_QUESTIONS + "/answers"
export const VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION = CONTENT_ATTEMPT_QUESTIONS + "/:contentAttemptQuestionId"
export const COMPLETE_CONTENT_ATTEMPT = VIEW_CONTENT_ATTEMPT + "/complete"
export const RESET_CONTENT_ATTEMPT = VIEW_CONTENT_ATTEMPT + "/reset"
export const START_CONTENT_ATTEMPT_QUESTION = VIEW_UPDATE_CONTENT_ATTEMPT_QUESTION + "/start"

//Parent Auth
export const PARENT_AUTH = "/user_management/parent/auth"
export const PARENT_LOGIN = PARENT_AUTH + "/login"
export const PARENT_LOGOUT = PARENT_AUTH + "/logout"
export const PARENT_AUTH_ME = PARENT_AUTH + "/me"
export const PARENT_AUTH_OTP = PARENT_AUTH + "/otp"
export const PARENT_AUTH_SIGNUP = PARENT_AUTH + "/signup"
export const PARENT_AUTH_INFO = PARENT_AUTH + "/info"
export const PARENT_STUDENTS = "/user_management/parent/students"
export const VIEW_STUDENT = PARENT_STUDENTS + "/:studentId"

export const ME = "/user_management/student/auth/me"


//Analytics URL
export const STUDENT_REPORT = "/StudentReport";
export const ANALYTICS_CLASS = STUDENT_COURSES + "/:courseId/student_class_results/view"
export const ANALYTICS_CLASS_PROGRESS = STUDENT_REPORT + "/GetProgressByCourse"
export const ANALYTICS_SUBJECTS = STUDENT_COURSES + "/:courseId/class_room_subjects/:subjectId/student_subject_results/view"
export const ANALYTICS_CHAPTERS = STUDENT_COURSES + "/:courseId/chaptors/:chapterId/student_chapter_results/view"
export const ANALYTICS_EXERCISES = ANALYTICS_CHAPTERS + "/:courseId/excercise_attempts/:attemptId/exercise_attempt_results/:attemptResultId"
export const ANALYTICS_EXERCISES_ATTEMPTS = STUDENT_COURSES + "/:courseId/excercise_attempts/:attemptId/exercise_attempt_results"
export const ANALYTICS_QUICK_QUIZ = STUDENT_COURSES + "/:courseId/content_exercise_attempts/:attemptId/content_exercise_attempt_results"

export const ANALYTICS_EXAM = STUDENT_COURSES + "/:courseId/exam_results/view"
export const ANALYTICS_SUBJECTS_EXAM = STUDENT_COURSES + "/:courseId/exam_subjects/:subjectId/exam_subject_results/view"
export const ANALYTICS_CHAPTERS_EXAM = STUDENT_COURSES + "/:courseId/exam_chapters/:chapterId/exam_chapter_results/view"
export const ANALYTICS_EXERCISES_ATTEMPTS_EXAM = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_results"
export const ANALYTICS_QUICK_QUIZ_EXAM = STUDENT_COURSES + "/:courseId/exam_exercise_attempts/:attemptId/exam_exercise_attempt_results"
export const ANALYTICS_PAPER_ATTEMPTS = STUDENT_COURSES + "/:courseId/paper_attempts/:attemptId/paper_attempt_results"

export const ANALYTICS_PAPER = STUDENT_COURSES + "/:courseId/exam_paper_results/view"

/*Notifications */
export const LIST_NOTIFICATIONS = "/notice/student/notifications"
export const SINGLE_NOTIFICATION = LIST_NOTIFICATIONS + "/:notificationId"
export const CLEAR_NOTIFICATIONS = LIST_NOTIFICATIONS + "/destroy_all"
export const VIEW_NOTIFICATION = SINGLE_NOTIFICATION + "/viewed"

// Leaderboard
export const LEADERBOARD = "/StudentReport/GetLeaderBoardByCourse"
//export const LEADERBOARD = "/leaderboard/student/student_course/:courseId/course_leaderboard"

//Live Class
const LIVE_CLASS_MANAGEMENT = "/live_class_management/student"
export const UPCOMING_LIVE_CLASSES = LIVE_CLASS_MANAGEMENT + "/upcoming_live_classes"
export const LIVE_CLASSES_NOW = LIVE_CLASS_MANAGEMENT + "/live_now"
export const LIVE_CLASS_DETAILS = LIVE_CLASS_MANAGEMENT + "/live_classes/:liveClassId"
export const LIVE_CLASS_JOIN = LIVE_CLASS_DETAILS + "/join_live"
export const LIVE_CLASS_END = LIVE_CLASS_DETAILS + "/end_live"
export const LIVE_CLASS_JOIN_NEW = '/Tutor/JoinLiveClass'

// Worksheet
const WORKSHEET = "/worksheet"
export const WORKSHEET_LIST = WORKSHEET+"/GetStudentWorkSheetList"
export const WORKSHEET_INFO=WORKSHEET+"/GetStudentWorksheetInfo"
export const SUBMIT_WORKSHEET=WORKSHEET+"/SubmitWorkSheet"

//Announcement
export const ANNOUNCEMENT="/Announcement"
export const CREATE_ANNOUNCEMENT=ANNOUNCEMENT+"/CreateAnnouncement"
export const ANNOUNCEMENT_LIST = ANNOUNCEMENT+"/GetStudentAnnouncementList"
export const ANNOUNCEMENT_INFO = ANNOUNCEMENT+"/GetAnnouncement"
export const ADD_COMMENT = ANNOUNCEMENT+"/AddComment"

// Products
const PRODUCT="/Product"
export const PRODUCT_LIST=PRODUCT+"/GetProductList"

//Meta
export const BOARD_MASTER = "/Master/GetActiveBoards"
export const CLASS_ROOMS = "/Master/GetGradesByBoard"

//Cart
const STORE='/api/Store/Store'
export const GET_CART = STORE+"/GetCart"
export const ADD_TO_CART = STORE+"/AddToCart"
export const DELETE_FROM_CART = STORE+"/DeleteFromCart"
export const APPLY_COUPON =STORE+"/ApplyCoupon"
export const INITIATE_TRANSACTION=STORE+"/InitiateTransaction"
export const GET_TRANSACTION_INFO=STORE+"/GetTransactionInfo"

// Content History
export const RECENT_CONTENT_HISTORY = `${STUDENT_COURSES}/:courseId/content_histories`
export const UPDATE_RECENT_CONTENT_HISTORY = `${RECENT_CONTENT_HISTORY}/:id`
