import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  Course,
  Student,
  StudentCourse,
  UpdateStudentCourse,
} from "../../models/student.model";
import {
  STUDENT_COURSES,
  VERIFY,
  VIEW_STUDENT,
} from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";

export class StudentService {
  static createStudentCourse(
    data: object,
    onSuccess: Function,
    onError: Function
  ) {
    const studentCourses = Object.assign(new StudentCourse(), data);
    const payload = { student_course: serialize(studentCourses) };
    return axiosInstance
      .post(STUDENT_COURSES, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static updateStudentCourse(
    id: any,
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal?: () => void,
  ) {
    const studentCourse = serialize(UpdateStudentCourse, data);
    const payload = { student_course: studentCourse };
    return axiosInstance
      .put(STUDENT_COURSES + `/${id}`, payload)
      .then((response) => {
        const data = deserialize(Course, response["data"]["student_course"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal)
  }
  static getStudentDetails(
    onSuccess: (data: Student) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(VERIFY)
      .then((response) => {
        const student = deserialize(Student, response.data["student"]);
        onSuccess(student);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static updateStudent(
    studentId: number,
    data: any,
    onSuccess: (data: Student) => void,
    onError: Function
  ) {
    return axiosInstance
      .put(generatePath(VIEW_STUDENT, {studentId}), data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const student = deserialize(Student, response["data"]["student"]);
        onSuccess(student);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Cannot update student!",
          type: NotificationTypes.ERROR,
        });
      });
  }
}
