import { serializable, alias, object, list, primitive } from "serializr";
import { LiveClassDurationTypes } from "../../enums/liveClassDurationTypes";
import { IdNameModel } from "../user.model";


class Tutor {
  @serializable(alias("id", primitive()))
  id!: number;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("display_picture_url", primitive()))
  displayPictureUrl?: string;
}

export class LiveClassBranchClassRoomModel extends IdNameModel {

  @serializable(alias("branch_class_room_id", primitive()))
  branchClassRoomId?: number;

}

export class LiveClassModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("is_join_live", primitive()))
  isJoinLive?: boolean;

  @serializable(alias("start_time", primitive()))
  startTime?: string;

  @serializable(alias("duration", primitive()))
  duration?: LiveClassDurationTypes;

  @serializable(alias("end_time", primitive()))
  endTime?: string;

  @serializable(alias("class_room", object(LiveClassBranchClassRoomModel)))
  classRoom?: LiveClassBranchClassRoomModel;

  @serializable(alias("sections", list(object(IdNameModel))))
  sections?: IdNameModel[];

  @serializable(alias("subject", object(IdNameModel)))
  subject?: IdNameModel;

  @serializable(alias("tutors", list(object(Tutor))))
  tutors?: Tutor[] = [];

  @serializable(alias("created_tutor_id", primitive()))
  createdTutorId?: number;
  
}

export class JoinLiveClassModel { 
  @serializable(alias('liveClassId', primitive()))
	liveClassId?: number;
  @serializable(alias('userType', primitive()))
	userType?: string;
  @serializable(alias('userId', primitive()))
	userId?: number;
  @serializable(alias('requestFrom', primitive()))
	requestFrom?: string;
}

export class JoinLiveClassResponseModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

  @serializable(alias('channel_name', primitive()))
	channelName?: string;

	@serializable(alias('token', primitive()))
	token?: string;

	@serializable(alias('start_time', primitive()))
	startTime?: string;

	@serializable(alias('end_time', primitive()))
	endTime?: string;

	@serializable(alias('duration', primitive()))
	duration?: string;

  @serializable(alias('rtm_token', primitive()))
	rtmToken?: string;

  @serializable(alias('rtm_token_uid', primitive()))
	rtmTokenUid?: string;

}
