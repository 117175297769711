import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../../routes/routeConstants/appRoutes";
import RegisterForm from "./Register";
import Login from "./Login";
import LoginOTP from "./LoginOTP";
import Register from "./Register";
import ParentVerification from "./Register/ParentVerification";
import Profiles from "./Register/Profiles";
import { StudentDetails } from "./Register/UserStory/studentDetails";
import SchoolBoard from "./Register/UserStory/schoolBoard";
import UsingPanditji from "./Register/UserStory/usingPanditji";
import ClassForBoard from "./Register/UserStory/classForBoard";
import PrepareExam from "./Register/UserStory/prepareExam";
import ForgotPassword from "./Login/ForgotPassword";
import UpdatePassword from "./Login/UpdatePassword";

//style
import "./auth.scss";
import ERP from './ERP';

const authRouter = () => {
  let hostName = window.location.host.split('.')[0].toLocaleLowerCase()

  return (
    <div className={`auth__container ${hostName}`}>
      <Switch>
        <Redirect exact strict from={routes.AUTH} to={routes.LOGIN} />
        <Route exact path={routes.LOGIN} component={() => <Login />} />
        <Route exact path={routes.AUTHENTICAT_ERP} component={() => <ERP />} />
        <Route exact path={routes.LOGIN_OTP} component={() => <LoginOTP />} />
        <Route exact path={routes.REGISTER} component={() => <Register />} />
        <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword}/>
        <Route exact path={routes.UPDATE_PASSWORD} component={UpdatePassword}/>
        <Route exact path={routes.RESET_PASSWORD} component={UpdatePassword}/>
        <Route path={routes.PARENT_REGISTER} component={() => <h1 className="not-found__info">Page Not Found!</h1>} exact />
        <Route path={routes.PARENT_VERIFY} component={ParentVerification} exact />
        <Route path={routes.PROFILES} component={Profiles} exact />
        <Route path={routes.ADD_PROFILE} component={StudentDetails} exact />
        <Route path={routes.STUDENT_USAGE} component={UsingPanditji} exact />
        <Route path={routes.STUDENT_BOARDS} component={SchoolBoard} exact />
        <Route path={routes.STUDENT_CLASSES} component={ClassForBoard} exact />
        <Route path={routes.STUDENT_EXAMS} component={PrepareExam} exact />
      </Switch>
    </div>
  );
};

export default authRouter;
