import { Drawer } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LiveClassModel } from "../../../../../models/LiveClass/liveClass.model";
import LiveClassService from "../../../../../services/LiveClassService/liveClass.service";
import "./liveClassInfo.scss";
import InfoIcon from "../../../../../assets/icon/info-fill.svg";
import CloseIcon from "../../../../../assets/icon/close-white.svg";
import { ClockCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
import SubjectIcon from "../../../../../assets/icon/subject.svg";
import UserIcon from "../../../../../assets/icon/username.svg";
import ProfilePlaceholderIcon from "../../../../../assets/images/placeholder.svg";

interface LiveClassInfoProps {
  visible: boolean;
  closeHandler: () => void;
}

const LiveClassInfo: FC<LiveClassInfoProps> = (props) => {
  const { visible, closeHandler } = props;

  const [liveClass, setLiveClass] = useState<LiveClassModel>();
  const params: any = useParams();
  const fetchLiveClass = () => {
    LiveClassService.fetchLiveClassDetails(
      params?.liveClassId,
      (data) => {
        setLiveClass(data);
      },
      () => {},
      () => {}
    );
  };
  useEffect(() => {
    fetchLiveClass();
  }, []);
  return (
    <Drawer
      className="live-class-info"
      placement="right"
      onClose={closeHandler}
      visible={visible}
      mask={false}
    >
      <div className="live-class-info__header">
        <div className="live-class-info-header__left">
          <img src={InfoIcon} />
          <h4>Live Class Info</h4>
        </div>
        <div className="live-class-info-header__right">
          <img src={CloseIcon} onClick={closeHandler} />
        </div>
      </div>
      <div className="live-class-info__content">
        <p className="live-class__title">
          {liveClass?.title}
        </p>
        <p className="live-class__date">
          <ClockCircleTwoTone />
          {moment(liveClass?.date).format("DD MMM [@]")} {moment(liveClass?.startTime).format("hh:mm A [-]")} {moment(liveClass?.endTime).format("hh:mm A")}
        </p>
        <div className="live-class__tutors">
          {liveClass?.tutors?.map((tutor, index) => {
            return (
              <p className="live-class-tutor__details">
                <span>
                  <img
                    src={tutor?.displayPictureUrl ?? ProfilePlaceholderIcon}
                  />
                </span>
                {tutor?.fullName}
              </p>
            );
          })}
        </div>
        <p className="live-class__subject">
          <span>
            <img src={SubjectIcon} />
          </span>
          {liveClass?.subject?.name}
        </p>
        <p className="live-class__sections">
          <span>
            <img src={UserIcon} />
          </span>
          Class: {liveClass?.classRoom?.name}&nbsp;&nbsp;&nbsp;Section:{" "}
          {liveClass?.sections?.map(
            (section, index) =>
              `${section?.name}${
                liveClass?.sections &&
                liveClass?.sections?.length - 1 !== index &&
                liveClass?.sections?.length > 1
                  ? ", "
                  : liveClass?.sections &&
                    liveClass?.sections?.length - 1 == index &&
                    liveClass?.sections?.length > 1
                  ? " & "
                  : ""
              }`
          )}
        </p>
      </div>
    </Drawer>
  );
};

export default LiveClassInfo;
