import { Col, Row } from "antd";
import moment from "moment";
import React, { FC, Fragment } from "react";
import "./liveClassCard.scss";
import { ClockCircleOutlined } from "@ant-design/icons";
import SubjectIcon from "../../../../assets/icon/subject.svg";
import UsernameIcon from "../../../../assets/icon/username.svg";
import UIButton from "../../../../shared/components/UIButton";
import { LiveClassModel } from "../../../../models/LiveClass/liveClass.model";

interface LiveClassCardProps {
  data: LiveClassModel;
  isLive?: boolean;
  isJoinLoading?: boolean;
  joinHandler?: () => void;
}

const LiveClassCard: FC<LiveClassCardProps> = (props) => {
  const { data, isLive, joinHandler, isJoinLoading } = props;

  return (
    <Row className={`live-class-card ${isLive && "live"}`} align="middle">
      <Col span={4}>
        <div className="live-class-card-date__container">
          <p className="live-class-card__date">
            {moment(data?.date).format("ddd DD, MMM")}
          </p>
          <p className="live-class-card__time">
            <ClockCircleOutlined />
            {moment(data?.startTime)?.format("hh:mm A")} -{" "}
            {moment(data?.endTime)?.format("hh:mm A")}
          </p>
        </div>
      </Col>
      <Col span={18}>
        <div className="live-class-card-title__container">
          <p className="live-class-card__title">{data?.title}</p>
          <div className="live-class-card-more__container">
            <div className="live-class-card__tutors">
              {data?.tutors?.map((value, index) => {
                return (
                  <Fragment>
                    {value?.displayPictureUrl ? (
                      <img src={value?.displayPictureUrl} />
                    ) : (
                      ""
                    )}
                    {data?.tutors?.length == 1 || !value?.displayPictureUrl ? (
                      <span>
                        {value?.fullName}
                        {data?.tutors && data?.tutors?.length - 1 !== index
                          ? ", "
                          : ""}
                      </span>
                    ) : (
                      ""
                    )}
                  </Fragment>
                );
              })}
            </div>
            <p className="live-class-card__subject">
              <span>
                <img src={SubjectIcon} />
              </span>
              {data?.subject?.name}
            </p>
            <p className="live-class-card__class">
              <span>
                <img src={UsernameIcon} />
              </span>
              Class: {data?.classRoom?.name}&nbsp;&nbsp;&nbsp;Section:{" "}
              {data?.sections ? data?.sections?.map((section, index) => {
                return `${section?.name}${data?.sections?.length && data?.sections?.length !== 1 && data?.sections?.length !== 2 && data?.sections?.length - 1 !== index && data?.sections?.length - 2 !== index ? ", " : ""}${(data?.sections?.length && (data?.sections?.length !== 1 && data?.sections?.length - 2 == index)) ? " & " : ""}`
              }) : ""}
            </p>
          </div>
        </div>
      </Col>
      <Col span={2}>
        <div className="live-class-card__actions">
          {isLive ? (
            <UIButton
              info
              clickHandler={joinHandler}
              loading={isJoinLoading}
              // disabled={!data?.isJoinLive}
            >
              Join Class
            </UIButton>
          ) : (
            ""
          )}
        </div>
      </Col>
    </Row>
  );
};

export default LiveClassCard;
