import { SET_STEP } from '../definitions/stepConstants'
export const setStep = (step: number, stepPercentage: number) => {
    return {
        type: SET_STEP,
        payload: {
            step: step,
            stepPercentage: stepPercentage
        }

    }
}