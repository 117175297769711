import React from "react";

import { Col, Row } from "antd";

import SendOTPForm from "./SendOTPForm";

// images
import Logo from "../../../assets/images/app-icon-white.svg";
import SignUpImage from "../../../assets/images/signup.svg";

// style
import "./loginotp.scss";

const LoginMobile = (props: any, { history }: any) => {
  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={SignUpImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <SendOTPForm />
        </div>
      </Col>
    </Row>
  );
};

export default LoginMobile;
