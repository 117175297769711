import React, { FC, Fragment } from "react";
import { Col, Row } from "antd";

import { CheckCircleTwoTone } from "@ant-design/icons";
import SubjectIcon from "../../../../assets/images/Class_4.jpg";

import { CartModel } from "../../../../models/CartModel/cart.model";

import "./cartItem.scss";



interface CartItemProps {
  data: CartModel;
  isLive?: boolean;
  isJoinLoading?: boolean;
  onClick?: () => void;
  handleDelete?: () => void;

}

const CartItem: FC<CartItemProps> = (props) => {
  const { data, isLive, onClick, handleDelete } = props;


  return (
    <Row className={`cartitem-card ${isLive && "btn"}`} onClick={onClick} >
      <Col span={21}>
        <div className="cartitem-card-date__container">
          <p className="cartitem-card__date">
            <span>
              <img
                src={data?.thumbnail || SubjectIcon}
                onError={event => {
                  event.currentTarget.src = SubjectIcon
                  event.currentTarget.onerror = null
                }}

              />
            </span>
            <span className="__text">
              <span className="__sub_title">{data?.productName}</span>
              {/* <span> {data?.name}{data.turnInStatus && <CheckCircleTwoTone style={{ fontSize: 15, marginLeft: 5 }} twoToneColor="#52c41a" />}</span> */}
              <span onClick={handleDelete} className="__remove_text">Remove</span>
            </span>
          </p>
        </div>
      </Col>
      {/* <Col span={3}>
        <p className="cartitem-card__actions">
         Remove
        </p>
      </Col> */}
      <Col span={3}>
        <div className="cartitem-card-date__container">
          <p className="cartitem-card__price">
            ₹ {data?.totalAmount}
            {data?.totalAmount !== data?.amount && <span>₹{data?.amount}</span>}
          </p>
        </div>

      </Col>
    </Row>
  );
};

export default CartItem;
