import {serializable, alias, object, list, primitive} from 'serializr';
export class ContentExerciseAttemptResults { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias("id", primitive()))
	value?: number;
  
	@serializable(alias("created_at", primitive()))
	label?: string;
	
	@serializable(alias('score', primitive()))
	score?: number;

	@serializable(alias('accuracy', primitive()))
	accuracy?: number;

	@serializable(alias('average_time_per_question', primitive()))
	averageTimePerQuestion?: number;

	@serializable(alias('duration', primitive()))
	duration?: number;

	@serializable(alias('for_result', primitive()))
	forResult?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('correct', primitive()))
	correct?: string;

	@serializable(alias('incorrect', primitive()))
	incorrect?: string;

	@serializable(alias('unanswered', primitive()))
	unanswered?: string;

	@serializable(alias('evaluating', primitive()))
	evaluating?: string;

}

export class ContentExerciseAttempts { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('started_at', primitive()))
	startedAt?: string;

	@serializable(alias('completed_at', primitive()))
	completedAt?: string;

	@serializable(alias('evaluation', primitive()))
	evaluation?: string;

	@serializable(alias('content_exercise_attempt_results', list(object(ContentExerciseAttemptResults))))
	contentExerciseAttemptResults?: ContentExerciseAttemptResults[] = [];

}
export class StudentSubjectChapterContentsResult { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('is_active', primitive()))
	isActive?: string;

	@serializable(alias('position', primitive()))
	position?: number;

	@serializable(alias('content_exercise_attempts', list(object(ContentExerciseAttempts))))
	contentExerciseAttempts?: ContentExerciseAttempts[] = [];

}