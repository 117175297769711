import React, { FC, useState } from "react";
import { Col, Divider, Dropdown, Menu, Progress, Row, Select } from "antd";
import { Student } from "../../../../models/student.model";
import AuthContainer from "../../../../store/container/AuthContainer";
import PlaceholderIcon from "../../../../assets/images/placeholder.svg";
import PlaceholderSsvmIcon from "../../../../assets/images/placeholder-ssvm.svg";
import PlaceholderDarkIcon from "../../../../assets/images/placeholder-dark.svg";
import PlaceholderSsvmDarkIcon from "../../../../assets/images/placeholder-ssvm-dark.svg";
import SpeedIcon from "../../../../assets/icon/sketch.svg";
import AccuracyIcon from "../../../../assets/icon/accuracy.svg";
import ScoreIcon from "../../../../assets/icon/reward.svg";
import DurationIcon from "../../../../assets/icon/sand-clock.svg";
import ContentLearnIcon from "../../../../assets/icon/content-viewed.svg";
import ExerciseAttemptedIcon from "../../../../assets/icon/excercise-attempted.svg";
import InfoIcon from "../../../../assets/icon/info-grey.svg";
import { DownOutlined } from "@ant-design/icons";
import Chart from "react-google-charts";
import "./analyticsComponent.scss";
import MetricsDescription from "../../../../shared/components/MetricsDescription";
import { StudentSubjectResult } from "../../../../models/Analytics/subject.model";
import { QuestionStats } from "../../../../shared/components/ExamPannel/QuestionsDrawer/QuestionsList";
import { secondsFormatConverter } from "../../../../shared/utils/secondsFormatConverter";
import { ExerciseAttemptResults } from "../../../../models/Analytics/exercise.model";
import DropdownField from "../../../../shared/components/DropdownField";
import moment from "moment";
interface AnalyticsComponentProps {
  // currentProfile: Student;
  user: Student,
  showAttempts?: boolean;
  accuracy: any;
  score: any;
  speed: any;
  duration: any;
  contentLearnt?: number;
  totalContent?: number;
  exerciseAttempted: number;
  totalExercise: number;
  attempts?: ExerciseAttemptResults[];
  selectedAttempt?: ExerciseAttemptResults;
  onChangeAttempt?: (value: any) => void;
  noOfExerciseAttempted: number;
  app_name: string;
}
const AnalyticsComponent: FC<AnalyticsComponentProps> = ({
  user,
  showAttempts = false,
  accuracy,
  speed,
  score,
  duration,
  contentLearnt,
  totalContent,
  exerciseAttempted,
  totalExercise,
  attempts,
  selectedAttempt,
  onChangeAttempt,
  noOfExerciseAttempted,
  app_name
}) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a>1st menu item</a>
      </Menu.Item>
      <Menu.Item>
        <a>2nd menu item</a>
      </Menu.Item>
    </Menu>
  );
  const colors = {
    blue: {
      dark: "#145CE7",
      light: "#D0DEFA",
    },
    black: {
      dark: "#0C1013",
      light: "#ECEDEF",
    },
    grey: {
      dark: "rgba(145, 155, 176, 0.5)",
      light: "#919BB0",
    },
    orange: {
      dark: "#FF9810",
      light: "#FFEACF",
    },
    red: {
      dark: "#DC1313",
      light: "#F8CFCF",
    },
    green: {
      dark: "#108E10",
      light: "#CFE8CF",
    },
  };
  const [showMetrics, setMetricsVisibility] = useState(false);
  const formatAttempts = (): any => {
    let formatted: any = [];
    formatted.push(["Attempt", "Score"]);
    attempts?.map((value, index) => {
      formatted.push([(index + 1).toString(), value.score]);
    });
    return formatted;
  };

  const profilePicSrc = () => {
    const isSsvmKidscasa = app_name === 'ssvmkidscasa';
    const isDark = user?.displayPictureUrl;

    return isSsvmKidscasa ?
      (isDark ? PlaceholderSsvmDarkIcon : PlaceholderSsvmIcon) :
      (isDark ? PlaceholderDarkIcon : PlaceholderIcon);
  };

  return (
    <div className="analytics__component">
      <MetricsDescription
        visible={showMetrics}
        closeHandler={() => setMetricsVisibility(false)}
      />
      {showAttempts && (
        <div className="attempts__dropdown">
          <DropdownField
            options={attempts?.map((value) => ({value: value?.id, label: moment(value?.createdAt).format("LLL")}))}
            onChange={(value: any, option: any) => {
              onChangeAttempt && onChangeAttempt(option);
            }}
            showSearch={false}
            name="attempts"
            value={selectedAttempt?.value}
            placeholder={""}
          />
        </div>
      )}

      <div className="rank__container">
        <div className="rank-picture__container">
          <img src={profilePicSrc()} />
          {/* <div className="rank">04</div>
          <div className="rank__text">Rank</div> */}
        </div>
      </div>
      {!showAttempts && (
        <Row>
          <Col span={24}>
            <div className="progress__container">
              <p className="analytics-progress__title">Learn Analytics</p>
              <div className="progress-content__learnt">
                <div className="progress-content__left">
                  <p className="progress__value">
                    {contentLearnt} <span>/{totalContent}</span>
                  </p>
                  <Progress
                    strokeColor="#ED4C8A"
                    percent={
                      contentLearnt && totalContent
                        ? Math.ceil((contentLearnt / totalContent) * 100)
                        : 0
                    }
                  />
                  <p className="progress__desc">Content learnt</p>
                </div>
                <div className="progress-content__right">
                  <img src={ContentLearnIcon} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <div
        className={`measure-cards__container ${
          showAttempts && "measure-card-no-attempt--padding"
        }`}
      >
        {!showAttempts && (
          <p className="analytics-progress__title">
            Practice Analytics
            <span>
              <img onClick={() => setMetricsVisibility(true)} src={InfoIcon} />
            </span>
          </p>
        )}
        <Row
          justify="space-between"
          align="middle"
          className="measure-cards__wrapper"
        >
          <Col span={10}>
            <div className="measures__card score">
              <img src={ScoreIcon} />
              <div>
                <p className="measure__value">
                  {score !== undefined ? (score)?.toPrecision(3)  : 0}{" "}
                  <span>%</span>
                </p>
                <p className="measure__title">Score</p>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="measures__card accuracy">
              <img src={AccuracyIcon} />
              <div>
                <p className="measure__value">
                  {accuracy !== undefined ? (accuracy)?.toPrecision(3) : 0}
                  <span>%</span>
                </p>
                <p className="measure__title">Accuracy</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          className="measure-cards__wrapper"
        >
          <Col span={10}>
            <div className="measures__card speed">
              <img src={SpeedIcon} />
              <div>
                <p className="measure__value">
                  {speed !== undefined ? speed : 0} sec/ques
                </p>
                <p className="measure__title">Speed</p>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="measures__card duration">
              <img src={DurationIcon} />
              <div>
                <p className="measure__value">
                  {secondsFormatConverter(duration * 60)}
                </p>
                <p className="measure__title">Duration Spent</p>
              </div>
            </div>
          </Col>
          {!showAttempts && (
            <Col span={24}>
              <div className="exercise-progress__container">
                <div className="progress-exercise__attempted">
                  <div className="progress-content__left">
                    <p className="progress__value">
                      {exerciseAttempted ?? 0} <span>/{totalExercise}</span>
                    </p>
                    <Progress
                      strokeColor="#ED4C8A"
                      percent={
                        exerciseAttempted && totalExercise
                          ? Math.ceil((exerciseAttempted / totalExercise) * 100)
                          : 0
                      }
                    />
                    <p className="progress__desc">Exercise attempted</p>
                  </div>
                  <div className="progress-content__right">
                    <img src={ExerciseAttemptedIcon} />
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>

      {showAttempts && (
        <>
          <Divider />
          <Row justify="center" className="mtp-10">
            <Col xl={6} xxl={4}>
              <QuestionStats
                nos={selectedAttempt?.correct}
                text="Correct"
                color={colors.green?.dark}
              />
            </Col>
            <Col xl={6} xxl={4}>
              <QuestionStats
                nos={selectedAttempt?.incorrect}
                text="Incorrect"
                color={colors.red?.dark}
              />
            </Col>
            <Col xl={6} xxl={4}>
              <QuestionStats
                nos={selectedAttempt?.evaluating}
                text="Evaluating"
                color={colors.orange.dark}
              />
            </Col>
            <Col xl={6} xxl={4}>
              <QuestionStats
                nos={selectedAttempt?.unanswered}
                text="Unanswered"
                color={colors.grey.dark}
              />
            </Col>
          </Row>
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Attempt", "Performance per Attempt"],
              ["Correct", selectedAttempt?.correct],
              ["Incorrect", selectedAttempt?.incorrect],
              ["Evaluating", selectedAttempt?.evaluating],
              ["Unanswered", selectedAttempt?.unanswered],
            ]}
            options={{
              title: "",
              is3D: true,
              slices: {
                0: { color: colors.green?.dark },
                1: { color: colors.red?.dark },
                2: { color: colors.orange?.dark },
                3: { color: colors.black?.light },
              },
            }}
            rootProps={{ "data-testid": "2" }}
          />
          <Divider />
          <div className="attempts__dropdown">
            <DropdownField
              name="graph"
              placeholder="Select graph .."
              options={[
                { value: "score_trend_graph", label: "Score trend graph" },
              ]}
              value={"score_trend_graph"}
              showSearch={false}
              onChange={() => {}}
            />
          </div>
          <Chart
            width={"100%"}
            height={"400px"}
            chartType="AreaChart"
            style={{ marginTop: "2%" }}
            loader={<div>Loading Chart</div>}
            data={formatAttempts()}
            options={{
              title: "",
              hAxis: {
                title: "Attempt",
                titleTextStyle: { color: "#333" },
              },
              vAxis: { minValue: 0 },
              legend: { position: "none" },
              chartArea: { width: "75%", height: "70%" },
              colors: [colors.green.dark],
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </>
      )}
    </div>
  );
};
export default AuthContainer(AnalyticsComponent);
