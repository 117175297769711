import React, { FC, useState } from "react";
import {
  PaperAttemptModel,
  PreviousPaperModel,
} from "../../../../../models/Exam/PaperModel/paper.model";
import { ClockCircleFilled } from "@ant-design/icons";
import "./previousPaper.scss";
import InstructionPopup from "../../PracticeChapter/Quizes/Instruction";
import AuthContainer from "../../../../../store/container/AuthContainer";
import QuestionContainer from "../../../../../store/container/QuestionContainer";
import { PreviousPaperService } from "../../../../../services/PreviousPaperService/previouspaper.service";
import { QuestionReducerProps } from "../../../../../store/reducers/questionReducer";
import { Question } from "../../../../../models/Question/question.model";
import { generatePath, useHistory, useParams } from "react-router";
import {
  EXERCISE_EXAM,
  EXERCISE_EXAM_RESULTS,
  PAPER_EXAM,
  PAPER_EXAM_RESULTS,
  PRACTICE_EXAMS,
} from "../../../../../routes/routeConstants/appRoutes";
interface PreviousPaperProps extends QuestionReducerProps {
  papers: PreviousPaperModel[];
  courseId: any;
  exam: {
    examName: string;
    examId: number;
  };
}
const PreviousPaper: FC<PreviousPaperProps> = ({
  papers,
  setQuestions,
  setExamAttempt,
  exam,
}) => {
  const history = useHistory();
  const params = useParams();
  //console.log(params);
  const [infoPopup, setInfoPopup] = useState(false);
  const [attemptId, setAttemptId] = useState<number>();
  const [currentPaper, setCurrentPaper] = useState<PreviousPaperModel>();
  const handleOkInstruction = (
    Service: any,
    attemptId?: number,
    viewAns?: boolean
  ) => {
    if (attemptId) {
      Service(
        attemptId,
        (questions: Question[]) => {
          setQuestions(questions);
          //console.log(questions);
          //console.log(viewAns);
          if (viewAns) {
            history.push({
              pathname: generatePath(PAPER_EXAM_RESULTS, {
                id: currentPaper?.id,
              }),
              state: {
                ...params,
                examName: exam?.examName,
                url: PRACTICE_EXAMS,
              },
            });
            return;
          }
          setInfoPopup(false);
          history.push({
            pathname: generatePath(PAPER_EXAM, {
              id: currentPaper?.id,
            }),
            state: {
              ...params,
              examName: exam?.examName,
              url: PRACTICE_EXAMS,
            },
          });
        },
        () => {}
      );
    }
  };
  const createAttempt = (
    paperId: any,
    paperAttempt: PaperAttemptModel | undefined
  ) => {
    if (paperAttempt && paperAttempt.completedAt) {
      setExamAttempt(paperAttempt);
      handleOkInstruction(
        PreviousPaperService.getPaperQuestionsWithAnswers,
        paperAttempt?.id,
        true
      );
      return;
    }
    PreviousPaperService.createPaperAttempt(
      { paperId },
      (response) => {
        setExamAttempt(response);
        setInfoPopup(true);
        setAttemptId(response.id);
      },
      () => {
        paperAttempt && setExamAttempt(paperAttempt);
        handleOkInstruction(
          PreviousPaperService.getPaperQuestions,
          paperAttempt?.id
        );
      }
    );
  };
  return (
    <div className="previous-paper__wrapper">
      <InstructionPopup
        okHandler={() => {
          handleOkInstruction(
            PreviousPaperService.getPaperQuestions,
            attemptId
          );
        }}
        closeHandler={() => setInfoPopup(false)}
        visiblity={infoPopup}
      />
      {papers?.map((value: PreviousPaperModel, index: number) => {
        return (
          <div
            className="previous-paper__card"
            onClick={(event) => {
              setCurrentPaper(value);
            }}
          >
            <div className="previous-paper-card__index">
              {(index + 1)?.toString()?.padStart(2, "0")}
            </div>
            <div className="previous-paper-card__body">
              <p className="previous-paper__name">{value.title}</p>
              <div>
                <p className="previous-paper__time">
                  <span>
                    <ClockCircleFilled />
                  </span>
                  &nbsp;&nbsp;{value.time} Minutes
                </p>
              </div>
            </div>
            <div
              className={
                value.paperAttempt
                  ? "previous-paper__result"
                  : "previous-paper__start"
              }
              onClick={() => createAttempt(value.id, value.paperAttempt)}
            >
              {value.paperAttempt && value.paperAttempt?.completedAt
                ? "View result"
                : "Start"}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default AuthContainer(QuestionContainer(PreviousPaper));
