import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, createRef, useEffect } from "react";
import UIButton from "../../../../shared/components/UIButton";
import InputField from "../../../../shared/components/InputField";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./parentVerification.scss";
import NumberInput from "../../Login/OTPForm/NumberInput";
import AuthService from "../../../../services/AuthService/auth.service";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import {
  PROFILES,
  LEARN,
  REGISTER,
  STUDENT_USAGE,
} from "../../../../routes/routeConstants/appRoutes";
import { store } from "../../../../store";
import { SET_STEP } from "../../../../store/definitions/stepConstants";
import { Parent } from "../../../../models/user.model";
import Logo from "../../../../assets/images/app-icon-white.svg";
import VerifyOtpImage from "../../../../assets/images/verify-otp.svg";

const initialValues = {
  "0": "",
  "1": "",
  "2": "",
  "3": "",
};

const ParentVerification: FC = () => {
  const history = useHistory();
  const { state }: { state: any } = useLocation();
  const onSubmit = (values: any) => {
    // history.push(
    //   generatePath(STUDENT_USAGE, { studentId: '123456' })
    // );

    const otp = Object.values(values).join("");
    AuthService.verifyUser(
      {
        phoneNumber: state?.phoneNumber,
        otp,
        grantType: "otp",
      },
      (user) => {
        store.dispatch({
          type: SET_STEP,
          payload: {
            step: 2,
            stepPercentage: 10,
          },
        });
        history.push(
          generatePath(STUDENT_USAGE, { studentId: user?.currentStudent?.id })
        );
      },
      () => { }
    );
  };
  const sendOTP = () => {
    AuthService.sendOTP(
      {
        phoneNumber: state?.phoneNumber,
      },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    sendOTP();
  }, []);
  const refs = Array(4)
    .fill("")
    .map(() => createRef());
  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={VerifyOtpImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <div className="pv-form__container">
            <h1>OTP sent to {state?.phoneNumber}</h1>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
            // validationSchema={validationSchema}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <div className="otp-wrapper">
                        {Array(4)
                          .fill("")
                          .map((_, index) => (
                            <NumberInput
                              index={index}
                              refs={refs}
                              setFieldValue={setFieldValue}
                              values={values}
                            />
                          ))}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <p className="resend__otp" onClick={() => sendOTP()}>
                        Resend OTP
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="btn__wrapper" span={24}>
                      <UIButton
                        type="submit"
                        className="mt-5"
                        icon={<ArrowRightOutlined />}
                      >
                        Verify and Proceed
                      </UIButton>
                    </Col>
                  </Row>
                  <p className="dont-have-acc">
                    Wrong number? <span onClick={() => { history.replace(REGISTER) }}>Change here</span>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ParentVerification;
