import React, { FC } from "react";
import Expand from "../../../../assets/icon/SVG/menu.svg";
import Close from "../../../../assets/icon/SVG/closeWrong.svg";
import "./questionsDrawer.scss";
import ExamStats from "./Stats";
import QuestionsList from "./QuestionsList";

interface QuestionDrawerProps {
	srink: boolean;
	handleDrawer: () => void;
}

const QuestionsDrawer: FC<QuestionDrawerProps> = ({ srink, handleDrawer }) => {
	return (
		<div className="questions-drawer__container">
			<div
				className={`questions-drawer-expand__wrapper ${
					srink && "questions-drawer-expand"
				}`}
			>
                {/* <ExamStats shrink={srink}/> */}
				
                <QuestionsList shrink={srink}/>
            </div>
			<div className={`fixed-opener ${srink && "fixed-opener__move"}`}>
				<img
					src={srink ? Close : Expand}
					alt=""
					onClick={handleDrawer}
				/>
				<span>text</span>
			</div>
		</div>
	);
};

export default QuestionsDrawer;
