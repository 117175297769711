import { Spin } from 'antd';

import React, { useEffect, useState } from 'react';
import { LoginCredentials } from '../../../models/user.model';
import { LEARN } from '../../../routes/routeConstants/appRoutes';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import AuthContainer from '../../../store/container/AuthContainer';
import AuthService from '../../../services/AuthService/auth.service';

import './erp.scss';

interface LoginProps extends RouteComponentProps {
  successHandler: (email: string, type: 'update' | 'success') => void;
  setProductDetails: (productDetails: any) => void;
}

const ERP = ({ setProductDetails, history }: LoginProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getProductDetails = (values: any, callbackUrl: any) => {
    AuthService.getStudentProductDetails((response: any) => {
      setProductDetails(response);
      history.push(callbackUrl || LEARN);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const params = new URLSearchParams(window.location.search);
    const values: LoginCredentials = {
      email: params.get('email') || '',
      password: params.get('password') || '',
    };
    AuthService.loginWithCredentials(
      values,
      (student) => {
        getProductDetails(values, params.get('callbackUrl'));
      },
      (error) => {},
      () => {
        setIsLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className='loading__container'>
          <Spin size='large' tip='Authenticating...' />
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default AuthContainer(withRouter(ERP));
