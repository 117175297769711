import { Col, Progress, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import AnalyticsContainer from "../../../../store/container/AnalyticsContainer";
import AuthContainer from "../../../../store/container/AuthContainer";
import "./analyticsCard.scss";
import { ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import ScoreIcon from "../../../../assets/icon/reward.svg";
import AccuracyIcon from "../../../../assets/icon/dart.svg";
import SpeedIcon from "../../../../assets/icon/sketch.svg";
import DurationIcon from "../../../../assets/icon/sand-clock.svg";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import { StudentSubjectResult } from "../../../../models/Analytics/subject.model";
import { StudentClassResult } from "../../../../models/Analytics/class.model";
import { Course } from "../../../../models/student.model";
import { secondsFormatConverter } from "../../../../shared/utils/secondsFormatConverter";
interface AnalyticsCardProps {
  type: string;
  currentCourse: Course;
  showPaper: boolean;
  setAnalyticsData: (result: StudentClassResult) => void;
}
const AnalyticsCard: FC<AnalyticsCardProps> = ({
  type,
  currentCourse,
  showPaper = false,
  setAnalyticsData
}) => {
  const [classAnalytics, setClassAnalytics] = useState<StudentClassResult>();
  const fetchClass = () => {
    if (showPaper) {
      AnalyticsService.fetchPaperAnalytics(
        currentCourse.id,
        (result) => {
          setClassAnalytics(result);
        },
        () => {}
      );
    } else if (type == "exam") {
      AnalyticsService.fetchExamAnalytics(
        currentCourse.id,
        (result) => {
          setClassAnalytics(result);
        },
        () => {}
      );
    } else if (type == "class_room") {
      setAnalyticsData({})
      AnalyticsService.fetchClassAnalytics(
        currentCourse.id,
        (result) => {
          setAnalyticsData(result)
          setClassAnalytics(result);
        },
        () => {}
      );
    }
  };

  useEffect(() => {
       fetchClass();
  }, [type]);

  return (
    <div className="analytics__card">
      <Row className="mlp-3">
        {/* <Col span={3}>
           <div className="analytics__rank">Rank 04</div> 
        </Col> */}
        <Col span={4}>
          <div className="analytics__metric">
            <img src={ScoreIcon} />
            <div>
              <p className="analytics-measure__value">
                {(classAnalytics?.score !== undefined ? (classAnalytics?.score)?.toFixed(1): 0)} <span>%</span>
              </p>
              <p className="analytics__measure">Score</p>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="analytics__metric">
            <img src={AccuracyIcon} />
            <div>
              <p className="analytics-measure__value">
              {(classAnalytics?.accuracy !== undefined ? (classAnalytics?.accuracy)?.toFixed(1) : 0 )} <span>%</span>

              </p>
              <p className="analytics__measure">Accuracy</p>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="analytics__metric">
            <img src={SpeedIcon} />
            <div>
              <p className="analytics-measure__value">
                {(classAnalytics?.averageTimePerQuestion !== undefined) ? (classAnalytics?.averageTimePerQuestion?.toFixed(1)) : 0}{" "}
                <span>sec/ques</span>
              </p>
              <p className="analytics__measure">Speed</p>
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div className="analytics__metric">
            <img src={DurationIcon} />
            <div>
              <p className="analytics-measure__value">
                {secondsFormatConverter(
                 (classAnalytics?.exerciseAttemptDuration !== undefined) ? classAnalytics?.exerciseAttemptDuration * 60 : 0
                )}
              </p>
              <p className="analytics__measure">Duration spent</p>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="progress__metric">
            <div className="progress__container">
              <p className="progress__title">Content learnt</p>
              <div className="progress__wrapper">
                <p className="progress__score">
                  {classAnalytics?.completedContentCount|| 0}
                  <span>/{classAnalytics?.totalContentCount || 0}</span>
                </p>
                <Progress
                  percent={
                    
                    classAnalytics?.contentCompletionPercentage || 0
                  }
                />
              </div>
            </div>
            <div className="progress__container">
              <p className="progress__title">Exercise attempted</p>
              <div className="progress__wrapper">
                <p className="progress__score">
                  {classAnalytics?.numberOfExerciseAttempts || 0}
                  <span>/{classAnalytics?.totalExerciseCount || 0}</span>
                </p>
                <Progress
                  percent={
                    classAnalytics?.exerciseCompletionPercentage || 0
                  }
                />
              </div>
            </div>
          </div>
        </Col>
        {/* <Col span={3}>
          <img src={AccuracyIcon} />
          <p className="analytics-measure__value">
            40<span>/90</span>
          </p>
          <p className="analytics__measure">Exercise attempted</p>
        </Col>
        <Col span={3}>
          <img src={ScoreIcon} />
          <p className="analytics-measure__value">
            40<span>/90</span>
          </p>
          <p className="analytics__measure">Content learnt</p>
        </Col> */}
      </Row>
    </div>
  );
};
export default AuthContainer(AnalyticsContainer(AnalyticsCard));
