import React, { FC } from "react";
import { Leaderboard } from "../../../../models/Leaderboard/leaderboard.model";
import "./rankBoard.scss";
import CrownIcon from "../../../../assets/icon/crown.svg";
import faker from "faker";
import PlaceholderIcon from "../../../../assets/images/placeholder-dark.svg"

interface RankBoardProps {
  data: Leaderboard;
}

const RankBoard: FC<RankBoardProps> = (props) => {
  const { data } = props;
 
  return (
    <div className="rank-board__container">
      {data?.secondRank && (
        <div className="rank-board-2">
          <div className="student-image__container">
            <img className="student__image" src={data?.secondRankImageUrl || PlaceholderIcon} />
            <div className="student__rank">2</div>
          </div>
          <p className="student__name">{data?.secondRank}</p>
          <p className="student__point">{data?.secondRankPoint}</p>
        </div>
      )}
      {data?.firstRank && (
        <div className="rank-board-1">
          <img className="crown__icon" src={CrownIcon} />

          <div className="student-image__container">
            <img className="student__image" src={data?.firstRankImageUrl || PlaceholderIcon} />
            <div className="student__rank">1</div>
          </div>
          <p className="student__name">{data?.firstRank}</p>
          <p className="student__point">{data?.firstRankPoint}</p>
        </div>
      )}
      {data?.thirdRank && (
        <div className="rank-board-3">
          <div className="student-image__container">
            <img className="student__image" src={data?.thirdRankImageUrl || PlaceholderIcon} />
            <div className="student__rank">3</div>
          </div>
          <p className="student__name">{data?.thirdRank}</p>
          <p className="student__point">{data?.thirdRankPoint}</p>
        </div>
      )}
    </div>
  );
};

export default RankBoard;
