import { alias, primitive, serializable } from "serializr";

export class ContentAttemptModel {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('started_at', primitive()))
    startedAt?: string;

    @serializable(alias('completed_at', primitive()))
    completedAt?: string;
}