import React, { useEffect, useMemo, useState, FC } from "react";
import { generatePath, useLocation, Link } from "react-router-dom";


import { Skeleton, Spin } from "antd";
import { Col, Row, Space, Button } from "antd";
import UIButton from "../../../../shared/components/UIButton";

import { CloudDownloadOutlined } from '@ant-design/icons';
import { ClockCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import SubjectIcon from "../../../../assets/icon/subject.svg";
import pdfIcon from "../../../../assets/icon/pdf-chapter-icon.svg";


import { LiveClassModel } from "../../../../models/LiveClass/liveClass.model";
import TitleContainer from "../../../../store/container/TitleContainer";

// style
import "./assignmentDetails.scss";
import UploadContent from "../../../../shared/components/UploadContent";
import WorksheetService from "../../../../services/WorksheetService/Worksheet.service";
import { FileMetaModel, WorksheetModel } from "../../../../models/Worksheet/worksheet.model";
import moment from "moment";




export const SkeletonLoader = (): any => {
  return Array(2)
    .fill(" ")
    .map((value) => {
      return (
        <div className="skeleton-loader">
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        </div>
      );
    });
};


interface AssignmentDetailsProps {
  setTitle: Function;

}


const AssignmentDetails: FC<AssignmentDetailsProps> = ({ setTitle }) => {


  const { state }: { state: WorksheetModel } = useLocation();

  const [worksheetInfo, setWorksheetInfo] = useState<WorksheetModel>({});
  const [loading, setLoading] = useState(false);
  const [UploadedFile, setUploadedFile] = useState<any>(null)
  const [loadingFile, setFileLoading] = useState({ id: 0, status: false });

  const fetchWorksheetInfo = () => {
    setLoading(true);
    let queryParams = { WorksheetId: state.worksheetId || 0, StudentId: state.studentId }
    WorksheetService.fetchWorksheetInfo(
      queryParams,
      (activities) => {
        setWorksheetInfo(activities);
        setLoading(false);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    setTitle({
      title: `Activity`,
      showBack: true,
      subText: "You are doing good, Keep it up!",
      icon: null,
      showSearch: false,
      float: false,
      searchText: "",
      twoBgs: false,
    });
    fetchWorksheetInfo()
  }, []);

  const handleTurnIn = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('WorksheetId', worksheetInfo.worksheetId + "")
    formData.append('StudentId', worksheetInfo.studentId + "")
    formData.append('TurnInStatus', 'true')

    if (UploadedFile)
      UploadedFile.forEach(element => {
        formData.append('AttachmentList', element)
      });

    WorksheetService.submitWorkSheet(
      formData,
      (activities) => {
        fetchWorksheetInfo()
      },
      () => { },
      () => { setLoading(false); }
    );
  }



  const fileDowload = (file: FileMetaModel, type: string) => {
    setFileLoading({ id: file?.attachmentId ?? 0, status: true })
    if (file?.path !== undefined)
      fetch(file?.path)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          setFileLoading({ id: file?.attachmentId ?? 0, status: false })
          let objectURL = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = objectURL;
          document.body.appendChild(link);
          link.setAttribute('download', file?.name ?? "");
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          // Remove link from body
          document.body.removeChild(link);


          if (type === 'attachmentList')
            setWorksheetInfo((prevState) => {
              return {
                ...prevState,
                attachmentList: prevState?.attachmentList?.map(data => {
                  if (data.attachmentId === file?.attachmentId)
                    data.isDownload = true
                  return data
                })
              }
            })


          if (type === 'evaluationAttachmentList')
            setWorksheetInfo((prevState) => {
              return {
                ...prevState,
                evaluationAttachmentList: prevState?.evaluationAttachmentList?.map(data => {
                  if (data.attachmentId === file?.attachmentId)
                    data.isDownload = true
                  return data
                })
              }
            })

          if (type === 'studentAttachmentList')
            setWorksheetInfo((prevState) => {
              return {
                ...prevState,
                studentAttachmentList: prevState?.studentAttachmentList?.map(data => {
                  if (data.attachmentId === file?.attachmentId)
                    data.isDownload = true
                  return data
                })
              }
            })



        }).catch((error) => {
          console.log(error.message)
        });
  }

  const dateTimeFormat = "MM/DD/YYYY";
  const dueDate = moment(worksheetInfo?.dueDate).format(dateTimeFormat)
  const cdate = moment().utc().add(330, 'm').format(dateTimeFormat)

  return (
    <div className="assignment-details assignment-details-info">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>

          <Row className={`details-card`}>
            <Col span={14} className="">
              <div className="card-title__container">
                <p className="assignment-card__title">
                  <span>
                    <img src={SubjectIcon} />
                  </span>
                  <span className="__text">
                    <span className="__sub_title">{worksheetInfo.subjectName}</span>
                    <span>{worksheetInfo.name}{worksheetInfo.turnInStatus && <CheckCircleTwoTone style={{ fontSize: 15, marginLeft: 5 }} twoToneColor="#52c41a" />}</span>
                  </span>
                </p>

                <div className="assignment-card-time__container">
                  <div>
                    <p className="assignment-card__time">
                      <ClockCircleOutlined />
                      {"Posted on"}:{" "}
                      <span className="__sub_title">{moment(worksheetInfo?.createdOn).format('Do MMMM YYYY  h:mm a')}</span>
                    </p>

                    <p className="assignment-card__time">
                      <ClockCircleOutlined />
                      {"Due on"}:{" "}
                      <span className="__sub_title">{moment(worksheetInfo?.dueDate).format('Do MMMM YYYY')}</span>
                    </p>
                  </div>

                  <p className="assignment-card__title">
                    <span className="__text">
                      <span className="__sub_title">Graded</span>
                      {(worksheetInfo?.studentPoint ?? 0) + " / " + worksheetInfo?.points}
                    </span>
                  </p>

                </div>


              </div>

              <div className="card-file__container">
                <p className="assignment-card__instruction">
                  {worksheetInfo?.instructions}
                </p>

                {worksheetInfo?.attachmentList?.length ?
                  <>
                    <br></br>
                    <p className="assignment-card__instruction ">
                      <span className="__sub_title">{"Worksheet "}</span>
                    </p>
                    <Row  >
                      {worksheetInfo.attachmentList?.map(attachment => (
                        <Col span={10} offset={1} className='file__container' key={attachment.attachmentId}>
                          <div className={`activity_file ${attachment.isDownload && "download"} `}key={attachment.attachmentId}>
                            {(loadingFile.id === attachment.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                            <img src={pdfIcon} alt="" className="pdf_icon" />
                            <div onClick={() => fileDowload(attachment, 'attachmentList')} className="file_title">{attachment.name}</div>
                          </div>

                          {/* <div className="file__thumbnail">
                        <img src={pdfIcon} alt="" className="thumbnail__image" />
                      </div> */}
                          {/* <div className="info_text">
                        {attachment.name}
                        <Button
                          shape="circle"
                          size="middle"
                          icon={<CloudDownloadOutlined style={{ fontSize: '25px' }} />} />
                      </div> */}

                        </Col>
                      ))}
                    </Row>
                  </>
                  : null}
              </div>

              {worksheetInfo?.evaluationAttachmentList?.length ?
                <div className="card-file__container">
                  <p className="assignment-card__instruction">
                    <span className="__sub_title"> {"Evaluated Worksheet "}</span>
                  </p>
                  <Row  >
                    {worksheetInfo.evaluationAttachmentList?.map(attachment => (
                      <Col span={10} offset={1} className="file__container" key={attachment.attachmentId}>
                        <div className={`activity_file ${attachment.isDownload && "download"}`} key={attachment.attachmentId}>
                          {(loadingFile.id === attachment.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                          <img src={pdfIcon} alt="" className="pdf_icon" />
                          <div onClick={() => fileDowload(attachment, 'evaluationAttachmentList')} className="file_title">{attachment.name}</div>
                        </div>
                        {/* <div className="file__thumbnail">
                        <img src={pdfIcon} alt="" className="thumbnail__image" />
                      </div>
                        <div className="info_text">
                          {attachment.name}
                          <Button
                            shape="circle"
                            size="middle"
                            icon={<CloudDownloadOutlined style={{ fontSize: '25px' }} />} />
                        </div> */}

                      </Col>
                    ))}
                  </Row>
                </div>
                : null
              }
            </Col>

            <Col span={10}>
              <div className="card-button__container">
                <p className="work__title">
                  {"Your Work"}
                </p>
                <p className="type__title">
                  {"PDF/JPG"}
                </p>
                <div className="button__group">
                  <UploadContent
                    disabled={worksheetInfo?.turnInStatus || moment(cdate).isAfter(dueDate)}
                    setUploadedFile={setUploadedFile}
                    className="action__button" />
                  <UIButton
                    disabled={worksheetInfo?.turnInStatus || moment(cdate).isAfter(dueDate)}
                    type="button"
                    success
                    clickHandler={handleTurnIn}
                    className="action__button">Turn In</UIButton>
                </div>
                <div>
                  {worksheetInfo.studentAttachmentList?.map(attachment => (
                    <div className={`activity-turned_file ${attachment.isDownload && "download"} `} key={attachment.attachmentId}>
                      <img src={pdfIcon} alt="" className="pdf_icon" />
                      <div onClick={() => fileDowload(attachment, 'studentAttachmentList')} className="file_title">{attachment.name}</div>
                      {(loadingFile.id === attachment.attachmentId && loadingFile.status === true) && <Spin size="small" />}
                    </div>
                  ))}

                  {worksheetInfo.privateMessage &&
                    <>
                      <div className="work__title"> {"Teachers Message"}</div>
                      <div> {worksheetInfo.privateMessage}</div>
                    </>}
                </div>
              </div>
            </Col>
          </Row>

        </>
      )}
    </div>


  );
};

export default TitleContainer(AssignmentDetails);
