import { alias, list, object, primitive, serializable } from "serializr";

import { ChapterContent } from "../../Chapters/chapter.model";




export class ExamChapterModel {
    @serializable(alias("id", primitive()))
    id?: number;
  
    @serializable(alias("name", primitive()))
    name?: string;
  
    @serializable(alias("excercises_count", primitive()))
    exercisesCount?: number;
  
    @serializable(alias("exam_exercises_count", primitive()))
    examExercisesCount?: number;
  
    @serializable(alias("completion_percentage", primitive()))
    completionPercentage?: number;
  
    @serializable(alias("exam_chapter_contents", list(object(ChapterContent))))
    chapterContents?: ChapterContent[];
  
    @serializable(alias('cummulative_completion_percentage', primitive()))
    cummulativeCompletionPercentage?: number;
    @serializable(alias('exam_exercise_completion_percentage', primitive()))
    examExerciseCompletionPercentage?: number;
    @serializable(alias('exam_content_completion_percentage', primitive()))
    examContentCompletionPercentage?: number;
    @serializable(alias('exercise_completion_percentage', primitive()))
    exerciseCompletionPercentage?: number;
    @serializable(alias('content_completion_percentage', primitive()))
    contentCompletionPercentage?: number;
  

}
