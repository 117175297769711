import { generatePath } from "react-router";
import { deserialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import _axiosInstance from "../../interceptor/_axiosInstance";
import { ExamChapterResult, StudentChapterResult } from "../../models/Analytics/chapter.model";
import { StudentClassResult, StudentExamResult } from "../../models/Analytics/class.model";
import { ExamSubjectResult, StudentSubjectResult } from "../../models/Analytics/subject.model";
import { ContentExerciseAttemptResults, StudentSubjectChapterContentsResult } from "../../models/Analytics/content.model";
import { ANALYTICS_CHAPTERS, ANALYTICS_CLASS, ANALYTICS_CLASS_PROGRESS, ANALYTICS_EXERCISES_ATTEMPTS, ANALYTICS_QUICK_QUIZ, ANALYTICS_SUBJECTS, ANALYTICS_EXAM, ANALYTICS_EXERCISES_ATTEMPTS_EXAM, ANALYTICS_SUBJECTS_EXAM, ANALYTICS_CHAPTERS_EXAM, ANALYTICS_PAPER_ATTEMPTS, ANALYTICS_PAPER } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { ExamExerciseAttemptResults, ExerciseAttemptResults } from "../../models/Analytics/exercise.model";
import { PaperResult } from "../../models/Analytics/paper.model";

export class AnalyticsService {
    //-OLD
    // static fetchClassAnalytics(
    //     courseId: any,
    //     onSuccess: (value: StudentClassResult) => void,
    //     onError: Function
    // ) {
    //     return axiosInstance
    //         .get(generatePath(ANALYTICS_CLASS, { courseId }))
    //         .then(response => {
    //             const result = deserialize(StudentClassResult, response['data']['student_class_result'])
    //             console.log(result);
    //             onSuccess(result)
    //         }).catch(error => {
    //             onError(error)
    //             //Notification({
    //             //               message: "Failed",
    //             //                description: "Problem in fetching analytics!",
    //             //                type: NotificationTypes.ERROR
    //             //         })
    //         })
    // }


    static fetchClassAnalytics(
        courseId: any,
        onSuccess: (value: StudentClassResult) => void,
        onError: Function
    ) {
        return _axiosInstance
            .get(ANALYTICS_CLASS_PROGRESS,{params:{
                CourseId:courseId
            }})
            .then(response => {
                const result = deserialize(StudentClassResult, response['data'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }



    static fetchSubjectAnalytics(
        courseId: any,
        subjectId: any,
        onSuccess: (value: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_SUBJECTS, { courseId, subjectId }))
            .then(response => {
                const result = deserialize(StudentSubjectResult, response['data']['student_subject_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }
    static fetchChapterAnalytics(
        courseId: any,
        chapterId: any,
        onSuccess: (value: StudentSubjectResult) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_CHAPTERS, { courseId, chapterId }))
            .then(response => {
                const result = deserialize(StudentChapterResult, response['data']['student_chapter_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }
    static fetchExerciseAnalytics(
        courseId: any,
        attemptId: any,
        onSuccess: (value: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_EXERCISES_ATTEMPTS, { courseId, attemptId }))
            .then(response => {
                const result = deserialize(ExerciseAttemptResults, response['data']['exercise_attempt_results'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }
    static fetchContentAnalytics(
        courseId: any,
        attemptId: any,
        onSuccess: (values: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_QUICK_QUIZ, { courseId, attemptId }))
            .then(response => {
                const result = deserialize(ContentExerciseAttemptResults, response['data']['content_exercise_attempt_results'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }
    static fetchExamAnalytics(
        courseId: any,
        onSuccess: (value: StudentExamResult) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_EXAM, { courseId }))
            .then(response => {
                const result = deserialize(StudentExamResult, response['data']['exam_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                //Notification({
                //               message: "Failed",
                //                description: "Problem in fetching analytics!",
                //                type: NotificationTypes.ERROR
                //         })
            })
    }
    static fetchExamSubjectAnalytics(
        courseId: any,
        subjectId: any,
        onSuccess: (value: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_SUBJECTS_EXAM, { courseId, subjectId }))
            .then(response => {
                const result = deserialize(ExamSubjectResult, response['data']['exam_subject_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                //Notification({
                //               message: "Failed",
                //                description: "Problem in fetching analytics!",
                //                type: NotificationTypes.ERROR
                //         })
            })
    }
    static fetchExamChapterAnalytics(
        courseId: any,
        chapterId: any,
        onSuccess: (value: ExamChapterResult) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_CHAPTERS_EXAM, { courseId, chapterId }))
            .then(response => {
                const result = deserialize(ExamChapterResult, response['data']['exam_chapter_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                //Notification({
                //               message: "Failed",
                //                description: "Problem in fetching analytics!",
                //                type: NotificationTypes.ERROR
                //         })
            })
    }
    static fetchExamExerciseAnalytics(
        courseId: any,
        attemptId: any,
        onSuccess: (value: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_EXERCISES_ATTEMPTS_EXAM, { courseId, attemptId }))
            .then(response => {
                const result = deserialize(ExamExerciseAttemptResults, response['data']['exam_exercise_attempt_results'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                //Notification({
                //               message: "Failed",
                //                description: "Problem in fetching analytics!",
                //                type: NotificationTypes.ERROR
                //         })
            })
    }
    static fetchPaperAttemptsAnalytics(
        courseId: any,
        attemptId: any,
        onSuccess: (value: any) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_PAPER_ATTEMPTS, { courseId, attemptId }))
            .then(response => {
                const result = deserialize(ExamExerciseAttemptResults, response['data']['paper_attempt_results'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                //Notification({
                //               message: "Failed",
                //                description: "Problem in fetching analytics!",
                //                type: NotificationTypes.ERROR
                //         })
            })
    }
    static fetchPaperAnalytics(
        courseId: any,
        onSuccess: (value: PaperResult) => void,
        onError: Function
    ) {
        return axiosInstance
            .get(generatePath(ANALYTICS_PAPER, { courseId }))
            .then(response => {
                const result = deserialize(PaperResult, response['data']['exam_paper_result'])
                onSuccess(result)
            }).catch(error => {
                onError(error)
                // Notification({
                //     message: "Failed",
                //     description: "Problem in fetching analytics!",
                //     type: NotificationTypes.ERROR
                // })
            })
    }
}

