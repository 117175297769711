import React from "react";

import Heading from "../../../../../shared/components/Heading";
import VideoCard from "../../../../../shared/components/VideoCard";
import UICarousel from "../../../../../shared/components/UICarousel";
import SubscriptionPopup from "../../../../../shared/components/SubscriptionPopup";

import SubIcon from "../../../../../assets/icon/math.svg";
import "./continueWhereYouLeft.scss";
import faker from "faker";
import AuthContainer from "../../../../../store/container/AuthContainer";
import { Course } from "../../../../../models/student.model";
import { FC } from "react";
import { ContentService } from "../../../../../services/ContentService/content.service";
import { useState } from "react";
import { ChapterContent } from "../../../../../models/Chapters/chapter.model";
import { useEffect } from "react";
import { STUDY_MATERIAL } from "../../../../../routes/routeConstants/appRoutes";
import { ContentHistoryService } from '../../../../../services/ContentHistoryService/contentHistory.service';

interface ContinueWhereYouLeftProps {
  type: string;
  currentCourse: Course;
  isTrialCourse: boolean;
  isCourseExpired: boolean
}
const ContinueWhereYouLeft: FC<ContinueWhereYouLeftProps> = ({
  type,
  currentCourse,
  isTrialCourse,
  isCourseExpired
}) => {
  const [contents, setContents] = useState<ChapterContent[]>([]);
  const [infoPopup, setInfoPopup] = useState(false);

  useEffect(() => {
    const fetchContents = () => {
      if (type == "exam") {
        ContentService.getPendingExamChapterContents(
          {
            courseId: currentCourse?.id,
          },
          (contents) => {
            setContents(contents);
          },
          () => { }
        );
      } else if (type == "class_room") {
        ContentHistoryService.getRecentWatchedHistory(
          {
            courseId: currentCourse?.id,
          },
          (contents) => {
            setContents(contents);
          },
          () => {}
        );

      } else {
      }
    };

    if (currentCourse?.id) {
      fetchContents();
    }
  }, [currentCourse, type]);
  return (
    <div className={contents?.length > 4 ? "continue-where-you-left__container" : "continue-where-you-left-no-btn__container"}>
      {contents?.length ?
        <SubscriptionPopup visiblity={infoPopup} closeHandler={() => setInfoPopup(false)}/>
        : null}

      {contents?.length ? <Heading sub>Continue where you left</Heading> : ""}
      <UICarousel
        data={contents.map((value, index) => (
          <VideoCard
            disabled={isTrialCourse || isCourseExpired}
            onSubscription={() => setInfoPopup(true)}
            color="#FD6C00"
            thumbnail={value?.contentLibrary?.thumbnailUrl}
            icon={SubIcon}
            subject={value?.classRoomSubjectName || value?.examSubjectName}
            content={value?.title}
            chapterName={value?.examChapterName || value?.chapterName}
            key={index}
            id={value?.id}
            description={value?.description}
            contentLibrary={value?.contentLibrary}
            contentType={value?.contentLibrary?.contentType}
            chapterId={value?.chapterId || value?.examChapterId}
            subjectId={value?.classRoomSubjectId || value?.examSubjectId}

          />
        ))}
      />
    </div>
  );
};

export default AuthContainer(ContinueWhereYouLeft);
