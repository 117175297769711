import React, { FC, Fragment, ReactElement } from "react";
import { Button, Spin } from "antd";
import "./uiButton.scss";
import { LoadingOutlined } from '@ant-design/icons';
type HtmlType = "button" | "submit" | "reset" | undefined;

interface ButtonProps {
  children: string;
  type?: HtmlType;
  primary?: boolean;
  icon?: ReactElement;
  className?: string;
  clickHandler?: Function;
  info?: boolean;
  center?: boolean;
  ghost?: boolean;
  secondary?: boolean;
  disabled?:boolean;
  loading?: boolean;
  danger?: boolean;
  outline?: boolean;
  success?:boolean
}

const UIButton: FC<ButtonProps> = ({
  children,
  type = "button",
  primary,
  icon,
  className,
  clickHandler,
  info,
  center,
  secondary,
  ghost,
  loading = false,
  danger,
  outline,
  success,
  ...rest
}) => {
  const content = () => {
    if (icon) {
      return (
        <Fragment>
          <span>{children}</span>
          {/* {icon} */}
          {/* {loading ? <LoadingOutlined color="#fff"/> : icon} */}
        </Fragment>
      );
    }
    return children;
  };
  return (
    //${icon && "ui-button__flex"}
    <Button
      className={`ui-button ${className}  ${info && "ui-button__info"} ${
        center && "ui-button__center"
      } ${secondary && "ui-button__secondary"} ${ghost && "ui-button__ghost"} 
      ${danger && "ui-button__danger"} 
      ${outline && "ui-button__outline"}
      ${success && "ui-button__success"}` 
    }
      htmlType={type}
      onClick={(e) => clickHandler && clickHandler(e)}
      loading={loading}
      {...rest}
    >
      {icon ? content() : children}
    </Button>
  );
};

export default UIButton;
