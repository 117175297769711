import { Col, Row } from "antd";
import LoginImage from "../../../assets/images/login.png";
import React, { useCallback, useState, useEffect } from "react";
import "./register.scss";
import RegisterForm from "./RegisterForm";
import { StudentDetails } from "./UserStory/studentDetails";

import { Route, Switch, withRouter } from "react-router-dom";
import StepContainer from "../../../store/container/StepContainer";
import OTPForm from "../Login/OTPForm";
import {
  PROFILES,
  HOME,
  PARENT_REGISTER,
  PARENT_VERIFY,
} from "../../../routes/routeConstants/appRoutes";
import ParentVerification from "./ParentVerification";
import AddStudents from "./Profiles";
import Logo from "../../../assets/images/app-icon-white.svg";
import SignUpImage from "../../../assets/images/signup.svg";
import AddCourse from "../../Home/MyProfileModule/AddCourse";
const Register = (props: any, { history }: any) => {
  const [step, setStep] = useState(0);
  const [otpStep, setOtpStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [isBuilding, setBuilding] = useState(false);
  const [usageType, setUsageType] = useState("");
  const steps = [
    { id: 1, label: "01" },
    { id: 2, label: "02" },
    { id: 3, label: "03" },
    { id: 4, label: "04" },
  ];

  useEffect(() => {
    const { step } = props;
    setCurrentStep(step);
  }, [props]);

  const handleStep = (step: number, mobileNumber: string) => {
    // setOtpStep(step);
    setPhoneNumber("+91" + mobileNumber);
    setCurrentStep(step);
  };

  const handleBoard = (selectedBoard: any) => {
    setSelectedBoard(selectedBoard);
  };

  const onSelectUsage = (value: string) => {
    setUsageType(value);
  };
  /*
  const renderComponent = (step: number) => {
    //console.log(step);
    switch (step) {
      case 0: {
        return <RegisterForm handleStep={handleStep} />;
        break;
      }
      case 0.1: {
        return <OTPForm phoneNumber={phoneNumber} handleStep={handleStep} />;
        break;
      }
      case 1: {
        return <StudentDetails />;
        break;
      }
      case 2: {
        return <UsingPanditji onSelectUsage={onSelectUsage} />;
        break;
      }
      case 3: {
        if (usageType == "exam") {
          return <PrepareExam history={history} usageType={usageType} />;
          break;
        } else {
          return (
            <SchoolBoard handleBoard={handleBoard} usageType={usageType} />
          );
          break;
        }
      }
      case 4: {
        if (usageType == "exam") {
          return (
            <SchoolBoard handleBoard={handleBoard} usageType={usageType} />
          );
          break;
        } else {
          return <ClassForBoard board={selectedBoard} usageType={usageType} />;
          break;
        }
      }
      case 5: {
        if (usageType == "exam") {
          return <ClassForBoard board={selectedBoard} usageType={usageType} />;
          break;
        } else {
          return <PrepareExam history={history} usageType={usageType} />;
          break;
        }
      }
    }
  };
  */

  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={SignUpImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
          <img src={Logo} className="mindjjo__logo" />
          <RegisterForm />
        </div>
      </Col>
    </Row>
    //     <>
    //       {!isBuilding ? (
    //         <div className="register__container">
    //           <Row justify="space-around" className="register-container__wrapper">
    //             <Col span={13}>
    //               <div className="image__wrapper">
    //                 <img src={LoginImage} />
    //               </div>
    //             </Col>
    //             <Col span={8}>
    //               <div className="form__wrapper">
    //               <img src={Logo} className="mindjjo__logo" />

    //                 {/* {currentStep <= 1 ? (
    //                   <img src={Logo} className="mindjjo__logo" />
    //                 ) : (
    //                   <div className="step__counter">
    //                     {steps?.map((value, index) => {
    //                       return (
    //                         <div
    //                           className={`step__item ${
    //                             value.id == currentStep - 1 ? "current__step" : ""
    //                           } ${
    //                             value.id < currentStep - 1 ? "visited__step" : ""
    //                           }`}
    //                         >
    //                           {value.label}
    //                         </div>
    //                       );
    //                     })}
    //                   </div>
    //                 )} */}
    // {/*
    //                 {renderComponent(currentStep)} */}

    //               </div>
    //             </Col>
    //           </Row>
    //         </div>
    //       ) : (
    //         <div className="building__container">
    //           <Row>
    //             <Col span={24}>
    //               <img src={Logo} className="mindjjo__logo building__logo" />
    //             </Col>
    //           </Row>
    //         </div>
    //       )}
    //     </>
  );
};

export default withRouter(StepContainer(Register));
