import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/_axiosInstance";
import {
  MetaModel,
} from "../../models/meta.model";
import { deserialize } from "serializr";

export class MetaService {
  static fetchBoardsMeta(
    onSuccess: (value: MetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.BOARD_MASTER)
      .then((response) => {
        const data = deserialize(
          MetaModel,
          response.data
        ) as MetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static fetchClassRoomsMeta(
    BoardId: number | string,
    onSuccess: (value: MetaModel[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(ApiRoutes.CLASS_ROOMS, { params: { BoardId } })
      .then((response) => {
        const data = deserialize(
          MetaModel,
          response.data
        ) as MetaModel[];
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
