import { Checkbox, Col, Input, Row } from "antd";
import * as React from "react";
import { useState, useEffect } from "react";
import UIButton from "../../../../../shared/components/UIButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./listCourseExams.scss";
import AuthService from "../../../../../services/AuthService/auth.service";
import { useHistory, withRouter } from "react-router-dom";
import { StudentService } from "../../../../../services/StudentService/student.service";
import { CheckboxField } from "../../../../../shared/components/CheckboxField";

const ListCourseExams = () => {
  const [selectedExam, setSelectedExam] = useState("");
  const [exams, setExams] = useState<any>([]);
  const [examsHolder, setExamsHolder] = useState<any>([]);
  const history = useHistory();
  const fetchExams = () => {
    AuthService.listExams(
      (response: any) => {
        setExams(response);
        setExamsHolder(response);
      },
      () => {}
    );
  };

  useEffect(() => {
    fetchExams();
  }, []);

  const onSubmit = () => {
    StudentService.createStudentCourse(
      {
        courseType: "Exam",
        courseId: selectedExam,
      },
      () => {
        history.goBack();
      },
      () => {}
    );
  };
  const onSearch = (value: string) => {
    const filtered = examsHolder.filter((item: any) => {
      const itemName = item.exam.toUpperCase();

      const textData = value.toUpperCase();
      return itemName.indexOf(textData) > -1;
    });

    setExams(filtered);
  };

  return (
    <div className="add-exam-form__container">
      <h1>Preparing for which exam</h1>
      <Row>
        <Col span={24}>
          <Input
            placeholder="Search for exams"
            onChange={(e) => onSearch(e.target.value)}
            bordered={false}
            className="search__input"
          />
        </Col>
      </Row>
      <div className="exams__wrapper">
        {exams.map((value: any, index: number) => {
          return (
            <Row>
              <Col span={24}>
                <div
                  className={`exam-board__card ${
                    selectedExam == value.id ? "exam-card__border" : ""
                  }`}
                  onClick={() => {
                    setSelectedExam(value.id);
                  }}
                >
                  <CheckboxField checked={selectedExam == value.id} />
                  <img
                    alt=""
                    src={value.displayPictureUrl}
                    className="card__icon"
                  />
                  <div className="exam-details__wrapper">
                    <p>{value?.name}</p>
                    <p className="acronym">{value?.acronym}</p>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row>
        <Col className="exam-btn__wrapper" span={24}>
          <UIButton
            type="submit"
            className="mt-5"
            icon={<ArrowRightOutlined />}
            clickHandler={onSubmit}
          >
            Subscribe
          </UIButton>
        </Col>
      </Row>
    </div>
  );
};
export default withRouter(ListCourseExams);
