import { serializable, alias, primitive, object, list } from "serializr";
import { ContentAttemptModel } from "../Content/content.model";

export class ContentLibrary {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("content_type", primitive()))
  contentType?: string;

  @serializable(alias("thumbnail_url", primitive()))
  thumbnailUrl?: string;

  @serializable(alias("s3_attachment_url", primitive()))
  attachmentUrl?: string;

  @serializable(alias("content_url", primitive()))
  contentUrl?: string;

  @serializable(alias("content_exercise_attempt", object(ContentAttemptModel)))
  contentExerciseAttempt?: ContentAttemptModel;
}
export class ChapterContent {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("chaptor_id", primitive()))
  chapterId?: number;

  @serializable(alias("exam_chapter_id", primitive()))
  examChapterId?: number;

  @serializable(alias("chaptor_name", primitive()))
  chapterName?: string;

  @serializable(alias("exam_chapter_name", primitive()))
  examChapterName?: string;

  @serializable(alias("subject_name", primitive()))
  subjectName?: string;

  @serializable(alias("class_room_subject_name", primitive()))
  classRoomSubjectName?: string;

  @serializable(alias("class_room_subject_id", primitive()))
  classRoomSubjectId?: number;

  @serializable(alias("exam_subject_id", primitive()))
  examSubjectId?: number;

  @serializable(alias("exam_subject_name", primitive()))
  examSubjectName?: string;

  @serializable(alias("subject_id", primitive()))
  subjectId?: number;

  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("notes", primitive()))
  notes?: string;

  @serializable(alias("is_completed", primitive()))
  isCompleted?: boolean;

  @serializable(alias("current_student_vote", primitive()))
  currentStudentVote?: string | boolean;

  @serializable(alias("upvote", primitive()))
  upvote?: string | boolean;

  @serializable(alias("total_views", primitive()))
  totalViews?: number;

  @serializable(alias("total_upvotes", primitive()))
  totalVotes?: number;

  @serializable(alias("total_downvotes", primitive()))
  totalDownvotes?: number;

  @serializable(alias("content_library", object(ContentLibrary)))
  contentLibrary?: ContentLibrary;

  @serializable(alias("content_type", primitive()))
  contentType?: string;

  @serializable(alias('is_active', primitive()))
  isactive?: boolean;

}
export class Chapter {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("excercises_count", primitive()))
  exercisesCount?: number;

  @serializable(alias("exam_exercises_count", primitive()))
  examExercisesCount?: number;

  @serializable(alias("completion_percentage", primitive()))
  completionPercentage?: number;

  @serializable(alias("chaptor_contents", list(object(ChapterContent))))
  chapterContents?: ChapterContent[];

  @serializable(alias("exam_chapter_contents", list(object(ChapterContent))))
  examChapterContents?: ChapterContent[];

  @serializable(alias('cummulative_completion_percentage', primitive()))
  cummulativeCompletionPercentage?: number;
  @serializable(alias('exam_exercise_completion_percentage', primitive()))
  examExerciseCompletionPercentage?: number;
  @serializable(alias('exam_content_completion_percentage', primitive()))
  examContentCompletionPercentage?: number;
  @serializable(alias('exercise_completion_percentage', primitive()))
  exerciseCompletionPercentage?: number;
  @serializable(alias('content_completion_percentage', primitive()))
  contentCompletionPercentage?: number;
  @serializable(alias('is_active', primitive()))
  isactive?: boolean;
  @serializable(alias('disabled', primitive()))
  disabled?: boolean;
}

export class Chapters {
  @serializable(alias("chaptors", list(object(Chapter))))
  chapters?: Chapter[];

  @serializable(alias("exam_chapters", list(object(Chapter))))
  examChapters?: Chapter[];
}
