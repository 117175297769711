import React, { FC } from "react";
import PopupModal from "../../../../shared/components/PopupModal";
import "./leaderboardInfo.scss";
import PointsIcon from "../../../../assets/icon/points.svg";
interface LeaderboardInfoProps {
  visible: boolean;
  okHandler: () => void;
  closeHandler: () => void;
}

const LeaderboardInfo: FC<LeaderboardInfoProps> = (props) => {
  const { visible, okHandler, closeHandler } = props;

  return (
    <PopupModal
      visiblity={visible}
      okHandler={okHandler}
      closeHandler={closeHandler}
      className="leaderboard-info"
    >
      <div className="leaderboard-info__container">
        <div className="leaderboard-info__header">
          <img src={PointsIcon} />
          <div className="leaderboard-info-header__content">
            <p className="leaderboard-info-header-content__title">
              Mindjjo Points
            </p>
            <p className="leaderboard-info-header-content__desc">
              you earn points for the following actions
            </p>
          </div>
        </div>
        <div className="leaderboard-info__body">
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+10</p>
            <p className="leaderboard-info-point__desc">
              for completing a content
            </p>
          </div>
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+10</p>
            <p className="leaderboard-info-point__desc">
              for completing all the content in the chapter
            </p>
          </div>
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+10</p>
            <p className="leaderboard-info-point__desc">
              for completing all the chapter in the subject
            </p>
          </div>
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+5</p>
            <p className="leaderboard-info-point__desc">
              for scoring above 80% in quick quiz, excercise practice paper in
              the first attempt
            </p>
          </div>
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+4</p>
            <p className="leaderboard-info-point__desc">
              for scoring between 60 to 80% in quick quiz, excercise practice
              paper in the first attempt
            </p>
          </div>
          <div className="leaderboard-info-point__container">
            <p className="leaderboard-info__point">+3</p>
            <p className="leaderboard-info-point__desc">
              for scoring upto 60% in quick quiz, excercise practice paper in
              the first attempt
            </p>
          </div>
        </div>
      </div>
    </PopupModal>
  );
};

export default LeaderboardInfo;
