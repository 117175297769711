import * as ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/_axiosInstance";
import { AnnouncementModel } from "../../models/Announcement/Announcement.model";
import { PaginationModel } from "../../models/pagination.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class AnnouncementService {

  static fetchAnnouncements(
    queryParams: Object,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ANNOUNCEMENT_LIST, { params: queryParams })
      .then((response) => {
        const data = deserialize(AnnouncementModel, response.data["announcement"]) as AnnouncementModel[];
        const pageMeta = deserialize(PaginationModel, response.data["meta"]);
        
        onSuccess(data, pageMeta);

      })
      .catch((error) => {
        console.log(error);
        
        onError(error);
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAnnouncementInfo(
    AnnouncementId: Number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ANNOUNCEMENT_INFO, { params: { AnnouncementId } })
      .then((response) => {
        const data = deserialize(AnnouncementModel, response.data) as AnnouncementModel;
        onSuccess(data);
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAnnouncement(
    formData: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.CREATE_ANNOUNCEMENT, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.isSuccess) {
          Notification({ message: "Create", description: "Messages create successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.errorMessage, type: NotificationTypes.ERROR });
        }
        onSuccess("");
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }


  static createComment(
    formData: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.ADD_COMMENT, formData).then((response) => {       
        if (response.data.isSuccess) {
          Notification({ message: "Create", description: "Comment add successfully", type: NotificationTypes.SUCCESS });
        } else {
          Notification({ message: "Error", description: response.data.errorMessage, type: NotificationTypes.ERROR });
        }
        onSuccess("");
      })
      .catch((error) => {
        onError();
        Notification({
          message: "Error",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      })
      .finally(() => {
        onFinal();
      });
  }
}
