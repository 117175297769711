import { ClientConfig } from "agora-rtc-sdk-ng";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import {
  JoinLiveClassResponseModel,
  LiveClassModel,
} from "../../../../models/LiveClass/liveClass.model";
import LiveClassService from "../../../../services/LiveClassService/liveClass.service";
import { LoadingComponent } from "../../../../shared/components/LoadingComponent";
import "./liveClassMeeting.scss";
import LiveClassVideoCall from "./LiveClassVideoCall";
import { createClient, createMicrophoneAndCameraTracks, createMicrophoneAudioTrack } from "agora-rtc-react";
import TitleContainer from "../../../../store/container/TitleContainer";
import { ITitleState } from "../../../../store/reducers/titleReducer";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { LIVE_CLASS } from "../../../../routes/routeConstants/appRoutes";

interface LiveClassMeetingProps {
  setTitle: (title: ITitleState) => void;
}
const config: ClientConfig = {
  mode: "rtc",
  codec: "vp8",
};

export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
export const useMicrophoneTrack = createMicrophoneAudioTrack();

const LiveClassMeeting: FC<LiveClassMeetingProps> = (props) => {
  const [channel, setChannel] = useState<JoinLiveClassResponseModel>({});
  const [joinLoading, setJoinLoading] = useState(true);
  const params: any = useParams();
  const history = useHistory();

  const joinLiveClass = () => {
    if (params?.liveClassId) {
      LiveClassService.joinLiveClass(
        params?.liveClassId,
        (response) => {
          setChannel(response);
          setJoinLoading(false);
        },
        (error) => {
          Notification({
            type: NotificationTypes.ERROR,
            message: error,
            description: "Unable to join the live class",
          });
          leaveHandler();
        },
        () => {}
      );
    }
  };

  useEffect(() => {
    joinLiveClass();
  }, []);

  const leaveHandler = () => {
    history.push(LIVE_CLASS)
  };

  return (
    <div className="live-class-meeting">
      {joinLoading ? (
        <LoadingComponent title={"Loading meeting..."} />
      ) : (
        <LiveClassVideoCall leaveCall={leaveHandler} channel={channel} />
      )}
    </div>
  );
};

export default withRouter(TitleContainer(LiveClassMeeting));
