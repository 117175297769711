import { StudentClassResult } from "../../models/Analytics/class.model";

import { CreateReducer } from "../../shared/utils/createReducer";
import { SET_ANALYTICS_DATA } from "../definitions/analyticsConstants";

interface AnalyticsState {
    courseAnalytics?: StudentClassResult;
}

const initState: AnalyticsState = {
    courseAnalytics: {}
};

const AnalyticsReducer = CreateReducer(initState, {
    [SET_ANALYTICS_DATA](state: any, action: any) {
        return {
            ...state,
            courseAnalytics:action.payload
        }
    }
})
export default AnalyticsReducer