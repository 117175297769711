import React, { FC, Fragment } from "react";
import moment from "moment";
import { Col, Row } from "antd";

import { ClockCircleOutlined,CheckCircleTwoTone } from "@ant-design/icons";
import SubjectIcon from "../../../../assets/icon/subject.svg";

import { WorksheetModel } from "../../../../models/Worksheet/worksheet.model";

import "./assignmentCard.scss";



interface AssignmentCardProps {
  data: WorksheetModel;
  isLive?: boolean;
  isJoinLoading?: boolean;
  onClick?: () => void;

}

const AssignmentCard: FC<AssignmentCardProps> = (props) => {
  const { data, isLive, onClick } = props;


  return (
    <Row className={`assignment-card ${isLive && "btn"}`} onClick={onClick}>
      <Col span={21}>
        <div className="assignment-card-date__container">
          <p className="assignment-card__date">
            <span>
              <img src={SubjectIcon} />
            </span>
            <span className="__text">
              <span className="__sub_title">{data?.subjectName}</span>
              <span> {data?.name}{data.turnInStatus&&<CheckCircleTwoTone style={{fontSize:15,marginLeft:5}} twoToneColor="#52c41a" />}</span>
            </span>
          </p>
          <p className="assignment-card__time">
            <ClockCircleOutlined />
            {"Posted on"}:{" "}
            {moment(data?.createdOn).format('MMMM Do YYYY  h:mm a')}
          </p>
        </div>
      </Col>
      <Col span={3}>
        <p className="assignment-card__actions">
          {"Graded "} :{" "}{(data?.studentPoint ?? 0) + " / " + data?.points}
        </p>
      </Col>
    </Row>
  );
};

export default AssignmentCard;
