import React, { FC } from 'react';
import "./subjectCard.scss";

interface SubjectCardProps {
    name?: string,
    completion?: string,
    color?: string,
    icon?: any,
    clickHandler: () => void;
}

const SubjectCard: FC<SubjectCardProps> = ({
    name, completion, color, icon, clickHandler
}) => {
    return (
        <div className="subject-card__container" style={{ background: color }} onClick={clickHandler}>
            <div>
                <img src={icon} alt=""/>
            </div>
            <div className="subject-name__container">
                <p className="name">{name}</p>
                <p className="percentage">{completion}</p>
            </div>
        </div>
    )
}

export default SubjectCard