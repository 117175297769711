import Modal from "antd/lib/modal/Modal";
import React, { FC } from "react";
import "./uIModal.scss";
interface ModalProps {
  children: any;
  noControl?: boolean;
  visible: boolean;
  title?: string;
  subtitle?: string;
  closeHandler?: () => void;
  className?: string;
}
const UIModal: FC<ModalProps> = ({
  children,
  noControl = false,
  visible,
  title,
  subtitle,
  closeHandler,
  className
}) => {
  return (
    <Modal
      className={`ui-modal ${className}`}
      visible={visible}
      onCancel={closeHandler}
      title={null}
      centered
      width={"50%"}
    >
      <p className="ui-modal__title">{title}</p>
      <p className="ui-modal__subtitle">{subtitle}</p>
      {children}
      {!noControl && <div></div>}
    </Modal>
  );
};

export default UIModal;
