import { Col, message, Row } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import "./updatePassword.scss";
import UpdatePasswordImage from "../../../../assets/images/using-mindjjo-for.svg";
import Logo from "../../../../assets/images/app-icon-white.svg";
import { Form, Formik, FormikValues } from "formik";
import { updatePasswordValidation } from "./updatePasswordValidation";
import InputField from "../../../../shared/components/InputField";
import { ArrowRightOutlined } from "@ant-design/icons";
import UIButton from "../../../../shared/components/UIButton";
import { useHistory, useLocation } from "react-router";
import AuthService from "../../../../services/AuthService/auth.service";
import { UpdatePasswordModel } from "../../../../models/user.model";
import useQuery from "../../../../shared/utils/queryParamsHook";
import { LOGIN } from "../../../../routes/routeConstants/appRoutes";
import { isMobile } from "react-device-detect";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import AuthContainer from "../../../../store/container/AuthContainer";

let LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../../assets/${appName}/app-icon-white.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../../assets/images/app-icon-white.svg')} {...rest} />;
  }
}

interface UpdatePasswordProps {
  app_name: String;
  setAppName:Function
 }

const UpdatePassword: FC<UpdatePasswordProps> = (props) => {
  const {app_name, setAppName } = props;
  const [initialValues, setInitialValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showLogin, setLoginVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const formRef = useRef<any>();

  useEffect(() => {
    let hostName = window.location.host.split('.')[0].toLocaleLowerCase()
    sessionStorage.setItem("APP_NAME", hostName);
    setAppName(hostName)
  }, [setAppName])


  const onSubmit = (values: FormikValues) => {
    setLoading(true);
    AuthService.updatePassword(
      {
        newPassword: values?.password,
        resetPasswordToken: query?.get("reset_password_token") ?? "",
        "email": query.get("email")?.replace(/ /gi, "+") ?? "",
      },
      () => {
        message.success("Password updated successfully!");
        formRef.current.resetForm(initialValues);
        setLoginVisibility(true);
      },
      (e) => {
        Notification({
          message: e?.response?.data?.error,
          description: "Please try again!",
          type: NotificationTypes.ERROR,
        });
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <Row className="auth-container__wrapper">
      <Col xl={14} xs={0}>
        <div className="image__wrapper">
          {/* {app_name !== null && <LoadImage className="mindjjo__logo" appName={app_name} />} */}
          <img src={UpdatePasswordImage} />
        </div>
      </Col>
      <Col xl={8} xs={24}>
        <div className="form__wrapper">
        {app_name !== null && <LoadImage className="mindjjo__logo" appName={app_name} />}
          {/* <img src={Logo} className="mindjjo__logo" /> */}
          <div className="update-password">
            <h2>
              {location?.pathname?.includes("reset-password")
                ? "Reset"
                : "Update"}{" "}
              your password
            </h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={updatePasswordValidation}
              innerRef={formRef}
            >
              {({ values, isValid }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <InputField
                        type="password"
                        name="password"
                        placeholder="New Password"
                        className="mt-5"
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="mt-5"
                      />
                    </Col>
                    <Col span={24}>
                      <div className="btn__wrapper">
                        {showLogin && !isMobile ? (
                          <UIButton
                            type="button"
                            icon={<ArrowRightOutlined />}
                            clickHandler={() => {
                              history.push(LOGIN);
                            }}
                          >
                            Proceed to Login
                          </UIButton>
                        ) : (
                          <UIButton
                            type="submit"
                            className="mt-5"
                            loading={loading}
                            icon={<ArrowRightOutlined />}
                          >
                            {location?.pathname?.includes("reset-password")
                              ? "Reset Password"
                              : "Update Password"}
                          </UIButton>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AuthContainer(UpdatePassword);
