import { Badge, Col, Dropdown, Input, Menu, Progress, Row, Select, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { RightCircleOutlined } from "@ant-design/icons";
import "./subjectsAnalyticsCards.scss";
import ScoreIcon from "../../../../assets/icon/reward.svg";
import AccuracyIcon from "../../../../assets/icon/dart.svg";
import SpeedIcon from "../../../../assets/icon/sketch.svg";
import DurationIcon from "../../../../assets/icon/sand-clock.svg";
import { SubjectModel } from "../../../../models/Exam/SubjectModel/subject.model";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import AnalyticsContainer from "../../../../store/container/AnalyticsContainer";
import AuthContainer from "../../../../store/container/AuthContainer";
import { Course } from "../../../../models/student.model";
import { StudentSubjectResult } from "../../../../models/Analytics/subject.model";
import { StudentClassResult } from "../../../../models/Analytics/class.model";

import {
  SearchOutlined,
  RightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import AnalyticsComponent from "../AnalyticsComponent";
import { ExamService } from "../../../../services/ExamService/exam.service";
import { LearnService } from "../../../../services/LearnService/learn.service";
import Search from "antd/lib/input/Search";
import { AnalyticsEmptyScreen } from "../EmptyScreen";
import DropdownField from '../../../../shared/components/DropdownField';
import { Field } from 'formik';

interface SubjectsProps {
  onClick: (id: any, name: any) => void;
  courseAnalytics: StudentClassResult;
  currentCourse: Course;
  type: any;
}
const SubjectsAnalyticsCard: FC<SubjectsProps> = ({
  onClick,
  type,
  courseAnalytics,
  currentCourse,
}) => {
  const [subjects, setSubjects] = useState<SubjectModel[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<any>();
  const [studentSubjectResult, setStudentSubjectResult] = useState<StudentSubjectResult>();
  const [showSearch, setSearchVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const fetchSubjects = (params: any) => {
    if (type == "exam") {
      ExamService.getExamSubjects(
        params,
        currentCourse?.id,
        (response: SubjectModel[]) => {
          setSubjects(response);
          if (response?.length) {
            setSelectedSubject(response[0]);
            fetchSubjectAnalytics(response[0].id);
          }
          setIsLoading(false);
        },
        () => { }
      );
    } else if (type == "class_room" && courseAnalytics?.studentSubjects?.length) {
      // LearnService.getSubjects(params, currentCourse?.id, (response: any) => {
      //   setSubjects(response);
      //   if (response?.length) {
      //     setSelectedSubject(response[0]);
      //     fetchSubjectAnalytics(response[0].id);
      //   }
      // });
      let subjectList = courseAnalytics?.studentSubjects

      if (params?.search?.toUpperCase() !== undefined)
        subjectList = subjectList.filter(element => element.name?.toUpperCase()?.includes(params?.search?.toUpperCase()))

      setSubjects(subjectList);
      if (subjectList.length) {
        setSelectedSubject(subjectList[0]);
        fetchSubjectAnalytics(subjectList[0].id);
      }
      setIsLoading(false);
    }
  };

  const fetchSubjectAnalytics = (subjectId: any) => {
    if (type == "exam") {
      AnalyticsService.fetchExamSubjectAnalytics(
        currentCourse?.id,
        subjectId,
        (result) => {
          setStudentSubjectResult(result);
        },
        () => { }
      );
    } else if (type == "class_room") {
      //old
      // AnalyticsService.fetchSubjectAnalytics(
      //   currentCourse?.id,
      //   subjectId,
      //   (result) => {
      //     //console.log(result);
      //     setStudentSubjectResult(result);
      //   },
      //   () => { }
      // );
      setStudentSubjectResult(courseAnalytics?.studentSubjects?.find(element => element.subjectId == subjectId));
    }
  };

  useEffect(() => {
    fetchSubjects({});
  }, [courseAnalytics?.studentSubjects]);


  return (
    <Row className="list__subjects">
      {subjects.length == 0 && !showSearch && isLoading ? (
        <Spin tip="Loading" size="large">
      </Spin>
      ) : (
        <>
          <Col span={12} style={{ height: "100%" }} className='select-subject'>
            <div className="content__left">
              {showSearch ? (
                <div className="content-left__search">
                  <Input
                    className="content-left-search__input"
                    placeholder={"Search"}
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      fetchSubjects({ search: e.target.value });
                    }}
                  />
                  <CloseOutlined
                    className="content-left-search__icon"
                    onClick={() => {
                      fetchSubjects({});
                      setSearchVisibility(false)
                    }
                    }
                  />
                </div>
              ) : (
                <div className="content-left__header">
                  <p>Subjects</p>
                  <div onClick={() => setSearchVisibility(true)}>
                    <SearchOutlined />
                  </div>
                </div>
              )}

              {subjects?.map((value, index) => {
                return (
                  <div
                    key={"subject__card#" + index + "_" + value.id}
                    className={`subject__card ${selectedSubject?.id == value.id && "subject-card__active"
                      }`}
                    onClick={() => {
                      setSelectedSubject(value);
                      fetchSubjectAnalytics(value?.id);
                    }}
                  >
                    <p className="subject-card__name">{value?.name}</p>
                    <div className="subjects-details__container">
                      <p>
                        {value?.chapterCount || value?.examChapterCount || 0}{" "}
                        Chapters
                      </p>
                      <Badge status="default" className="dull-text" />
                      <div className="progress__wrapper">
                        <Progress showInfo={false} />
                        <p>
                          {(value?.cummulativeCompletionPercentage !==
                            undefined &&
                            value?.cummulativeCompletionPercentage !== null &&
                            value?.cummulativeCompletionPercentage > 0 &&
                            Math.ceil(
                              value?.cummulativeCompletionPercentage
                            )) ||
                            0}
                          % Completed
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col xs={24} xl={12} xxl={12} style={{ height: "100%" }}>
            {subjects.length !== 0 && <div className="content__right">
              <div className="content-right__header">
                <p className='subject-name'>{selectedSubject?.name}</p>
                <Select
                    defaultValue={selectedSubject?.id}
                    style={{ width: 120 }}
                    className='analytics__subject-dropdown'
                    value={selectedSubject?.id}
                    options={subjects.map((sub) => ({
                      value: sub.id,
                      label: sub.name,
                    }))}
                    onChange={(value) => {
                      setSelectedSubject(subjects.find((s) => s.id === value));
                      fetchSubjectAnalytics(value);
                    }}
                  />
                <div
                  className="content-right__btn"
                  onClick={() =>
                    onClick(selectedSubject?.id, selectedSubject?.name)
                  }
                >
                  View by chapter{" "}
                  <span>
                    <RightOutlined />
                  </span>
                </div>
              </div>
              <AnalyticsComponent
                showAttempts={false}
                accuracy={studentSubjectResult?.accuracy}
                contentLearnt={studentSubjectResult?.completedContentCount || 0}
                totalContent={studentSubjectResult?.totalContentCount || 0}
                exerciseAttempted={
                  studentSubjectResult?.numberOfExerciseAttempts || 0
                }
                noOfExerciseAttempted={
                  studentSubjectResult?.numberOfExerciseAttempts || 0
                }
                totalExercise={studentSubjectResult?.totalExerciseCount || 0}
                speed={studentSubjectResult?.averageTimePerQuestion}
                score={studentSubjectResult?.score}
                duration={studentSubjectResult?.exerciseAttemptDuration}
              />
            </div>
            }
          </Col>
        </>
      )}
    </Row>
  );
};
export default AuthContainer(AnalyticsContainer(SubjectsAnalyticsCard));
