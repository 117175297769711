import React, { FC } from "react";
import Modal from "antd/lib/modal/Modal";
import "./popupModal.scss";
import UIButton from "../UIButton";
import { Col, Row } from "antd";

interface PopupModal {
	visiblity: boolean;
	className?: string;
	okHandler: () => void;
	closeHandler: () => void;
	okText?: string;
	closeText?: string;
	withCancel?: boolean;
	buttonDirection?: string
}

const PopupModal: FC<PopupModal> = ({
	children,
	visiblity,
	className,
	okHandler,
	okText,
	closeHandler,
	closeText,
	withCancel = false,
	buttonDirection
}) => {
	return (
		<Modal
			className={`pop-up__modal ${className}`}
			centered
			visible={visiblity}
			destroyOnClose
			onCancel={closeHandler}
		>
			{children}
			{withCancel ?
				buttonDirection === 'row'
					? <Row gutter={[20, 10]}>
						<UIButton
							className="mt-5"
							type="button"
							info
							center
							clickHandler={okHandler}
						>
							{okText || "Okay"}
						</UIButton>
						
						<UIButton
							className="mt-5"
							type="button"
							center
							clickHandler={closeHandler}
							ghost
						>
							{closeText || "Cancel"}
						</UIButton>
					</Row>
					: <Row gutter={[20, 10]}>
						<Col span={12}>
							<UIButton
								className="popup-btn mt-5"
								type="button"
								center
								clickHandler={closeHandler}
								ghost
							>
								{closeText || "Cancel"}
							</UIButton>
						</Col>
						<Col span={12}>
							<UIButton
								className="popup-btn mt-5"
								type="button"
								info
								center
								clickHandler={okHandler}
							>
								{okText || "Okay"}
							</UIButton>
						</Col>
					</Row>
				: (
					<UIButton
						className="popup-btn__okay mt-5"
						type="button"
						info
						center
						clickHandler={okHandler}
					>
						{okText || "Okay"}
					</UIButton>
				)}
		</Modal>
	);
};

export default PopupModal;
