import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import * as React from "react";
import "./studentDetails.scss";
import UIButton from "../../../../../shared/components/UIButton";
import InputField from "../../../../../shared/components/InputField";
import { studentDetailsValidationSchema } from "../../RegisterValidation";
import AuthService from "../../../../../services/AuthService/auth.service";
import { CreateStudent, Student } from "../../../../../models/student.model";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../../../shared/utils/dataFormatConverter";
import ProfileUpload from "../../../../../shared/components/ProfileUpload";
import { generatePath, useHistory } from "react-router";
import { STUDENT_USAGE } from "../../../../../routes/routeConstants/appRoutes";
import { store } from "../../../../../store";
import { SET_CURRENT_PROFILE, SET_STEP } from "../../../../../store/definitions/authConstants";
import Logo from "../../../../../assets/images/app-icon-white.svg";
import SignUpImage from "../../../../../assets/images/signup.svg";

export const StudentDetails = () => {
  const history = useHistory();
  const initialValues = {
    firstName: "",
    dob: "",
    displayPicture: "",
  };

  const onCreateStudent = (values: any) => {
    values["lastName"] = "Joshua";
    values["isCurrent"] = true;
    const serializeData = serialize(CreateStudent, values);
    const jsonData = {
      ...serializeData,
      display_picture: values["displayPicture"],
    };

    const formData = convertJSONToFormData({ student: jsonData });

    AuthService.createStudent(
      formData,
      (student) => {
        store.dispatch({
          type: SET_CURRENT_PROFILE,
          payload: {
            currentProfile: student
          }
        })
        store.dispatch({
          type: SET_STEP,
          payload: {
            step: 2,
            stepPercentage: 10,
          },
        });
        history.push(generatePath(STUDENT_USAGE, { studentId: student?.id }));
      },
      () => {}
    );
  };

  return (
    <Row className="auth-container__wrapper">
      <Col span={14}>
        <div className="image__wrapper">
          <img src={SignUpImage} />
        </div>
      </Col>
      <Col span={8}>
        <div className="form__wrapper">
        <img src={Logo} className="mindjjo__logo" />

          <div className="student-details__container">
            <h1>Student Details</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={studentDetailsValidationSchema}
              onSubmit={(values) => {
                //console.log(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col span={24}>
                      <ProfileUpload
                        onUpload={(file) =>
                          setFieldValue("displayPicture", file)
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        type="text"
                        name="firstName"
                        placeholder="Full Name"
                        className="mt-5"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputField
                        type="date"
                        name="dob"
                        placeholder="Date of Birth"
                        className="mt-5 dob__field"
                        setFieldValue={(date) => setFieldValue("dob", date)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="btn__wrapper">
                        <UIButton
                          type="submit"
                          className="mt-5"
                          icon={<ArrowRightOutlined />}
                          clickHandler={() => onCreateStudent(values)}
                        >
                          Proceed
                        </UIButton>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
};
