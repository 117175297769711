import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(10, "Mobile Number should not be more or less then 10 digits").max(10, "Mobile Number should not be more or less then 10 digits")
    .required('Mobile Number is required!'),

  email: Yup.string().email().required("Mail Id is required!"),
  firstName: Yup.string().required("Student Name is required!"),
  dob: Yup.string().required('Date of Birth is required!')
})


export const studentDetailsValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required!"),
  dob: Yup.string().required('Date of Birth is required!')
})