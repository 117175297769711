import React, { FC, useEffect, useRef, useState } from "react";
import "./profileCard.scss";
import ProfileCardRocket from "../../../../assets/images/profile-card-rocket.svg";
import EditIcon from "../../../../assets/icon/SVG/edit-white.svg";
import SwitchProfileIcon from "../../../../assets/icon/switch-profile-white.svg";
import StudentPlaceholderImage from "../../../../assets/images/placeholder-dark.svg";
import { StudentService } from "../../../../services/StudentService/student.service";
import { Course, Student } from "../../../../models/student.model";
import moment from "moment";

import { CameraOutlined } from "@ant-design/icons";
import { DatePicker, Input } from "antd";
import AuthService from "../../../../services/AuthService/auth.service";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../../shared/utils/dataFormatConverter";
import AuthContainer from "../../../../store/container/AuthContainer";
import SwitchProfile from "../../../../shared/components/SwitchProfile";
interface ProfileCardProps {
  currentCourse: Course;
  // currentProfile: Student;
  user: Student;
}
const ProfileCard: FC<ProfileCardProps> = ({
  currentCourse,
  user,
}) => {
  const [showEdit, setEditVisibility] = useState<boolean>(false);
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<any>(null);
  const [onChangeDp, setOnChangeDp] = useState(false)
  const [showSwitchProfile, setSwitchProfileVisibility] = useState(false);
  const [studentDetails, setStudentDetails] = useState<Student>({}) 
  const onSave = () => {
    const serializeUser = Object.assign(new Student(), studentDetails);
    let payload = serialize(serializeUser);
    if (attachment) {
      payload = {  ...payload, display_picture: attachment  };
    }
    const formData = convertJSONToFormData({student: payload});

    AuthService.updateStudent(
      formData,
      () => {
        setOnChangeDp(false)  
        setEditVisibility(false);
      },
      () => {}
    );
  };

  useEffect(() => {
    setStudentDetails(user)
  }, [user])

  return (
    <div className="profile__card">
        <SwitchProfile
        visible={showSwitchProfile}
        handleCancel={() => setSwitchProfileVisibility(false)}
        handleOK={() => {}}
        setVisible={(value: any) => setSwitchProfileVisibility(value)}
      />
      <div className="profile-card__details">
        <div className="profile-card-details-student-image__container">
          <img
            src={studentDetails?.displayPictureUrl || StudentPlaceholderImage}
            className="profile-card-student__image"
          />
          {showEdit && (
            <div
              className="profile-card-student-image__edit"
              onClick={() => {
                attachmentRef.current?.click();
              }}
            >
              <CameraOutlined />
              <input
                accept="image/*"
                type="file"
                ref={attachmentRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  let file = e.target.files?.length && e.target.files[0];
                  setStudentDetails({
                    ...studentDetails,
                    displayPictureUrl: URL.createObjectURL(file),
                  });
                  setAttachment(file);
                  setOnChangeDp(true)
                }}
              />
            </div>
          )}
        </div>
        <div className="profile-card-details-student-details__container">
          <div className="flex align-center">
            {showEdit ? (
              <>
                <Input
                  className="profile-card-details-name__input"
                  defaultValue={studentDetails?.firstName}
                  onChange={(e) => {
                    setStudentDetails({
                      ...studentDetails,
                      firstName: e.target.value,
                      lastName: ""
                    });
                  }}
                />
                <div
                  className="profile-card-details__save"
                  onClick={() => {
                    onSave();
                  }}
                >
                  <p>Save</p>
                </div>
              </>
            ) : (
              <>
                <p className="profile-card-details-student__name">
                  {studentDetails?.firstName}
                </p>
                <div className="profile-card-details__edit">
                  <img src={EditIcon} onClick={() => setEditVisibility(true)} />
                </div>
              </>
            )}
          </div>

          {showEdit ? (
            <DatePicker
              className="profile-card-details__datepicker"
              onChange={(date) => {
                setStudentDetails({
                  ...studentDetails,
                  dob: date?.format("YYYY-MM-DD"),
                });
              }}
              format={"MMM DD, YYYY"}
              defaultValue={moment(studentDetails?.dob, "YYYY-MM-DD")}
            />
          ) : (
            <p className="profile-card-details-student__dob">
              {studentDetails?.dob &&
                moment(studentDetails?.dob, "YYYY-MM-DD").format(
                  "MMM DD, YYYY"
                )}
            </p>
          )}
        </div>
      </div>
      <div className="profile-card-rocket__container">
        <img src={ProfileCardRocket} className="profile-card-rocket__image" />
      </div>
      {/* <div className="switch-profile__btn" onClick={() => setSwitchProfileVisibility(true)}>
        <span><img src={SwitchProfileIcon}/></span>Switch Profile
      </div> */}
    </div>
  );
};
export default AuthContainer(ProfileCard);
