import { Button, Col, Row, Upload } from "antd";
import React, { FC, useRef } from "react";
import { PaperClipOutlined, CloseCircleOutlined } from "@ant-design/icons";

import "./attachment.scss";
import { ExcAtmptQuestAttchmtModel } from "../../../models/Question/question.model";
interface AttachmentProps {
  enableUpload: boolean;
  onUpload: (file: any) => void;
  data: any;
  handleDelete: (index: number) => void;
}
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const Attachment: FC<AttachmentProps> = ({
  enableUpload,
  onUpload,
  data,
  handleDelete,
}) => {
  const attachmentRef = useRef<HTMLInputElement>(null);
  return enableUpload ? (
    <div className="attachment__container">
      <input
        ref={attachmentRef}
        accept="image/*"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          let file = e.target.files?.length && e.target.files[0];
          onUpload && onUpload(file);
        }}
      />
      <Row>
        {data?.map((value: ExcAtmptQuestAttchmtModel, index: number) => {
          return (
            <Col span={7}>
              <div className="attached-image__container">
                <CloseCircleOutlined
                  className="attached-image__close"
                  onClick={() => {
                    handleDelete && handleDelete(index);
                  }}
                />
                <img
                  src={BASE_URL ? BASE_URL + value.excAtmptQuestAttchmtUrl : ""}
                  className="attached__image"
                />
              </div>
            </Col>
          );
        })}
      </Row>

      <Button
        onClick={() => attachmentRef.current?.click()}
        icon={<PaperClipOutlined />}
      >
        Add Attachments
      </Button>
    </div>
  ) : (
    <div></div>
  );
};
