import axiosInstance from "../../interceptor/axiosInstance";
import _axiosInstance from "../../interceptor/_axiosInstance";
import {
  USER_LOGIN,
  SEND_OTP,
  VERIFY,
  USER_REGISTER,
  UPDATE_USER,
  EXAMS_LIST,
  PARENT_AUTH_SIGNUP,
  PARENT_LOGIN,
  PARENT_AUTH_ME,
  PARENT_AUTH_OTP,
  PARENT_STUDENTS,
  VIEW_STUDENT,
  PARENT_AUTH_INFO,
  AUTH,
  RESET_PASSWORD_EMAIL,
  UPDATE_PASSWORD,
  ME,
  REGISTER_TRIAL,
  STUDENT_PRODUCT_DETAILS
} from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import {
  User,
  UserInfo,
  UserRegister,
  StudentDetails,
  StepUpdate,
  Parent,
  LoginCredentials,
  TokenModel,
  ResetPasswordEmail,
  UpdatePasswordModel,
} from "../../models/user.model";
import { store } from "../../store";
import {
  AUTHENTICATED,
  SET_CURRENT_PROFILE,
  SET_STEP,
  SET_TYPE,
} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Exam, Student } from "../../models/student.model";
import { LocalStorage } from "../../shared/utils/LocalStorageHelpers";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { generatePath } from "react-router";

export default class AuthService {
  static updateStep(
    step: any,
    studentId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializeData = serialize(Student, { step: step });
    const payload = { student: serializeData };

    return axiosInstance
      .put(generatePath(VIEW_STUDENT, { studentId }), payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        //console.log(error);
        Notification({
          message: "Error",
          description: "Please try again",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static listExams(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(EXAMS_LIST)
      .then((response) => {
        const exams = deserialize(Exam, response["data"]["exams"]);
        //console.log(response);
        onSuccess(exams);
      })
      .catch((error) => {
        Notification({
          message: "Error",
          description: "Unable to fetch exams",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      });
  }

  static createParent(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(UserRegister, data);
    const payload = { parent: serializeData };
    return axiosInstance
      .post(PARENT_AUTH_SIGNUP, payload)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Account already exists!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  // static updateParent(data: Parent, onSuccess: Function, onError: Function) {
  //   const serializeData = serialize(Parent, data);
  //   const payload = { parent: serializeData };
  //   return axiosInstance
  //     .put(PARENT_AUTH_INFO, payload)
  //     .then((response) => {
  //       const userDetails = deserialize(Parent, response.data["parent"]);
  //       store.dispatch({
  //         type: AUTHENTICATED,
  //         payload: {
  //           authenticated: true,
  //           user: userDetails,
  //         },
  //       });
  //       LocalStorage.setItem("USER", userDetails);
  //       LocalStorage.setItem("CURRENT_PROFILE", userDetails?.currentStudent);
  //       onSuccess();
  //     })
  //     .catch((error) => {
  //       onError(error);
  //       Notification({
  //         message: "Failed",
  //         description: "Account already exists!",
  //         type: NotificationTypes.ERROR,
  //       });
  //     });
  // }

  static loginParent(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(User, data);
    const payload = { parent: serializeData };
    return axiosInstance
      .post(PARENT_LOGIN, payload)
      .then((response) => {
        const userDetails = deserialize(Parent, response.data["parent"]);
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user: userDetails,
          },
        });
        store.dispatch({
          type: SET_CURRENT_PROFILE,
          payload: {
            currentProfile: userDetails?.currentStudent,
          },
        });
        const token = response.data["token"];
        LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
        LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
        LocalStorage.setItem("USER", userDetails);
        LocalStorage.setItem("CURRENT_PROFILE", userDetails?.currentStudent);

        onSuccess(userDetails);
      })
      .catch((error) => {
        console.log(error);
        onError(error);
        Notification({
          message: "Failed",
          description: "Please enter valid credentials or try again later!",
          type: NotificationTypes.ERROR,
        });
      });
  }

  static getMyInfo(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(PARENT_AUTH_ME)
      .then((response) => {
        onSuccess(response);
      })
      .catch((errror) => {
        onError(errror);
      });
  }

  static sendOTP(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(UserInfo, data);
    const payload = { parent: serializeData };
    return axiosInstance
      .put(PARENT_AUTH_OTP, payload)
      .then(() => {
        onSuccess();
        Notification({
          message: "OTP sent successfully!",
          description:
            "Please enter the OTP below to verify your mobile number",
          type: NotificationTypes.SUCCESS,
        });
      })
      .catch(() => {
        onError();
        Notification({
          message: "Failed",
          description: "Mobile number isn't registered or try again later!",
          type: NotificationTypes.ERROR,
        });
      });
  }

  static verifyUser(
    data: User,
    onSuccess: (parent: Parent) => void,
    onError: Function
  ) {
    const serializeData = serialize(User, data);
    const payload = { parent: serializeData };
    return axiosInstance
      .post(PARENT_LOGIN, payload)
      .then((response) => {
        const userDetails = deserialize(Parent, response.data["parent"]);
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user: userDetails,
          },
        });
        store.dispatch({
          type: SET_CURRENT_PROFILE,
          payload: {
            currentProfile: userDetails?.currentStudent,
          },
        });

        const token = response.data["token"];
        LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
        LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
        LocalStorage.setItem("USER", userDetails);
        LocalStorage.setItem("CURRENT_PROFILE", userDetails?.currentStudent);

        Notification({
          message: "Account created",
          description: "Signed up successfully",
          type: NotificationTypes.SUCCESS,
        });

        onSuccess(userDetails);
      })
      .catch((e) => {
        //console.log(e)
        onError();
        Notification({
          message: "Failed",
          description: "Mobile number isn't registered or try again later!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static createStudent(
    data: any,
    onSuccess: (student: Student) => void,
    onError: Function
  ) {
    return axiosInstance
      .post(PARENT_STUDENTS, data, {})
      .then((response) => {
        const student = deserialize(Student, response["data"]["student"]);
        onSuccess(student);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Cannot create student!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static updateStudent(
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .put(ME, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const student = deserialize(Student, response["data"]["student"]);
        onSuccess(student);
        // const user = store.getState()?.auth?.user;
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            user: student,
          },
        });
        // store.dispatch({
        //   type: SET_CURRENT_PROFILE,
        //   payload: {
        //     currentProfile: student,
        //   },
        // });
        LocalStorage.setItem("USER", student);
        // LocalStorage.setItem("CURRENT_PROFILE", student);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Cannot update student!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static listStudents(onSuccess: (students: any) => void, onError: Function) {
    return axiosInstance
      .get(PARENT_STUDENTS)
      .then((response) => {
        const students = deserialize(Student, response["data"]["students"]);
        onSuccess(students);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Failed",
          description: "Cannot get students!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static loginWithCredentials(
    data: LoginCredentials,
    onSuccess: (student: Student) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = {
      student: serialize(LoginCredentials, { ...data, grantType: "password" }),
    };
    return axiosInstance
      .post(USER_LOGIN, payload)
      .then((response) => {
        const student = deserialize(Student, response.data["student"]);
        const token = deserialize(TokenModel, response.data["token"]);
        //if (student?.confirmedAt) {
          store.dispatch({
            type: AUTHENTICATED,
            payload: {
              authenticated: true,
              user: student,
            },
          });

          LocalStorage.setItem("ACCESS_TOKEN", token?.accessToken);
          LocalStorage.setItem("REFRESH_TOKEN", token?.refreshToken);
          LocalStorage.setItem("USER", student);
        

        onSuccess(student);
      })
      .catch((error) => {
        onError(error);
        Notification({
          message: "Login failed",
          description: "Incorrect email or password",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }
  static sendResetPasswordEmail(
    data: ResetPasswordEmail,
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = { student: serialize(ResetPasswordEmail, data) };
    return axiosInstance
      .post(RESET_PASSWORD_EMAIL, payload)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError();
      })
      .finally(onFinal);
  }
  static updatePassword(
    data: UpdatePasswordModel,
    onSuccess: () => void,
    onError: Function,
    onFinal: () => void
  ) {
    const payload = { student: serialize(UpdatePasswordModel, data) };
    return axiosInstance
      .put(UPDATE_PASSWORD, payload)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
      })
      .finally(onFinal);
  }

  // ------------------- new ----------------
  static registerTrial() {
    let user = LocalStorage.getItem("USER")
    return _axiosInstance.get(REGISTER_TRIAL, {
      params: {
        parentid: user?.id
      }
    })
      .then((response) => {
        localStorage.clear();
      })
      .catch((errror) => {
        Notification({
          message: "Failed",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      });
  }

  static getStudentProductDetails(
    onSuccess: (data: any) => void
  ) {
    return _axiosInstance.get(STUDENT_PRODUCT_DETAILS)
      .then((response) => {
        LocalStorage.setItem("PRODUCT_DETAILS", response?.data);
        onSuccess(response.data);
      })
      .catch((errror) => {
        Notification({
          message: "Failed",
          description: "Something went wrong!",
          type: NotificationTypes.ERROR,
        });
      });
  }

}

