export const countryCodes = [
    { value: '+93', country: 'Afghanistan (+93)', label: '🇦🇫 AF (+93)' },
    { value: '+244', country: 'Angola (+244)', label: '🇦🇴 AO (+244)' },
    { value: '+54', country: 'Argentina (+54)', label: '🇦🇷 AR (+54)' },
    { value: '+374', country: 'Armenia (+374)', label: '🇦🇲 AM (+374)' },
    { value: '+297', country: 'Aruba (+297)', label: '🇦🇼 AW (+297)' },
    { value: '+247', country: 'Ascension Island (+247)', label: '🇦🇨 AC (+247)' },
    { value: '+61', country: 'Australia (+61)', label: '🇦🇺 AU (+61)' },
    { value: '+43', country: 'Austria (+43)', label: '🇦🇹 AT (+43)' },
    { value: '+994', country: 'Azerbaijan (+994)', label: '🇦🇿 AZ (+994)' },
    { value: '+973', country: 'Bahrain (+973)', label: '🇧🇭 BH (+973)' },
    { value: '+880', country: 'Bangladesh (+880)', label: '🇧🇩 BD (+880)' },
    { value: '+32', country: 'Belgium (+32)', label: '🇧🇪 BE (+32)' },
    { value: '+501', country: 'Belize (+501)', label: '🇧🇿 BZ (+501)' },
    { value: '+229', country: 'Benin (+229)', label: '🇧🇯 BJ (+229)' },
    { value: '+975', country: 'Bhutan (+975)', label: '🇧🇹 BT (+975)' },
    { value: '+591', country: 'Bolivia (+591)', label: '🇧🇴 BO (+591)' },
    { value: '+267', country: 'Botswana (+267)', label: '🇧🇼 BW (+267)' },
    { value: '+55', country: 'Brazil (+55)', label: '🇧🇷 BR (+55)' },
    { value: '+246', country: 'British Indian Ocean Territory (+246)', label: '🇮🇴 IO (+246)' },
    { value: '+673', country: 'Brunei (+673)', label: '🇧🇳 BN (+673)' },
    { value: '+226', country: 'Burkina Faso (+226)', label: '🇧🇫 BF (+226)' },
    { value: '+257', country: 'Burundi (+257)', label: '🇧🇮 BI (+257)' },
    { value: '+855', country: 'Cambodia (+855)', label: '🇰🇭 KH (+855)' },
    { value: '+237', country: 'Cameroon (+237)', label: '🇨🇲 CM (+237)' },
    { value: '+238', country: 'Cape Verde (+238)', label: '🇨🇻 CV (+238)' },
    { value: '+236', country: 'Central African Republic (+236)', label: '🇨🇫 CF (+236)' },
    { value: '+235', country: 'Chad (+235)', label: '🇹🇩 TD (+235)' },
    { value: '+56', country: 'Chile (+56)', label: '🇨🇱 CL (+56)' },
    { value: '+86', country: 'China (+86)', label: '🇨🇳 CN (+86)' },
    { value: '+57', country: 'Colombia (+57)', label: '🇨🇴 CO (+57)' },
    { value: '+269', country: 'Comoros (+269)', label: '🇰🇲 KM (+269)' },
    { value: '+242', country: 'Congo (+242)', label: '🇨🇬 CG (+242)' },
    { value: '+682', country: 'Cook Islands (+682)', label: '🇨🇰 CK (+682)' },
    { value: '+506', country: 'Costa Rica (+506)', label: '🇨🇷 CR (+506)' },
    { value: '+243', country: 'Democratic Republic of the Congo (+243)', label: '🇨🇩 CD (+243)' },
    { value: '+253', country: 'Djibouti (+253)', label: '🇩🇯 DJ (+253)' },
    { value: '+670', country: 'East Timor (+670)', label: '🇹🇱 TL (+670)' },
    { value: '+20', country: 'Egypt (+20)', label: '🇪🇬 EG (+20)' },
    { value: '+503', country: 'El Salvador (+503)', label: '🇸🇻 SV (+503)' },
    { value: '+240', country: 'Equatorial Guinea (+240)', label: '🇬🇶 GQ (+240)' },
    { value: '+291', country: 'Eritrea (+291)', label: '🇪🇷 ER (+291)' },
    { value: '+372', country: 'Estonia (+372)', label: '🇪🇪 EE (+372)' },
    { value: '+268', country: 'Eswatini (+268)', label: '🇸🇿 SZ (+268)' },
    { value: '+251', country: 'Ethiopia (+251)', label: '🇪🇹 ET (+251)' },
    { value: '+298', country: 'Faroe Islands (+298)', label: '🇫🇴 FO (+298)' },
    { value: '+679', country: 'Fiji (+679)', label: '🇫🇯 FJ (+679)' },
    { value: '+358', country: 'Finland (+358)', label: '🇫🇮 FI (+358)' },
    { value: '+33', country: 'France (+33)', label: '🇫🇷 FR (+33)' },
    { value: '+689', country: 'French Polynesia (+689)', label: '🇵🇫 PF (+689)' },
    { value: '+241', country: 'Gabon (+241)', label: '🇬🇦 GA (+241)' },
    { value: '+220', country: 'Gambia (+220)', label: '🇬🇲 GM (+220)' },
    { value: '+995', country: 'Georgia (+995)', label: '🇬🇪 GE (+995)' },
    { value: '+49', country: 'Germany (+49)', label: '🇩🇪 DE (+49)' },
    { value: '+233', country: 'Ghana (+233)', label: '🇬🇭 GH (+233)' },
    { value: '+30', country: 'Greece (+30)', label: '🇬🇷 GR (+30)' },
    { value: '+299', country: 'Greenland (+299)', label: '🇬🇱 GL (+299)' },
    { value: '+671', country: 'Guam (+671)', label: '🇬🇺 GU (+671)' },
    { value: '+502', country: 'Guatemala (+502)', label: '🇬🇹 GT (+502)' },
    { value: '+224', country: 'Guinea (+224)', label: '🇬🇳 GN (+224)' },
    { value: '+245', country: 'Guinea-Bissau (+245)', label: '🇬🇼 GW (+245)' },
    { value: '+509', country: 'Haiti (+509)', label: '🇭🇹 HT (+509)' },
    { value: '+504', country: 'Honduras (+504)', label: '🇭🇳 HN (+504)' },
    { value: '+852', country: 'Hong Kong (+852)', label: '🇭🇰 HK (+852)' },
    { value: '+91', country: 'India (+91)', label: '🇮🇳 IN (+91)' },
    { value: '+62', country: 'Indonesia (+62)', label: '🇮🇩 ID (+62)' },
    { value: '+98', country: 'Iran (+98)', label: '🇮🇷 IR (+98)' },
    { value: '+964', country: 'Iraq (+964)', label: '🇮🇶 IQ (+964)' },
    { value: '+353', country: 'Ireland (+353)', label: '🇮🇪 IE (+353)' },
    { value: '+972', country: 'Israel (+972)', label: '🇮🇱 IL (+972)' },
    { value: '+39', country: 'Italy (+39)', label: '🇮🇹 IT (+39)' },
    { value: '+225', country: 'Ivory Coast (+225)', label: '🇨🇮 CI (+225)' },
    { value: '+81', country: 'Japan (+81)', label: '🇯🇵 JP (+81)' },
    { value: '+962', country: 'Jordan (+962)', label: '🇯🇴 JO (+962)' },
    { value: '+7', country: 'Kazakhstan (+7)', label: '🇰🇿 KZ (+7)' },
    { value: '+254', country: 'Kenya (+254)', label: '🇰🇪 KE (+254)' },
    { value: '+686', country: 'Kiribati (+686)', label: '🇰🇮 KI (+686)' },
    { value: '+965', country: 'Kuwait (+965)', label: '🇰🇼 KW (+965)' },
    { value: '+996', country: 'Kyrgyzstan (+996)', label: '🇰🇬 KG (+996)' },
    { value: '+856', country: 'Laos (+856)', label: '🇱🇦 LA (+856)' },
    { value: '+371', country: 'Latvia (+371)', label: '🇱🇻 LV (+371)' },
    { value: '+961', country: 'Lebanon (+961)', label: '🇱🇧 LB (+961)' },
    { value: '+266', country: 'Lesotho (+266)', label: '🇱🇸 LS (+266)' },
    { value: '+231', country: 'Liberia (+231)', label: '🇱🇷 LR (+231)' },
    { value: '+218', country: 'Libya (+218)', label: '🇱🇾 LY (+218)' },
    { value: '+423', country: 'Liechtenstein (+423)', label: '🇱🇮 LI (+423)' },
    { value: '+370', country: 'Lithuania (+370)', label: '🇱🇹 LT (+370)' },
    { value: '+352', country: 'Luxembourg (+352)', label: '🇱🇺 LU (+352)' },
    { value: '+853', country: 'Macau (+853)', label: '🇲🇴 MO (+853)' },
    { value: '+389', country: 'Macedonia (+389)', label: '🇲🇰 MK (+389)' },
    { value: '+261', country: 'Madagascar (+261)', label: '🇲🇬 MG (+261)' },
    { value: '+265', country: 'Malawi (+265)', label: '🇲🇼 MW (+265)' },
    { value: '+60', country: 'Malaysia (+60)', label: '🇲🇾 MY (+60)' },
    { value: '+960', country: 'Maldives (+960)', label: '🇲🇻 MV (+960)' },
    { value: '+223', country: 'Mali (+223)', label: '🇲🇱 ML (+223)' },
    { value: '+356', country: 'Malta (+356)', label: '🇲🇹 MT (+356)' },
    { value: '+692', country: 'Marshall Islands (+692)', label: '🇲🇭 MH (+692)' },
    { value: '+222', country: 'Mauritania (+222)', label: '🇲🇷 MR (+222)' },
    { value: '+230', country: 'Mauritius (+230)', label: '🇲🇺 MU (+230)' },
    { value: '+262', country: 'Mayotte (+262)', label: '🇾🇹 YT (+262)' },
    { value: '+52', country: 'Mexico (+52)', label: '🇲🇽 MX (+52)' },
    { value: '+691', country: 'Micronesia (+691)', label: '🇫🇲 FM (+691)' },
    { value: '+373', country: 'Moldova (+373)', label: '🇲🇩 MD (+373)' },
    { value: '+377', country: 'Monaco (+377)', label: '🇲🇨 MC (+377)' },
    { value: '+976', country: 'Mongolia (+976)', label: '🇲🇳 MN (+976)' },
    { value: '+382', country: 'Montenegro (+382)', label: '🇲🇪 ME (+382)' },
    { value: '+212', country: 'Morocco (+212)', label: '🇲🇦 MA (+212)' },
    { value: '+258', country: 'Mozambique (+258)', label: '🇲🇿 MZ (+258)' },
    { value: '+95', country: 'Myanmar (+95)', label: '🇲🇲 MM (+95)' },
    { value: '+264', country: 'Namibia (+264)', label: '🇳🇦 NA (+264)' },
    { value: '+674', country: 'Nauru (+674)', label: '🇳🇷 NR (+674)' },
    { value: '+977', country: 'Nepal (+977)', label: '🇳🇵 NP (+977)' },
    { value: '+31', country: 'Netherlands (+31)', label: '🇳🇱 NL (+31)' },
    { value: '+687', country: 'New Caledonia (+687)', label: '🇳🇨 NC (+687)' },
    { value: '+64', country: 'New Zealand (+64)', label: '🇳🇿 NZ (+64)' },
    { value: '+505', country: 'Nicaragua (+505)', label: '🇳🇮 NI (+505)' },
    { value: '+227', country: 'Niger (+227)', label: '🇳🇪 NE (+227)' },
    { value: '+234', country: 'Nigeria (+234)', label: '🇳🇬 NG (+234)' },
    { value: '+683', country: 'Niue (+683)', label: '🇳🇺 NU (+683)' },
    { value: '+850', country: 'North Korea (+850)', label: '🇰🇵 KP (+850)' },
    { value: '+47', country: 'Norway (+47)', label: '🇳🇴 NO (+47)' },
    { value: '+968', country: 'Oman (+968)', label: '🇴🇲 OM (+968)' },
    { value: '+92', country: 'Pakistan (+92)', label: '🇵🇰 PK (+92)' },
    { value: '+680', country: 'Palau (+680)', label: '🇵🇼 PW (+680)' },
    { value: '+970', country: 'Palestine (+970)', label: '🇵🇸 PS (+970)' },
    { value: '+507', country: 'Panama (+507)', label: '🇵🇦 PA (+507)' },
    { value: '+675', country: 'Papua New Guinea (+675)', label: '🇵🇬 PG (+675)' },
    { value: '+595', country: 'Paraguay (+595)', label: '🇵🇾 PY (+595)' },
    { value: '+51', country: 'Peru (+51)', label: '🇵🇪 PE (+51)' },
    { value: '+63', country: 'Philippines (+63)', label: '🇵🇭 PH (+63)' },
    { value: '+48', country: 'Poland (+48)', label: '🇵🇱 PL (+48)' },
    { value: '+351', country: 'Portugal (+351)', label: '🇵🇹 PT (+351)' },
    { value: '+974', country: 'Qatar (+974)', label: '🇶🇦 QA (+974)' },
    { value: '+40', country: 'Romania (+40)', label: '🇷🇴 RO (+40)' },
    { value: '+7', country: 'Russia (+7)', label: '🇷🇺 RU (+7)' },
    { value: '+250', country: 'Rwanda (+250)', label: '🇷🇼 RW (+250)' },
    { value: '+590', country: 'Saint Barthélemy (+590)', label: '🇧🇱 BL (+590)' },
    { value: '+290', country: 'Saint Helena (+290)', label: '🇸🇭 SH (+290)' },
    { value: '+508', country: 'Saint Pierre and Miquelon (+508)', label: '🇵🇲 PM (+508)' },
    { value: '+685', country: 'Samoa (+685)', label: '🇼🇸 WS (+685)' },
    { value: '+378', country: 'San Marino (+378)', label: '🇸🇲 SM (+378)' },
    { value: '+239', country: 'Sao Tome and Principe (+239)', label: '🇸🇹 ST (+239)' },
    { value: '+966', country: 'Saudi Arabia (+966)', label: '🇸🇦 SA (+966)' },
    { value: '+221', country: 'Senegal (+221)', label: '🇸🇳 SN (+221)' },
    { value: '+381', country: 'Serbia (+381)', label: '🇷🇸 RS (+381)' },
    { value: '+248', country: 'Seychelles (+248)', label: '🇸🇨 SC (+248)' },
    { value: '+232', country: 'Sierra Leone (+232)', label: '🇸🇱 SL (+232)' },
    { value: '+65', country: 'Singapore (+65)', label: '🇸🇬 SG (+65)' },
    { value: '+421', country: 'Slovakia (+421)', label: '🇸🇰 SK (+421)' },
    { value: '+386', country: 'Slovenia (+386)', label: '🇸🇮 SI (+386)' },
    { value: '+677', country: 'Solomon Islands (+677)', label: '🇸🇧 SB (+677)' },
    { value: '+252', country: 'Somalia (+252)', label: '🇸🇴 SO (+252)' },
    { value: '+27', country: 'South Africa (+27)', label: '🇿🇦 ZA (+27)' },
    { value: '+82', country: 'South Korea (+82)', label: '🇰🇷 KR (+82)' },
    { value: '+211', country: 'South Sudan (+211)', label: '🇸🇸 SS (+211)' },
    { value: '+34', country: 'Spain (+34)', label: '🇪🇸 ES (+34)' },
    { value: '+94', country: 'Sri Lanka (+94)', label: '🇱🇰 LK (+94)' },
    { value: '+249', country: 'Sudan (+249)', label: '🇸🇩 SD (+249)' },
    { value: '+597', country: 'Suriname (+597)', label: '🇸🇷 SR (+597)' },
    { value: '+46', country: 'Sweden (+46)', label: '🇸🇪 SE (+46)' },
    { value: '+41', country: 'Switzerland (+41)', label: '🇨🇭 CH (+41)' },
    { value: '+963', country: 'Syria (+963)', label: '🇸🇾 SY (+963)' },
    { value: '+886', country: 'Taiwan (+886)', label: '🇹🇼 TW (+886)' },
    { value: '+992', country: 'Tajikistan (+992)', label: '🇹🇯 TJ (+992)' },
    { value: '+255', country: 'Tanzania (+255)', label: '🇹🇿 TZ (+255)' },
    { value: '+66', country: 'Thailand (+66)', label: '🇹🇭 TH (+66)' },
    { value: '+228', country: 'Togo (+228)', label: '🇹🇬 TG (+228)' },
    { value: '+690', country: 'Tokelau (+690)', label: '🇹🇰 TK (+690)' },
    { value: '+676', country: 'Tonga (+676)', label: '🇹🇴 TO (+676)' },
    { value: '+216', country: 'Tunisia (+216)', label: '🇹🇳 TN (+216)' },
    { value: '+90', country: 'Turkey (+90)', label: '🇹🇷 TR (+90)' },
    { value: '+993', country: 'Turkmenistan (+993)', label: '🇹🇲 TM (+993)' },
    { value: '+688', country: 'Tuvalu (+688)', label: '🇹🇻 TV (+688)' },
    { value: '+256', country: 'Uganda (+256)', label: '🇺🇬 UG (+256)' },
    { value: '+380', country: 'Ukraine (+380)', label: '🇺🇦 UA (+380)' },
    { value: '+971', country: 'United Arab Emirates (+971)', label: '🇦🇪 AE (+971)' },
    { value: '+44', country: 'United Kingdom (+44)', label: '🇬🇧 GB (+44)' },
    { value: '+1', country: 'United States (+1)', label: '🇺🇸 US (+1)' },
    { value: '+598', country: 'Uruguay (+598)', label: '🇺🇾 UY (+598)' },
    { value: '+998', country: 'Uzbekistan (+998)', label: '🇺🇿 UZ (+998)' },
    { value: '+678', country: 'Vanuatu (+678)', label: '🇻🇺 VU (+678)' },
    { value: '+39', country: 'Vatican City (+39)', label: '🇻🇦 VA (+39)' },
    { value: '+58', country: 'Venezuela (+58)', label: '🇻🇪 VE (+58)' },
    { value: '+84', country: 'Vietnam (+84)', label: '🇻🇳 VN (+84)' },
    { value: '+681', country: 'Wallis and Futuna (+681)', label: '🇼🇫 WF (+681)' },
    { value: '+967', country: 'Yemen (+967)', label: '🇾🇪 YE (+967)' },
    { value: '+260', country: 'Zambia (+260)', label: '🇿🇲 ZM (+260)' },
    { value: '+263', country: 'Zimbabwe (+263)', label: '🇿🇼 ZW (+263)' }
];
