import React,{FC} from "react";
import {Button} from "antd";
import './button.scss';

interface ButtonProps{
    type?: "link"|"text"|"ghost"|"default"|"primary"|"dashed";
    htmlType?: "button"|"submit"|"reset";
    onClick?: () => void;
    buttonContent: any;
    loading?: boolean;
    icon? :any
    disabled?:boolean
    className?: string;
    iconLeft?: any
}

const ButtonComponent:FC<ButtonProps> = (props) => {

    const {type, htmlType, onClick, buttonContent, loading, icon, disabled, className, iconLeft} = props;

    return(
        <Button type={type}
                htmlType={htmlType}
                onClick={onClick}
                loading={loading}
                disabled={disabled}
                className={className}
                icon={iconLeft}
        >
            {buttonContent} {icon ? <img src={icon} alt=''/> : null}
        </Button>
    )
}

export default ButtonComponent;
