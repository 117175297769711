import React, { FC, useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import "./sidebar.scss";
import { sidebarData } from "./sidebar";
import { LocalStorage } from "../../utils/LocalStorageHelpers";
import { Course, Student } from "../../../models/student.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { useHistory, useLocation } from "react-router-dom";

import { CABLE_URL } from "../../../routes/routeConstants/apiRoutes";
import ActionCable from "actioncable";
import platform from "platform";
import { browserName } from "react-device-detect";

import * as routes from "../../../routes/routeConstants/appRoutes";
import activity from "../../../assets/icon/activity.svg";
import activitySelected from "../../../assets/icon/activity-selected.svg";

import announcement from "../../../assets/icon/announcement.svg";
import announcementSelected from "../../../assets/icon/announcement-selected.svg";


import subscription from "../../../assets/icon/course-structure.svg";
import subscriptionSelected from "../../../assets/icon/course-structure-active.svg";
import { BUY_COURSE_LINK, WHITE_LABLED_APPS } from '../../../store/definitions/whiteLabledApps';

let cable: ActionCable.Cable | null = null;
const { Sider } = Layout;
const publicIp = require("public-ip");
interface SidebarProps {
  user: Student;
  currentCourse: Course;
  app_name: String;
  currentProduct: any

}
let listener: any;

let LoadImage = ({ appName, ...rest }) => {
  try {
    return <img src={require(`../../../assets/${appName}/app-icon-blue.svg`)}  {...rest} />;
  }
  catch (err) {
    return <img src={require('../../../assets/images/app-icon-blue.svg')} {...rest} />;
  }
}
const Sidebar: FC<SidebarProps> = ({ user, currentCourse, app_name, currentProduct }) => {
  const history = useHistory();
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState<any>();

  const connectSessionCable = () => {
    if (!cable?.ensureActiveConnection()) {
      cable?.disconnect();
    }

    (async () => {
      try {
        let ipAddress = await publicIp?.v4();
        cable = ActionCable.createConsumer(
          `${CABLE_URL}?access_token=${LocalStorage.getItem(
            "ACCESS_TOKEN"
          )}&operating_system=${platform.os?.family
          }&ip_address=${ipAddress}&browser=${browserName}&course_id=${currentCourse?.course?.classRoom?.id ||
          currentCourse?.course?.exam?.id
          }&course_type=${currentCourse?.course?.type}&student_id=${user?.id
          }`
        );

        cable?.subscriptions?.create(
          {
            channel: "SessionActivityChannel",
            room: `session_activity_${user?.id}`,
          },
          {
            received: (data: any) => { },
          }
        );

        listener = window.addEventListener("blur", () => {
          cable?.disconnect()
          window.removeEventListener("focus", listener)
        }, false);

      } catch (error) { }
    })();
  };

  useEffect(() => {
    if (currentCourse && user) {
      connectSessionCable();
    } else {
      cable?.disconnect();
    }
  }, [currentCourse, user]);

  useEffect(() => {
    window.addEventListener("focus", () => connectSessionCable())
  }, [])

  return (
    <Sider collapsed={false} className="sidebar-pj">
      <div className="logo-wrapper">
        {app_name !== null && <LoadImage className="mindjjo__logo" appName={app_name} />}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["0"]}
        selectedKeys={[location?.pathname?.split("/")[1]]}
      >
        <Menu.ItemGroup>
          {sidebarData?.map(({ icon, text, path, selectedIcon, key }, index) => {
            return (
              <Menu.Item
                key={key}
                icon={
                  <img
                    src={location?.pathname?.includes(key) ? selectedIcon : icon}
                    className="sidebar__icon"
                  />
                }
                onClick={() => {
                  history.push(path);
                }}
                className={`${app_name}`}
              >
                {text}
              </Menu.Item>
            );
          })}

          {WHITE_LABLED_APPS.includes(app_name)
            ? null
            : <Menu.Item
              key={'products'}
              icon={
                <img
                  src={location?.pathname?.includes('products') ? subscriptionSelected : subscription}
                  className="sidebar__icon"
                />
              }
              onClick={() => {
                window.open(BUY_COURSE_LINK, '_blank');
              }}
              className={`${app_name}`}
            >
              {'Buy Course'}
            </Menu.Item>
          }


          {/* {currentProduct.studentType === 'institution'
            ? <> */}
          <Menu.Item
            key={'assignment'}
            icon={
              <img
                src={location?.pathname?.includes('assignment') ? activitySelected : activity}
                className="sidebar__icon"
              />
            }
            onClick={() => {
              history.push(routes.ASSIGNMENT);
            }}
            className={`${app_name}`}
          >
            {'Activity'}
          </Menu.Item>
          <Menu.Item
            key={'announcement'}
            icon={
              <img
                src={location?.pathname?.includes('announcement') ? announcementSelected : announcement}
                className="sidebar__icon"
              />
            }
            onClick={() => {
              history.push(routes.ANNOUNCEMENT);
            }}
            className={`${app_name}`}
          >
            {'Messages'}
          </Menu.Item>

          {/* </>
            : null
          } */}

        </Menu.ItemGroup>
      </Menu>
    </Sider>
  );
};

export default AuthContainer(Sidebar);
