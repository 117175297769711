import { Col, Pagination, Row, Spin } from 'antd';
import React, { FC, Fragment, useEffect, useState } from 'react';
import {
    generatePath,
    useHistory,
    useParams,
    useLocation,
} from 'react-router-dom';
import { Chapter } from '../../../../../models/Chapters/chapter.model';
import {
    ExcerciseAttempt,
    Exercise,
} from '../../../../../models/Exercise/exercise.model';
import { ExerciseService } from '../../../../../services/ExerciseService/exercise.service';
import { initZoro } from '../../../../../shared/utils/initZero';
import { RightCircleFilled } from '@ant-design/icons';
import { ExamService } from '../../../../../services/ExamService/exam.service';
import InstructionPopup from './Instruction';
import QuestionContainer from '../../../../../store/container/QuestionContainer';
import { QuestionReducerProps } from '../../../../../store/reducers/questionReducer';
import './quizes.scss';
import { Question } from '../../../../../models/Question/question.model';
import TimeIcon from '../../../../../assets/icon/time.svg';
import QuestionsIcon from '../../../../../assets/icon/total-questions.svg';
import AttemptsIcon from '../../../../../assets/icon/attempts.svg';
import {
    EXERCISE_EXAM,
    EXERCISE_EXAM_RESULTS,
    PRACTICE_CHAPTERS,
} from '../../../../../routes/routeConstants/appRoutes';
import AuthContainer from '../../../../../store/container/AuthContainer';
import { LocalStorage } from '../../../../../shared/utils/LocalStorageHelpers';
import { ITitleState } from '../../../../../store/reducers/titleReducer';
import TitleContainer from '../../../../../store/container/TitleContainer';

import SubscriptionPopup from '../../../../../shared/components/SubscriptionPopup';
import PaginationComponent from '../../../../../shared/components/PaginationComponent';
interface QuizesProps extends QuestionReducerProps, ITitleState {
    chapter: Chapter;
    type: string;
    isTrialCourse: boolean;
    isCourseExpired: boolean;
}

const getTimeType = (selected: string) => {
    switch (selected) {
        case 'whole_quiz':
            return 'Mins';
        case 'per_question':
            return 'Sec';
        default:
            return '';
    }
};

const Quizes: FC<QuizesProps> = ({
    chapter,
    setQuestions,
    setExamAttempt,
    type,
    onSearch,
    isTrialCourse,
    isCourseExpired,
}) => {
    const params: { subjectId: any } = useParams();
    const history = useHistory();
    const [exercises, setExercises] = useState<any>([]);
    const [infoPopup, setInfoPopup] = useState(false);
    const [attemptId, setAttemptId] = useState<number | undefined>(1);
    const [paginationMeta, setPaginationMeta] = useState<any>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const { state }: { state: any } = useLocation();
    const [loading, setLoading] = useState(false);

    const [popupSubscription, setPopupSubscription] = useState(false);

    const fetchExercises = (queryParams: any) => {
        setLoading(true);
        if (chapter) {
            type == 'exam'
                ? ExamService.getExamExercises(
                      queryParams,
                      {
                          courseId: LocalStorage.getItem('COURSE_ID'),
                          subjectId: params.subjectId,
                          chapterId: chapter?.id,
                      },
                      (exercises: any, meta) => {
                          setExercises(exercises);
                          setPaginationMeta(meta);
                      },
                      () => {},
                      () => {
                          setLoading(false);
                      }
                  )
                : ExerciseService.getExercises(
                      { ...params, search: onSearch, chaptorId: chapter?.id },
                      (exercises: any, meta) => {
                          setExercises(exercises);
                          setPaginationMeta(meta);
                      },
                      () => {},
                      () => {
                          setLoading(false);
                      }
                  );
        }
    };
    useEffect(() => {
        fetchExercises({});
    }, [chapter]);

    useEffect(() => {
        fetchExercises({ search: onSearch });
    }, [onSearch]);

    const handlePageChange = (page: number, pageSize: any) => {
        fetchExercises({ page });
        setCurrentPageNumber(page);
    };

    const createAttempt = (
        excercise_id: number,
        exerciseAttempt?: ExcerciseAttempt
    ) => {
        if (exerciseAttempt && exerciseAttempt?.completedAt) {
            setExamAttempt(exerciseAttempt);
            handleOkInstruction(
                type == 'exam'
                    ? ExerciseService.getExamQuestionsWithAnswers
                    : ExamService.getExamQuestionsWithAnswers,
                exerciseAttempt?.id,
                true
            );
            return;
        }

        if (type == 'exam') {
            const payload = {
                exam_exercise_attempt: {
                    exam_exercise_id: excercise_id,
                },
            };

            ExerciseService.createAttempt(
                { chapterId: chapter?.id as number },
                payload,
                (exerciseAttempt) => {
                    //console.log(exerciseAttempt);
                    setAttemptId(exerciseAttempt?.id);
                    setInfoPopup(true);
                    setExamAttempt(exerciseAttempt);
                },
                () => {
                    exerciseAttempt && setExamAttempt(exerciseAttempt);
                    handleOkInstruction(
                        ExerciseService.getExamQuestions,
                        exerciseAttempt?.id
                    );
                }
            );
        } else {
            const payload = {
                excercise_attempt: {
                    excercise_id,
                },
            };

            ExamService.createAttempt(
                { chaptorId: chapter?.id as number },
                payload,
                (exerciseAttempt) => {
                    setAttemptId(exerciseAttempt?.id);
                    setInfoPopup(true);
                    setExamAttempt(exerciseAttempt);
                },
                () => {
                    exerciseAttempt && setExamAttempt(exerciseAttempt);
                    handleOkInstruction(
                        ExamService.getExamQuestions,
                        exerciseAttempt?.id
                    );
                }
            );
        }
    };

    const handleOkInstruction = (
        Service: any,
        attemptId?: number,
        viewAns?: boolean
    ) => {
        if (attemptId) {
            Service(attemptId, (questions: Question[]) => {
                setQuestions(questions);
                //console.log(questions);
                if (viewAns) {
                    history.push({
                        pathname: generatePath(EXERCISE_EXAM_RESULTS, {
                            id: chapter?.id,
                        }),
                        state: {
                            name: chapter?.name,
                            url: PRACTICE_CHAPTERS,
                            ...params,
                            ...state,
                        },
                    });
                    return;
                }
                setInfoPopup(false);
                history.push({
                    pathname: generatePath(EXERCISE_EXAM, {
                        id: chapter?.id,
                    }),
                    state: {
                        name: chapter?.name,
                        url: PRACTICE_CHAPTERS,
                        ...params,
                        ...state,
                    },
                });
            });
        }
    };

    return (
        <div className='quizes__container'>
            <SubscriptionPopup
                visiblity={popupSubscription}
                closeHandler={() => setPopupSubscription(false)}
            />

            <InstructionPopup
                okHandler={() => {
                    if (type == 'exam') {
                        //console.log(attemptId);
                        handleOkInstruction(
                            ExerciseService.getExamQuestions,
                            attemptId
                        );
                    } else {
                        handleOkInstruction(
                            ExamService?.getExamQuestions,
                            attemptId
                        );
                    }
                }}
                closeHandler={() => setInfoPopup(false)}
                visiblity={infoPopup}
            />
            <div className='quizes__title__wrapper'>
                <h2>Exercise</h2>
                <p>{chapter?.name}</p>
            </div>
            <div className='exercises-list__container'>
                {loading ? (
                    <Spin
                        tip='Fetching exercises...'
                        className='exercise-spin-loader'
                    />
                ) : exercises?.length ? (
                    exercises?.map((exercise: any, index: number) => (
                        <div
                            className='exercise__wrapper'
                            onClick={() => {
                                isTrialCourse || isCourseExpired
                                    ? setPopupSubscription(true)
                                    : createAttempt(
                                          exercise?.id as number,
                                          exercise?.excerciseAttempt
                                      );
                            }}
                        >
                            <Row>
                                <Col xs={4} xl={2} className='mt-1'>
                                    {' '}
                                    <span
                                        className={`question__no ${
                                            exercise?.excerciseAttempt &&
                                            'question__no-viewed'
                                        }`}
                                    >
                                        {initZoro(index + 1, 2)}
                                    </span>
                                </Col>
                                <Col className='question' xs={12} xl={18}>
                                    {exercise?.title}
                                </Col>
                                <Col span={4}>
                                    <RightCircleFilled className='start-quiz' />
                                </Col>
                            </Row>
                            <Row className='exercise-details'>
                                <Col span={2} />
                                <Col span={18}>
                                    <Row>
                                        <Col xl={7} xxl={6} xs={10}>
                                            <p className='question__opt q-time'>
                                                <span>
                                                    <img src={TimeIcon} />
                                                </span>
                                                {`${
                                                    exercise?.time
                                                } ${getTimeType(
                                                    exercise?.timerOptions ?? ''
                                                )}`}
                                            </p>
                                        </Col>
                                        <Col
                                            xl={7}
                                            xxl={6}
                                            xs={12}
                                            className='question__opt'
                                        >
                                            <p className='question__opt q-questions'>
                                                <span>
                                                    <img src={QuestionsIcon} />
                                                </span>
                                                {exercise?.examQuestionsCount ||
                                                    exercise?.questionsCount ||
                                                    0}{' '}
                                                Questions
                                            </p>
                                        </Col>
                                        <Col
                                            xl={7}
                                            xxl={6}
                                            className='question__opt question__opt_attempts'
                                        >
                                            <p className='question__opt q-attempts'>
                                                <span>
                                                    <img src={AttemptsIcon} />
                                                </span>
                                                {exercise?.examExerciseAttemptCount ||
                                                    exercise?.exerciseAttemptCount ||
                                                    0}{' '}
                                                Attempts
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ))
                ) : (
                    <div className='no-exercises-text'>No exercises!</div>
                )}
            </div>
            <PaginationComponent
                handlePageChange={handlePageChange}
                paginationMeta={paginationMeta}
            />
        </div>
    );
};

export default TitleContainer(AuthContainer(QuestionContainer(Quizes)));
