import { Col, Input, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { generatePath, useHistory, useLocation } from "react-router";
import AnalyticsCard from "../ AnalyticsCard";
import { ExerciseAttemptResults } from "../../../../models/Analytics/exercise.model";
import {
  PaperAttemptModel,
  PreviousPaperModel,
} from "../../../../models/Exam/PaperModel/paper.model";
import { Exercise } from "../../../../models/Exercise/exercise.model";
import { Course } from "../../../../models/student.model";
import { AnalyticsService } from "../../../../services/AnalyticsService/analytics.service";
import { PreviousPaperService } from "../../../../services/PreviousPaperService/previouspaper.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import AnalyticsComponent from "../AnalyticsComponent";
import { AnalyticsEmptyScreen } from "../EmptyScreen";
import { SearchOutlined } from "@ant-design/icons";
import "./listPaperAnalytics.scss";
import { PaperResult } from "../../../../models/Analytics/paper.model";
import InstructionPopup from "../../PracticeModule/PracticeChapter/Quizes/Instruction";
import { QuestionReducerProps } from "../../../../store/reducers/questionReducer";
import QuestionContainer from "../../../../store/container/QuestionContainer";
import { Question } from "../../../../models/Question/question.model";
import {
  ANALYTICS,
  PAPER_EXAM,
  PAPER_EXAM_RESULTS,
} from "../../../../routes/routeConstants/appRoutes";
interface ListPaperAnalyticsProps extends QuestionReducerProps {
  currentCourse: Course;
}
const ListPaperAnalytics: FC<ListPaperAnalyticsProps> = ({
  currentCourse,
  setQuestions,
  setExamAttempt,
}) => {
  const [papers, setPapers] = useState<PreviousPaperModel[]>([]);
  const { state }: { state: any } = useLocation();
  const [selectedPaper, setSelectedPaper] = useState<PreviousPaperModel>();
  const [paperAnalytics, setPaperAnalytics] = useState<PaperResult>();
  const [studentPaperResults, setStudentPaperResults] = useState<
    ExerciseAttemptResults[]
  >([]);
  const [selectedResult, setSelectedResult] =
    useState<ExerciseAttemptResults>();
  const [noAttempts, setNoAttempts] = useState(false);
  const fetchPapers = () => {
    PreviousPaperService.fetchPreviousPaperList(
      (response: PreviousPaperModel[], meta) => {
        setPapers(response);
        if (response.length) {
          setSelectedPaper(response[0]);
          fetchStudentPaperResults(response[0]?.paperAttempt?.id);
        }
      },
      (error: any) => {}
    );
  };
  const fetchStudentPaperResults = (attemptId: any) => {
    if (attemptId) {
      AnalyticsService.fetchPaperAttemptsAnalytics(
        currentCourse?.id,
        attemptId,
        (response: ExerciseAttemptResults[]) => {
          setNoAttempts(false);
          setStudentPaperResults(response);
          if (response?.length) {
            setSelectedResult(response[0]);
          }
        },
        () => {}
      );
    } else {
      setNoAttempts(true);
    }
  };

  const fetchPaperAnalytics = () => {
    AnalyticsService.fetchPaperAnalytics(
      currentCourse?.id,
      (response) => {
        setPaperAnalytics(response);
      },
      () => {}
    );
  };

  useEffect(() => {
    fetchPaperAnalytics();
    fetchPapers();
  }, []);
  const history = useHistory();
  const [infoPopup, setInfoPopup] = useState(false);
  const [attemptId, setAttemptId] = useState<number>();
  const handleOkInstruction = (
    Service: any,
    attemptId?: number,
    viewAns?: boolean
  ) => {
    if (attemptId) {
      Service(
        attemptId,
        (questions: Question[]) => {
          setQuestions(questions);
          setInfoPopup(false);
          history.push({
            pathname: generatePath(PAPER_EXAM, {
              id: selectedPaper?.id,
            }),
            state: {
              ...state,
              examName: selectedPaper?.title,
              url: ANALYTICS,
            },
          });
        },
        () => {}
      );
    }
  };
  const createAttempt = (
    paperId: any,
    paperAttempt: PaperAttemptModel | undefined
  ) => {
    if (paperAttempt && paperAttempt.completedAt) {
      setExamAttempt(paperAttempt);
      handleOkInstruction(
        PreviousPaperService.getPaperQuestionsWithAnswers,
        paperAttempt?.id,
        true
      );
      return;
    }
    PreviousPaperService.createPaperAttempt(
      { paperId },
      (response) => {
        setExamAttempt(response);
        setInfoPopup(true);
        setAttemptId(response.id);
      },
      () => {
        paperAttempt && setExamAttempt(paperAttempt);
        handleOkInstruction(
          PreviousPaperService.getPaperQuestions,
          paperAttempt?.id
        );
      }
    );
  };
  return (
    <Row className="list__papers">
      <InstructionPopup
        okHandler={() => {
          handleOkInstruction(
            PreviousPaperService.getPaperQuestions,
            attemptId
          );
        }}
        closeHandler={() => setInfoPopup(false)}
        visiblity={infoPopup}
      />
      {papers.length == 0 ? (
        <AnalyticsEmptyScreen title={"No papers"} />
      ) : (
        <>
          <Col span={12} style={{ height: "100%" }}>
            <div className="content__left">
              <div className="content-left__header">
                <p>Previous Paper</p>
                <div>
                  <SearchOutlined />
                </div>
              </div>
              {papers?.map((value, index) => {
                return (
                  <div
                    className={`subject__card ${
                      selectedPaper?.id == value.id && "subject-card__active"
                    }`}
                    onClick={() => {
                      setSelectedPaper(value);
                      fetchStudentPaperResults(value?.paperAttempt?.id);
                    }}
                  >
                    <div className="subject-card__index">
                      {(index + 1).toString().padStart(2, "0")}
                    </div>
                    <div className="subject-card__details">
                      <p className="subject-card__name">{value?.title}</p>
                      <div className="subjects-details__container">
                        <p>{"0"} Attempts</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col span={12} style={{ height: "100%" }}>
            {noAttempts ? (
              <div className="mtp-15">
                <AnalyticsEmptyScreen
                  title={"No attempts"}
                  buttonText={"Take Test"}
                  showButton
                  clickHandler={() => {
                    createAttempt(
                      selectedPaper?.id,
                      selectedPaper?.paperAttempt
                    );
                  }}
                />
              </div>
            ) : (
              <div className="content__right">
                <div className="content-right__header">
                  <p>{selectedPaper?.title}</p>
                </div>
                <AnalyticsComponent
                  attempts={studentPaperResults}
                  selectedAttempt={selectedResult}
                  showAttempts={true}
                  accuracy={selectedResult?.accuracy}
                  speed={selectedResult?.averageTimePerQuestion}
                  score={selectedResult?.score}
                  duration={selectedResult?.exerciseAttemptDuration}
                  onChangeAttempt={(option: ExerciseAttemptResults) => {
                    setSelectedResult(option);
                  }}
                />
              </div>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};
export default QuestionContainer(AuthContainer(ListPaperAnalytics));
