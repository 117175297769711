import * as React from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from 'antd';
import Error from "../Error";
import './dropdownField.scss';

interface DropdownFieldProps {
    name?: string;
    placeholder?: string;
    mode?: "tags" | "multiple";
    options: any;
    showSearch: boolean;
    value:any;
    onChange: (value: any, option: any) => void;
    optionFilterProp?: string;
    defaultValue?: any;
    className?: string
}

const DropdownField: React.FC<DropdownFieldProps> = (props) => {

    return (
        <div className='dropdown-field'>
           <Select {...props}/>
        </div>
    )
}

export default DropdownField;
