import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Board } from "../../models/Boards/board.model";
import * as apiRoutes from "../../routes/routeConstants/apiRoutes";
export class BoardService {
  static getBoardsList(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    return axiosInstance
      .get(apiRoutes.BOARDS_LIST)
      .then((response) => {
        const data = deserialize(Board, response.data.boards);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(onFinal);
  }
}
